/*
 The maximal time within the client must pay back the loan.

 UNIT: years
 USED IN:
  - Amortization
  - Loan
*/
export const MAX_LOAN_DURATION_IN_YEARS = 10;

/*
 The minimum down payment the client must make.

 UNIT: CHF
 USED IN:
  - Amortization
  - Loan
*/
export const MIN_DOWN_PAYMENT = 0;
