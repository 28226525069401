import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import { getAdminProjectDetails, getProjectById } from '../../../store/project/project.action';
import {
  AdminProjectOverview,
  GetProjectById,
  NplProjectState
} from '../../../store/project/project.types';
import Tabs from '../../../components/Tabs/Tabs';
import TabContent from '../../../components/Tabs/TabContent/TabContent';
import './Project.scss';
import ProjectReview from './ProjectReview/ProjectReview';
import ProjectDetails from '../../Projects/ProjectDetails/ProjectDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { UserRoles } from '../../../store/user/types';

const Project = () => {
  const { projectUuid } = useParams();
  const { t } = useTranslation();
  // const { isOpened, toggle } = useModal();
  const role = useSelector<RootState>((state) => state.userReducer.role) as UserRoles[];

  const [project, setProject] = useState<AdminProjectOverview>({} as AdminProjectOverview);
  const [nplProject, setNplProject] = useState<GetProjectById | undefined>(undefined);

  useEffect(() => {
    getProject();
  }, [projectUuid]);

  const getProject = async () => {
    if (projectUuid) {
      const data = await getAdminProjectDetails(projectUuid);
      data && setProject(data);
      const resp = await getProjectById(projectUuid);
      resp && setNplProject(resp);
    }
  };

  // const isDisabled =
  //   project?.projectStatus === ProjectStatus.CHECK_FAILED ||
  //   project?.projectStatus === ProjectStatus.CHECK_OPEN ||
  //   project?.projectStatus === ProjectStatus.CHECK_APPROVE_REQUESTED;

  const kycNotCompleted =
    nplProject?.project.currentState === NplProjectState.QUESTIONAIRE_SUBMITTED ||
    nplProject?.project.currentState === NplProjectState.QUOTE_APPROVED ||
    nplProject?.project.currentState === NplProjectState.KYC_PASSED;

  const tabs = [
    {
      key: 'projectOverview',
      comp: <ProjectDetails role={role} />
    },
    {
      key: 'projectReview',
      comp: <ProjectReview project={project} nplProject={nplProject} />,
      disabled: kycNotCompleted
    }
    // {
    //   key: 'contracts',
    //   comp: (
    //     <Contracts
    //       projectId={project.id}
    //       electricitySaleIncluded={project.activeModules?.some(
    //         (elem) => elem.type === ModuleType.INVESTERRA_ELECTRICITY_SALES
    //       )}
    //     />
    //   ),
    //   disabled: isDisabled
    // },
    // { key: 'invoices', comp: <Invoices projectId={project.id} />, disabled: isDisabled }
  ];

  return (
    <>
      <div className="project-heading-wrapper">
        <Text as="h2" category="display">
          {t('admin.projectDetails.project')}: {project.id ? project.id : ''}
        </Text>
        {/* {role?.includes(UserRoles.ADMIN) && (
          <Button variation="primary" size="large" dataTest="" onClick={toggle}>
            {t('deleteProject.heading')}
          </Button>
        )} */}
      </div>
      <Tabs className="project-details-tabs">
        {tabs.map((tab, index) => (
          <TabContent
            title={t(`admin.projectDetails.tabs.${tab.key}`)}
            className={tab.key === 'projectReview' ? 'project-details-wrapper' : ''}
            key={index}
            disabled={tab?.disabled}>
            {tab.comp}
          </TabContent>
        ))}
      </Tabs>
      {/* <Modal isOpened={isOpened} close={toggle} title={t('deleteProject.heading')}>
        <DeleteProject closeModal={toggle} projectId={projectUuid} />
      </Modal> */}
    </>
  );
};

export default Project;
