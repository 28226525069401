import api, { kotlinApi } from '../../api/api';
import { HttpStatusCode } from 'axios';
import { PagableResponse, Sort } from '../../types/util.types';
import { toastUtil } from '../../utils/toast.utils';
import {
  PartnerProjectData,
  PartnerCommission,
  RegisterPartner,
  PartnerCompany,
  UpdatePartnerCompany,
  Canton,
  PartnerLevel,
  PartnerCommissionDetails,
  GetPartnersFilter,
  Partner
} from './partners.types';
import { t } from 'i18next';

export const getAllPartners = async ({
  keyword,
  page,
  size = 3,
  sort,
  partnerType,
  cantonCode,
  verified,
  moduleType
}: GetPartnersFilter): Promise<PagableResponse<Partner> | undefined> => {
  try {
    const transformSort = sort
      ?.filter((elem: Sort) => elem.sort)
      .map((item) => `${item.name},${item.sort}`)
      .join('&sort=');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      size,
      page: page - 1,
      verified,
      cantonCode,
      ...(keyword ? { keyword: encodeURIComponent(keyword) } : {}),
      ...(partnerType ? { type: partnerType } : {}),
      ...(moduleType ? { moduleType } : {})
    };

    if (transformSort) {
      params.sort = transformSort;
    }

    const { data } = await kotlinApi.get('/partner/search', { params });

    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getPartnerCommissions = async (): Promise<PartnerCommission[] | undefined> => {
  try {
    const { data } = await api.get('/global-variables/commissions');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateAllCommissions = async (commissions: any): Promise<boolean | undefined> => {
  try {
    const data = await api.put('/global-variables/commissions', commissions);
    return data.status === HttpStatusCode.NoContent;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};

export const getPartnerFinancialOverview = async (
  projectId: number | string,
  solarPotential: number
) => {
  try {
    const { data } = await api.post(
      `/financial-overview/partner/project/${projectId}?solarPotential=${solarPotential}`
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getPartnerProjectDetails = async (
  projectUuid: string,
  userUuid: string
): Promise<PartnerProjectData | undefined> => {
  try {
    const { data } = await api.get(`/projects/${projectUuid}/partner/details`, {
      params: {
        userUuid: userUuid
      }
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const createPartner = async (partner: Partial<RegisterPartner>) => {
  try {
    const { status } = await kotlinApi({
      method: 'POST',
      url: '/partner',
      data: partner
    });
    return HttpStatusCode.Created === status;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    if (err.response.status === 500) return Promise.reject(err);
    if (err.response.data.message === 'Partner already registered') {
      return Promise.reject(err.response.data.message);
    }
    toastUtil('error', err.message);
  }
};

export const getPartnerDetails = async (id: number): Promise<PartnerCompany | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/partner/${id}`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updatePartnerDetails = async (id: number, company: UpdatePartnerCompany) => {
  try {
    await kotlinApi.put(`/partner/${id}`, company);
    toastUtil('success', t('partners.account.companyDataUpdated'));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updatePartnerEmail = async (id: number, email: string) => {
  try {
    const response = await kotlinApi.put(`/partner/${id}/email?email=${email}`);
    return response.status === HttpStatusCode.Ok;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const getAllCantons = async (): Promise<Canton[] | undefined> => {
  try {
    const { data } = await api.get('/cantons');
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updatePartnerLevel = async (partnerId: number, level: PartnerLevel) => {
  try {
    const { status } = await kotlinApi.put(`/partner/${partnerId}/level?level=${level}`);
    return status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const createCustomPartnerLevel = async (
  partnerId: number,
  values: Partial<PartnerCommissionDetails>
) => {
  try {
    const { status } = await kotlinApi.put(`/partner/${partnerId}/custom-level`, values);
    return status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};
