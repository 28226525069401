import { formatDateWithTime } from '../../../utils/formatting.utils';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import Text from '../../Text/Text';
import './UploadedFileDetails.scss';
import downloadIcon from '../../../assets/Icons/download.svg';
import { useTranslation } from 'react-i18next';

interface UploadedFileDetails {
  fileName: string;
  fileAuthor: string;
  uploadedFileTime: Date;
  onClick?: () => void;
}

const UploadedFileDetails = ({
  fileName,
  fileAuthor,
  uploadedFileTime,
  onClick
}: UploadedFileDetails) => {
  const { t } = useTranslation();

  return (
    <div className="uploaded-file-details-wrapper">
      <div className="upload-file-description">
        <Icon name="zip-file" />
        <div>
          <Text className="file-label" as="span" category="label" size="large" variety={1}>
            {fileName}
          </Text>
          <div>
            <Text className="file-label" as="span" category="label" size="large" variety={1}>
              {fileAuthor} | {formatDateWithTime(uploadedFileTime)}
            </Text>
          </div>
        </div>
      </div>
      {onClick && (
        <Button
          variation="transparent"
          size="large"
          dataTest=""
          icon={downloadIcon}
          iconPosition="left"
          onClick={onClick}>
          {t('download')}
        </Button>
      )}
    </div>
  );
};

export default UploadedFileDetails;
