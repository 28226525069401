import './IconText.scss';
import Text from '../Text/Text';
import Icon, { icons } from '../Icon/Icon';

interface IconTextProps {
  icon: string;
  description: string;
  size?: 'small' | 'medium' | 'large';
  clasName?: string;
  onClick?: () => void;
}

const IconText = ({ icon, description, size = 'small', onClick, clasName }: IconTextProps) => {
  return (
    <div className={`icon-text-wrapper ${clasName || ''}`} onClick={onClick}>
      <Icon name={icon as keyof typeof icons} className={'text-icon'} />
      <Text as="span" category="body" size={size}>
        {description}
      </Text>
    </div>
  );
};

export default IconText;
