import './Checklist.scss';
import ChecklistItem, { ChecklistItemType } from './ChecklistItem/ChecklistItem';

interface ChecklistProps<T extends ChecklistItemType> {
  items: T[];
  checked: T[];
  alignment?: 'left' | 'right';
  size?: 'large' | 'medium' | 'small';
  className?: string;
  disabled?: boolean;
  isPrice?: boolean;
  onCheck: (item: T) => void;
}

const Checklist = <T extends ChecklistItemType>({
  items,
  checked,
  alignment = 'right',
  size = 'large',
  className,
  disabled = false,
  onCheck,
  isPrice
}: ChecklistProps<T>) => {
  return (
    <div className={`checklist ${className ? className : ''}`}>
      {items.map((item) => {
        return (
          <ChecklistItem
            key={item.id}
            item={item}
            checked={!!checked.find((checked) => checked.id === item.id)}
            onCheck={onCheck}
            alignment={alignment}
            size={size}
            disabled={disabled}
            isPrice={isPrice}
          />
        );
      })}
    </div>
  );
};

export default Checklist;
