import { ScrollActions } from './types';

export const lockScroll = () => {
  return {
    type: ScrollActions.LOCK
  };
};

export const unlockScroll = () => {
  return {
    type: ScrollActions.UNLOCK
  };
};
