import { useTranslation } from 'react-i18next';
import Text from '../Text/Text';
import './SepLegend.scss';

interface SepLegendType {
  type: string;
  color: 'dark-blue' | 'blue' | 'yellow' | 'orange' | 'red';
}

const SepLegend = () => {
  const { t } = useTranslation();
  const sepLegend: SepLegendType[] = [
    { type: t('sepLegend.low'), color: 'dark-blue' },
    { type: t('sepLegend.medium'), color: 'blue' },
    { type: t('sepLegend.good'), color: 'yellow' },
    { type: t('sepLegend.veryGood'), color: 'orange' },
    { type: t('sepLegend.excelent'), color: 'red' }
  ];

  return (
    <div className="sep-legend-wrapper">
      {sepLegend.map((elem: SepLegendType, index) => (
        <Text as="span" category="body" size="small" className={`legend ${elem.color}`} key={index}>
          {elem.type}
        </Text>
      ))}
    </div>
  );
};

export default SepLegend;
