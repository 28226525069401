import { useTranslation } from 'react-i18next';
import Text from '../../Text/Text';
import './TableHead.scss';

interface TableHeadProps {
  values: string[];
  columnWidths: number[];
  children: JSX.Element | JSX.Element[];
  emptyFirstCell?: boolean;
  hoverEffect?: boolean;
  translationKey?: string;
}

const TableHead = ({
  values,
  children,
  columnWidths,
  emptyFirstCell,
  hoverEffect,
  translationKey
}: TableHeadProps) => {
  const { t } = useTranslation();

  const getClassName = () => `table-wrapper ${hoverEffect ? 'hover-effect' : ''}`;

  return (
    <div className={getClassName()}>
      <table className="table" cellPadding={0} cellSpacing={0}>
        <thead>
          <tr className="table-row-head">
            {emptyFirstCell && <th style={{ width: `${columnWidths[0]}%` }}></th>}
            {values.map((elem: string, index) => (
              <Text
                as="th"
                category="headline"
                size="small"
                variety={1}
                key={index}
                className="table-heading"
                style={{ width: `${columnWidths[emptyFirstCell ? index + 1 : index]}%` }}>
                {translationKey ? t(`${translationKey}.${elem}`) : elem}
              </Text>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default TableHead;
