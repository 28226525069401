import './KycResult.scss';
import KycSuccess from './KycSuccess/KycSuccess';
import KycError from './KycError/KycError';
import { useParams } from 'react-router-dom';

enum kycResultType {
  SUCCESS = 'success',
  ERROR = 'error'
}

const KycResult = () => {
  const { kycResult } = useParams();

  const getKycResult = () => {
    switch (kycResult) {
      case kycResultType.SUCCESS:
        return <KycSuccess />;
      case kycResultType.ERROR:
        return <KycError />;
      default:
        return <></>;
    }
  };
  return <div className="kyc-result-wrapper">{getKycResult()}</div>;
};

export default KycResult;
