import { useTranslation } from 'react-i18next';
import { MaritalStatus } from '../../../store/questionnaire/questionnaire.types';
import Text from '../../../components/Text/Text';
import Radio from '../../../components/Inputs/Radio/Radio';
import './MaritalStatusList.scss';

interface MaritalStatusListProps {
  label: string;
  checkedStatus: string;
  handleCheck: (value: string) => void;
}

const MaritalStatusList = ({ label, checkedStatus, handleCheck }: MaritalStatusListProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Text as="label" category="headline">
        {label}
      </Text>
      <div className="martial-status-checks">
        {Object.values(MaritalStatus).map((status, index) => {
          return (
            <Radio
              key={index}
              value={status}
              label={t(`creditCheck.maritalStatus.${status}`)}
              checked={status === checkedStatus}
              onClick={handleCheck}
              className="marital-status-check"
            />
          );
        })}
      </div>
    </div>
  );
};

export default MaritalStatusList;
