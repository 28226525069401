import './FileSelectorUpload.scss';
import Text from '../../Text/Text';
import Icon from '../../Icon/Icon';
import { useTranslation } from 'react-i18next';

interface FileSelectorUploadProps {
  name: string;
  disable?: boolean;
  className?: string;
  onDelete?: () => void;
  onUpload?: () => void;
}

const FileSelectorUpload = ({
  name,
  disable = false,
  className = '',
  onDelete,
  onUpload
}: FileSelectorUploadProps) => {
  const { t } = useTranslation();

  const getFileSelectorClassName = () =>
    `file-selector-wrapper  ${disable ? 'disabled' : ''} ${className ? className : ''}`;

  return (
    <div className={getFileSelectorClassName()}>
      <Icon className="file-icon" name="file" />
      <Text className="file-selector-label" as="label" category="label" size="large" variety={1}>
        {`${name} ${t('readyForUpload')}`}
      </Text>
      {onUpload && !disable && <Icon name="send-active" onClick={onUpload} />}
      {onDelete && !disable && <Icon name="delete" onClick={onDelete} />}
    </div>
  );
};

export default FileSelectorUpload;
