import { useTranslation } from 'react-i18next';
import './PartnerProjectDetails.scss';
import ContactBox from '../../../../components/ContactBox/ContactBox';
import ModulesAndExtras from '../../../../components/ModulesAndExtras/ModulesAndExtras';
import TooltipHeader from '../../../../components/TooltipHeader/TooltipHeader';
import { PartnerProjectData } from '../../../../store/partners/partners.types';
import { ProjectFinancialOverview } from '../../../../store/project/project.types';
import { getMatchingModules } from '../../../../utils/solarSystem.utils';
import PartnerProjectInfo from './PartnerProjectInfo/PartnerProjectInfo';
import { Module } from '../../../../types/financialOffer.types';

interface PartnerProjectDetailsProps {
  projectDetails?: PartnerProjectData;
  overview?: ProjectFinancialOverview;
}

const PartnerProjectDetails = ({ projectDetails, overview }: PartnerProjectDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div className="partner-project-details-wrapper">
      {projectDetails && (
        <>
          <PartnerProjectInfo data={projectDetails} />
          <div>
            {overview && (
              <ModulesAndExtras
                modules={getMatchingModules(projectDetails.activeModules) as Module[]}
                annualSavings={overview.annualSavings}
              />
            )}
            <TooltipHeader
              heading={t('partnerProjectDetails.customerContactDetails.headline')}
              tooltip={{
                text: t('partnerProjectDetails.customerContactDetails.tooltip.text')
              }}
            />
            <ContactBox
              name={projectDetails.contact.name}
              phone={projectDetails.contact.phoneNumber}
              email={projectDetails.contact.email}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PartnerProjectDetails;
