import { BuildingAddress } from '../types/sepMap.types';

export const format = (value?: number): string => {
  return !value && value !== 0
    ? ''
    : value.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
};

export const toSwissFrancNotation = (amount: number | string, isCurrency = true): string => {
  const decimalSeparator = amount?.toString().includes(',') ? ',' : '.';
  const thosandsSeparator = decimalSeparator === ',' ? '.' : '';
  let amountStringCleaned = amount?.toString().replace(/[^0-9.,]/g, '');
  if (thosandsSeparator) amountStringCleaned = amountStringCleaned?.replace(/\./g, '');
  const amountAsNumber = +amountStringCleaned?.replace(decimalSeparator, '.');
  return isCurrency
    ? amountAsNumber?.toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : amountAsNumber?.toLocaleString('de-CH', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
};

export const formatAddress = (address?: BuildingAddress): string => {
  if (!address) return '';
  return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.municipality}`;
};

export const formatDate = (value?: Date | string): string => {
  try {
    if (!value) return '';
    const date = typeof value === 'string' ? new Date(`${value} Z`) : value;
    if (isNaN(date.getTime())) return '';
    return date.toISOString().slice(0, 10);
  } catch (err) {
    return '';
  }
};

export const formatUnixDate = (date: number) => formatDateWithTime(new Date(date * 1000));

export const formatDateWithDot = (value?: Date | string): string => {
  if (!value) return '';
  const date = value instanceof Date ? value : new Date(value);
  return date
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    .split('/')
    .join('.');
};

export const formatDateWithTime = (value?: Date | string): string => {
  if (!value) return '';
  const date = value instanceof Date ? value : new Date(value);
  const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

  return `${formatDateWithDot(date)}, ${time}`;
};
