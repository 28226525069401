import { useTranslation } from 'react-i18next';
import { BuildingAddress } from '../../types/sepMap.types';
import { formatDateWithDot } from '../../utils/formatting.utils';
import InfoField from '../InfoField/InfoField';
import './UserInfoBox.scss';

interface UserInfoBoxProps {
  id: number | string;
  name: string;
  address: BuildingAddress;
  date: string;
}

const UserInfoBox = ({ id, name, address, date }: UserInfoBoxProps) => {
  const { t } = useTranslation();

  return (
    <div className="user-info-box">
      <div>
        <InfoField className="field" label={t('fullName')} disabled={false} variety={2}>
          {name}
        </InfoField>
        <InfoField className="field" label={t('customerNumber')} disabled={false} variety={2}>
          {id}
        </InfoField>
      </div>
      <InfoField
        className="field"
        label={t('offers.offerDetails.address')}
        disabled={false}
        variety={2}>
        {`${address.street} ${address.houseNumber}, ${address.postalCode} ${address.municipality}`}
      </InfoField>
      <InfoField
        className="field"
        label={t('offers.offerDetails.offerDate')}
        disabled={false}
        variety={2}>
        {formatDateWithDot(date)}
      </InfoField>
    </div>
  );
};

export default UserInfoBox;
