import { toastUtil } from '../../utils/toast.utils';
import {
  QuestionnaireType,
  QuestionnaireFormActionType,
  KYCQuestionaireData,
  ApplicantData,
  FinancialData,
  HouseholdPartnerData,
  HouseholdData
} from './questionnaire.types';
import { t } from 'i18next';
import { Dispatch } from 'react';
import { QuestionnaireFormAction } from '../../reducers/questionnaireReducer';
import { kotlinApi } from '../../api/api';
import { HttpStatusCode } from 'axios';
import { DeepPartial } from 'redux';

export const getQuestionnaire = async (
  id: string
): Promise<Partial<QuestionnaireType> | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/project/${id}/questionnaire`);
    return changeToOneTimeMonthlyExpenses(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updateQuestionnaire = async (
  id: string | string,
  payload: Partial<QuestionnaireType>
) => {
  try {
    await kotlinApi.patch(
      `/project/${id}/questionnaire`,
      changeToOneTimeTriannualExpenses(payload)
    );
    toastUtil('success', t('changesSaved'));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const submitQuestionnaire = async (
  id: string,
  payload: QuestionnaireType
): Promise<boolean> => {
  try {
    const { status } = await kotlinApi.post(
      `/project/${id}/questionnaire`,
      changeToOneTimeTriannualExpenses(payload)
    );
    return status === HttpStatusCode.Ok;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const changeFinancialDetails = (
  e: React.ChangeEvent<HTMLInputElement>,
  dispatch: Dispatch<QuestionnaireFormAction>
) => {
  if (+e.target.value < 0) return;
  dispatch({
    type: QuestionnaireFormActionType.CHANGE_FINANCIAL_DETAILS,
    payload: {
      field: e.target.name as keyof FinancialData,
      value: parseFloat(e.target.value)
    }
  });
};

export const changeHouseholdPartnerFinancialDetails = (
  e: React.ChangeEvent<HTMLInputElement>,
  dispatch: Dispatch<QuestionnaireFormAction>
) => {
  dispatch({
    type: QuestionnaireFormActionType.CHANGE_PARTNER_FINANCIAL_DETAILS,
    payload: {
      field: e.target.name as keyof FinancialData,
      value: parseFloat(e.target.value)
    }
  });
};

export const updateApplicanDetails = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof ApplicantData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  return {
    ...state,
    creditCheckData: {
      ...state.creditCheckData,
      applicant: {
        ...state.creditCheckData?.applicant,
        [key]: value
      }
    }
  };
};

export const updateApplicanCreditCheckFinancialDetails = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof FinancialData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  return {
    ...state,
    creditCheckData: {
      ...state.creditCheckData,
      applicant: {
        ...state.creditCheckData?.applicant,
        financialData: {
          ...(state.creditCheckData?.applicant?.financialData || {}),
          [key]: value
        }
      }
    }
  };
};

export const updateKycQuestionnaireData = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof KYCQuestionaireData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  return {
    ...state,
    kycData: {
      ...state.kycData,
      [key]: value
    }
  };
};

export const updateHouseholdData = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof HouseholdData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  return {
    ...state,
    creditCheckData: {
      ...state.creditCheckData,
      household: {
        ...state.creditCheckData?.household,
        [key]: value
      }
    }
  };
};

export const removeHouseholdDataValues = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof HouseholdData
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...modifiedHouseholdValues } = state.creditCheckData?.household ?? {};

  return {
    ...state,
    creditCheckData: {
      ...state.creditCheckData,
      household: modifiedHouseholdValues
    }
  };
};

export const updateHouseholdPartnerData = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof HouseholdPartnerData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  return {
    ...state,
    creditCheckData: {
      ...state.creditCheckData,
      householdPartner: {
        ...state.creditCheckData?.householdPartner,
        [key]: value
      }
    }
  };
};

export const updatePartnerCreditCheckFinancialDetails = (
  state: DeepPartial<QuestionnaireType>,
  key: keyof FinancialData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  return {
    ...state,
    creditCheckData: {
      ...state.creditCheckData,
      householdPartner: {
        ...state.creditCheckData?.householdPartner,
        financialData: {
          ...(state.creditCheckData?.householdPartner?.financialData || {}),
          [key]: value
        }
      }
    }
  };
};

//TODO: fix this properly, either ask customer for the monthly costs, or do the transformation in the backend
//TODO: I tried using a destructuring pattern but it seems there are some type inconsistencies.
const changeToOneTimeMonthlyExpenses = (questionnaire: Partial<QuestionnaireType>) => {
  return adjustOneTimeExpenses(questionnaire, (it) => it * 36);
};

const changeToOneTimeTriannualExpenses = (questionnaire: Partial<QuestionnaireType>) => {
  return adjustOneTimeExpenses(questionnaire, (it) => it / 36);
};

const adjustOneTimeExpenses = (
  questionnaire: Partial<QuestionnaireType>,
  mutate: (vs: number) => number
): Partial<QuestionnaireType> => {
  const expenses: number | undefined =
    questionnaire.creditCheckData?.applicant.financialData?.monthlyOneTimeExpenses;
  const expensesPartner: number | undefined =
    questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyOneTimeExpenses;

  if (expenses) {
    // eslint-disable-next-line
        questionnaire.creditCheckData!.applicant.financialData.monthlyOneTimeExpenses =
      mutate(expenses);
  }

  if (expensesPartner) {
    // eslint-disable-next-line
        questionnaire.creditCheckData!.householdPartner.financialData!.monthlyOneTimeExpenses =
      mutate(expensesPartner);
  }

  return questionnaire;
};
