import './PartnerDetails.scss';
import PartnerInfo from './PartnerInfo/PartnerInfo';
import Text from '../../../components/Text/Text';
import Tabs from '../../../components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import TabContent from '../../../components/Tabs/TabContent/TabContent';
import { useParams } from 'react-router-dom';
import SinglePartnerCommissions from './SinglePartnerCommissions/SinglePartnerCommissions';
import { useEffect, useState } from 'react';
import { getPartnerDetails } from '../../../store/partners/partners.action';
import { PartnerCompany } from '../../../store/partners/partners.types';

const PartnerDetails = () => {
  const { t } = useTranslation();
  const { partnerId } = useParams();

  const [partnerData, setPartnerData] = useState<PartnerCompany>({} as PartnerCompany);

  const fetchPartnerDetails = async () => {
    if (partnerId) {
      const data = await getPartnerDetails(+partnerId);
      data && setPartnerData(data);
    }
  };

  useEffect(() => {
    fetchPartnerDetails();
  }, []);

  const partnerDetailsTabs = [
    {
      key: 'partnerInfo',
      comp: <PartnerInfo partnerData={partnerData} partnerId={partnerId} />
    },
    {
      key: 'partnerCommissions',
      comp: (
        <SinglePartnerCommissions
          partnerData={partnerData}
          setPartnerData={setPartnerData}
          partnerId={partnerId}
          fetchPartnerDetails={fetchPartnerDetails}
        />
      )
    }
  ];

  return (
    <div className="partner-details-wrapper">
      <Text as="h2" category="display">
        {t('partnerDetails.heading')}
      </Text>
      <Tabs>
        {partnerDetailsTabs.map((tab, index) => (
          <TabContent title={`${t(`partnerDetails.tabs.${tab.key}`)}`} key={index}>
            {tab.comp}
          </TabContent>
        ))}
      </Tabs>
    </div>
  );
};

export default PartnerDetails;
