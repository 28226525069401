import './ChecklistItem.scss';
import Checkbox from '../../Checkbox/Checkbox';
import Text from '../../Text/Text';
import Price from '../../Price/Price';
import { useTranslation } from 'react-i18next';

export type ChecklistItemType = {
  id: number | string;
  label: string;
  displayValue?: number | string;
  unit?: string;
  disabled?: boolean;
};

interface ChecklistItemProps<T extends ChecklistItemType> {
  item: T;
  checked: boolean;
  isPrice?: boolean;
  alignment?: 'left' | 'right';
  size?: 'large' | 'medium' | 'small';
  className?: string;
  disabled?: boolean;
  onCheck: (item: T) => void;
}

const ChecklistItem = <T extends ChecklistItemType>({
  item,
  checked = false,
  alignment = 'right',
  size = 'large',
  disabled = false,
  className,
  onCheck,
  isPrice = false
}: ChecklistItemProps<T>) => {
  const { t } = useTranslation();

  const isDisabled = disabled ? disabled : item.disabled;

  return (
    <div
      className={`checklist-item ${alignment} ${size} ${checked ? 'checked' : ''} ${
        isDisabled ? 'disabled' : ''
      } ${className ? className : ''}`}
      onClick={() => {
        isDisabled ? undefined : onCheck(item);
      }}>
      <div className="labels">
        <Text
          className="checklist-label"
          as="p"
          category="label"
          size={size}
          variety={1}
          disabled={isDisabled}
          style={{ lineHeight: 0 }}>
          {item.label}
        </Text>

        {item.displayValue &&
          (isPrice ? (
            <Text
              className="checklist-value"
              as="span"
              category="headline"
              size={'medium'}
              disabled={isDisabled}
              style={{ lineHeight: 0 }}>
              {item.displayValue}
            </Text>
          ) : (
            <span className={'checklist-price-wrapper'}>
              <Text
                className="checklist-label"
                as="h3"
                category="label"
                size={size}
                variety={1}
                disabled={isDisabled}>
                {t('from')}
              </Text>
              <Price
                className="checklist-value"
                as="span"
                category="headline"
                size={'medium'}
                disabled={isDisabled}
                style={{ lineHeight: 0 }}>
                {item.displayValue ? item.displayValue : ''}
              </Price>
            </span>
          ))}
      </div>
      <Checkbox type="round" checked={checked} disabled={isDisabled} />
    </div>
  );
};

export default ChecklistItem;
