export const de = {
  investerraDetails: {
    name: 'Investera AG',
    street: 'Vadianstrasse',
    streetNumber: '50',
    zipCode: '9001',
    city: 'St. Gallen',
    support: {
      email: 'hallo@investerra.ch',
      phone: '+41 71 571 98 00',
      infoEmail: 'info@investerra.ch'
    }
  },
  loader: { heading: 'Einen kleinen Moment bitte...' },
  header: {
    navigation: {
      calculateOffer: 'Offerte rechnen',
      submitOffer: 'Offerte hochladen',
      ['my_profile']: 'Mein Konto'
    }
  },
  footer: {
    description:
      'Investerra bietet dir einfach und sicher attraktive Konditionen für die Finanzierung deiner Energiewendelösung.',
    contact: {
      label: 'Kontakt aufnehmen',
      number: '+41 71 571 98 00',
      webPage: 'info@investerra.ch',
      contactUs: 'Kontakt'
    },
    info: {
      aboutInvestera: 'Über Investerra',
      contact: 'Kontaktiere uns',
      becomePartner: 'Fachpartner werden',
      faq: 'FAQ',
      insurance: 'Versicherung'
    },
    rules: {
      impresion: 'Impressum',
      dataProtection: 'Datenschutz',
      termsOfUse: 'Nutzungsbedingungen'
    },
    copyright: '©2024, Investerra AG'
  },
  financalOfferPreview: {
    financingOffer: 'Finanzierungsofferte',
    tooltipFinancingOffer:
      'Unsere Offerte für deine Finanzierung. Die Informationen beruhen auf deinen Angaben und unseren Erfahrungswerten.',
    indicative: {
      label: 'Indikativer',
      tooltip:
        'Indikativer Gesamtpreis des Projekts - Kosten die Du für das Projekt einkalkulieren musst.'
    },
    objectPrice: {
      label: 'Objektpreis',
      tooltip:
        'Gesamtpreis des Projekts - Kosten die Du für das Projekt einkalkulieren müsst inkl. den Fördergeldern '
    },
    loan: {
      label: 'Kreditbetrag',
      tooltip: 'Der Kreditbetrag zeigt die benötige Finanzierungssumme. '
    },
    baseFee: {
      label: 'Minimale Basisgebühr',
      tooltip: 'Die Basisgebühr fällt einmalig als Dienstleistungspauschale an.'
    },
    effectiveAnualIntrestRate: {
      label: 'Effektiver Jahreszinssatz',
      tooltip: 'Dein individueller Zins für die Finanzierung deiner Anlage. '
    },
    saleOfElectricityByInvesterra: {
      label: 'Stromverkauf durch Investerra',
      tooltip:
        'Profitiere vom aktuellen Strompreis. Deine Überschussenergie wird von uns direkt am Spotmarkt zu aktuellen Energiepreisen verkauft. ',
      value: ' Rp. 10.5 kWh'
    },

    co2Potential: {
      label: 'Potenzielle CO2-Einsparung',
      tooltip:
        'Mit der Installation einer Solaranlage trägst du zur Reduktion des CO2-Austosses bei. Wir stellen dir eine ungefähre Schätzung deiner Einsparung zur Verfügung, basierend auf Erfahrungswerten.'
    },
    anualSavings: {
      label: 'Jährliche Einsparungen',
      tooltip: 'Einsparungen durch Eigenverbrauch der produzierten Leistung.'
    },
    feedInTariff: {
      label: 'Einspeisevergütung',
      tooltip:
        'Diesen Betrag bekommst du vom Energieversorger für deine Überschussenergie. Also alles was du nicht selber verbrauchen konntest.'
    },
    financialSupport: {
      label: 'Förderung',
      tooltip:
        'Einmalvergütungen für kleine Photovoltaikanlagen. Es handelt sich hierbei um die Bundesförderung. Je nach Wohnort können noch Förderungen auf Kantons- und Gemeindeebne möglich sein.'
    }
  },
  /*** DO NOT TRANSLATE BEGIN ***/
  offerAndFinancingText: {
    calculateOfferNow: 'Jetzt Offerte berechnen',
    calculateOfferHeadline: 'Offerte rechnen',
    energyTransitionTopicQuestion:
      'Du hast dich noch nicht mit dem Thema Energiewende beschäftigt?',
    energyTransitionTopicAnswer: 'Dann zeigen wir dir dein Potenzial über unseren Rechner auf.',
    solarCalculatorDescription:
      'Basierend auf dem Standort deiner Immobilie kann unser Solarrechner bereits einschätzen, ob sich eine oder mehrere Dachflächen für eine Solaranlage eignen. Bei Bedarf helfen wir dir anschliessend bei der Suche nach dem geeigneten Installationspartner.'
  },
  uploadOfferHome: {
    uploadOfferAndCalulate: 'Jetzt Offerte hochladen & Finanzierung berechnen',
    uploadOfferHeadline: 'Offerte hochladen',
    alreadyHaveOffer:
      'Du hast dich schon mit dem Thema Energiewende beschäftigt und bereits eine Offerte vom Fachmann?',
    sugestion: 'Dann können wir dir in wenigen Schritten ein Finanzierungsangebot machen.',
    uploadOffer:
      'Lade einfache die vorhandenen Offerte als PDF hoch und wir berechnen dir direkt und unkompliziert deine persönliche monatliche Finanzierungsrate, die du ganz an deine Bedürfnisse anpassen kannst.'
  },
  /*** DO NOT TRANSLATE END ***/

  module: {
    label: 'Module',
    labelPowerFeed: 'Stromeinspeisung ins Netz',
    selectAdditionalModules: 'Wähle bei Bedarf noch zusätzliche Module aus:',
    selectPowerFeedModule:
      'Investerra kauft den Teil deines PV-Stroms, den du nicht selbst verwendest. Mit der Überschusseinspeisung bekommst du für den eingespeisten Strom attraktive Preise.',
    types: {
      E_CHARGING_STATION: 'E-Ladestation',
      HEAT_PUMP: 'Wärmepumpe',
      STORAGE_SOLUTION: 'Speicherlösung',
      INVESTERRA_ELECTRICITY_SALES: 'Stromverkauf durch Investerra'
    },
    tooltips: {
      E_CHARGING_STATION: 'Ladestation für elektrisches Fahrzeug',
      HEAT_PUMP:
        'Heizungslösung die Wärme aus der Umgebung (Luft, Erdreich oder Grundwasser) aufnimmt und auf ein höheres Temperaturniveau bringt.',
      STORAGE_SOLUTION: 'Energiespeicher für die elektrische Energie.',
      INVESTERRA_ELECTRICITY_SALES:
        'Profitiere vom aktuellen Strompreis. Deine Überschussenergie wird von uns direkt am Spotmarkt zu aktuellen Energiepreisen verkauft. '
    },
    tooltip:
      'Wähle die gewünschten Produkte. Du kannst die Module beliebig kombinieren. Beachte es handelt sich um Richtpreise.',
    tooltipPowerFeed:
      'Profitiere vom aktuellen Strompreis. Deine Überschussenergie wird von uns direkt am Spotmarkt zu aktuellen Energiepreisen verkauft. '
  },
  solarSystem: {
    modalTitle: {
      lowSustainability: 'Es tut uns leid!',
      selectPartner: 'Partner auswählen'
    },
    selectPartnerContent: {
      automaticSelection: 'Automatische Auswahl',
      noPartnersWithThisName: 'Partner konnte nicht gefunden werden',
      selectUpToNumberPartners:
        'Diese Funktion wird gerade weiterentwickelt. Bitte wähle «Investerra». Wir unterstützen dich, den passenden Anbieter zu finden.',
      showMorePartners: 'Mehr Partner anzeigen',
      searchPartner: 'Suhe partner'
    },
    lowSustainabilityModal: {
      text: 'Wir finden an dieser Adresse kein geeignetes Dach für eine Photovoltaik Anlage. Bei Fragen kontaktiere uns unters '
    },
    headingDescription:
      'Basierend auf deinem Standort kann unser Solarrechner einschätzen, ob sich eine oder mehrere Dachflächen für eine Solaranlage eignen. Über unsere Fachpartner kannst du direkt und unverbindlich eine Offerte für dein Projekt anfordern und deine individuelle Finanzierung beantragen.',
    solarPotential: {
      label: 'Solarpotenzial',
      unit: 'kWh / Jahr',
      tooltip:
        'Mit dem Solarpotenzial kannst du schnell erkennen, ob und wie gut deine Dachfläche für Solarenergie geeignet ist.'
    },
    consumptionPotential: {
      label: 'Eigenverbrauchspotenzial',
      prefix: 'ca.',
      tooltip:
        'Das Eigenverbrauchspotenzial legt fest, wie viel der produzierten Energie im eigenen Haushalt verbraucht werden kann.'
    },
    energyClass: {
      label: 'Energieklasse',
      tooltip:
        'Ihr aktueller Status sieht heute in etwa so aus wie im folgenden Bild und reicht insgesamt vom bestmöglichen Bereich dunkelgrün/grün über schlechtere Bereiche, aber mit noch guten Werten wie Gelb bis hin in den wirklich schlechten roten Bereich.'
    },
    sustainability: {
      label: 'Eignung',
      LOW: 'Gering',
      MEDIUM: 'Mittel',
      GOOD: 'Gut',
      VERY_GOOD: 'Sehr Gut',
      EXCELLENT: 'Hervorragend',
      NA: 'Keine Informationen',
      tooltip:
        'Die Eignung wird in einem vierstufigen  Leistungsbewertungsmodell dargestellt. (Sehr Gut, Gut, Ausreichend bis Ungeeignet)'
    },
    financingPlan: {
      label: 'Finanzierungsplan',
      tooltip:
        'Du kannst dein Finanzierungsmodell individuell anpassen oder unseren Vorschlag verwenden.',
      duration: 'Laufzeit',
      deposit: 'Anzahlung',
      indicativePrice: 'Richtpreis pro Monat',
      tooltipIndicativePrice:
        'Der Richtpreis pro Monat setzt sich zusammen aus: Kreditbetrag, effektiver Jahreszins, Laufzeit (Zeitraum für die Rückzahlung), Die Kreditvergabe ist verboten, falls sie zur Überschuldung führt (Art. 3 UWG).',
      prohibitLending:
        'Die Kreditvergabe ist verboten, falls sie zur Überschuldung führt (Art. 3 UWG).'
    },
    pvSystemOutput: {
      label: 'Leistung Photovoltaik-Anlage',
      tooltip: 'Maximale Leistung der Anlage in kWp'
    }
  },
  breadCrumbs: {
    home: 'Home',
    'solar-system': 'Offerte rechnen',
    'upload-offer': 'Offerte hochladen',
    'my-profile': 'Mein Konto',
    'personal-data': 'Persönliche Daten',
    offer: 'Offerten',
    edit: 'Angebot bearbeiten',
    projects: 'Projekte',
    'platform-terms': 'Plattformkonditionen',
    clients: 'Kundenübersicht',
    invoices: 'Rechnungs-Review',
    details: 'Einzelheiten',
    inbox: 'Mein Posteingang',
    partners: 'Partnerübersicht',
    account: 'Mein Konto',
    offers: 'Projektübersicht Installateur'
  },
  auth: {
    registration: 'Registrierung',
    register: 'Registrieren',
    registerHere: 'Hier registrieren',
    createProfileText: 'Um die Finanzierung final zu beantragen, müssen Sie ein Profil anlegen.',
    name: 'Vor-und Nachname',
    password: 'Passwort',
    or: 'oder',
    alreadyHaveAProfile: 'Schon registriert?',
    dontHaveAProfile: 'Noch kein Konto?',
    login: 'Anmelden',
    loginGoogle: 'Google anmelden',
    loginApple: 'Mit Apple anmelden',
    loginHere: 'Hier anmelden',
    signout: 'Ausloggen',
    thankForRegistration: 'Danke für \ndeine Registrierung.',
    toActivateAccount:
      'Dein Konto ist erstellt. Um das Konto zu aktivieren und sicher zu stellen, dass du es bist, ist eine einmalige Bestätigung deiner E-Mail Adresse notwendig.',
    confirmYourEmail: 'Klicke dazu bitte auf den Bestätigungslink in der E-Mail.',
    verifyEmail: 'E-Mail-Überprüfung',
    checkSpamSignUp:
      'Du findest keine E-Mail in deinem Posteingang? Prüfe bitte deinen Spam Ordner.',
    contactUs:
      'Gibt es Probleme beim Erstellen oder Zugriff auf dein Konto? <contactUs>Kontaktiere uns.</contactUs>',
    emailAndPassword: 'E-Mail und Passwort',
    forgotPassword: 'Passwort vergessen',
    passwordReset: {
      title: 'Passwort zurücksetzen',
      pleaseEnterEmail:
        'Bitte gib deine E-Mail Adresse ein, die du bei Investerra verwendest. Du wirst eine E-Mail mit Anweisungen erhalten. Falls du in den nächsten Minuten keine Nachricht in deinem E-Mail Posteingang hast, prüfe bitte deinen Spam Ordner.',
      clickOnTheLink: 'Klicken Sie auf den Link in der E-Mail zum Zurücksetzen des Passworts.',
      sendInstructions: 'Anweisungen zum Zurücksetzen senden',
      emailSent:
        'Die E-Mail mit Anweisungen zum Zurücksetzen des Passwortes ist unterwegs an {{email}}.',
      newPassword: 'Neues Passwort',
      repeatPassword: 'Passwort wiederholen',
      passwordMatching: 'Passwörter müssen übereinstimmen',
      linkExpired: 'Der Link zum Zurücksetzen ist abgelaufen',
      passwordChanged: 'Ihr Passwort wurde erfolgreich geändert!',
      checkSpam:
        'Falls du in den nächsten Minuten keine Nachricht in deinem E-Mail Posteingang findest, prüfe bitte den Spam Ordner.'
    },
    termsAndConditions:
      'Durch meine Registration akzeptiere ich die <termsLink>allgemeinen Nutzungsbedingungen</termsLink> und die <privacyLink>Datenschutzerklärung</privacyLink>',
    errors: {
      nameCanContainOnlyLetters: 'Name darf nur Buchstaben enthalten',
      pleaseEnterValidEmail: 'Bitte eine gültige E-Mail Adresse eingeben',
      emailAllreadyInUse: 'E-Mail bereits im Einsatz',
      passwordValidationError:
        'Das Passwort muss 8 Zeichen lang sein (1 Großbuchstabe, 1 Zahl, 1 Sonderzeichen)',
      pleaseTryAgain:
        'Oje, da hat etwas nicht funktioniert. Bitte versuche es noch einmal.Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal',
      loginError: 'Die E-Mail Adresse oder das Passwort ist nicht korrekt',
      requiredField: 'Dieses Feld ist erforderlich',
      incorrectInput: 'Bitte überprüfe die E-Mail Adresse und das Passwort',
      yourAccountHasBeenCreatedWith:
        'Dein Konto wurde mit {{method}} erstellt. Bitte ändere dein Passwort einmalig.',
      emailNotFound: 'E-Mail nicht gefunden.',
      emailRegistered: 'Diese E-Mail wird registriert über {{method}}',
      invalidPhoneNumber: '+41799999999 - Eingabe mit Landesvorwahl (+41 für Schweiz).',
      wrongPassword: 'Das eingegebene Passwort ist falsch',
      invalidVerificationCode: 'Ungültiger Verifizierungscode',
      confirmPasswordError: 'Die Passwörter stimmen nicht überein!',
      verificationLinkExpired: 'Ihr Verifizierungslink ist abgelaufen.',
      invalidVerificationLink: 'Ihr Verifizierungslink ist ungültig oder wurde bereits verwendet.',
      partnerAlreadyRegistered: {
        heading: 'Diese Firma ist bereits registriert',
        text: 'Diese Firma ist bereits bei Investerra registriert. Bitte wende dich an deine interne Administration um die Logindaten zu erhalten. Mit den Logindaten kannst du dich <contactLink> hier</contactLink> anmelden.'
      },
      somethingWentWrongRegistration:
        'Es tut uns leid – bei der Registrierung und Anmeldung ist etwas schief gelaufen. Bitte kontaktiere uns direkt unter <emailLink>hallo@investerra.ch</emailLink>.',
      loginFailed: 'Anmeldung fehlgeschlagen'
    }
  },
  requestOfferTitle: {
    selectPartner: 'Partner auswählen',
    offerRequestedNotification: 'Offerte beantragt',
    registration: 'Registrierung'
  },
  emailConfirmation: {
    accountVerified: 'Konto verifiziert',
    verificationSuccessful: 'Konto Verifizierung war erfolgreich.',
    continueToFunding: 'Weiter zum Finanzierungsantrag',
    offerAvailableGetsNotification:
      'Du wirst via E-Mail benachrichtigt, sobald die Offerte verfügbar ist.',
    dataSentToPartners:
      'Deine Anfrage wurde an den Fachpartner übermittelt, eine Installationsofferte wird erstellt.',
    checkYourSpamOffer:
      'Solltest du in den nächsten Tagen keine E-Mail in deinem Posteingang finden, prüfe bitte deinen Spam Ordner.',
    financingRequestSent:
      'Deine Offerte wurde an Investerra übermittelt. Die Prüfung für dein Finanzierungsangebot wurde gestartet.',
    willBeNotifiedInTwoDays:
      'Du wirst innerhalb der nächsten zwei Arbeitstage benachrichtigt, sobald dein Angebot verfügbar ist. '
  },
  uploadOffer: {
    title: 'Offerte hochladen',
    description:
      'Bitte lade die Offerte des Installateurs für deine Solaranlage hier hoch, um einen Finanzierungsvorschlag zu erhalten.',
    dragFileHere:
      'Datei einfach in diesen Bereich ziehen oder via Klick auf untenstehenden Link hochladen',
    qr: {
      title: 'Offerte jetzt mobil einscannen',
      description: {
        doNotHaveOfferOficial: 'Du hast deine Offerte noch nicht digital vorliegen?',
        scanQrCode:
          'Kein Prolem - scanne mit deinem Mobiltelefon einfach den QR-Code und folge den Anweisungen.'
      }
    },
    pending: {
      title: 'Daten werden übermittelt...',
      description:
        'Die Offerte wird hochgeladen und verarbeitet. Ihre Finanzierungsofferte wird generiert. Dies kann einige Minuten in Anspruch nehmen.'
    },
    success: {
      title: 'Daten erfolgreich übermittelt',
      description: 'Deine Daten wurden erfolgreich hochgeladen. Bitte prüfe die erfassten Daten',
      troubleUploading: 'Schwierigkeiten beim Upload? <contactLink>Kontaktiere uns!</contactLink>',
      buttonLabel: 'Weiter'
    },
    error: {
      title: 'Das Dokument konnte nicht verarbeitet werden!',
      description:
        'Oops, da hat leider etwas nicht funktioniert. Beim Upload ist ein Problem aufgetreten - das Dokument konnte nicht verarbeitet werden. Bitte lade das Dokument erneut hoch oder scanne deine Offerte direkt mit deinem mobilen Endgerät, in dem du den QR-Code einscannst. Vielen Dank.'
    },
    errorType: {
      title: 'Vielen Dank',
      description: 'Beachte, im Moment funktioniert nur ein PDF Upload',
      buttonLabel: 'Erneut versuchen'
    },
    searchHereForAddress: 'Suche Objektadresse'
  },
  partnerOfferView: {
    projectDetails: 'Details zum Projekt',
    mandatoryFieldsShouldBePopulated: 'Alle Pflichtfelder sollten ausgefüllt sein.'
  },
  currency: {
    chf: 'CHF'
  },
  uploadOfferEdit: {
    projectDetails: 'Details zum Projekt',
    allFieldsRequired:
      'Die mit einem * gekennzeichneten Felder sind Pflicht. Bitte fülle diese aus, um dein Angebot zu erhalten.',
    thankYou: 'Vielen Dank',
    maintenance: 'Wartungshinweis',
    maintenanceText:
      'Das automatische Einlesen wird demnächst freigeschaltet. Bitte fülle die Felder mit einem * bis dahin händisch aus.'
  },
  editPartner: {
    partnerDetails: 'Details zum Installationspartner',
    partnerContactName: 'Partner Vor- und Nachname',
    companyName: 'Firmenname',
    companyAddress: 'Adresse der Firma',
    companyEmail: 'Firmen-E-Mail'
  },
  offer: {
    price: 'Offertenpreis',
    pvSystem: 'PV-Anlage',
    plantPower: 'Anlagenleistung',
    issuanceDate: 'Ausgabedatum',
    validThru: 'Verfallsdatum',
    deliveryDate: 'Datum der Auslieferungs',
    contactPerson: 'Kontaktperson',
    partnerCompany: 'Firma'
  },
  projectDetails: {
    label: 'Projektdetails',
    status: 'Projektstatus',
    statusChangeSuccess: 'Der Projektstatus wurde aktualisiert!',
    uploadReport: 'Abnahmerapport hochladen'
  },
  partnerFinance: { heading: 'Finanzen & Verträge', tabs: { partnerInvoices: 'Rechnungen' } },
  partnerInvoices: {
    table: {
      project: {
        id: 'Projekt #',
        building: { address: { street: 'Projektadresse' } },
        owner: { firstName: 'Kundenname' }
      },
      projectStatus: 'Projektstatus',
      amount: 'Rechnungsbetrag',
      timestamp: 'Upload Datum',
      transactionStatus: 'Rechnungsstatus'
    },
    transactionStatuses: 'Rechnungsstatus',
    searchPlaceholder: 'Suche nach Kunde oder Projektadresse'
  },
  projectOverview: {
    tabs: { projectOverview: 'Projekt-Übersicht', contracts: 'Verträge', invoices: 'Rechnungen' },
    snackbar: {
      heading: 'Neue Installationsofferte',
      text: 'Du hast eine oder mehrere neue Offerten vorliegen'
    },
    contractsTab: {
      contractDetails: 'Details zu den Verträgen',
      procesDescription:
        'Bitte den Vertrag / die Verträge herunterladen, drucken und unterschrieben und dann an Investerra senden via Post:',
      processProblem: 'Probleme mit dem Vertrag? Bitte melde dich bei Investerra hier:',
      uploadSignedPdf: 'Unterschriebener Vertrag hochladen',
      downloadInPdf: 'PDF herunterladen',
      showPdf: 'PDF anzeigen',
      uploadProofOfInsurance: 'Versicherungsnachweis hochladen',
      contracts: {
        CREDIT_LINE: 'Kreditrahmenvertrag',
        ELECTRICITY_TRADING: 'Stromhandelvertrag',
        ORDER_CONFIRMATION: 'Auftragsbestätigung'
      },
      orderConfirmationDescription:
        'Bitte hier die Projekt-Auftragsbestätigung von deinem Installateur hochladen.',
      projectInsurance: 'Projektversicherung',
      supplementBuildingInsurance: 'Ergänzung Gebäudeversicherung',
      descriptionInstruction:
        'Investerra verpflichtet in den Verträgen den Kreditnehmer eine Versicherungslösung für die Anlage abzuschliessen. Details zur Versicherung <contactLink> hier nachlesen.</contactLink>',
      descriptionChoice:
        'Solltest du eine eigene Versicherung wählen, musst du vor Auszahlung des Kredits einen Versicherungsnachweis hochladen.',
      useYourInsurance: 'Ich möchte meine eigene Versicherungslösung nutzen'
    },
    invoices: {
      creditText:
        'Hier erscheint deine Rechnung für die Abzahlung des Kredites. Bitte richte mit der Rechnung dein LSV oder eBill bei deiner Bank ein'
    }
  },
  genericErrors: {
    notEmpty: 'Das Feld darf nicht leer sein.',
    positiveNumber: 'Der Wert muss eine positive Zahl sein.',
    entereValidPostcode: 'Bitte gib Du eine gültige PLZ ein.',
    enterValidIBAN: 'Bitte gib eine gültige IBAN ein',
    mustBe18YearsOld: 'Du musst mindestens 18 Jahre alt sein.'
  },
  partnerProjectsOverview: {
    heading: 'Projektübersicht Installateur',
    searchByNameEmail: 'Suche nach Name, Email oder Adresse',
    invoiceAmount: 'Rechnungsbetrag',
    creditPrice: 'Objektkreditpreis',
    table: {
      heading: {
        id: 'Projekt #',
        project: {
          owner: { firstName: 'Vor / Nachname', email: 'Email' },
          building: { address: { street: 'Projektadresse' } }
        },
        sentTimestamp: 'Datum Anfrage',
        offeredPrice: 'Totaler Objektkreditpreis',
        status: 'Status'
      }
    }
  },
  units: {
    meterSqared: 'm²',
    kg: 'kg',
    kilowatt: 'kW',
    kilowattPerformance: 'kWp',
    rpKilowatt: 'Rp. / kWh'
  },
  offers: {
    heading: 'Installationen Offerten',
    finalMonthlyRate: 'Finale Monatsrate',
    installerDetails: 'Installer details',
    tooltipFinalMonthlyRate:
      'Die finale Monatsrate setzt sich zusammen aus: Kreditbetrag, effektiver Jahreszins, Laufzeit (Zeitraum für die Rückzahlung), Die Kreditvergabe ist verboten, falls sie zur Überschuldung führt (Art. 3 UWG).',
    tab: { heading: 'Offerten' },
    snackbar: {
      heading: {
        CREATED: 'Partner-Offerten verfügbar',
        PENDING: 'Partner-Offerte pendet',
        ACCEPTED: 'Partner-Offerte angenommen'
      },
      text: {
        PENDING:
          'Diese Offerte ist noch unter Bearbeitung bei dem Installations-Partner. Wir benachrichtigen dich sobald die Offerte eingereicht wird.',
        CREATED: 'Neue Installationsofferte verfügbar',
        ACCEPTED: 'Installationsangebot angenommen'
      }
    },
    offerDetails: {
      heading: 'Installationsofferte',
      offerDate: 'Offertendatum',
      address: 'Adresse',
      downloadAcceptanceReport: 'Download Abnahmerapport PDF',
      subsidy: {
        label: 'Förderung'
      },
      pvSystem: {
        label: 'PV-Anlage',
        tooltip: { heading: 'PV-Anlage', text: 'Für die Anlage verwendete Fläche.' }
      },
      plantPower: {
        label: 'Anlagenleistung',
        tooltip: {
          heading: 'Anlagenleistung',
          text: 'Maximale Leistung der Anlage in kWp.'
        }
      },
      validThruDate: {
        label: 'Verfallsdatum',
        tooltip: { heading: 'Verfallsdatum', text: 'Gültigkeit der Offerte.' }
      },
      instructionNote: {
        pleaseNote: 'Bitte beachte:',
        instruction: 'Um die Änderungen zu speichern, muss ein Angebot hochgeladen werden.'
      },
      installationDate: {
        label: 'Installationsdatum',
        tooltip: {
          heading: 'Installationsdatum',
          text: 'Voraussichtliches Installationsdatum der Anlage.'
        }
      },
      offerPrice: {
        label: 'Offertenpreis',
        tooltip: { heading: 'Offertenpreis', text: 'Offerierter Preis für die Anlage.' }
      },
      instalationPartner: {
        tooltip: { text: 'Instalation partner text', heading: 'Instalation partner heading' }
      }
    },
    financialOfferPreview: {
      extrasAndModules: 'Ausgewählte Module & Extras',
      extrasAndModulesTooltip: 'Zusammenstellung der gewünschten Zusatzmodule und Zusatzleistungen.'
    },
    keyFigures: { heading: 'Kennzahlen' },
    modulePreview: { heading: 'Ausgewählte Module' },
    image: { heading: 'Details zum Projekt' },
    offerNumber: 'Offertennummer',
    description: 'Beschreibung',
    additionalModules: 'Zusatzmodule',
    modal: {
      deleteProject: 'Projekt löschen',
      register: 'Registrierung',
      creditVolumeRestriction: 'Kredite über CHF {{maxLoanAmount}}'
    },
    contract: {
      description:
        'Wichtig: Um die Installationsofferte abzuschliessen bitte das PDF herunterladen, unterzeichnen und an den Installationspartner retournieren.',
      buttonText: 'PDF herunterladen',
      orderConfirmationDownload: 'Auftragsbestätigung herunterladen'
    },
    loanRequested: { success: 'Kreditantrag eingereicht' },
    loanRequestException: {
      contactDirectly:
        'Bei grossen Krediten von über CHF {{maxLoanAmount}} bitten wir dich uns direkt zu kontaktieren, für diese Beträge werden detailliertere Prüfungen durchgeführt.',
      contactUsHere: 'Kontaktiere uns hier'
    }
  },
  myProfile: {
    title: 'Mein Konto',
    cards: {
      personalData: {
        title: 'Persönliche Daten',
        description: 'Hinterlegte persönliche Daten einsehen und bearbeiten.',
        buttonText: 'Bearbeiten'
      },
      myProjects: {
        title: 'Meine Projekte',
        description: 'Alle Projekte auf einen Blick.',
        buttonText: 'Details'
      },
      myNews: {
        title: 'Mein Posteingang',
        description: 'Nachrichten von Investerra und Partnern anzeigen.',
        buttonText: 'Öffnen'
      },
      support: {
        title: 'Hilfe & Support',
        description: 'Lösungen für häufige Probleme oder Hilfe von einem Support-Mitarbeiter.',
        buttonText: 'Kontakt'
      }
    },
    personalData: {
      title: 'Persönliche Daten',
      address: 'Wohnadresse',
      profileUpdated: 'Dein Profil wurde erfolgreich aktualisiert.',
      kyc: {
        identification: 'Identifikation',
        startIdentification: 'Identifikation jetzt starten',
        status: {
          title: 'Status',
          successful: 'Erfolgreich',
          pending: 'Pendent',
          unsuccessful: 'Nicht erfolgreich'
        },
        description: 'Um einen Kredit zu erhalten ist eine Identifikation (KYC) erforderlich.'
      },
      deleteUser: {
        title: 'Benutzer-Löschung',
        buttonText: 'Benutzer löschen',
        text: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
        toastMessage: 'Erfolgreiche Löschung'
      },
      deleteAccount: 'Um dein Konto zu löschen, kontaktiere uns <contactLink>hier</contactLink>.'
    },
    security: {
      title: 'Sicherheit'
    },
    changePassword: {
      title: 'Passwort ändern',
      oldPassword: 'Altes Passwort',
      newPassword: 'Neues Passwort',
      success: 'Passwort aktualisiert'
    },
    twoFactorAuth: {
      title: 'Zwei-Faktor-Authentifizierung',
      description:
        'Du kannst deine Sicherheit erhöhen, indem Du die optionale Zwei-Faktor-Authentifizierung aktivierst. Wenn sie aktiviert ist, musst Du bei jeder Anmeldung einen eindeutigen Code über eine Authentifizierungs-App eingeben.',
      label: 'Zwei-Faktor-Authentifizierung aktivieren',
      changePasswordHeading: 'Passwort ändern',
      changePasswordText:
        'Falls Du dein Passwort vergessen hast kannst Du über den Button dein Passwort ändern.'
    },
    bankDetails: {
      title: 'Bankangaben',
      bankName: 'Name der Bank',
      accountHolder: 'Kontoinhaber',
      address: 'Adresse',
      bic: 'BIC',
      updateSuccess: 'Ihre Änderungen wurden erfolgreich gespeichert'
    },
    myProjects: {
      labels: {
        project: 'Projekt',
        created: 'Erstellt',
        status: 'Status',
        showDetails: 'Details anzeigen',
        search: 'Suche nach Address oder Projektnummer'
      }
    }
  },
  inbox: { title: 'Mein Posteingang', empty: 'Ihr Posteingang ist leer.' },
  admin: {
    projects: {
      title: 'Projekte im Überblick',
      table: {
        heading: {
          id: 'Projekt #',
          creationTime: 'Datum',
          owner: { firstName: 'Kundenname' },
          building: { address: { street: 'Projektadresse' } },
          offer: { partner: { name: 'Installationspartner' } },
          status: 'Status',
          lastLogAuthor: 'Letzte Änderung'
        }
      },
      labels: {
        approveCheck: 'Prüfung Bearbeiten',
        projectDetails: 'Projekte Details',
        lastChange: 'Letzte Änderung',
        customerName: 'Kundenname',
        projectAddress: 'Projektadresse',
        requestLoanAmount: 'Angefragte Kreditsumme'
      }
    },
    projectDetails: {
      project: 'Projekt',
      tabs: {
        projectOverview: 'Projekt-Übersicht',
        projectReview: 'Projektprüfung',
        contracts: 'Verträge',
        invoices: 'Rechnungen'
      },
      projectReview: {
        modalTitles: {
          kremo: 'Kremo',
          experian: 'Einzelheiten: {{experianStatus}}',
          dataConfirmationConfirm: 'Antrag genehmigen?',
          dataConfirmationReject: 'Antrag ablehnen?',
          complianceCheck: 'Überprüfung der Einhaltung (PEP)',
          zek: 'ZEK',
          financialDataValuation: 'Bewertung von Finanzdaten',
          personalDetails: 'Persönliche Angaben',
          creditOffer: 'Kreditangebot',
          kycPersonalDetails: 'KYC Persönliche Angaben',
          comment: 'Kommentar'
        },
        instructions: {
          heading: 'Prüfungseinreichung',
          processingNote: {
            heading: 'Bearbeitungshinweis:',
            text: 'Sie können hier eine neue Prüfungseinreichung hinterlegen. Bitte laden Sie alle benötigten Dokumente im rechten Bereich hoch. Um den Dokumentupload und den Kommentar final zu sichern schliessen Sie die Prüfung durch “Annehmen” oder “Ablehnen” ab.'
          },
          pleaseNote: {
            heading: 'Bitte beachten Sie: ',
            text: 'Sollte die Seite neu geladen werden oder geschlossen werden bereits hinterlegte Kommentare und Dateien gelöscht.'
          }
        },
        newComment: 'Neuer Kommentar',
        documentUpload: 'Dokument Upload',
        userProjectData: {
          fields: {
            kycPersonalDetails: 'KYC',
            experian: 'Experian',
            kremo: 'Kremo (offener Betrag vor Antrag)',
            complianceCheck: 'Überprüfung der Einhaltung',
            zek: 'ZEK',
            financialDataValuation: 'Bewertung von Finanzdaten',
            creditCheck: 'Intrum Kreditprüfung',
            uploadedFiles: 'Hochgeladene Dateien'
          },
          questions: {
            installerChecked: 'Wurde der Installateur überprüft?',
            confirmation:
              'Ich bestätige, dass ich alle Themen einer allgemeinen Überprüfung durchgeführt habe!'
          }
        },
        kremoTables: {
          description:
            'In der Schweiz bezieht sich “KREMO” auf das “Kredite Monitoring”-System, ein Kreditüberwachungstool, das von Finanzinstituten und Unternehmen zur Beurteilung der Kreditwürdigkeit von Einzelpersonen und Unternehmen verwendet wird. Es hilft bei der Bewertung von Kreditrisiken, indem es detaillierte Berichte über die finanzielle Historie einer Person oder eines Unternehmens bereitstellt, einschließlich ausstehender Schulden, Zahlungsverhalten und anderer relevanter finanzieller Informationen. Dieses System ist entscheidend für fundierte Kreditentscheidungen und Geschäftspraktiken.',
          applicantTable: {
            heading: {
              applicantExpenditures: 'Antragsteller Ausgaben',
              partnerExpenditures: 'Partner-Ausgaben'
            },
            data: {
              exemptAmount: 'Steuerbefreiter Betrag',
              taxAtSource: 'Steuer an der Quelle',
              socialCharges: 'Sozialabgaben',
              externalFoodBoardingDeduction: 'Externer Verpflegungsabzug',
              increasedFoodIntakeDeduction: 'Abzug für erhöhte Nahrungsaufnahme',
              increasedClothingRequirementDeduction: 'Erhöhter Abzug für Bekleidungsbedarf',
              transportationCost: 'Transportkosten',
              openCreditAmount: 'Offener Kreditbetrag',
              openLeasingRateAmount: 'Offener Leasingratenbetrag',
              exemptAmout: 'Freigegebener Betrag'
            }
          },
          houseHoldTable: {
            heading: {
              houseHoldExpenditures: 'Ausgaben der Haushalte'
            },
            data: {
              healthInsuranceFee: 'Krankenversicherungsbeitrag',
              subsistenceMinimum: 'Existenzminimum'
            }
          }
        },
        dataConfirmation: {
          text: "Sind Sie sicher, dass Sie alle erforderlichen Daten überprüft haben und von Ihrer Entscheidung überzeugt sind? Wenn Sie auf 'Bestätigen' klicken, wird Ihre Entscheidung zu Protokoll genommen."
        },
        complianceCheck: {
          pep: 'PEP',
          sanctionList: 'Sanktionsliste',
          watchList: 'Beobachtungsliste',
          table: {
            heading: {
              id: 'Id',
              firstName: 'Vorname',
              fullName: 'Vollständiger Name',
              lastName: 'Nachname',
              dateOfBirth: 'Geburtsdatum',
              country: 'Land',
              nameOfList: 'Name der Liste'
            }
          }
        },
        zek: {
          description:
            'ZEK (Zentralstelle für Kreditinformation) ist ein Schweizer Kreditbüro, das kreditbezogene Informationen sammelt und verwaltet und Kreditgebern hilft, die Kreditwürdigkeit von Personen zu beurteilen. Es spielt eine entscheidende Rolle bei der Erleichterung einer verantwortungsvollen Kreditvergabe, indem es einen umfassenden Überblick über die Kreditgeschichte und die finanziellen Verpflichtungen einer Person bietet.',
          zekScore: 'Zek Score',
          table: {
            heading: {
              clientId: 'Client Id',
              zek: 'ZEK',
              firstName: 'Vorname',
              lastName: 'Nachname',
              gender: 'Gender',
              dateOfBirth: 'Geburtsdatum',
              fullAddress: 'Vollständige Adresse',
              nationality: 'Land',
              personStatus: 'Person Status',
              hitScore: 'Trefferquote'
            }
          }
        },
        financialValuation: {
          table: {
            heading: { applicant: 'Antragsteller', partner: 'Partner' },
            data: {
              monthlyHousingCosts: 'Monatliche Wohnkosten',
              monthlyExpensesAlimony: 'Monatliche Ausgaben für Unterhalt',
              monthlyEducationCosts: 'Monatliche Ausbildungskosten',
              monthlyProfessionalOrganizationFee: 'Monatliche Kosten für Berufsverbände',
              monthlyOtherFixExpenses: 'Monatliche Wohnkosten',
              monthlyOneTimeExpenses: 'Monatliche einmalige Ausgaben',
              totalMonthlyIncomeGrossAmount12: 'Monatliches Gesamteinkommen Bruttobetrag 12',
              monthlyIncomeGrossAmountMain12: 'Monatseinkommen Bruttobetrag Haupt 12',
              disposableMonthlyIncome: 'Verfügbares Monatseinkommen',
              monthlySurplusInstallmentRatio: 'Verhältnis der monatlichen Überschussraten'
            }
          }
        },
        kycPersonalDetailsTable: {
          table: {
            heading: { title: 'Titel', data: 'Daten' },
            data: {
              firstName: 'Vorname',
              lastName: 'Nachname',
              gender: 'Geschlecht',
              maritalStatus: 'Familienstand',
              nationality: 'Nationalität',
              householdType: 'Haushaltstyp',
              householdMonthlyInsuranceFee: 'Monatliche Haushaltsversicherungsgebühr',
              street: 'Straße',
              houseNumber: 'Hausnummer',
              postalCode: 'Postleitzahl',
              city: 'Stadt',
              canton: 'Kanton',
              country: 'Land',
              residenceType: 'Wohnungstyp'
            }
          }
        },
        personalDetailsTable: {
          table: {
            heading: { title: 'Titel', data: 'Daten' },
            data: {
              firstName: 'Vorname',
              lastName: 'Nachname',
              email: 'E-Mail',
              phoneNumber: 'Telefonnummer',
              street: 'Straße',
              houseNumber: 'Hausnummer',
              zipCode: 'Postleitzahl',
              town: 'Stadt',
              bank: 'Bank',
              iban: 'IBAN'
            }
          }
        },
        creditOfferTable: {
          table: {
            heading: { title: 'Titel', data: 'Daten' },
            data: {
              loanAmount: 'Angeforderter Betrag',
              loanTerm: 'Angeforderte Laufzeit',
              monthlyRate: 'Angeforderte monatliche Rate',
              decliningBalance: 'Restschuld',
              deposit: 'Anzahlung',
              interestRate: 'Nominalzins',
              contractStartDate: 'Vertragsbeginn'
            }
          }
        },
        commentPreview: {
          previousComment: 'Vorheriger Kommentar',
          nextComment: 'Nächster Kommentar'
        }
      },
      title: 'Projektdetails',
      history: 'Historie',
      review: 'Prüfung',
      course: 'Verlauf & Kommentare',
      log: 'Dokumente',
      completeCheck: 'Prüfung abschliessen',
      residentalAddress: 'Wohnadresse',
      offerRequested: 'Offertenanfrage',
      adminComment: 'Bemerkungen Admin',
      commentSaved: 'Gespeicherter Kommentar',
      contracts: {
        contractsList: 'Verträge',
        uploadSignedContracts: 'Hier die von Investerra signierten, finalen Verträge hochladen.',
        uploadFinalContract: 'Finalen Vertrag hochladen',
        creditContract: 'Kreditvertrag',
        elictricityTradingContract: 'Stromhandelvertrag',
        projectInsurance: 'Projektversicherung',
        submit: 'Einreichen',
        pdf: 'PDF'
      },
      invoices: {
        creditOpenTooltip: {
          heading: 'Kredit Offen',
          text: 'Geschuldeter Betrag gegenüber der Investerra AG'
        },
        confirmationModal: {
          title: 'Transaktionsbestätigung',
          text: 'Bitte Dokumenttyp bestätigen und exakten Betrag angeben.'
        },
        approveBill: 'Änderung speichern'
      }
    },
    dashboard: {
      cards: {
        projectOverview: {
          heading: 'Projektübersicht',
          text: 'Alle Ihre Projekte auf einen Blick.',
          buttonText: 'Öffnen'
        },
        platformCondition: {
          heading: 'Plattformkonditionen',
          text: 'Konditionen anpassen für Kredite, Produkte, Partner, etc.',
          buttonText: 'Öffnen'
        },
        tabs: {
          creditTerms: { heading: 'Kredit' },
          products: { heading: 'Produkte' },
          partnerCommissions: { heading: 'Konditionen Installationspartner' }
        },
        invoice: {
          heading: 'Rechnungs-Review',
          text: 'Prüfung Installationsrechnungen und Zahlungsbestätigungen.',
          buttonText: 'Öffnen'
        },
        kycReview: {
          heading: 'KYC Review',
          text: 'Link zu externem KYC-Anbieter für Identitätsprüfungen.',
          buttonText: 'Öffnen'
        },
        personalData: {
          heading: 'Persönliche Daten',
          text: 'Hinterlegte persönliche Daten einsehen und bearbeiten.',
          buttonText: 'Öffnen'
        },
        clients: {
          heading: 'Kundenübersicht',
          text: 'All Investerra Kunden in Übersicht',
          buttonText: 'Öffnen'
        },
        partners: {
          heading: 'Partnerübersicht',
          text: 'All Investerra Partner in Übersicht',
          buttonText: 'Öffnen'
        }
      }
    },
    clients: {
      clientOverview: 'Kundenübersicht',
      searchPlaceholder: 'Suche nach Name, Email oder ID',
      table: {
        heading: {
          firstName: 'Kundenname',
          email: 'Email',
          id: 'ID',
          lastChange: 'Letzte Änderung',
          bankDetails: 'Bankangaben',
          status: 'Status'
        }
      },
      clientDetails: 'Details zum Kunden',
      clientData: 'Kundeninfo',
      changesSaved: 'Änderungen wurden erfolgreich gespeichert'
    },
    partnerProducts: {
      table: {
        heading: {
          productName: 'Produktname'
        }
      }
    }
  },
  partnersOverview: {
    heading: 'Partnerübersicht',
    searchText: 'Suche nach Firmenname, Name',
    partnerType: 'Typ',
    table: {
      heading: {
        name: 'Firmenname',
        contactFirstName: 'Vor- und Nachname',
        partnerType: 'Type',
        partnerProducts: 'Produkte',
        level: 'Level'
      }
    }
  },
  partners: {
    id: 'Partner ID',
    dashboard: {
      title: 'Partner Dashboard',
      cards: {
        myAccount: {
          heading: 'Firmenprofil',
          text: 'Hinterlegte persönliche Daten einsehen und bearbeiten.',
          buttonText: 'Öffnen'
        },
        financesAndContracts: {
          heading: 'Finanzen & Verträge',
          text: 'Alle Verträge und finanzielle Themen auf einen Blick.',
          buttonText: 'Öffnen'
        },
        projectsOverview: {
          heading: 'Projektübersicht',
          text: 'Alle Projekte auf einen Blick.',
          buttonText: 'Öffnen'
        },
        myBranches: {
          heading: 'Meine Filialen',
          text: 'Erfasste Filialen einsehen und bearbeiten.',
          buttonText: 'Öffnen'
        },
        support: {
          heading: 'Hilfe & Kontakt',
          text: 'Lösungen für häufige Fragen und direkter Support.',
          buttonText: 'Öffnen'
        }
      }
    },
    account: {
      companyData: {
        title: 'Firmendaten',
        userData: 'Benutzerdaten'
      },
      bankDetails: {
        title: 'Bankangaben'
      },
      security: {
        title: 'Sicherheit'
      },
      contracts: {
        title: 'Verträge'
      },
      companyDataUpdated: 'Die Daten deiner Firma wurden aktualisiert.'
    },
    vatNumber: 'MWST-Nummer',
    legalForm: 'Rechtsform',
    averageClientRating: 'Average client rating',
    geoCoverage: 'Geographische Abdeckung',
    geoCoverageTooltip:
      'Wähle die Regionen aus, welche du bearbeitest. Es werden dir nur Offertanfragen aus diesen Regionen zugestellt.',
    whichCantons: 'Klicke alle Kantone an, in denen dein Unternehmen tätig ist:',
    allSwiss: 'Gesamte Schweiz',
    partnerProducts: {
      title: 'Produktangebot',
      description: 'Wähle alle Produktkategorien aus, die dein Unternehmen anbietet:',
      SOLAR_SYSTEM: 'Solaranlage',
      HEAT_PUMP: 'Wärmepumpe',
      CHARGING_STATION: 'Ladestation',
      RENOVATION: 'Renovierung'
    },
    registration: {
      title: 'Als Fachpartner registrieren',
      personalData: 'Persönliche Daten',
      instalationPartnerContract: 'Installationspartnervertrag',
      acceptContract: 'Ich akzeptiere den Installationspartnervertrag',
      downloadPdfContract: 'Laden Sie hier die PDF mit den Vertragsbedingungen herunter',
      completeRegistration: 'Registrierung abschliessen',
      cantFindCompany:
        'Kannst du deinen Firmennamen nicht finden? Kontaktiere uns auf <emailLink>hallo@investerra.ch</emailLink>'
    },
    acceptanceReport: { heading: 'Abnahmerapport hochladen', tooltip: 'Acceptance report tooltip' },
    clientContactDetails: 'Kontaktdetails Kunde',
    selectCompanyTitle: 'Bitte wähle deinen korrekten Partner aus'
  },
  kycResult: {
    success: {
      heading: 'Geschafft! Vielen Dank.',
      emailNotification:
        'Wir bitten um etwas Geduld. Du wirst via E-Mail benachrichtigt, wenn die Prüfung erfolgreich abgeschlossen ist.',
      steps: 'Die nächsten Schritte:',
      creditWorthiness: 'Wir prüfen Deine Kreditwürdigkeit',
      applicationChecks: 'Dein Antrag wird intern überprüft. Dies wird in etwa 2 Tage dauern',
      confirmation: 'Du erhältst eine Bestätigung / Ablehnung deines Antrags',
      projectFinancing: 'Danach kannst Du dein Projektfinanzierung einreichen'
    },
    error: {
      heading: 'Schlechte Nachrichten..',
      enabledIdentification:
        'Leider konnten wir Deine Identifikation nicht erfolgreich abschliessen. Dies kann mehrere Gründe haben:',
      reasons: 'Gründe einer erfolglosen Identifikation:',
      incompleteInformation: 'Deine Angaben waren unvollständig',
      noMatchInfo: 'Deine Dokumente stimmen nicht mit den Angaben überein',
      startProcessAgain:
        'Bitte starte den Prozess erneut und überprüfe Deine Angaben auf Vollständigkeit.'
    }
  },
  billing: {
    table: {
      heading: {
        date: 'Datum',
        documentType: 'Dokument type',
        partner: 'Installationspartner',
        status: 'Status',
        partnersInvoice: 'Unbestätigte Partner Rechnung'
      }
    },
    invoicesAndPayment: 'Rechnungen & Zahlungsbestätigungen',
    invoicesAndPaymentTooltip: 'Übersicht über alle Transaktionen.',
    confirmDocumentType: 'Bitte Dokumenttyp bestätigen und exakten Betrag angeben',
    pdf: 'PDF',
    creditPayoff: 'Abzahlung Kredit',
    taxDocument: 'Steuerbeleg',
    creditTotalTooltip: {
      heading: 'Kredit Gesamt',
      text: 'Zugesicherte, bzw. Ausbezahlte Kreditsumme.'
    },
    creditOpenTooltip: {
      heading: 'Kredit Offen',
      text: 'Geschuldeter Betrag gegenüber der Investerra AG'
    },
    uploadQrInvoice: 'QR Rechnung hochladen',
    invoicePdf: 'Rechnung PDF',
    uploadInterestCalculation: 'Zinsrechnung hochladen',
    uploadOneTimeInterest: 'Einmalige Zinsrechnung hochladen',
    interestCalculation: 'Gebühren und Zinsrechnung',
    interestCalculationText:
      'Einmalig wird von der Investerra eine Basisgebühr, sowie die angelaufenen Zinskosten für allfällige Teilzahlungen verrechnet. Die Rechnung findest du unten zum Download.'
  },
  partnerDetails: {
    heading: 'Details zum Partner',
    tabs: { partnerInfo: 'Partnerinfo', partnerCommissions: 'Kreditkonditionen' },
    partnerInfo: { companyData: 'Firmendaten', productRange: 'Produktangebot' }
  },
  checks: {
    monthlyRate: 'Monatrate',
    allowance: 'Freibetrag',
    installationPartner: {
      label: 'Installationspartner'
    },
    generalCheck: {
      label: 'Allgemeine Prüfung',
      value: 'Volumen, Nachhaltigkeit, Projektart / Installation, Standort'
    },
    creditCheck: {
      label: 'Bonitätsprüfung',
      value: 'Kreditwürdigkeit um Überschuldung des Kreditnehmers vermeiden'
    },
    creditWorthiness: {
      label: 'Kreditfähigkeit',
      value: 'Kreditvergabe gemäss KKG'
    }
  },
  creditTerms: {
    base_fee: {
      heading: 'Minimale Basisgebühr',
      description: 'Einmalige Bearbeitungsgebühr bei Abschluss'
    },
    percentage_completion_fee: {
      heading: 'Prozentuale Basisgebühr',
      description: 'Minimum einmalige Abschlussgebühr auf dem Volumen'
    },
    refinancing_rate: {
      heading: 'Refinanzierungszins',
      description: 'Zinssatz zu welchen Konditionen wir die Refinanzierung erhalten'
    },
    margin_platform: {
      heading: 'Plattformmarge',
      description: 'Plattformmarge'
    },
    interest_rate: {
      heading: 'Zinssatz Kredit Vertrag',
      description:
        'Effektiver Zinssatz, welcher zur Berechnung der Raten genutzt wird. (Addition Refinanzierungszins und Marge)'
    },
    min_loan_amount: {
      heading: 'Mindestkreditbetrag',
      description: ''
    },
    max_loan_amount: {
      heading: 'Maximaler darlehensbetrag',
      description: ''
    },
    min_loan_duration_in_years: {
      heading: 'Minimale darlehenslaufzeit in jahreng',
      description: ''
    },
    max_loan_duration_in_years: {
      heading: 'Maximale darlehenslaufzeit in jahren',
      description: ''
    },
    min_down_payment: {
      heading: 'Mindestanzahlung',
      description: ''
    },
    max_down_payment: {
      heading: 'Maximaler Abschlag Zahlung',
      description: ''
    },
    max_down_payment_percentage: {
      heading: 'Maximaler  Abschlagszahlung Prozentsatz',
      description: ''
    },
    kleiv_base_amount: {
      heading: 'Kleiv basis betrag:',
      description: ''
    },
    kleiv_altitude_bounds: {
      heading: 'Kleiv höhe grenzen',
      description: ''
    },
    indicative_price_per_kwp_small: {
      heading: 'Indikativer preis pro kWp klein',
      description: ''
    },
    indicative_price_per_kwp_medium: {
      heading: 'Indikativer preis pro kWp mittel',
      description: ''
    },

    indicative_price_per_kwp_big: {
      heading: 'Indikativer preis pro kWp gross',
      description: ''
    },
    vat: {
      heading: 'Mehrwertsteuer',
      description: ''
    },
    feed_in_tariff: {
      heading: 'Einspeisevergütung',
      description: ''
    }
  },
  partnerCommissions: {
    table: {
      heading: {
        LEVEL: 'Level',
        BROKERED_VALUE: 'Min. Vermittlungsvolumen / Jahr',
        ORDER_VOLUME: 'Kommission Auftragsvolumen',
        LOAN_AMOUNT: 'Kommission Kreditvolumen',
        PAYMENT_TERMS: 'Zahlungsbedingungen'
      }
    }
  },
  partnerProjectSummary: {
    tabs: { projectDetails: 'Projekt-Details', partnerBilling: 'Rechnungen' }
  },
  partnerProjectDetails: {
    customerContactDetails: {
      tooltip: { text: 'Customer contact details' },
      headline: 'Kontaktdetails Kunde'
    },
    offerStatus: 'Angebotstatus'
  },
  partnerBilling: {
    heading: 'Projektrechnungen',
    table: {
      heading: { date: 'Datum', documentType: 'Dokumenttype', status: 'Status', download: '' }
    }
  },
  allInvoices: {
    invoiceReview: 'Rechnungs-Review',
    searchPlaceholder: 'Suche nach Address oder Projektnummer',
    table: {
      heading: {
        project: {
          id: 'Projekt #',
          building: { address: { street: 'Projektadresse' } },
          offer: { partner: { name: 'Installationspartner' } }
        },
        timestamp: 'Datum',
        type: 'Dokument type',
        status: 'Status '
      }
    }
  },
  kycIdentification: {
    heading: 'Überprüfung deiner Identität',
    text: 'Deine Identität wird sicher und in wenigen einfachen Schritten online geprüft. Du kannst den Prozess auf zwei unterschiedlichen Arten vervollständigen, entweder mit deinem Smartphone und dem QR Code oder über einen Link hier in deinem Browser. Der Prozess wird jeweils nur wenige Minuten dauern.',
    thirdPartyText:
      'Hinweis: Die Investerra arbeitet für die Identitätsüberprüfung mit der Firma Intrum zusammen. Du wirst für die Überprüfung auf die Intrumplattform umgeleitet. Sobald die Überprüfung abgeschlossen ist, landest du automatisch wieder bei der Investerra. Einzige Ausnahme ist, wenn du hier auf dein Mobile wechselst.',
    scanAndStart: 'Scannen und Prozess starten',
    verificationWithQrCode: 'Überprüfung mit QR Code',
    verifyWithLink: 'Überprüfung per Link direkt im Browser',
    prepareSmartphone:
      'Halte dein Smartphone bereit und starte den Prozess, indem du den QR Code scannst.',
    buttonDescription: 'Starte den Prozess, indem du den Button klickst.',
    buttonText: 'Starte die Identifikation',
    yourIdentId: 'Deine Ident-ID'
  },
  deleteProject: {
    heading: 'Projekt löschen',
    text: 'Wenn Sie bestätigen, wird das Projekt gelöscht und die Daten gehen verloren.',
    confirmationMessage: 'Erfolgreiches Projekt gelöscht',
    deleteNotAllowed: 'Das Löschen von Projekten ist nicht erlaubt'
  },
  sepLegend: {
    low: 'Gering: < 800 kWh/m2',
    medium: 'Mittel: > 800 und < 1000 kWh/m2',
    good: 'Gut:> 1000 und < 1200 kWh/m2',
    veryGood: 'Sehr Gut:> 1200 und < 1400 kWh/m2',
    excelent: 'Hervorragend: > 1400 kWh/m2'
  },
  commissionUnits: { DAYS: ' Tage' },
  creditCheck: {
    personalDetails: {
      title: 'Personenangaben Antragsteller/in',
      name: 'Name',
      data: 'Persönliche Daten',
      gender: 'Geschlecht',
      maritalStatus: 'Zivilstand',
      contactDetails: 'Kontaktdaten',
      married: 'Verheiratet',
      widowed: 'Verwitwet',
      divorced: 'Geschieden',
      single: 'Ledig',
      separated: 'Getrennt',
      registeredPartnership: 'Eingetragene Partnerschaft',
      selectGender: 'Geschlecht',
      nationality: 'Nationalität',
      chooseNationality: 'Wählen Sie Ihre Nationalität',
      residencePermit: 'Aufenthaltsbewilligung',
      residencePermitType: 'Art der Aufenthaltsbewilligung',
      doYouHaveResidencePermit:
        'Besitzt Du eine Schweizer Aufenthaltsbewilligung? Falls die Art Deiner Aufenthaltsbewilligung nicht in der unten stehenden Liste aufgeführt ist, kontaktiere uns bitte direkt.',
      changePhoneNumber:
        'Du kannst deine Telefonnummer in den <changeLink>Persönliche Daten</changeLink> ändern.',
      changePhoneNumberWarning:
        'Um die Personenangaben vollständig auszufüllen, musst du deine Telefonnummer <text>hier</text> angeben.'
    },
    lifePartner: {
      title: 'Personenangaben Lebenspartner/in',
      lifePartnerIn: 'Lebenspartner/in',
      maritalStatusPartner: 'Zivilstand Lebenspartner/in',
      placeOfResidence: 'Wohnort Lebenspartner/in',
      jointPlaceOfResidence: 'Gemeinsamer Wohnort'
    },
    housingSituation: {
      title: 'Wohnsituation Antragsteller/in',
      householdPlaceholder: 'Haushaltstyp',
      selectHouseholdType: 'Bitte wähle deinen Haushaltstyp aus',
      householdTypeText:
        'Hinweise: Wenn sowohl Kinder als auch unterstützungsbedürftige Personen im Haushalt leben, so ist der jeweilige Haushaltstyp, die Kinder berücksichtigend, zu wählen.',
      selectAddressType: 'Wähle bitte Deinen Adresstyp aus:',
      residingSince: 'Wohnhaft seit',
      residenceTypePlaceholder: 'Bezug zu deiner Wohnadresse',
      selectHousingReference: 'Bitte wähle den Bezug zu deiner Wohnadresse aus',
      numberOfHouseholdMembers: 'Anzahl der Haushaltsmitglieder',
      howManyAdultsInHousehold:
        'Wie viele Erwachsene wohnen im gleichen Haushalt wie du und werden von dir finanziell unterstützt?',
      howManyChildrenInHousehold: 'Wieviele minderjährige Kinder wohnen im gleichen Haushalt?',
      householdMembers: 'Haushaltsmitglieder',
      numberOfChildren: 'Anzahl Kinder',
      householdInsuranceFee: 'Krankenkassenprämie',
      householdInsuranceFeeText:
        'Die Summe der Krankenkassenkosten für den Antragsteller/in und ggf. Partner/in und Kinder im Haushalt.',
      childAge: 'Alter Kind',
      customerAddress: {
        heading: 'Wohnort',
        street: 'Strasse',
        houseNumber: 'Hausnr.',
        postalCode: 'PLZ',
        municipality: 'Ort',
        country: 'Land',
        canton: 'Kanton'
      }
    },
    financialSituation: {
      title: 'Finanzielle Verhältnisse Antragsteller/in',
      bankDetails: 'Wie lautet deine Bankverbindung?',
      accountHolderName: 'Wie lautet der Vor- und Nachname des Kontoinhabers?',
      employmentStatus: 'Beschäftigungsverhältnis',
      employmentStatusText: 'Wie ist dein Beschäftigungsverhältnis?',
      monthlyIncomeGrossAmountMain: 'Wie hoch ist dein Monatseinkommen (brutto) in CHF?',
      thirteenSalary: 'Ich erhalte einen 13. Monatslohn',
      additionalIncome: 'Hast Du ein Zusatzeinkommen?',
      ammountOfAdditionalIncome:
        'Falls du ein Zusatzeinkommen hast, welches beispielsweise als Bonus ausbezahlt wird: Wie hoch ist dieses Zusatzeinkommen pro Monat?',
      additionalIncomeThirteenSalary: 'Ich erhalte einen 13. Monatslohn auf mein Zusatzeinkommen',
      rentalCostMonthly: 'Wie hoch sind Deine Wohnkosten pro Monat?',
      rentalCostMonthlyExamples: '(z.B. Miete, Hypothekarzins)',
      workTransport: 'Transportmittel',
      workTransportText: 'Transportmittel für deinen Weg zur Arbeit?',
      monthlyTransportationCosts:
        'Wie hoch sind deine monatlichen Ausgaben für diese Transportmittel?',
      onlyIfPublicTransportUsed:
        'Nur falls du öffentliche Verkehrsmittel oder/und dein Auto benutzt.',
      workingDaysPerMonth: 'Wie viele Arbeitstage arbeitest du pro Monat?',
      workingDaysPerMonthText:
        'Die durchschnittliche Arbeitszeit im Monat beträgt 22 Tage bei Vollbeschäftigung.',
      changeBankDetails:
        'Du kannst die Nummer deiner Bankverbindung unter <changeLink>Bankverbindung</changeLink> ändern.',
      changeBankDetailsWarning:
        'Um die Bankverbindung zu vervollständigen, musst du deinen Banknamen und deine IBAN <text>hier</text> eingeben.',
      isChurchTaxObligatory: 'Bezahlst Du Kirchensteuer?',
      monthlyEducationCosts: 'Wie hoch sind deine monatlichen Aus-/Weiterbildungskosten?',
      monthlyOneTimeExpenses:
        'Hast Du während den nächsten 3 Jahren grössere Einmalausgaben geplant?',
      monthlyOtherFixExpenses: 'Wie hoch sind deine anderen zusätzlichen monatlichen Belastungen?',
      majorExpensesPlanned:
        'Welche grösseren Einmalausgaben hast du innerhalb der nächsten 3 Jahre geplant (z.B. Auto-/Wohnungskauf, medizinische Ausgaben, etc.)?',
      monthlyExpensesAlimony: 'Falls du Alimente bezahlst, wie hoch sind die monatlichen Beträge?',
      monthlyExpensesAlimonyText: 'Nur falls du Alimentenzahlungen leistest.',
      confirmInformationIsCorrect: 'Hiermit bestätige ich die Richtigkeit der Angaben',
      confirmationText:
        'Als Vertragspartner/in erkläre ich hiermit, dass ich alleine an diesen Vermögenswerten wirtschaftlich berechtig bin, und meine Personalien korrekt festgehalten sind. Falls weitere Person(en) an in die Geschäftsbeziehung eingebrachten Vermögenswerten wirtschaftlich berechtigt ist/sind, verpflichte ich mich, diese Investerra AG zu melden. Als Vertragspartner/in verpflichte ich mich, Änderungen jeweils unaufgefordert mitzuteilen.',
      beneficialOwnerText:
        'Die vorsätzliche Angabe falscher Informationen in diesem Formular ist eine strafbare Handlung (Urkundenfälschung gemäss Artikel 251 des Schweizerischen Strafgesetzbuchs).',
      correctnessOfInformation: 'Richtigkeit der Angaben & wirtschaftliche Berechtigung'
    },
    lifePartnerFinancialSituation: {
      title: 'Finanzielle Verhältnisse Lebenspartner/in',
      lifePartnerEmploymentStatus:
        'Wie ist das Anstellungsverhältnis deines/deiner Lebenspartners/in?',
      lifePartnerMonthlyIncomeGrossAmountMain:
        'Wie hoch ist das Monatseinkommen deines/r Lebenspartner/in (brutto) in CHF?',
      additionalIncome: 'Hat dein/deine Lebenspartner/in ein Zusatzeinkommen?',
      ammountOfAdditionalIncome:
        'Falls dein Lebenspartner ein Zusatzeinkommen hat, welches beispielsweise als Bonus ausbezahlt wird: Wie hoch ist dieses Zusatzeinkommen pro Monat?',
      additionalIncomeThirteenSalary:
        'Mein Lebenspartner erhält einen 13. Monatslohn auf das Zusatzeinkommen',
      monthlyHealthInsurance: 'Wie hoch ist deine monatliche Krankenkassenprämie??',
      monthlyAlimony:
        'Wie hoch sind die monatlichen Alimentenzahlungen deines/deiner Lebenspartner/in?',
      onlyIfYouPayAlimony: 'Nur falls du Alimentenzahlungen leistest.',
      monthlyEducationCosts:
        'Wie hoch sind die monatlichen Aus-/Weiterbildungskosten deines/deiner Lebenspartners/in?',
      monthlyOtherFixExpenses:
        'Wie hoch sind die anderen zusätzlichen monatlichen Belastungen deines/deiner Lebenspartners/in?',
      monthlyOneTimeExpenses:
        'Hat dein/deine Lebenspartner/in während den nächsten 3 Jahren grössere Einmalausgaben geplant?',
      majorExpensesPlanned:
        'Welche grösseren Einmalausgaben hat dein/deine Lebenspartner/in innerhalb der nächsten 3 Jahre geplant (z.B. Auto-/Wohnungskauf, medizinische Ausgaben, etc.)?',
      yearlyIncome: 'Wie hoch ist dein Jahreseinkommen (brutto) in CHF?',
      thirteenSalary: 'Mein Lebenspartner erhält einen 13. Monatslohn',
      rentalCostMonthly: 'Wie hoch sind die Wohnkosten deine/s Lebenspartners/in pro Monat?',
      workTransportText:
        'Welche Transportmittel verwendet dein/e Lebenspartner/in für den Arbeitsweg?',
      monthlyTransportationCosts:
        'Wie hoch sind die monatlichen Ausgaben für diese Transportmittel?',
      onlyIfPublicTransportUsed:
        'Nur falls du öffentliche Verkehrsmittel oder/und dein Auto benutzt.',
      workingDaysPerMonth: 'Wie viele Arbeitstage arbeitet dein/deine Lebenspartner/in pro Monat?',
      isChurchTaxObligatory: 'Bezahlt dein/e Lebenspartner/in Kirchensteuer?'
    },
    maritalStatus: {
      MARRIED: 'Verheiratet',
      WIDOWED: 'Verwitwet',
      DIVORCED: 'Geschieden',
      SINGLE: 'Ledig',
      SEPARATED: 'Getrennt',
      REGISTERED: 'Eingetragene Partnerschaft',
      JURIDICAL_RESOLVED: 'Juristisch geklärt'
    },
    householdType: {
      SINGLE: 'Alleinstehender Haushalt',
      MARRIED_COUPLE: 'Ehepaar / eingetragene Partnerschaft / Paar mit gemeinsamen Kindern',
      SINGLE_HOUSEHOLD_COMMUNITY: 'Alleinstehend in Haushaltsgemeinschaft mit erwachsenen Personen',
      SINGLE_PARENT: 'Alleinerziehender Haushalt',
      SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD:
        'Alleinstehend mit unterstützungsbedürftiger Person im gleichen Haushalt lebend',
      SINGLE_PARENT_HOUSEHOLD_COMMUNITY:
        'Alleinerziehend in Haushaltsgemeinschaft mit erwachsenen Personen'
    },
    residenceType: {
      FAMILY: 'Familie',
      EMPLOYER: 'Arbeitgeber gestellt',
      NO_PERMANENT_RESIDENCE: 'Kein fester Wohnsitz',
      OWNER_WITH_MORTGAGE: 'Eigentümer mit Hypothek',
      OWNER_WITHOUT_MORTGAGE: 'Eigentümer ohne Hypothek',
      RENTER: 'Mieter'
    },
    addressType: {
      CURRENT: 'Aktuelle Wohn- und Meldeadresse',
      MAILING_CORRESPONDENCE: 'Korrespondenzadresse',
      PREVIOUS: 'Frühere Wohnadresse',
      EMPLOYMENT: 'Geschäftsadresse',
      TEMPORARY: 'Vorübergehende Wohnadresse'
    },
    transportType: {
      NONE: 'Keines',
      BICYCLE: 'Fahrrad',
      MOPED: 'Mofa',
      MOTORCYCLE: 'Motorrad',
      CAR: 'Auto',
      PUBLIC: 'Öffentliche Transportmittel'
    },
    employmentStatus: {
      EMPLOYED: 'Beschäftigt',
      UNEMPLOYED: 'Arbeitslos',
      SELF_EMPLOYED: 'Selbstständig',
      STUDENT: 'Student',
      PENSION: 'Rente',
      TEMPORARY_EMPLOYMENT: 'Zeitarbeit',
      HOUSE_WIFE_MAN: 'Hausfrau/Hausmann'
    }
  },
  projectLogs: {
    CREATED: 'Projekt erstellt',
    QUESTIONAIRE_SUBMITTED: 'Fragebogen eingereicht',
    FOUR_EYE_CHECK_REQUESTED: 'Vier-Augen-Prüfung angefordert',
    FOUR_EYE_CHECK_APPROVED: 'Vier-Augen-Prüfung genehmigt',
    FOUR_EYE_CHECK_REJECTED: 'Vier-Augen-Prüfung abgelehnt',
    FOUR_EYE_CHECK_REOPENED: 'Vier-Augen-Prüfung wieder geöffnet',
    PROJECT_APPROVED: 'Projekt genehmigt',
    PROJECT_DECLINED: 'Projekt abgelehnt',
    PROJECT_RESET: 'Projekt zurückgesetzt',
    PROJECT_RETRIGGER: 'Projekt erneut ausgelöst'
  },
  leaveModal: {
    title: 'Seite verlassen?',
    description: 'Die von dir vorgenommenen Änderungen werden möglicherweise nicht gespeichert.'
  },

  // ENUMS
  errorCode: { FILE_ALREADY_EXISTS: 'Datei existiert bereits' },
  partnerType: { SALES_PARTNER: 'Vertriebspartner', INSTALLATION_PARTNER: 'Installationspartner' },
  partnerProducts: {
    HEAT_PUMP: 'Wärmepumpe',
    E_CHARGING_STATION: 'Ladestation',
    STORAGE_SOLUTION: 'Speicherlösung',
    SOLAR_SYSTEM: 'Solaranlage',
    INVESTERRA_ELECTRICITY_SALES: 'Stromverkauf durch Investerra'
  },
  clientStatuses: {
    KYC_OPEN: 'KYC / KKG offen',
    KKG_FINAL: 'KKG final',
    AML_FINAL: 'AML final',
    FIRST_CHECK_SUCCESS: 'Erste prüfung erfolgreich',
    FIRST_CHECK_FAIL: 'Erste prüfung fehlgeschlagen',
    SECOND_CHECK_SUCCESS: 'Zweite prüfung erfolgreich',
    SECOND_CHECK_FAIL: 'Zweite prüfung fehlgeschlagen',
    KYC_SUCCESS: 'KYC Erfolg',
    KYC_FAIL: 'KYC Nicht bestanden',
    ADMONISHED: 'Verwaltet'
  },
  partnerLevel: {
    STARTER: 'Starter / Go Green',
    VOLT_ON: 'Volt On / CO2 Sparer',
    HEAVY_CURRENT: 'Starkstrom / Nachhaltigkeitsaktivist',
    POWER_PLANT: 'Kraftwerk / Greeny'
  },
  transactionType: {
    INVOICE: 'Rechnung',
    PAYMENT_CONFIRMATION: 'Zahlungsbestätigung'
  },
  transactionStatus: {
    OPEN: 'Offen',
    USER_APPROVAL_PENDING: 'Kundengenehmigung ausstehend',
    UNDER_ASSESSMENT: 'In Prüfung',
    APPROVED: 'Genehmigt',
    PAID: 'Bezahlt',
    REJECTED: 'Abgelehnt'
  },
  projectStatus: {
    OFFER_REQUESTED: 'Installationsofferte beantragt',
    OFFER_RECEIVED: 'Installationsofferte erhalten',
    CHECK_OPEN: 'Prüfung offen',
    CHECK_APPROVE_REQUESTED: '2. Prüfung offen',
    CHECK_FAILED: 'Prüfung fehlgeschlagen',
    CHECK_DONE: 'Prüfung abgeschlossen',
    CONTRACTS_ISSUED: 'Verträge ausgestellt',
    CLIENT_SIGNED: 'Kunde unterschreibt',
    LOAN_OPEN: 'Kredit offen',
    LOAN_CLOSED: 'Kredit abgeschlossen',
    // KOTlIN STATUSES
    quoteApproved: 'Finanzierung für Offerte angefragt',
    questionaireSubmitted: 'Prüfungsfragebogen ausgefüllt - Prüfung offen',
    kycPassed: 'Prüfungsfragebogen ausgefüllt - Prüfung offen',
    kycFailed: 'Prüfungsfragebogen ausgefüllt - Prüfung offen',
    creditCheckCompleted: 'Prüfungsfragebogen ausgefüllt - Prüfung offen',
    fourEyeCheckToBeReviewedBySuperAdmin: 'Prüfungsfragebogen ausgefüllt - Prüfung offen',
    reopened: 'Prüfungsfragebogen ausgefüllt - Prüfung offen',
    fourEyeCheckPassed: 'Prüfung angenommen',
    fourEyeCheckFailed: 'Prüfung abgelehnt',
    closed: 'Closed'
  },
  offerStatus: {
    PENDING: 'Offen',
    CREATED: 'Geschaffen',
    ACCEPTED: 'Angenommen',
    DECLINED: 'Abgelehnt',
    EXPIRED: 'Abgelaufen',
    OFFER_IN_DELIVERY: 'Bei Lieferung',
    OFFER_COMPLETED: 'Abgeschlossen'
  },
  financingOfferStatus: {
    indicative: 'Indikative',
    final: 'Finale'
  },
  role: {
    ADMIN: 'Admin',
    USER: 'Benutzer'
  },
  cantons: {
    AG: 'Aargau',
    AI: 'Appenzell I.Rh.',
    AR: 'Appenzell A.Rh.',
    BE: 'Bern',
    BL: 'Basel-Landschaft',
    BS: 'Basel-Stadt',
    FR: 'Freiburg',
    GE: 'Genf',
    GL: 'Glarus',
    GR: 'Graubünden',
    JU: 'Jura',
    LU: 'Luzern',
    NE: 'Neuenburg',
    NW: 'Nidwalden',
    OW: 'Obwalden',
    SG: 'Sankt Gallen',
    SH: 'Schaffhausen',
    SO: 'Solothurn',
    SZ: 'Schwyz',
    TG: 'Thurgau',
    TI: 'Tessin',
    UR: 'Uri',
    VD: 'Waadt',
    VS: 'Wallis',
    ZG: 'Zug',
    ZH: 'Zürich'
  },
  country: {
    SWITZERLAND: 'Schweiz',
    GERMANY: 'Deutschland',
    AUSTRIA: 'Österreich',
    ITALY: 'Italien',
    FRANCE: 'Frankreich',
    LIECHTENSTEIN: 'Liechtenstein',
    AFGHANISTAN: 'Afghanistan',
    ALBANIA: 'Albanien',
    ALGERIA: 'Algerien',
    ANDORRA: 'Andorra',
    ANGOLA: 'Angola',
    ANTIGUA_AND_DEPS: 'Antigua Und Abhängigkeiten',
    ARGENTINA: 'Argentinien',
    ARMENIA: 'Armenien',
    AUSTRALIA: 'Australien',
    AZERBAIJAN: 'Aserbaidschan',
    BAHAMAS: 'Bahamas',
    BAHRAIN: 'Bahrain',
    BANGLADESH: 'Bangladesch',
    BARBADOS: 'Barbados',
    BELARUS: 'Weißrussland',
    BELGIUM: 'Belgien',
    BELIZE: 'Belize',
    BENIN: 'Benin',
    BHUTAN: 'Bhutan',
    BOLIVIA: 'Bolivien',
    BOSNIA_HERZEGOVINA: 'Bosnien Herzegowina',
    BOTSWANA: 'Botswana',
    BRAZIL: 'Brasilien',
    BRUNEI: 'Brunei',
    BULGARIA: 'Bulgarien',
    BURKINA: 'Burkina',
    BURUNDI: 'Burundi',
    CAMBODIA: 'Kambodscha',
    CAMEROON: 'Kamerun',
    CANADA: 'Kanada',
    CAPE_VERDE: 'Kap Verde',
    CENTRAL_AFRICAN_REP: 'Zentralafrikanische Republik',
    CHAD: 'Tschad',
    CHILE: 'Chile',
    CHINA: 'China',
    COLOMBIA: 'Kolumbien',
    COMOROS: 'Komoren',
    CONGO: 'Kongo',
    CONGO_DEMOCRATIC_REP: 'Demokratische Republik Kongo',
    COSTA_RICA: 'Costa Rica',
    CROATIA: 'Kroatien',
    CUBA: 'Kuba',
    CYPRUS: 'Zypern',
    CZECH_REPUBLIC: 'Tschechische Republik',
    DENMARK: 'Dänemark',
    DJIBOUTI: 'Dschibuti',
    DOMINICA: 'Dominica',
    DOMINICAN_REPUBLIC: 'Dominikanische Republik',
    EAST_TIMOR: 'Osttimor',
    ECUADOR: 'Ecuador',
    EGYPT: 'Ägypten',
    EL_SALVADOR: 'El Salvador',
    EQUATORIAL_GUINEA: 'Äquatorialguinea',
    ERITREA: 'Eritrea',
    ESTONIA: 'Estland',
    ETHIOPIA: 'Äthiopien',
    FIJI: 'Fidschi',
    FINLAND: 'Finnland',
    GABON: 'Gabun',
    GAMBIA: 'Gambia',
    GEORGIA: 'Georgien',
    GHANA: 'Ghana',
    GREECE: 'Griechenland',
    GRENADA: 'Grenada',
    GUATEMALA: 'Guatemala',
    GUINEA: 'Guinea',
    GUINEA_BISSAU: 'Guinea Bissau',
    GUYANA: 'Guyana',
    HAITI: 'Haiti',
    HONDURAS: 'Honduras',
    HUNGARY: 'Ungarn',
    ICELAND: 'Island',
    INDIA: 'Indien',
    INDONESIA: 'Indonesien',
    IRAN: 'Iran',
    IRAQ: 'Irak',
    IRELAND_REPUBLIC: 'Republik Irland',
    ISRAEL: 'Israel',
    IVORY_COAST: 'Elfenbeinküste',
    JAMAICA: 'Jamaika',
    JAPAN: 'Japan',
    JORDAN: 'Jordanien',
    KAZAKHSTAN: 'Kasachstan',
    KENYA: 'Kenia',
    KIRIBATI: 'Kiribati',
    KOREA_NORTH: 'Nordkorea',
    KOREA_SOUTH: 'Südkorea',
    KOSOVO: 'Kosovo',
    KUWAIT: 'Kuwait',
    KYRGYZSTAN: 'Kirgisistan',
    LAOS: 'Laos',
    LATVIA: 'Lettland',
    LEBANON: 'Libanon',
    LESOTHO: 'Lesotho',
    LIBERIA: 'Liberia',
    LIBYA: 'Libyen',
    LITHUANIA: 'Litauen',
    LUXEMBOURG: 'Luxemburg',
    MACEDONIA: 'Mazedonien',
    MADAGASCAR: 'Madagaskar',
    MALAWI: 'Malawi',
    MALAYSIA: 'Malaysia',
    MALDIVES: 'Malediven',
    MALI: 'Mali',
    MALTA: 'Malta',
    MARSHALL_ISLANDS: 'Marshallinseln',
    MAURITANIA: 'Mauretanien',
    MAURITIUS: 'Mauritius',
    MEXICO: 'Mexiko',
    MICRONESIA: 'Mikronesien',
    MOLDOVA: 'Moldawien',
    MONACO: 'Monaco',
    MONGOLIA: 'Mongolei',
    MONTENEGRO: 'Montenegro',
    MOROCCO: 'Marokko',
    MOZAMBIQUE: 'Mosambik',
    MYANMAR_BURMA: 'Myanmar Birma',
    NAMIBIA: 'Namibia',
    NAURU: 'Nauru',
    NEPAL: 'Nepal',
    NETHERLANDS: 'Niederlande',
    NEW_ZEALAND: 'Neuseeland',
    NICARAGUA: 'Nicaragua',
    NIGER: 'Niger',
    NIGERIA: 'Nigeria',
    NORWAY: 'Norwegen',
    OMAN: 'Oman',
    PAKISTAN: 'Pakistan',
    PALAU: 'Palau',
    PANAMA: 'Panama',
    PAPUA_NEW_GUINEA: 'Papua-Neuguinea',
    PARAGUAY: 'Paraguay',
    PERU: 'Peru',
    PHILIPPINES: 'Philippinen',
    POLAND: 'Polen',
    PORTUGAL: 'Portugal',
    QATAR: 'Katar',
    ROMANIA: 'Rumänien',
    RUSSIAN_FEDERATION: 'Russische Föderation',
    RWANDA: 'Ruanda',
    ST_KITTS_AND_NEVIS: 'St. Kitts Und Nevis',
    ST_LUCIA: 'St. Lucia',
    SAINT_VINCENT_AND_THE_GRENADINES: 'St. Vincent Und Die Grenadinen',
    SAMOA: 'Samoa',
    SAN_MARINO: 'San Marino',
    SAO_TOME_AND_PRINCIPE: 'Sao Tome Und Principe',
    SAUDI_ARABIA: 'Saudi-Arabien',
    SENEGAL: 'Senegal',
    SERBIA: 'Serbien',
    SEYCHELLES: 'Seychellen',
    SIERRA_LEONE: 'Sierra Leone',
    SINGAPORE: 'Singapur',
    SLOVAKIA: 'Slowakei',
    SLOVENIA: 'Slowenien',
    SOLOMON_ISLANDS: 'Salomonen',
    SOMALIA: 'Somalia',
    SOUTH_AFRICA: 'Südafrika',
    SOUTH_SUDAN: 'Südsudan',
    SPAIN: 'Spanien',
    SRI_LANKA: 'Sri Lanka',
    SUDAN: 'Sudan',
    SURINAME: 'Suriname',
    SWAZILAND: 'Eswatini',
    SWEDEN: 'Schweden',
    SYRIA: 'Syrien',
    TAIWAN: 'Taiwan',
    TAJIKISTAN: 'Tadschikistan',
    TANZANIA: 'Tansania',
    THAILAND: 'Thailand',
    TOGO: 'Togo',
    TONGA: 'Tonga',
    TRINIDAD_AND_TOBAGO: 'Trinidad Und Tobago',
    TUNISIA: 'Tunesien',
    TURKEY: 'Türkei',
    TURKMENISTAN: 'Turkmenistan',
    TUVALU: 'Tuvalu',
    UGANDA: 'Uganda',
    UKRAINE: 'Ukraine',
    UNITED_ARAB_EMIRATES: 'Vereinigte Arabische Emirate',
    UNITED_KINGDOM: 'Vereinigtes Königreich',
    UNITED_STATES: 'Vereinigte Staaten',
    URUGUAY: 'Uruguay',
    UZBEKISTAN: 'Usbekistan',
    VANUATU: 'Vanuatu',
    VATICAN_CITY: 'Vatikanstadt',
    VENEZUELA: 'Venezuela',
    VIETNAM: 'Vietnam',
    YEMEN: 'Jemen',
    ZAMBIA: 'Sambia',
    ZIMBABWE: 'Simbabwe'
  },
  gender: {
    MALE: 'Männlich',
    FEMALE: 'Weiblich',
    OTHER: 'Andere'
  },
  months: {
    Jan: 'Januar',
    Feb: 'Februar',
    Mar: 'März',
    Apr: 'April',
    May: 'Mai',
    Jun: 'Juni',
    Jul: 'Juli',
    Aug: 'August',
    Sep: 'September',
    Oct: 'Oktober',
    Nov: 'November',
    Dec: 'Dezember'
  },
  languages: { fr: 'FR', de: 'DE' },
  // BASIC WORDS
  reject: 'Ablehnen',
  iban: 'IBAN',
  days: 'Tage',
  dateOfBirth: 'Geburtsdatum',
  placeOfBirth: 'Geburtsort',
  solarSystemHeading: 'Solaranlage',
  installationPartner: 'Installationspartner',
  noOptions: 'Keine Optionen',
  close: 'Schließen',
  loading: 'Laden',
  download: 'download',
  print: 'drucken',
  saveAsProject: 'Als Projekt speichern',
  confirm: 'Bestätigen',
  search: 'Suche',
  searchAddress: 'Suche hier nach der Adresse deines Objekts.',
  searchFile: 'Datei auswahlen',
  next: 'Weiter',
  back: 'Zurück',
  save: 'Speichern',
  nextStep: 'Nächster Schritt',
  getQuote: 'Offerte einholen',
  startFinancing: 'Finanzierungsprüfung starten',
  fromKleiv: 'KLEIV',
  years: 'Jahre',
  depozit: 'Anzahlung',
  sending: 'Absenden',
  to: 'Bis',
  endDate: 'Enddatum',
  startDate: 'Startdatum',
  resetAllFilters: 'Alle Filter zurücksetzen',
  date: 'Datum',
  notFound: 'Nicht gefunden',
  all: 'Alle',
  month: 'Monat',
  fileUploadedSuccesfull: 'Datei erfolgreich hochgeladen',
  transactionUpdated: 'Transaktion aktualisiert',
  filter: 'Filter',
  pleaseLeaveComment: 'Bitte Kommentar hinterlassen',
  selectStatus: 'Status Auswählen',
  monthly: 'p. / Monat',
  uploadPDF: 'PDF hochladen',
  credit: 'Kredit',
  total: 'Gesamt',
  year: 'Jahr',
  open: 'Offen',
  level: 'Level',
  changesSaved: 'Änderungen gespeichert',
  changeStatus: 'Status ändern',
  saveChanges: 'Änderungen speichern',
  cancel: 'Abbrechen',
  yes: 'Ja',
  no: 'Nein',
  copy: 'Kopieren',
  call: 'Anruf',
  address: 'Adresse',
  value: 'Wert',
  kilowatt: 'kWh',
  uploaded: 'Hochgeladen',
  verifyEmail:
    'Wenn Sie die Bestätigungs-E-Mail nicht erhalten haben oder sie abgelaufen ist, klicken Sie auf die Schaltfläche unten, um sie erneut zu senden.',
  sendVerificationEmailAgain: 'E-Mail-Bestätigung erneut senden',
  companyHeadquarters: 'Firmensitz',
  email: 'E-mail',
  nextAction: 'Nächste Aktion',
  from: 'ab',
  pageNotFound: 'Seite nicht gefunden',
  backToHomepage: 'Zurück zur Startseite',
  companyName: 'Firmenname',
  name: 'Vorname',
  lastName: 'Nachname',
  emailAddress: 'E-Mail Adresse',
  phoneNumber: 'Telefonnummer',
  password: 'Passwort',
  confirmPassword: 'Passwort wiederholen',
  street: 'Straße',
  houseNumber: 'Hsnr.',
  streetAndHouseNumber: 'Straße und Hausnummer',
  zipCode: 'PLZ',
  location: 'Ort',
  mobileNumber: 'Telefonnummer (Mobile)',
  francsMonthly: 'Franken / Monat',
  downloadAcceptanceReport: 'Abnahmerapport herunterladen',
  offerDetails: 'Offertendetails',
  customerNumber: 'Kundennummer',
  fullName: 'Vor- und Nachname',
  bankDetails: 'Bankangaben',
  switzerland: 'Schweiz',
  leave: 'Verlassen',
  personalDetails: 'Personenangaben',
  products: 'Produkte',
  status: 'Status',
  reopen: 'Wiedereröffnung',
  toMyProfile: 'Zu meinem Profil',
  toHomepage: 'Zur Startseite',
  restartIdentification: 'Identifikation neu starten',
  uploadSuccessful: 'Upload Erfolgreich',
  monthlyIncome: 'Monetäres Einkommen',
  monthlyTransportationCosts: 'Monatliche Transportkosten',
  readyForUpload: 'Bereit zum hochladen',
  edit: 'Bearbeiten',
  contactInvesterra: 'Kontakt zu investerra'
};
