import './InfoField.scss';
import Tooltip from '../Tooltip/Tooltip';
import { TootipType } from '../../types/util.types';
import Text from '../Text/Text';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface InfoFieldProps {
  label: string;
  disabled?: boolean;
  children?: JSX.Element | string | number;
  tooltipText?: TootipType;
  className?: string;
  variety?: number;
  buttonIcon?: string;
  onClick?: () => void;
}

const InfoField = ({
  label,
  children,
  tooltipText,
  disabled = false,
  className,
  variety,
  buttonIcon,
  onClick
}: InfoFieldProps) => {
  const { t } = useTranslation();

  const classNameProps = `${className ? className : ''}  info-field-wrapper ${
    disabled ? 'disabled' : ''
  }`;

  return (
    <div className={classNameProps}>
      <div className={`info-text v${variety}`}>
        <Text category="label" size="large" variety={1} disabled={disabled}>
          {label}
        </Text>
        {variety === 2 && (
          <Text as="span" category="headline" size="medium" className="value">
            {children}
          </Text>
        )}
        {disabled || (children === undefined && <span className="no-value">-</span>)}
        {variety !== 2 && !disabled && children && (
          <Text as="span" category="headline" size="medium">
            {children}
          </Text>
        )}
      </div>
      <div className="info-value-wrapper">
        {tooltipText && <Tooltip heading={tooltipText?.heading} text={tooltipText?.text} />}
        {onClick && (
          <Button
            icon={buttonIcon ? buttonIcon : undefined}
            iconPosition="right"
            variation="secondary"
            size="large"
            dataTest=""
            onClick={onClick}>
            {t('details')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default InfoField;
