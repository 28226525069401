import { KycStatus } from '../profile/profile.types';
import { Address } from '../questionnaire/questionnaire.types';

export interface User {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  id?: number;
  address?: Address;
  kycStatus?: KycStatus;
  phoneNumber?: string;
  role?: UserRoles[] | undefined;
  emailVerified?: boolean;
  hasError?: boolean;
  twoFactorAuth?: boolean;
  partnerId?: number;
  bankName?: string;
  iBAN?: string;
}

export enum UserRoles {
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
  USER = 'USER',
  REVIEWER = 'REVIEWER',
  SUPER_ADMIN = 'SUPERADMIN'
}

export enum UserActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  VERIFY_MAIL = 'VERIFY_MAIL',
  SET_TWO_FACTOR_AUTH = 'SET_TWO_FACTOR_AUTH',
  UPDATE_USER = 'UPDATE_USER',
  SET_ERROR = 'SET_ERROR'
}

export interface LoginUser {
  type: UserActions.LOGIN;
  payload: User;
}

export interface LogoutUser {
  type: UserActions.LOGOUT;
}

export interface VerifyMail {
  type: UserActions.VERIFY_MAIL;
}

export interface SetTwoFactorAuth {
  type: UserActions.SET_TWO_FACTOR_AUTH;
  payload: boolean;
}

export interface UpdateUser {
  type: UserActions.UPDATE_USER;
  payload: Partial<User>;
}

export interface SetError {
  type: UserActions.SET_ERROR;
}

export type UserActionTypes =
  | LoginUser
  | LogoutUser
  | VerifyMail
  | SetTwoFactorAuth
  | UpdateUser
  | SetError;
