import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import { RouterProvider } from 'react-router-dom';
import './i18n/i18n';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect } from 'react';
import { loginUser, setLoginError } from './store/user/actions';
import { createProfile, getProfile } from './store/profile/profile.actions';
import { AuthContext } from './context/AuthContextProvider';
import api, { kotlinApi } from './api/api';
import { InvProfile } from './store/profile/profile.types';
import { userCreation } from './utils/general.utils';
import { RootState } from './reducers/rootReducer';
import { User } from './store/user/types';
import router from './Routes';
import { AxiosInstance } from 'axios';
import LoginErrorNotification from './components/LoginErrorNotification/LoginErrorNotification';

const App = () => {
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);

  const userReducer = useSelector<RootState>((state) => state.userReducer) as User;

  const customInterceptor = () => {
    const instances: AxiosInstance[] = [api, kotlinApi];

    instances.map((i) => {
      i.interceptors.request.use(
        (config) => {
          if (auth?.keycloak.token) {
            const callback = () => {
              config.headers.Authorization = `Bearer ${auth.getToken()}`;
              return Promise.resolve(config);
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return auth.updateToken(callback) as any;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    });
  };

  customInterceptor();

  const findUserRole = async () => {
    const user = (await auth?.keycloak.loadUserProfile()) as InvProfile;
    try {
      const profile = await getProfile(true);
      profile && dispatch(loginUser(userCreation(profile)));
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      try {
        const createUser = await createProfile({
          email: user.email as string,
          firstName: user.firstName as string,
          lastName: user.lastName as string,
          role: user.attributes.ROLE_
        });
        createUser && dispatch(loginUser(userCreation(createUser)));
      } catch (error) {
        dispatch(setLoginError());
      }
    }
  };

  useEffect(() => {
    auth?.isAuthenticated && !userReducer.id && findUserRole();
  }, []);

  return (
    <>
      <ToastContainer className="toast-style" limit={5} transition={Slide} />
      <RouterProvider router={router} />
      {userReducer.hasError && <LoginErrorNotification />}
    </>
  );
};

export default App;
