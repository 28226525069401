import Navigation from '../Navigation/Navigation';
import './Header.scss';
import LogoGreen from '../../assets/Images/ivesterra-logo-green.png';
import useNavigation from '../../hooks/useNavigation';

const Header = () => {
  const { goTo } = useNavigation();

  return (
    <div className="header">
      <div>
        <img
          data-test="homepage-investerra-link"
          src={LogoGreen}
          alt="Company logo"
          className="logo"
          onClick={() => {
            goTo('/');
          }}
        />
      </div>
      <Navigation />
    </div>
  );
};

export default Header;
