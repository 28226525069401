import './Checkbox.scss';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

interface CheckboxProps {
  label?: string | number;
  checked: boolean;
  type?: 'round' | 'squared' | 'radio';
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

const Checkbox = ({
  label,
  checked,
  type = 'squared',
  onClick,
  disabled,
  required,
  className
}: CheckboxProps) => {
  return (
    <div
      className={`checkbox-wrapper ${className ? className : ''} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}>
      <span className={`checkbox ${type} ${checked ? 'checked' : ''}`} tabIndex={0}>
        <Icon name="check" />
      </span>
      {label && (
        <Text category="label" as="label" size="large">
          {`${label}${required ? '*' : ''}`}
        </Text>
      )}
    </div>
  );
};

export default Checkbox;
