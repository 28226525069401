import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { useState } from 'react';
import { RootState } from '../../../reducers/rootReducer';
import { useSelector } from 'react-redux';
import { User, UserRoles } from '../../../store/user/types';

const ProjectsOverivew = () => {
  const { t } = useTranslation();
  // const { state } = useLocation();
  // const { status } = state || {};

  const user = useSelector<RootState>((state) => state.userReducer) as User;

  // const [projectStatus, setProjectStatus] = useState<ProjectStatus>('' as ProjectStatus);
  const [projectId, setProjectId] = useState<number>();

  // const isContractsDisabled =
  //   projectStatus === ProjectStatus.OFFER_REQUESTED ||
  //   projectStatus === ProjectStatus.OFFER_RECEIVED ||
  //   projectStatus === ProjectStatus.CHECK_OPEN ||
  //   projectStatus === ProjectStatus.CHECK_APPROVE_REQUESTED ||
  //   projectStatus === ProjectStatus.CHECK_FAILED ||
  //   projectStatus === ProjectStatus.CHECK_DONE;
  // const isInvoicesDisabled =
  //   isContractsDisabled ||
  //   projectStatus === ProjectStatus.CONTRACTS_ISSUED ||
  //   projectStatus === ProjectStatus.CLIENT_SIGNED;

  // const tabs = [
  //   {
  //     key: 'projectOverview',
  //     comp: (
  //       <ProjectDetails
  //         role={user.role as UserRoles[]}
  //         // setStatus={setProjectStatus}
  //         setProjectId={setProjectId}
  //       />
  //     )
  //   }
  //   {
  //     key: 'contracts',
  //     comp: <ContractsTab userUuid={user.uid} projectId={projectId as number} />,
  //     disabled: isContractsDisabled
  //   },
  //   {
  //     key: 'invoices',
  //     comp: <UserInvoices projectId={projectId as number} />,
  //     disabled: isInvoicesDisabled
  //   }
  // ];

  // const checkPreselectedTab = () => {
  //   switch (status) {
  //     case ProjectStatus.CONTRACTS_ISSUED: {
  //       return 1;
  //     }
  //     case ProjectStatus.CLIENT_SIGNED: {
  //       return 2;
  //     }
  //     default:
  //       return 0;
  //   }
  // };

  return (
    <div>
      <Text as="h2" category="display" size="medium" className="heading">
        {`${t('myProfile.myProjects.labels.project')}: ${projectId ? projectId : ''}`}
      </Text>
      {/* <Tabs preSelectedTabIndex={checkPreselectedTab()}>
        {tabs.map((tab, index) => {
          return (
            <TabContent
              title={`${t(`projectOverview.tabs.${tab.key}`)}`}
              key={index}
              disabled={tab.disabled}>
              {tab.comp}
            </TabContent>
          );
        })}
      </Tabs> */}
      <ProjectDetails role={user.role as UserRoles[]} setProjectId={setProjectId} />
    </div>
  );
};

export default ProjectsOverivew;
