import { useTranslation } from 'react-i18next';
import Text from '../../components/Text/Text';
import './KycIdentification.scss';
import Radio from '../../components/Inputs/Radio/Radio';
import { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import { getKycLink } from '../../store/kyc/kyc.action';
import QRCode from 'react-qr-code';
import Icon from '../../components/Icon/Icon';
import { copyToClipboard } from '../../utils/general.utils';

const KycIdentification = () => {
  const { t } = useTranslation();

  const [kycProcess, setKycProcess] = useState({ qr: true, browser: false, link: '', id: '' });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      getKyc();
    }
  }, [loading]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const getKyc = async () => {
    const data = await getKycLink();

    data &&
      setKycProcess((prev) => ({
        ...prev,
        link: data as string,
        id: data.split('/').pop() as string
      }));
  };

  return (
    <div className="kyc-identification-wrapper">
      <Text as="h2" category="display" size="medium">
        {t('kycIdentification.heading')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('kycIdentification.text')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('kycIdentification.thirdPartyText')}
      </Text>
      <div className="kyc-radio-actions-wrapper">
        <Radio
          className="filter-radio"
          variation="secondary"
          label={t('kycIdentification.verificationWithQrCode')}
          value="qrCode"
          checked={kycProcess.qr}
          onClick={() => {
            setKycProcess((prev) => ({ ...prev, qr: !prev.qr, browser: false }));
          }}
        />
        <Radio
          className="filter-radio"
          variation="secondary"
          label={t('kycIdentification.verifyWithLink')}
          value="browser"
          checked={kycProcess.browser}
          onClick={() => {
            setKycProcess((prev) => ({ ...prev, browser: !prev.browser, qr: false }));
          }}
        />
      </div>
      {loading && (
        <Text as="p" category="body" size="medium">
          {t('loading')}
        </Text>
      )}
      {kycProcess.qr && kycProcess.link && !loading && (
        <>
          <Text as="p" category="body" size="medium">
            {t('kycIdentification.prepareSmartphone')}
          </Text>
          <QRCode value={kycProcess.link} className="kyc-link-qr" />
          <Text as="span" category="body" size="medium">
            {t('kycIdentification.scanAndStart')}
          </Text>
        </>
      )}
      {kycProcess.browser && kycProcess.link && !loading && (
        <>
          <Text as="p" category="body" size="medium">
            {t('kycIdentification.buttonDescription')}
          </Text>
          <Button
            variation="primary"
            size="large"
            dataTest=""
            onClick={() => {
              window.open(kycProcess.link, '_blank');
            }}>
            {t('kycIdentification.buttonText')}
          </Button>
        </>
      )}
      {kycProcess.id && !loading && (
        <div className="ident-id-wrapper">
          <Text as="p" category="label" size="medium">
            {t('kycIdentification.yourIdentId')}
          </Text>
          <Text as="span" className="ident-id" category="label" size="large">
            {kycProcess.id}
            <Icon name="copy" width={24} onClick={() => copyToClipboard(kycProcess.id)} />
          </Text>
        </div>
      )}
    </div>
  );
};

export default KycIdentification;
