import { useEffect, useState } from 'react';
import TableHead from '../../../../components/Tables/TableHead/TableHead';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/Text/Text';
import { ProductType } from '../../../../store/products/products.types';
import './Products.scss';
import { getAllProducts } from '../../../../store/products/products.actions';

enum ProductsTableHeading {
  PRODUCT_NAME = 'productName'
}

const Products = () => {
  const { t } = useTranslation();

  const [products, setProducts] = useState<ProductType[]>([] as ProductType[]);

  const projectTableHeadings = Object.values(ProductsTableHeading).map((elem) => elem);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const resp = await getAllProducts();
    resp && setProducts(resp);
  };

  return (
    <div className={'products-table-wrapper'}>
      <TableHead
        columnWidths={[100]}
        translationKey={'admin.partnerProducts.table.heading'}
        values={projectTableHeadings}>
        {products.map((elem: ProductType, index) => (
          <tr key={index}>
            <Text as="td" category="label" size="large">
              {t(`partnerProducts.${elem.type}`)}
            </Text>
          </tr>
        ))}
      </TableHead>
    </div>
  );
};

export default Products;
