import { useTranslation } from 'react-i18next';
import InfoField from '../../../../../components/InfoField/InfoField';
import Text from '../../../../../components/Text/Text';
import arrowRight from '../../../../../assets/Icons/arrow-right-green.svg';
import { ModalTitle } from '../ProjectReview';
import {
  ComplianceCheckData,
  ExemptAmountType,
  Get4EyeProjectDetails
} from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';

interface UserProjectDataProps {
  projectDetails?: Get4EyeProjectDetails;
  openModal: (modalTitle: ModalTitle) => void;
}

const UserProjectData = ({ projectDetails, openModal }: UserProjectDataProps) => {
  const { t } = useTranslation();

  const complianceCheckData = projectDetails?.creditCheckData?.complianceCheckData;

  const complianceCheckDisplay = () => {
    if (complianceCheckData) {
      const lengths = Object.keys(complianceCheckData).map(
        (key) => complianceCheckData[key as keyof ComplianceCheckData].length
      );
      return lengths.join('/');
    } else {
      return '';
    }
  };

  const getCurrentLiabilitiesValue = () =>
    projectDetails?.creditCheckData?.kremoData?.exemptAmountList
      ?.filter((elem) => elem.type === ExemptAmountType.AFTER_CURRENT_LIABILITIES)
      ?.map((elem) => elem.amount)[0];

  const data = [
    { name: 'kycPersonalDetails', value: projectDetails?.kYCResultsData?.result },
    { name: 'experian', value: projectDetails?.creditCheckData?.experianData?.systemDecision },
    {
      name: 'kremo',
      value: getCurrentLiabilitiesValue()
    },
    { name: 'complianceCheck', value: complianceCheckDisplay() },
    { name: 'zek', value: projectDetails?.creditCheckData?.zekData?.zekScore },
    {
      name: 'financialDataValuation',
      value:
        projectDetails?.creditCheckData?.financialValuationData?.applicant
          .monthlySurplusInstallmentRatio
    }
  ];

  return (
    <div className="user-project-data-wrapper">
      <Text as="h2" category="headline">
        {t('admin.projectDetails.review')}
      </Text>
      <div>
        {data &&
          data.map((elem, ind) => (
            <InfoField
              variety={2}
              key={ind}
              className="field"
              label={t(`admin.projectDetails.projectReview.userProjectData.fields.${elem.name}`)}
              disabled={false}
              buttonIcon={arrowRight}
              onClick={
                elem.name !== 'creditCheck' && elem.name !== 'uploadedFiles'
                  ? () => {
                      openModal(elem.name as ModalTitle);
                    }
                  : undefined
              }>
              {getValueOrPlaceholder(elem.value)}
            </InfoField>
          ))}
      </div>
    </div>
  );
};

export default UserProjectData;
