import Text from '../../../../../components/Text/Text';
import { useTranslation } from 'react-i18next';
import TooltipHeader from '../../../../../components/TooltipHeader/TooltipHeader';
import Chip from '../../../../../components/Chip/Chip';
import './CantonsSelect.scss';
import { Canton } from '../../../../../store/partners/partners.types';
import { useEffect, useState } from 'react';
import { getAllCantons } from '../../../../../store/partners/partners.action';

interface CantonsSelectProps {
  selectedCantons: Canton[] | undefined;
  setSelectedCantons: (cantons: Canton[]) => void;
}

const CantonsSelect = ({ selectedCantons, setSelectedCantons }: CantonsSelectProps) => {
  const { t } = useTranslation();

  const [cantons, setCantons] = useState<Canton[]>();

  useEffect(() => {
    const fetchCantons = async () => {
      const data = await getAllCantons();
      data && setCantons(data);
    };

    fetchCantons();
  }, []);

  const handleSelect = (elem: Canton) => {
    if (selectedCantons?.some((c) => c.id === elem.id)) return;
    selectedCantons && setSelectedCantons([...selectedCantons, elem]);
  };

  const handleDelete = (elem: Canton) => {
    const updatedCantons = selectedCantons?.filter((canton) => canton.id !== elem.id);
    updatedCantons && setSelectedCantons(updatedCantons);
  };

  return (
    <div className="cantons-select-wrapper">
      <TooltipHeader
        heading={t('partners.geoCoverage')}
        tooltip={{
          text: t('partners.geoCoverageTooltip'),
          heading: t('partners.geoCoverage')
        }}
      />
      <Text>{t('partners.whichCantons')}</Text>
      <div className="cantons-list">
        <Chip
          label={t('partners.allSwiss')}
          onClick={() => {
            cantons && setSelectedCantons(cantons);
          }}
          onDelete={() => {
            setSelectedCantons([] as Canton[]);
          }}
          selected={selectedCantons?.length === cantons?.length}
        />
        {cantons?.map((canton) => {
          return (
            <Chip
              key={canton.id}
              label={t(`cantons.${canton.code}`)}
              selected={selectedCantons?.some((c) => c.name === canton.name)}
              onClick={() => {
                handleSelect(canton);
              }}
              onDelete={() => {
                handleDelete(canton);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CantonsSelect;
