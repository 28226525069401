import Text from '../../Text/Text';
import './Radio.scss';

interface RadioProps {
  value: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  variation?: 'primary' | 'secondary';
  onClick: (val: string) => void;
}

const Radio = ({
  value,
  label,
  checked,
  disabled,
  className,
  variation = 'primary',
  onClick
}: RadioProps) => {
  return (
    <div
      className={`radio-wrapper ${className || ''} ${variation}`}
      onClick={() => {
        if (disabled) return;
        onClick(value);
      }}>
      <Text as="label" category="label" size="large">
        {label}
      </Text>
      <input
        readOnly
        type="radio"
        value={value}
        checked={checked}
        className="radio"
        disabled={disabled}
      />
    </div>
  );
};

export default Radio;
