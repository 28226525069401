import React from 'react';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import './Chip.scss';

interface ChipProps {
  label: string;
  selected?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

const Chip = ({ label, selected, onClick, onDelete }: ChipProps) => {
  return (
    <div className={`chip-wrapper ${selected ? 'chip-selected' : ''}`}>
      <Text
        as={'span'}
        category={'label'}
        size={'large'}
        className={'chip-label'}
        onClick={onClick}>
        {label}
      </Text>
      {selected && <Icon name={'remove'} onClick={onDelete} className={'chip-delete-icon'} />}
    </div>
  );
};

export default Chip;
