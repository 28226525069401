import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { BankDetailsType, Profile, UpdateProfile } from '../../store/profile/profile.types';
import { User, UserRoles } from '../../store/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { validateIban } from '../../utils/validations.utils';
import TextInput from '../Inputs/TextInput/TextInput';

interface BankDetailsProps {
  user: Profile;
  className?: string;
  updateBankDetails: (data: Partial<UpdateProfile>) => void;
}

const BankDetails = ({ user, updateBankDetails, className }: BankDetailsProps) => {
  const { t } = useTranslation();
  const userReducer = useSelector<RootState>((state) => state.userReducer) as User;

  const [bankDetails, setBankDetails] = useState<BankDetailsType>({} as BankDetailsType);

  useEffect(() => {
    setBankDetails({
      bankName: user?.bankName,
      iBAN: user?.iBAN
    });
  }, [user]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    bankDetails &&
      setBankDetails((prev: BankDetailsType) => ({
        ...prev,
        [name]: value
      }));
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateBankDetails({ bankDetails });
  };

  const disableBankDetailsUpdate =
    (!userReducer?.role?.includes(UserRoles.ADMIN) && userReducer.uid
      ? false
      : userReducer.uid
      ? false
      : true) ||
    (!!bankDetails.iBAN && !!validateIban(bankDetails.iBAN));

  return (
    <>
      {user && (
        <form className={className} onSubmit={onSubmit}>
          <TextInput
            type="text"
            name="bankName"
            value={bankDetails.bankName || ''}
            placeholder={t('myProfile.bankDetails.bankName')}
            onChange={onChangeHandler}
          />
          {/* <FormInput
            name="accountHolder"
            type="text"
            placeholder={t('myProfile.bankDetails.accountHolder')}
            value={parseName(user.firstName, user.lastName) || ''}
            disabled
          /> */}
          {/* <FormInput
            name="address"
            type="text"
            placeholder={t('myProfile.bankDetails.address')}
            value={formatAddress(user?.address) || ''}
            disabled
          /> */}
          <TextInput
            name="iBAN"
            type="text"
            placeholder={t('iban')}
            value={bankDetails.iBAN || ''}
            onChange={onChangeHandler}
            validate={validateIban}
          />
          <Button
            type="submit"
            size="large"
            variation="primary"
            dataTest="update-profile"
            disabled={disableBankDetailsUpdate}>
            {t('saveChanges')}
          </Button>
        </form>
      )}
    </>
  );
};

export default BankDetails;
