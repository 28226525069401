import { Dispatch, useEffect, useState } from 'react';
import Search from '../../../../components/Inputs/Search/Search';
import './MapsContent.scss';
import { useTranslation } from 'react-i18next';
import { DeepPartial, PagableResponse, SetStateType, SortBy } from '../../../../types/util.types';
import CheckboxList from '../../../../components/CheckboxList/CheckboxList';
import { getAllPartners } from '../../../../store/partners/partners.action';
import { GetPartnersFilter, Partner } from '../../../../store/partners/partners.types';
import Button from '../../../../components/Button/Button';
import addIcon from '../../../../assets/Icons/add.svg';
import Text from '../../../../components/Text/Text';
import { ProjectActionTypes } from '../../../../store/project/project.reducer';
import { Project } from '../../../../store/project/project.types';

interface MapsContentProps {
  project: DeepPartial<Project>;
  isPartnerSelected: SetStateType<boolean>;
  dispatch: Dispatch<ProjectActionTypes>;
}

const MapsContent = ({ project, isPartnerSelected, dispatch }: MapsContentProps) => {
  const { t } = useTranslation();

  const filterInitialState: GetPartnersFilter = {
    keyword: '',
    page: 1,
    size: 3,
    cantonCode: project.cantonCode,
    verified: true,
    sort: [{ name: 'partner_level', sort: SortBy.DESCENDING }]
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [checkedPartners, setCheckedPartners] = useState<string[]>([]);
  const [partners, setPartners] = useState<PagableResponse<Partner>>();

  const [filter, setFilter] = useState(filterInitialState);

  useEffect(() => {
    let timeOutId: ReturnType<typeof setTimeout>;
    setIsLoading(true);

    if (filter.keyword) {
      timeOutId = setTimeout(() => {
        getPartners(filter);
      }, 2000);
    } else {
      getPartners(filter);
    }

    return () => {
      filter.keyword && clearTimeout(timeOutId);
    };
  }, [filter]);

  useEffect(() => {
    const checkedPartnersIds = getCheckedPartnersId(checkedPartners);
    isPartnerSelected(!!checkedPartnersIds?.filter((elem) => elem.id).length);
    dispatch({ type: 'CHANGE_PARTNERS', payload: checkedPartnersIds as Partner[] });
  }, [checkedPartners]);

  const getPartners = async (filter: GetPartnersFilter) => {
    const response = await getAllPartners(filter);
    response && setPartners(response);
    setIsLoading(false);
  };

  const getCheckedPartnersId = (checkedPartners: string[]) => {
    return partners?.content
      .filter((partner) => checkedPartners.includes(partner.name))
      .map((item) => ({ id: item.id }));
  };

  return (
    <div className="map-content-wrapper">
      <Text as="p" category="body" size="medium" className="partner-desc">
        {t('solarSystem.selectPartnerContent.selectUpToNumberPartners')}
      </Text>
      <Search
        setValue={(e: string) => {
          setFilter({ ...filter, keyword: e });
        }}
        placeholder={`${t('search')} partner`}
      />
      {!isLoading && !!partners?.content.length && (
        <CheckboxList
          labelForAll={t('solarSystem.selectPartnerContent.automaticSelection')}
          values={partners?.content.map((partner: Partner) => partner.name)}
          setChecked={setCheckedPartners}
          maxSelected={3}
        />
      )}
      {isLoading && (
        <Text as="span" category="label" size="medium">
          {t('loading')}...
        </Text>
      )}
      {partners?.totalElements && partners.totalElements > filter.size && (
        <Button
          dataTest="request_offer-more-partners-button"
          className="more-partners-btn"
          variation="transparent"
          size="small"
          icon={addIcon}
          onClick={() => {
            setFilter({ ...filter, size: filter.size + 3 });
          }}
          iconPosition="left">
          {t('solarSystem.selectPartnerContent.showMorePartners')}
        </Button>
      )}
    </div>
  );
};

export default MapsContent;
