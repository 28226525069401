import { Dispatch } from 'react';
import { Project } from '../../store/project/project.types';
import { DeepPartial } from '../../types/util.types';
import { ProjectActionTypes } from '../../store/project/project.reducer';
import { formatDate } from '../../utils/formatting.utils';
import {
  validateEmpty,
  validatePositiveNumber,
  validatePositiveNumberAllowEmpty
} from '../../utils/validations.utils';
import TextInput from '../Inputs/TextInput/TextInput';
import Search from '../Search/Search';
import { useTranslation } from 'react-i18next';
import { formatAddress } from '../../utils/formatting.utils';
import { getBuildingByAddress, searchMesAddress } from '../../store/mes/mes.actions';
import { InputError } from '../../views/UploadOffer/UploadOfferEdit';

interface EditProjectProps {
  project: DeepPartial<Project>;
  disable?: boolean;
  valueMissing?: InputError;
  dispatch?: Dispatch<ProjectActionTypes>;
}

const EditProject = ({
  project,
  disable = false,
  valueMissing,
  dispatch = () => {
    return;
  }
}: EditProjectProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeAddress = async (selectedAddress: any) => {
    const building =
      selectedAddress &&
      (await getBuildingByAddress({ detail: selectedAddress.value, num: selectedAddress.num }));
    building && dispatch({ type: 'CHANGE_BUILDING', payload: building });
  };

  const getAddresses = async (address: string) => {
    const data = await searchMesAddress(address);
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.results?.map((elem: any) => {
        const { label, ...attrs } = elem.attrs;
        return { ...attrs, value: label.replace(/<b>/g, '').replace(/<\/b>/g, '') };
      });
    } else {
      return [];
    }
  };

  return (
    <>
      <TextInput
        value={project.owner?.name}
        name="fullName"
        type="text"
        placeholder={t('fullName')}
        validate={validateEmpty}
        onChange={(e) => dispatch({ type: 'CHANGE_OWNER_NAME', payload: e.target.value })}
        disabled={disable}
        className={valueMissing?.name && !project.owner?.name ? 'error' : ''}
        required
      />
      <TextInput
        value={project.offer?.offeredPrice}
        name="offeredPrice"
        type="number"
        placeholder={`${t('offer.price')}`}
        validate={validatePositiveNumber}
        onChange={(e) =>
          dispatch({ type: 'CHANGE_OFFER_PRICE', payload: parseFloat(e.target.value) })
        }
        unit={`${t('currency.chf')}`}
        disabled={disable}
        className={valueMissing?.offeredPrice && !project.offer?.offeredPrice ? 'error' : ''}
        required
      />
      <Search
        initialValue={formatAddress(project.building?.address)}
        placeholder={t('uploadOffer.searchHereForAddress')}
        fetchData={getAddresses}
        onOptionSelect={changeAddress}
        onClear={() => {
          dispatch({ type: 'REMOVE_BUILDING' });
        }}
        className={valueMissing?.address && !project.building?.address?.street ? 'error' : ''}
        required
      />
      <TextInput
        value={project.offer?.subsidy}
        name="subsidy"
        type="number"
        placeholder={`${t('financalOfferPreview.financialSupport.label')}`}
        validate={validatePositiveNumber}
        onChange={(e) =>
          dispatch({ type: 'CHANGE_OFFER_SUBSIDY', payload: parseFloat(e.target.value) })
        }
        unit={`${t('currency.chf')}`}
        disabled
      />
      <TextInput
        value={project.offer?.area}
        name="area"
        type="number"
        placeholder={`${t('offer.pvSystem')}`}
        validate={validatePositiveNumberAllowEmpty}
        onChange={(e) => {
          dispatch({ type: 'CHANGE_OFFER_AREA', payload: parseFloat(e.target.value) });
        }}
        unit={`${t('units.meterSqared')}`}
        disabled={disable}
      />
      <TextInput
        value={project.offer?.performance}
        name="performance"
        type="number"
        placeholder={`${t('offer.plantPower')}`}
        validate={validatePositiveNumber}
        onChange={(e) =>
          dispatch({ type: 'CHANGE_PERFORMANCE', payload: parseFloat(e.target.value) })
        }
        unit={`${t('units.kilowattPerformance')}`}
        disabled={disable}
        className={valueMissing?.performance && !project.offer?.performance ? 'error' : ''}
        required
      />
      <TextInput
        value={formatDate(project.offer?.issuanceDate)}
        name="issuanceDate"
        type="date"
        placeholder={`${t('offer.issuanceDate')}`}
        onChange={(e) =>
          dispatch({ type: 'CHANGE_OFFER_ISSUANCE_DATE', payload: new Date(e.target.value) })
        }
        disabled={disable}
        className={
          valueMissing?.issuanceDate && !formatDate(project.offer?.issuanceDate) ? 'error' : ''
        }
        required
      />
      <TextInput
        value={formatDate(project.offer?.validThru)}
        name="validThru"
        type="date"
        placeholder={`${t('offer.validThru')}`}
        onChange={(e) =>
          dispatch({ type: 'CHANGE_OFFER_VALID_THRU_DATE', payload: new Date(e.target.value) })
        }
        disabled={disable}
        className={valueMissing?.validThru && !formatDate(project.offer?.validThru) ? 'error' : ''}
        required
      />
      <TextInput
        value={formatDate(project.offer?.deliveryDate)}
        name="deliveryDate"
        type="date"
        placeholder={`${t('offer.deliveryDate')}`}
        onChange={(e) =>
          dispatch({ type: 'CHANGE_OFFER_DELIVERY_DATE', payload: new Date(e.target.value) })
        }
        disabled={disable}
      />
    </>
  );
};

export default EditProject;
