import './Tooltip.scss';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';

interface TooltipProps {
  text: string;
  className?: string;
  heading?: string;
}

const Tooltip = ({ text, heading, className }: TooltipProps) => {
  const classNameProps = `tooltip-wrapper  ${className ? className : ''}`;

  return (
    <div className={classNameProps}>
      <Icon name="info" className="info-icon" />
      <div className="tooltip-text-wrapper">
        {heading && (
          <Text as="h3" category="headline" size="small" variety={1}>
            {heading}
          </Text>
        )}
        <Text as="p" category="label" size="medium">
          {text}
        </Text>
      </div>
    </div>
  );
};

export default Tooltip;
