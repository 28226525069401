import { useTranslation } from 'react-i18next';
import Text from '../../../../../components/Text/Text';
import Button from '../../../../../components/Button/Button';
import { NewProjectLog } from '../../../../../store/project/project.types';
import {
  declineFourEyeCheck,
  makeFourEyeCheckDecision,
  reopenFourEyeCheck
} from '../../../../../store/fourEyeCheck/fourEyeCheck.action';
import { toastUtil } from '../../../../../utils/toast.utils';
import { useParams } from 'react-router-dom';
import { SetStateType } from '../../../../../types/util.types';
import { FourEyeCheckStatus } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';

interface DataConfirmationProps {
  newLog: NewProjectLog;
  fourEyeCheckId?: string;
  fourEyeCheckStatus?: FourEyeCheckStatus;
  setNewLog: SetStateType<NewProjectLog>;
  getProjectDetails: (projectId: string) => void;
  toggle: () => void;
}

const DataConfirmation = ({
  newLog,
  fourEyeCheckId,
  fourEyeCheckStatus,
  setNewLog,
  getProjectDetails,
  toggle
}: DataConfirmationProps) => {
  const { t } = useTranslation();
  const { projectUuid } = useParams();

  const submitDecision = async () => {
    if (!fourEyeCheckId) return;

    let resp;
    if (fourEyeCheckStatus === FourEyeCheckStatus.TO_BE_REVIEWED_BY_SUPER_ADMIN) {
      resp = newLog.approved
        ? await reopenFourEyeCheck(fourEyeCheckId)
        : await declineFourEyeCheck(fourEyeCheckId);
    } else {
      resp = await makeFourEyeCheckDecision(fourEyeCheckId, newLog);
    }

    if (resp) {
      projectUuid && getProjectDetails(projectUuid);
      setNewLog((prev) => ({ ...prev, comment: '' }));
      toastUtil('success', t('uploadSuccessful'));
      toggle();
    }
  };

  return (
    <>
      <Text as="p" category="body" size="medium">
        {t('admin.projectDetails.projectReview.dataConfirmation.text')}
      </Text>
      <Button
        variation="primary"
        size="large"
        dataTest=""
        onClick={submitDecision}
        className="data-confirmation-button">
        {t('confirm')}
      </Button>
    </>
  );
};

export default DataConfirmation;
