import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import { useModal } from '../../hooks/useModal';
import { useTranslation } from 'react-i18next';
import { CompanyDetails } from '../../store/partners/partners.types';
import Radio from '../Inputs/Radio/Radio';
import { SetStateType } from '../../types/util.types';
import Button from '../Button/Button';

interface CompanySelectModalProps {
  companies: CompanyDetails[];
  setCompanies: SetStateType<CompanyDetails[]>;
}

const CompanySelectModal = ({ companies, setCompanies }: CompanySelectModalProps) => {
  const { t } = useTranslation();

  const { isOpened, toggle } = useModal();

  const [selectedCompany, setSelectedCompany] = useState<CompanyDetails>(companies[0]);

  useEffect(() => {
    toggle();
  }, []);

  const onClick = () => {
    selectedCompany && setCompanies([selectedCompany]);
    toggle();
  };

  return (
    <Modal isOpened={isOpened} close={toggle} title={t('partners.selectCompanyTitle')}>
      <div>
        {companies?.map((c, i) => (
          <Radio
            key={i}
            label={`${c.name} (${c.legalForm.shortName.de}) , ${c.address.street} ${c.address.houseNumber}, ${c.address.swissZipCode} ${c.address.city} `}
            value={c.uid}
            checked={c.uid === selectedCompany?.uid}
            onClick={(val) => {
              if (val === c.uid) setSelectedCompany(c);
            }}
            className={'filter-radio'}
          />
        ))}
        <Button size="large" variation="primary" dataTest="" onClick={onClick}>
          {t('saveChanges')}
        </Button>
      </div>
    </Modal>
  );
};

export default CompanySelectModal;
