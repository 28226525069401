import i18n from '../i18n/i18n';

export const hubspotLinks = {
  investerra: `https://investerra.ch/${i18n.language}/`,
  contact: 'http://www.investerra.ch/contact',
  about: 'http://www.investerra.ch/about',
  impresium: 'http://www.investerra.ch/imprint',
  dataProtection: 'https://investerra.ch/de/datenschutzerklaerung',
  termsOfUse: 'https://investerra.ch/de/nutzungsbedingungen',
  becomePartner: 'http://www.investerra.ch/professional',
  insurance: 'https://investerra.ch/de/versicherung',
  faq: 'http://www.investerra.ch/faq',
  legal: 'http://www.investerra.ch/legal',
  partnerTermsAndConditions:
    'https://139785987.fs1.hubspotusercontent-eu1.net/hubfs/139785987/Downloads/investerra-fachpartnervereinbarung.pdf',
  supportEmail: 'hallo@investerra.ch'
};
