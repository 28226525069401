import { useTranslation } from 'react-i18next';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';
import Button from '../../../../../components/Button/Button';
import { FinancialValuationData } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';

enum TableHeading {
  APPLICANT = 'applicant',
  PARTNER = 'partner'
}

interface FinancialValuationTableProps {
  financialValuationData?: FinancialValuationData;
  toggle: () => void;
}

const FinancialValuationTable = ({
  financialValuationData,
  toggle
}: FinancialValuationTableProps) => {
  const { t } = useTranslation();

  const tableHeadings = [...Object.values(TableHeading).map((elem) => elem)];

  const data = [
    {
      name: 'monthlyHousingCosts',
      applicatantExp: financialValuationData?.applicant.monthlyHousingCost,
      partnerExp: financialValuationData?.spouse?.monthlyHousingCost
    },
    {
      name: 'monthlyExpensesAlimony',
      applicatantExp: financialValuationData?.applicant.monthlyExpensesAlimony,
      partnerExp: financialValuationData?.spouse?.monthlyHousingCost
    },
    {
      name: 'monthlyEducationCosts',
      applicatantExp: financialValuationData?.applicant.monthlyEducationCosts,
      partnerExp: financialValuationData?.spouse?.monthlyEducationCosts
    },
    {
      name: 'monthlyProfessionalOrganizationFee',
      applicatantExp: financialValuationData?.applicant.monthlyProfessionalOrganizationFee,
      partnerExp: financialValuationData?.spouse?.monthlyProfessionalOrganizationFee
    },
    {
      name: 'monthlyOtherFixExpenses',
      applicatantExp: financialValuationData?.applicant.monthlyOtherFixExpenses,
      partnerExp: financialValuationData?.spouse?.monthlyOtherFixExpenses
    },
    {
      name: 'monthlyOneTimeExpenses',
      applicatantExp: financialValuationData?.applicant.monthlyOneTimeExpenses,
      partnerExp: financialValuationData?.spouse?.monthlyOneTimeExpenses
    },
    {
      name: 'totalMonthlyIncomeGrossAmount12',
      applicatantExp: financialValuationData?.applicant.totalMonthlyIncomeGrossAmount,
      partnerExp: financialValuationData?.spouse?.totalMonthlyIncomeGrossAmount
    },
    {
      name: 'monthlyIncomeGrossAmountMain12',
      applicatantExp: financialValuationData?.applicant.monthlyIncomeGrossAmountMain,
      partnerExp: financialValuationData?.spouse?.monthlyIncomeGrossAmountMain
    },
    {
      name: 'disposableMonthlyIncome',
      applicatantExp: financialValuationData?.applicant.disposableMonthlyIncome,
      partnerExp: financialValuationData?.spouse?.disposableMonthlyIncome
    },
    {
      name: 'monthlySurplusInstallmentRatio',
      applicatantExp: financialValuationData?.applicant.monthlySurplusInstallmentRatio,
      partnerExp: financialValuationData?.spouse?.monthlySurplusInstallmentRatio
    }
  ];

  return (
    <div className="financial-valuation-table-wrapper">
      <TableHead
        values={tableHeadings}
        columnWidths={[40, 30, 30]}
        translationKey="admin.projectDetails.projectReview.financialValuation.table.heading"
        emptyFirstCell>
        {data.map((elem, ind) => (
          <tr key={ind}>
            <Text as="td" category="label" size="large">
              {t(`admin.projectDetails.projectReview.financialValuation.table.data.${elem.name}`)}
            </Text>
            <Text as="td" category="label" size="large">
              {elem.applicatantExp}
            </Text>
            <Text as="td" category="label" size="large">
              {getValueOrPlaceholder(elem.partnerExp)}
            </Text>
          </tr>
        ))}
      </TableHead>
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default FinancialValuationTable;
