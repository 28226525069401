import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { User, UserRoles } from '../../store/user/types';
import Home from '../../views/Home/Home';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import Loader from '../Loader/Loader';
import i18n from '../../i18n/i18n';

interface ProtectedRouteProps {
  children?: JSX.Element;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const auth = useContext(AuthContext);
  const language = i18n.language;

  const userReducer = useSelector<RootState>((state) => state.userReducer) as User;
  const showHomepage = process.env.REACT_APP_SHOW_HOMEPAGE?.toString() == 'show';

  const homepageUrl = `/${language}`;

  if (!auth?.keycloak.token) {
    if (location.pathname !== homepageUrl) {
      auth?.keycloak.login();
      return (
        <>
          <Outlet />
        </>
      );
    }

    if (!showHomepage && location.pathname === homepageUrl) return <Navigate to="/my-profile" />;

    if (showHomepage && location.pathname === homepageUrl) return <Home />;

    return <Outlet />;
  }

  if (!userReducer.role && !userReducer.hasError) return <Loader />;

  if (userReducer.role?.includes(UserRoles.USER)) {
    if (location.pathname === homepageUrl) {
      return <Navigate to={`/${language}/my-profile`} />;
    }
    if (location.pathname.includes('/admin'))
      return <Navigate to={`${location.pathname.replace('/admin', homepageUrl)}`} />;
    if (location.pathname.includes('/partners')) {
      return <Navigate to={`${location.pathname.replace('/partners', homepageUrl)}`} />;
    }
  }

  if (
    userReducer.role?.includes(UserRoles.PARTNER) &&
    location.pathname.includes(`/${language}/partners`) &&
    !location.pathname.includes('register') &&
    userReducer.id &&
    userReducer?.partnerId === undefined
  ) {
    return <Navigate to={`/${language}/partners/register`} />;
  }

  if (userReducer.role?.includes(UserRoles.ADMIN) && location.pathname === homepageUrl) {
    return <Navigate to={`${location.pathname.replace(homepageUrl, `/${language}/admin`)}`} />;
  }

  if (userReducer.role?.includes(UserRoles.PARTNER) && location.pathname === homepageUrl) {
    return <Navigate to={`${location.pathname.replace(homepageUrl, `/${language}/partners`)}`} />;
  }

  if (userReducer.role?.includes(UserRoles.ADMIN) && !location.pathname.includes('/admin')) {
    return <Navigate to={`/${language}/admin`} />;
  } else if (
    userReducer.role?.includes(UserRoles.PARTNER) &&
    !location.pathname.includes('/partners')
  ) {
    return <Navigate to={`/${language}/partners`} />;
  }

  return children ? children : <Outlet />;
};
