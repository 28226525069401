import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Text from '../Text/Text';
import './Wizard.scss';
import { SetStateType } from '../../types/util.types';

interface Step {
  label: string;
  completed: boolean;
}

interface WizardProps {
  steps: Step[];
  activeStep: number;
  setActiveStep: SetStateType<number>;
}

const Wizard = ({ steps, activeStep, setActiveStep }: WizardProps) => {
  return (
    <div className="wizard-wrapper">
      {steps.map((elem, i) => {
        return (
          <div key={i} className="wizard-item">
            <div
              className="wizard-step"
              onClick={() => {
                setActiveStep(i + 1);
              }}>
              <Checkbox
                checked={activeStep === i + 1 || (elem.completed && activeStep !== i + 1)}
                type="round"
                disabled={elem.completed && activeStep !== i + 1}
              />
              <Text category="title" size="large" className="step-title">
                {elem.label}
              </Text>
            </div>
            {steps.length - 1 !== i && <div className="wizard-separator"></div>}
          </div>
        );
      })}
    </div>
  );
};

export default Wizard;
