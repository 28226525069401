import { useEffect, useState } from 'react';
import './CheckboxList.scss';
import { CheckboxType, SetStateType } from '../../types/util.types';
import Checkbox from '../Checkbox/Checkbox';

interface CheckboxListProps {
  values: string[];
  setChecked: SetStateType<string[]>;
  labelForAll: string;
  maxSelected: number;
}

const CheckboxList = ({ values, setChecked, labelForAll, maxSelected }: CheckboxListProps) => {
  const initialState = values.map((elem: string) =>
    Object.assign({}, { name: elem, checked: false, disabled: false })
  );

  const [options, setOptions] = useState<CheckboxType[]>(initialState);

  const isAllChecked = options.every((option: CheckboxType) => option.checked === true);

  useEffect(() => {
    const selectedNames = options.filter((elem: CheckboxType) => elem.checked === true);
    const hasUnselected = options.some(
      (option: CheckboxType) => option.disabled === false && option.checked === false
    );
    if (selectedNames.length === maxSelected && hasUnselected) {
      setOptions(
        options.map((elem) => ({
          ...elem,
          disabled: !elem.checked
        }))
      );
    }
    if (options.length) setChecked(selectedNames.map((elem: CheckboxType) => elem.name));
  }, [options]);

  const toggle = (option: CheckboxType) => {
    if (option.disabled) return;

    setOptions((prev) =>
      prev.map((elem) => ({
        ...elem,
        disabled: false,
        checked: elem.name === option.name ? !option.checked : elem.checked
      }))
    );
  };

  return (
    <div className="checkbox-list-wrapper">
      {options.length > 0 && options.length <= maxSelected && (
        <Checkbox
          onClick={() => {
            setOptions(options.map((elem) => ({ ...elem, checked: !isAllChecked })));
          }}
          label={labelForAll}
          checked={isAllChecked}
          className="select-all-label"
        />
      )}
      {options.map((elem: CheckboxType, index: number) => (
        <Checkbox
          label={elem.name}
          checked={elem.checked}
          disabled={elem.disabled}
          key={index}
          onClick={() => {
            toggle(elem);
          }}
        />
      ))}
    </div>
  );
};

export default CheckboxList;
