import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Text from '../Text/Text';
import { useEffect } from 'react';
import './PromptModal.scss';

interface PromptModalProps {
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const PromptModal = ({ showModal, onConfirm, onCancel }: PromptModalProps) => {
  const { t } = useTranslation();
  const { isOpened, toggle } = useModal();

  useEffect(() => {
    showModal && toggle();
  }, [showModal]);

  const closeModalHandler = () => {
    onCancel();
    toggle();
  };

  return (
    <Modal title={t('leaveModal.title')} isOpened={isOpened} close={closeModalHandler}>
      <Text>{t('leaveModal.description')}</Text>
      <div className={'leave-modal-buttons'}>
        <Button onClick={closeModalHandler} size={'large'} variation={'secondary'} dataTest="">
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm} size={'large'} variation={'primary'} dataTest="">
          {t('leave')}
        </Button>
      </div>
    </Modal>
  );
};

export default PromptModal;
