import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import TextArea from '../Inputs/TextArea/TextArea';
import './CommentBox.scss';

interface CommentBoxProps {
  value: string;
  disabled?: boolean;
  onSubmit: (comment: string) => void;
}

const CommentBox = ({ value, disabled = false, onSubmit }: CommentBoxProps) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState<string>(value || '');

  const handleSubmit = () => {
    onSubmit(comment);
  };

  return (
    <div className="comment-box">
      <TextArea
        name="comment"
        placeholder={t('admin.projectDetails.adminComment')}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        value={comment}
        onSubmit={handleSubmit}
        disabled={disabled}
      />
      <Icon name="arrow-up" className="submit-comment" onClick={handleSubmit} />
    </div>
  );
};

export default CommentBox;
