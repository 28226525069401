export const mesConfig = {
  apiKey: process.env.REACT_APP_MES_API_KEY,
  clientConfig: {
    layoutEditMode: 2,
    layoutModelPV: {
      layoutModel: 0,
      priorityModel: 1,
      maxRoofsSelectable: 10,
      maxRoofsDisplayed: 24,
      minAreaSelectable: 6.4,
      minAreaDisplayed: 0,
      minIrradSelectable: 800,
      minIrradDisplayed: 0,
      maxRoofsPreselected: 10,
      maxCoverageLayout: 'true'
    },
    initialize: [],
    specify: [
      { paramName: 'roofSuitability', paramPath: 'pvSystem.roofSuitability' },
      {
        paramName: 'actualNominalPower',
        paramPath: 'pvSystem.existingNominalPower'
      },
      {
        paramName: 'deviceTypeHeating',
        paramPath: 'energySystem.heating-0.deviceType',
        value: '_aux_5_2'
      },
      {
        paramName: 'deviceTypeHotwater',
        paramPath: 'energySystem.hotwater-0.deviceType',
        value: '_aux_5_2'
      },
      {
        paramName: 'energyUnit',
        paramPath: 'energySystem.heating-0.energyUnit'
      },
      {
        paramName: 'constructionYear',
        paramPath: 'building.constructionYear',
        value: '2012'
      },
      {
        paramName: 'constructionPeriod',
        paramPath: 'building.constructionPeriod'
      },
      {
        paramName: 'occupantNumber',
        paramPath: 'building.occupantNumber',
        value: '3'
      },
      {
        paramName: 'apartmentNumber',
        paramPath: 'building.apartmentNumber',
        value: '2'
      }
    ],
    result: [
      { paramName: 'nominalPower', paramPath: 'pvSystem.nominalPower' },
      { paramName: 'installedArea', paramPath: 'pvSystem.installedArea' },
      { paramName: 'Eac', paramPath: 'pvSystem.Eac' },
      {
        paramName: 'pvGrossInvest',
        paramPath: 'pvSystem.costAnalysis.grossInvest'
      },
      {
        paramName: 'pvSubsidies',
        paramPath: 'pvSystem.costAnalysis.subsidies'
      },
      {
        paramName: 'pvSelfInvest',
        paramPath: 'pvSystem.costAnalysis.selfInvest'
      },
      {
        paramName: 'pvTaxSavings',
        paramPath: 'pvSystem.costAnalysis.taxSavings'
      },
      {
        paramName: 'pvNetInvest',
        paramPath: 'pvSystem.costAnalysis.netInvest'
      },
      {
        paramName: 'autarkyDegree',
        paramPath: 'energySystem.ownConsumptionNetwork.autarkyDegree-0'
      },
      {
        paramName: 'ownConsumptionFraction',
        paramPath: 'energySystem.ownConsumptionNetwork.ownConsumptionFraction-0'
      },
      {
        paramName: 'suppliedOcn',
        paramPath: 'energySystem.ownConsumptionNetwork.suppliedOcn-0'
      },
      {
        paramName: 'suppliedEndenergy',
        paramPath: 'energySystem.totalConsumption.suppliedEndenergy-0'
      },
      {
        paramName: 'CO2emission',
        paramPath: 'energySystem.totalConsumption.CO2emission-0'
      },
      {
        paramName: 'heatingGrossInvest',
        paramPath: 'energySystem.heating-0.costAnalysis.grossInvest'
      },
      {
        paramName: 'heatingSubsidies',
        paramPath: 'energySystem.heating-0.costAnalysis.subsidies'
      },
      {
        paramName: 'feedinCurrent',
        paramPath: 'energySystem.ownConsumptionNetwork.feedinCurrent-0'
      },
      { paramName: 'companyName', paramPath: 'districtGrid.companyName' },
      { paramName: 'startingDate', paramPath: 'districtGrid.startingDate' },
      { paramName: 'livingArea', paramPath: 'building.livingArea' },
      {
        paramName: 'deliveredThermal',
        paramPath: 'energySystem.totalConsumption.deliveredThermal-0'
      }
    ],
    response: 'pvSystem'
  }
};
