import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import Text from '../../../components/Text/Text';
import {
  NplProjectState,
  ProjectStatus,
  ProjectsListItem
} from '../../../store/project/project.types';
import { formatDateWithDot } from '../../../utils/formatting.utils';
import './ProjectItem.scss';
import ProjectStatusField from '../../../components/ProjectStatusField/ProjectStatusField';
import useNavigation from '../../../hooks/useNavigation';
import { getProjectById } from '../../../store/project/project.action';
import { useEffect, useState } from 'react';
import { getCorrectProjectStatus } from '../../../utils/general.utils';

interface ProjectItemProps {
  project: ProjectsListItem;
}

const ProjectItem = ({ project }: ProjectItemProps) => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const [nplProjectStatus, setNplProjectStatus] = useState<NplProjectState | undefined>();

  useEffect(() => {
    if (!project.projectUuid) return;
    getNplProjectStatus(project.projectUuid);
  }, [project.projectUuid]);

  const getNplProjectStatus = async (projectUuid: string) => {
    const data = await getProjectById(projectUuid);
    setNplProjectStatus(data?.project.currentState);
  };

  const findProjectPath = () => {
    if (
      project.status === ProjectStatus.OFFER_RECEIVED ||
      project.status === ProjectStatus.OFFER_REQUESTED
    ) {
      goTo(`/project/${project.id}/offer`);
    } else {
      goTo(`/my-profile/projects/${project.projectUuid}`, { state: { id: project.id } });
    }
  };

  const userNextAction = async () => {
    window.scrollTo(0, 0);
    if (
      project.status === ProjectStatus.OFFER_REQUESTED ||
      project.status === ProjectStatus.OFFER_RECEIVED
    ) {
      goTo(`/project/${project.id}/offer`, { replace: true });
    } else if (project.status === ProjectStatus.CHECK_OPEN) {
      if (nplProjectStatus === NplProjectState.QUOTE_APPROVED)
        goTo(`/questionnaire/${project.projectUuid}`);
      if (nplProjectStatus === NplProjectState.QUESTIONAIRE_SUBMITTED) goTo('/kyc-start');
      if (nplProjectStatus === NplProjectState.CREDIT_CHECK_COMPLETED)
        goTo(`/my-profile/projects/${project.projectUuid}`);
    } else if (project.status === ProjectStatus.CONTRACTS_ISSUED) {
      goTo(`/my-profile/projects/${project.projectUuid}`, {
        state: { status: ProjectStatus.CONTRACTS_ISSUED, id: project.id }
      });
    } else if (project.status === ProjectStatus.CLIENT_SIGNED) {
      goTo(`/my-profile/projects/${project.projectUuid}`, {
        state: { status: ProjectStatus.CLIENT_SIGNED, id: project.id }
      });
    } else {
      goTo(`/my-profile/projects/${project.projectUuid}`, {
        state: { status: ProjectStatus.CONTRACTS_ISSUED, id: project.id }
      });
    }
  };

  const checkFinished = () =>
    nplProjectStatus === NplProjectState.KYC_PASSED ||
    nplProjectStatus === NplProjectState.KYC_FAILED ||
    nplProjectStatus === NplProjectState.CREDIT_CHECK_COMPLETED ||
    nplProjectStatus === NplProjectState.FOUR_EYE_CHECK_PASSED;
  nplProjectStatus === NplProjectState.FOUR_EYE_CHECK_FAILED;

  return (
    <div className="project-item-wrapper">
      <div className="project-info-wrapper">
        <span className="project-icon">
          <Icon name="house" width={140} height={140} />
        </span>
        <div className="project-info">
          <Text as="h2" category="headline" size="medium">
            {project.address}
          </Text>
          <span>
            <Text as="p" category="label" size="medium">
              {t('myProfile.myProjects.labels.project')}:
            </Text>
            <Text as="h3" category="headline" size="small">
              {project.id}
            </Text>
          </span>
          <span>
            <Text as="p" category="label" size="medium">
              {t('myProfile.myProjects.labels.created')}:
            </Text>
            <Text as="h3" category="headline" size="small">
              {formatDateWithDot(project.creationTime)}
            </Text>
          </span>
        </div>
      </div>
      <div className="project-item-status">
        <Text as="p" category="label" size="medium">
          {t('myProfile.myProjects.labels.status')}:
        </Text>
        {nplProjectStatus && (
          <ProjectStatusField
            projectStatus={getCorrectProjectStatus(project.status, nplProjectStatus)}
          />
        )}
      </div>
      {process.env.REACT_APP_FEATURE_FLAG_QUESTIONNAIRE_KYC_KKG?.toString() === 'hide' ||
      checkFinished() ? (
        <Button
          variation="secondary"
          size="large"
          dataTest="open-project-details"
          onClick={findProjectPath}>
          {t('myProfile.myProjects.labels.showDetails')}
        </Button>
      ) : (
        <div className="project-item-buttons-action">
          <Button
            variation="secondary"
            size="large"
            dataTest="open-project-details"
            onClick={findProjectPath}>
            {t('myProfile.myProjects.labels.showDetails')}
          </Button>
          <Button
            variation="primary"
            size="large"
            dataTest="open-project-details"
            onClick={userNextAction}>
            {t('nextAction')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProjectItem;
