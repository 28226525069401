import { useTranslation } from 'react-i18next';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';
import './ComplianceCheck.scss';
import Button from '../../../../../components/Button/Button';
import { ComplianceCheckData } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { formatDate } from '../../../../../utils/formatting.utils';

enum TableHeading {
  ID = 'id',
  FIRST_NAME = 'firstName',
  FULL_NAME = 'fullName',
  LAST_NAME = 'lastName',
  DATE_OF_BIRTH = 'dateOfBirth',
  COUNTRY = 'country',
  NAME_OF_LIST = 'nameOfList'
}

interface ComplianceCheckProps {
  complianceCheckData?: ComplianceCheckData;
  toggle: () => void;
}

const ComplianceCheck = ({ complianceCheckData, toggle }: ComplianceCheckProps) => {
  const { t } = useTranslation();
  const pepTableHeading = Object.values(TableHeading).map((elem) => elem);

  return (
    <>
      {complianceCheckData && (
        <div className="compliance-check-wrapper">
          <Text
            as="span"
            category="headline"
            size="small"
            variety={1}
            className="table-name-heading">
            {t('admin.projectDetails.projectReview.complianceCheck.pep')}
          </Text>
          <TableHead
            values={pepTableHeading}
            columnWidths={[15, 15, 10, 15, 10, 15, 10]}
            translationKey="admin.projectDetails.projectReview.complianceCheck.table.heading">
            {complianceCheckData?.pepEntries.map((elem, ind) => (
              <tr key={ind}>
                <Text as="td" category="label" size="large">
                  {elem.id}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.firstName}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.fullName}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.lastName}
                </Text>
                <Text as="td" category="label" size="large">
                  {formatDate(elem.dateOfBirth)}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.country}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.nameOfList}
                </Text>
              </tr>
            ))}
          </TableHead>
          <Text
            as="span"
            category="headline"
            size="small"
            variety={1}
            className="table-name-heading">
            {t('admin.projectDetails.projectReview.complianceCheck.sanctionList')}
          </Text>
          <TableHead
            values={pepTableHeading}
            columnWidths={[15, 15, 10, 15, 10, 15, 10]}
            translationKey="admin.projectDetails.projectReview.complianceCheck.table.heading">
            {complianceCheckData.slEntries.map((elem, ind) => (
              <tr key={ind}>
                <Text as="td" category="label" size="large">
                  {elem.id}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.firstName}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.fullName}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.lastName}
                </Text>
                <Text as="td" category="label" size="large">
                  {formatDate(elem.dateOfBirth)}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.nameOfList}
                </Text>
              </tr>
            ))}
          </TableHead>
          <Text
            as="span"
            category="headline"
            size="small"
            variety={1}
            className="table-name-heading">
            {t('admin.projectDetails.projectReview.complianceCheck.watchList')}
          </Text>
          <TableHead
            values={pepTableHeading}
            columnWidths={[15, 15, 10, 15, 10, 15, 10]}
            translationKey="admin.projectDetails.projectReview.complianceCheck.table.heading">
            {complianceCheckData.wlblEntries.map((elem, ind) => (
              <tr key={ind}>
                <Text as="td" category="label" size="large">
                  {elem.id}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.firstName}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.fullName}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.lastName}
                </Text>
                <Text as="td" category="label" size="large">
                  {formatDate(elem.dateOfBirth)}
                </Text>
                <Text as="td" category="label" size="large">
                  {elem.nameOfList}
                </Text>
              </tr>
            ))}
          </TableHead>
          <Button variation="primary" size="large" dataTest="" onClick={toggle}>
            {t('close')}
          </Button>
        </div>
      )}
    </>
  );
};

export default ComplianceCheck;
