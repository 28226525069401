import { NplProjectState, ProjectStatus } from '../../store/project/project.types';
import './ProjectStatusField.scss';
import Text from '../Text/Text';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';

interface ProjectStatusProps {
  projectStatus: ProjectStatus | NplProjectState;
  withoutDot?: boolean;
}

const ProjectStatusField = ({ projectStatus, withoutDot }: ProjectStatusProps) => {
  const { t } = useTranslation();

  const getClassName = () => `circle status-${projectStatus} ${withoutDot ? 'noDot' : ''}`;

  const getIconClassName = (projectStatus: ProjectStatus | NplProjectState) => {
    if (
      projectStatus === NplProjectState.KYC_FAILED ||
      projectStatus === NplProjectState.FOUR_EYE_CHECK_FAILED
    ) {
      return 'warning';
    } else if (
      projectStatus === NplProjectState.KYC_PASSED ||
      projectStatus === NplProjectState.CREDIT_CHECK_COMPLETED
    ) {
      return 'check-rounded';
    } else {
      return 'loading-arrows';
    }
  };

  return (
    <div className="project-status-field-wrapper">
      {withoutDot ? (
        <Icon name={getIconClassName(projectStatus)} />
      ) : (
        <span className={getClassName()} />
      )}
      <Text as="span" category="label" size="large" className={`status ${projectStatus}`}>
        {t(`projectStatus.${projectStatus}`)}
      </Text>
    </div>
  );
};

export default ProjectStatusField;
