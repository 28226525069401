import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../../types/util.types';
import Button from '../../Button/Button';
import Search from '../Search/Search';
import filterIcon from '../../../assets/Icons/filter.svg';
import './SearchFilter.scss';
import { useEffect, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Radio from '../Radio/Radio';

interface SearchFilterProps {
  placeholder: string;
  filters?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilterValue?: any;
  setValue: SetStateType<string>;
}

const SearchFilter = ({ placeholder, filters, setFilterValue, setValue }: SearchFilterProps) => {
  const { t } = useTranslation();

  const ref = useOutsideClick(() => {
    setOpenFilterDropdown(false);
  });

  const [filter, setFilter] = useState<string>('');

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);

  useEffect(() => {
    filter && setFilterValue && setFilterValue(filter);
  }, [filter]);

  const handleChange = (value: string) => {
    setFilter(value);
    setOpenFilterDropdown(false);
  };

  return (
    <div className="search-filter-wrapper">
      <Search placeholder={placeholder} setValue={setValue} />
      <div ref={ref} className="search-filter-dropdown-wrapper">
        <Button
          variation="transparent"
          icon={filterIcon}
          iconPosition="left"
          size={'large'}
          onClick={() => setOpenFilterDropdown(!openFilterDropdown)}
          dataTest="filter-button"
          className="filter-button">
          {t('filter')}
        </Button>
        {filters && (
          <div className={`filter-dropdown ${openFilterDropdown ? 'open-filter-dropdown' : ''}`}>
            {!!filters && (
              <Radio
                label={t('all')}
                value="all"
                checked={'all' === filter || '' === filter}
                onClick={handleChange}
                className={'filter-radio'}
              />
            )}
            {filters?.map((value, index) => (
              <Radio
                key={index}
                label={value}
                value={value}
                checked={value === filter}
                onClick={handleChange}
                className={'filter-radio'}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFilter;
