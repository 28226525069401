import { Profile } from '../profile/profile.types';
import { PartnerOfferView } from './offers.types';

export const emptyPartnerOfferView: Partial<PartnerOfferView> = {};

type PartnerOfferViewActions =
  | {
      type: 'LOAD_PARTNER_OFFER_VIEW';
      payload: PartnerOfferView;
    }
  | {
      type: 'LOAD_CONTACT';
      payload: Profile;
    }
  | {
      type: 'CHANGE_OFFER_PRICE';
      payload?: number;
    }
  | {
      type: 'CHANGE_OFFER_SUBSIDY';
      payload?: number;
    }
  | {
      type: 'CHANGE_OFFER_AREA';
      payload: number;
    }
  | {
      type: 'CHANGE_PERFORMANCE';
      payload: number;
    }
  | {
      type: 'CHANGE_OFFER_ISSUANCE_DATE';
      payload?: Date;
    }
  | {
      type: 'CHANGE_OFFER_VALID_THRU_DATE';
      payload?: Date;
    }
  | {
      type: 'CHANGE_OFFER_DELIVERY_DATE';
      payload?: Date;
    }
  | {
      type: 'CHANGE_DESCRIPTION';
      payload?: string;
    }
  | {
      type: 'UPLOAD_OFFER';
      payload?: string;
    }
  | {
      type: 'REMOVE_UPLOADED_OFFER';
    };

export type PartnerOfferViewActionTypes = PartnerOfferViewActions;

export const partnerOfferViewReducer = (
  state: Partial<PartnerOfferView>,
  action: PartnerOfferViewActions
) => {
  switch (action.type) {
    case 'LOAD_PARTNER_OFFER_VIEW':
      state = action.payload;
      return state;
    case 'LOAD_CONTACT':
      return {
        ...state,
        offer: { ...state.offer, contact: action.payload }
      };
    case 'CHANGE_OFFER_PRICE':
      return {
        ...state,
        offer: { ...state.offer, offeredPrice: action.payload }
      };
    case 'CHANGE_OFFER_SUBSIDY':
      return {
        ...state,
        offer: { ...state.offer, subsidy: action.payload }
      };
    case 'CHANGE_OFFER_AREA':
      return {
        ...state,
        offer: { ...state.offer, area: action.payload }
      };
    case 'CHANGE_OFFER_ISSUANCE_DATE':
      return {
        ...state,
        offer: { ...state.offer, issuanceDate: action.payload }
      };
    case 'CHANGE_OFFER_VALID_THRU_DATE':
      return {
        ...state,
        offer: { ...state.offer, validThru: action.payload }
      };
    case 'CHANGE_OFFER_DELIVERY_DATE':
      return {
        ...state,
        offer: { ...state.offer, deliveryDate: action.payload }
      };
    case 'CHANGE_PERFORMANCE':
      return {
        ...state,
        offer: { ...state.offer, performance: action.payload }
      };
    case 'CHANGE_DESCRIPTION':
      return {
        ...state,
        offer: { ...state.offer, description: action.payload }
      };
    case 'UPLOAD_OFFER':
      return {
        ...state,
        offer: { ...state.offer, documentUrl: action.payload }
      };
    case 'REMOVE_UPLOADED_OFFER':
      return {
        ...state,
        offer: { ...state.offer, documentUrl: undefined }
      };
    default:
      return emptyPartnerOfferView;
  }
};
