import { Module, investerraModule, modules } from '../types/financialOffer.types';
import { RoofSustainability } from '../types/solarSystem.types';

/*
 Percentage of the roof(s) is (to be) covered with solar panels
 
 The numbers returned by swissenenergyplanning API default to 80%.

 UNIT: %
 USED IN:
  - Amortization
*/
const ROOF_COVERAGE_PERCENTAGE = 0.8;

/*
 What percentage of the produced electricity can be used of own consumption - if there is no battery to store energy

 UNIT: %
 USED IN:
  - Amortization
*/
const OWN_CONSUMPTION_PERCENTAGE_NO_BATTERY = 0.3;
/*
 What percentage of the produced electricity can be used of own consumption - if there is battery to store energy

 UNIT: %
 USED IN:
  - Amortization
*/
const OWN_CONSUMPTION_PERCENTAGE_WITH_BATTERY = 0.6;

/*
 Calculates the CO2 savings from producing electricity using solar panels.
 
 In average the production of 1 kWh electricity using fossil fuels emits 0.6 – 0.8 kg CO2
 
 UNIT: kg/kWh
 USED IN:
  - CO2 Savings
*/
const CO2_EMISSION_FOSSIL = 0.7;

/*
 One way to estimate the operating costs of a PV is to assume the amount of money per kWh electricity produces.
 
 Common values for Switzerland are 0.03 – 0.04 CHF / kWh

 UNIT: CHF/kWh
 USED IN:
  - Amortization
*/
const OPERATING_COSTS_PER_KWH = 0.04;
/*
 Another way to estimate the operating costs of a PV is to take a percentage of the total investment as the annual operating costs.
 Common values are 1 - 2%  

 UNIT: % p.a.
 USED IN:
  - Amortization
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OPERATING_COSTS_FACTOR_BASED_ON_INVESTMENT = 0.02;
/*
 Price for a kWh earned from selling not used electricity

 SEP API_URL: /api/marketsense/egidfeatures-by-addressid 
 SEP_API_FIELD: einspeisetarif

 UNIT: CHF/kWh
 USED IN:
  - Amortization
*/
const PRICE_PER_KWH_SOLD = 0.105;
/*
 Price for buying 1 kWh - used for calculation of savings/amortization time.

 Average price for private households in Switzerland (2023): 0.27 CHF/kWh

 SEP API_URL: /api/marketsense/egidfeatures-by-addressid 
 SEP_API_FIELD: bezugstarif

 UNIT: CHF/kWh
 USED IN:
  - Amortization
*/
const PRICE_PER_KWH_BOUGHT = 0.27;

export const calculateSolarPotential = (solarPotential?: number) => {
  if (solarPotential)
    return Math.round(scaleSolarPotential(solarPotential, 80, ROOF_COVERAGE_PERCENTAGE));
  return 0;
};

const scaleSolarPotential = (
  originalPotential: number,
  originalPercentage: number,
  targetPercentage: number
) => {
  const value = (originalPotential * 100) / originalPercentage;
  return (value / 100) * targetPercentage;
};

export const calculateConsumptionPercentage = (hasBattery = false) => {
  return hasBattery
    ? OWN_CONSUMPTION_PERCENTAGE_WITH_BATTERY
    : OWN_CONSUMPTION_PERCENTAGE_NO_BATTERY;
};

export const calculateSelfConsumptionPotential = (hasBattery = false) => {
  return calculateConsumptionPercentage(hasBattery) * 100;
};

export const calculateRoofSustainability = (category?: number): RoofSustainability => {
  switch (category) {
    case 1:
      return RoofSustainability.LOW;
    case 2:
      return RoofSustainability.MEDIUM;
    case 3:
      return RoofSustainability.GOOD;
    case 4:
      return RoofSustainability.VERY_GOOD;
    case 5:
      return RoofSustainability.EXCELLENT;
    default:
      return RoofSustainability.NA;
  }
};

export const calculateCO2Savings = (solarPotential?: number) => {
  if (solarPotential) {
    return Math.round(calculateSolarPotential(solarPotential) * CO2_EMISSION_FOSSIL);
  }
  return 0;
};

export const calculateEnergyAmountSold = (solarPotential?: number, hasBattery = false) => {
  if (solarPotential) {
    const consumptionPercentage = calculateConsumptionPercentage(hasBattery);
    return (1 - consumptionPercentage) * calculateSolarPotential(solarPotential);
  }
  return 0;
};

export const calculateEarningsFromSellingEnergy = (solarPotential?: number, hasBattery = false) => {
  return calculateEnergyAmountSold(solarPotential, hasBattery) * PRICE_PER_KWH_SOLD;
};

export const calculateEnergyAmountSelfConsumption = (
  solarPotential?: number,
  hasBattery = false
) => {
  if (solarPotential) {
    const consumptionPercentage = calculateConsumptionPercentage(hasBattery);
    return calculateSolarPotential(solarPotential) * consumptionPercentage;
  }
  return 0;
};

export const calculateSavingsFromSelfConsumption = (
  solarPotential?: number,
  hasBattery = false
) => {
  return calculateEnergyAmountSelfConsumption(solarPotential, hasBattery) * PRICE_PER_KWH_BOUGHT;
};

export const calculateEarningsPerYear = (solarPotential?: number, hasBattery = false) => {
  return (
    calculateEarningsFromSellingEnergy(solarPotential, hasBattery) +
    calculateSavingsFromSelfConsumption(solarPotential, hasBattery)
  );
};

export const calculateOperatingCostsPerYear = (solarPotential?: number) => {
  if (solarPotential) {
    return calculateSolarPotential(solarPotential) * OPERATING_COSTS_PER_KWH;
  }
  return 0;
};

export const getMatchingModules = (projectModules: Module[] | undefined): Module[] | undefined => {
  const allModules = [...modules, investerraModule];
  if (!projectModules) return undefined;

  return projectModules.map((item: Module) => {
    const matchedItem = allModules.find((obj) => obj.type === item.type);
    return matchedItem
      ? { ...item, label: `module.types.${matchedItem}`, value: matchedItem.value }
      : item;
  });
};
