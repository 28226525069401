import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './LowSustainabilityModal.scss';

const LowSustainabilityModal = () => {
  const { t } = useTranslation();

  return (
    <Text as="p">
      {t('solarSystem.lowSustainabilityModal.text')}{' '}
      <a
        className="low-sustainability-contact"
        href={`mailto:${t('investerraDetails.support.email')}`}>
        {t('investerraDetails.support.email')}
      </a>
    </Text>
  );
};

export default LowSustainabilityModal;
