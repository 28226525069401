import './TextArea.scss';
import { ChangeEvent, FocusEvent } from 'react';
import Text from '../../Text/Text';
import { useTranslation } from 'react-i18next';
import { emptyStringIfNull } from '../../../utils/general.utils';

export interface TextAreaProps {
  value?: string | number;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (evt: FocusEvent<HTMLTextAreaElement>) => void;
  validate?: (value?: string | number) => string;
  error?: string;
  dataTest?: string;
  className?: string;
  onSubmit?: () => void;
}

const TextArea = ({
  value,
  name,
  placeholder,
  disabled,
  onChange,
  onBlur,
  validate = () => '',
  error,
  dataTest,
  className,
  onSubmit
}: TextAreaProps) => {
  const { t } = useTranslation();

  const validationError = validate(value);

  const getClassName = () =>
    `text-area-input-container ${disabled ? 'disabled' : ''} ${
      error || validationError ? 'error' : ''
    } ${className ? className : ''}`;

  return (
    <div className={getClassName()}>
      <textarea
        className="text-area-input"
        placeholder=" "
        id={name}
        name={name}
        value={emptyStringIfNull(value)}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
          if (e.key === 'Enter' && onSubmit) onSubmit();
        }}
        disabled={disabled}
        data-test={dataTest}
      />
      <Text as="label" category="label" size="large" variety={1} className="placeholder">
        {placeholder}
      </Text>
      {!!error && (
        <Text as="p" category="body" size="small" className="error-text">
          {t(error)}
        </Text>
      )}
    </div>
  );
};

export default TextArea;
