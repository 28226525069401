import { toast } from 'react-toastify';
import { ToastOptions, ToastPosition } from 'react-toastify/dist/types';

interface toastType {
  error: string;
  success: string;
  info: string;
  warn: string;
}

export const toastUtil = (
  type: string,
  message: string | JSX.Element | null,
  options?: ToastOptions
) => {
  const defaultOptions: ToastOptions = {
    autoClose: 5000,
    position: 'bottom-left' as ToastPosition,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    closeButton: true
  };

  const mergedOptions = { ...defaultOptions, ...options };

  toast[type as keyof toastType](message, mergedOptions);
};
