import { useState } from 'react';
import './LanguageSelector.scss';
import Text from '../Text/Text';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Languages } from '../../i18n/i18n';
import { useNavigate } from 'react-router-dom';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const languages = Object.values(Languages);

  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useOutsideClick(() => {
    setShowDropdown(false);
  });

  const getClassName = () => {
    return `language-selector-wrapper ${showDropdown ? 'open' : ''}`;
  };

  const changeLanguage = (elem: Languages) => {
    const replaceLanguageFromPathname = location.pathname.replace(/^\/([^/]+)/, `/${elem}`);
    navigate(replaceLanguageFromPathname);
    i18n.changeLanguage(elem);
  };

  return (
    <div
      className={getClassName()}
      onClick={() => {
        setShowDropdown((prev) => !prev);
      }}
      ref={ref}>
      <div className="selected-language">
        <Text as="span" category="label" size="large">
          {t(`languages.${i18n.language}`)}
        </Text>
        <span className="arrow" />
      </div>
      <ul className="language-dropdown-wrapper">
        {languages
          .filter((elem) => elem !== i18n.language)
          .map((language, ind) => (
            <li
              key={ind}
              onClick={() => {
                changeLanguage(language);
              }}>
              <Text as="span" category="label" size="large">
                {t(`languages.${language}`)}
              </Text>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
