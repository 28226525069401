import { ProjectsListItem } from '../../../../store/project/project.types';
import ProjectItem from './ProjectItem/ProjectItem';
import { SetStateType, Sort } from '../../../../types/util.types';
import TableSortable from '../../../../components/Tables/TableSortable/TableSortable';

interface ProjectstableProps {
  projects: ProjectsListItem[];
  sort: Sort[];
  setSortedColumns: SetStateType<Sort[]>;
}

const ProjectsTable = ({ projects, sort, setSortedColumns }: ProjectstableProps) => {
  return (
    <div className="projects-table-wrapper">
      <TableSortable
        values={sort}
        columnWidths={[12, 11, 15, 18, 13, 12, 22]}
        translationKey="admin.projects.table.heading."
        onSortChange={setSortedColumns}
        hiddenSortColumns={[6, 7]}
        sortable
        hoverEffect>
        {projects?.map((project, index) => (
          <ProjectItem key={index} project={project} />
        ))}
      </TableSortable>
    </div>
  );
};

export default ProjectsTable;
