import Icon from '../../Icon/Icon';
import './MESSearch.scss';

const MESSearch = () => {
  return (
    <div className="autocomplete-container" tabIndex={0}>
      <div className="sep-search-decoration">
        <Icon name="search" />
      </div>
      <input className="address-input" type="text" id="address-input" />
      <div id="autocompleteSuggestions" className="autocomplete-suggestions"></div>
    </div>
  );
};

export default MESSearch;
