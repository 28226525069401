import './OfferDetails.scss';
import { Project } from '../../../store/project/project.types';
import { PartnerOffer } from '../../../store/offers/offers.types';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import InfoField from '../../../components/InfoField/InfoField';
import { DeepPartial } from 'redux';
import { formatDateWithDot, toSwissFrancNotation } from '../../../utils/formatting.utils';
import Button from '../../../components/Button/Button';
import DownloadIcon from '../../../assets/Icons/download.svg';
import { downloadOfferDocument } from '../../../store/offers/offers.action';
import ContactBox from '../../../components/ContactBox/ContactBox';

interface Props {
  offer: PartnerOffer;
  project?: DeepPartial<Project>;
}

const OfferDetails = ({ offer, project }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="details">
      <Text as="h3" category="headline" size="large">
        {t('offers.offerDetails.heading')}
      </Text>

      <InfoField className="field" label={t('fullName')} disabled={false} variety={2}>
        {`${project?.owner?.firstName} ${project?.owner?.lastName}`}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.address')}
        disabled={false}
        variety={2}>
        {`${project?.building?.address?.street} ${project?.building?.address?.houseNumber}, ${project?.building?.address?.postalCode} ${project?.building?.address?.municipality}`}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.offerDate')}
        disabled={false}
        variety={2}>
        {formatDateWithDot(offer.issuanceDate)}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.pvSystem.label')}
        tooltipText={{
          heading: t('offers.offerDetails.pvSystem.tooltip.heading'),
          text: t('offers.offerDetails.pvSystem.tooltip.text')
        }}
        disabled={false}
        variety={2}>
        {offer.area ? `${offer.area} ${t('units.meterSqared')}` : '-'}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.plantPower.label')}
        tooltipText={{
          heading: t('offers.offerDetails.plantPower.tooltip.heading'),
          text: t('offers.offerDetails.plantPower.tooltip.text')
        }}
        disabled={false}
        variety={2}>
        {offer.performance ? `${offer.performance} ${t('units.kilowattPerformance')}` : '-'}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.validThruDate.label')}
        tooltipText={{
          heading: t('offers.offerDetails.validThruDate.tooltip.heading'),
          text: t('offers.offerDetails.validThruDate.tooltip.text')
        }}
        disabled={false}
        variety={2}>
        {offer.issuanceDate ? formatDateWithDot(offer.validThru) : '-'}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.installationDate.label')}
        tooltipText={{
          heading: t('offers.offerDetails.installationDate.tooltip.heading'),
          text: t('offers.offerDetails.installationDate.tooltip.text')
        }}
        disabled={false}
        variety={2}>
        {offer.deliveryDate ? formatDateWithDot(offer.deliveryDate) : '-'}
      </InfoField>

      <InfoField
        className="field"
        label={t('offers.offerDetails.offerPrice.label')}
        tooltipText={{
          heading: t('offers.offerDetails.offerPrice.tooltip.heading'),
          text: t('offers.offerDetails.offerPrice.tooltip.text')
        }}
        disabled={false}
        variety={2}>
        {` ${offer.offeredPrice ? toSwissFrancNotation(offer.offeredPrice) : '-'}`}
      </InfoField>

      {offer.description && (
        <div className="offer-description">
          <Text as="h4" category="label" size="large">
            {t('offers.description')}
          </Text>
          <Text as="p" category="body" size="medium">
            {offer.description}
          </Text>
        </div>
      )}

      {offer.contact !== null && (
        <>
          <Text as="h3" category="headline" size="large" className="installation-partner-heading">
            {t('installationPartner')}
          </Text>
          <ContactBox
            partnerName={offer.partner.name}
            partnerAdress={offer.partner.partnerLocations[0]?.address}
            name={offer.contact.firstName + offer.contact.lastName}
            phone={offer.contact.phoneNumber}
            email={offer.contact.email}
          />
        </>
      )}
      {offer.documentUrl && (
        <div className="offer-contract">
          <Text as="p" category="body" size="medium">
            {t('offers.contract.description')}
          </Text>
          <Button
            variation="primary"
            size="large"
            dataTest=""
            icon={DownloadIcon}
            iconPosition="left"
            onClick={() => {
              downloadOfferDocument(offer.id);
            }}>
            {t('offers.contract.buttonText')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default OfferDetails;
