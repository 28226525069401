import { useContext, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Text from '../Text/Text';
import { Trans, useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';
import { AuthContext } from '../../context/AuthContextProvider';
import { hubspotLinks } from '../../utils/hubspotLinks';
import './LoginErrorNotification.scss';

const LoginErrorNotification = () => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const { isOpened, toggle } = useModal();

  useEffect(() => {
    toggle();
  }, []);

  const closeModalHandler = () => {
    auth?.logout();
    toggle();
  };

  return (
    <Modal title={t('auth.errors.loginFailed')} isOpened={isOpened} close={closeModalHandler}>
      <Text as="p">
        <Trans
          i18nKey="auth.errors.somethingWentWrongRegistration"
          t={t}
          components={{
            emailLink: <a href={`mailto:${hubspotLinks.supportEmail}`} className="email-link" />
          }}
        />
      </Text>
    </Modal>
  );
};

export default LoginErrorNotification;
