import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';
import { CreditOffer } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { formatDateWithDot } from '../../../../../utils/formatting.utils';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';
import { GetProjectById } from '../../../../../store/project/project.types';

enum PersonalDetailsTableHeading {
  TITLE = 'title',
  DATA = 'data'
}

interface CreditOfferTableProps {
  creditOffer?: CreditOffer;
  projectDetails?: GetProjectById;
  toggle: () => void;
}
const CreditOfferTable = ({ creditOffer, projectDetails, toggle }: CreditOfferTableProps) => {
  const { t } = useTranslation();
  const tableHeadings = [...Object.values(PersonalDetailsTableHeading).map((elem) => elem)];

  const data = [
    {
      name: 'loanAmount',
      value: projectDetails?.project.loanAmount
    },
    {
      name: 'loanTerm',
      value: projectDetails?.project.loanTerm
    },
    {
      name: 'monthlyRate',
      value: Math.round(projectDetails?.projectDto.financingModel.monthlyRate as number)
    },
    {
      name: 'decliningBalance',
      value: creditOffer?.decliningBalance
    },
    {
      name: 'deposit',
      value: projectDetails?.projectDto.financingModel.deposit
    },
    {
      name: 'interestRate',
      value: projectDetails?.projectDto.financingModel.interestRate
    },
    {
      name: 'contractStartDate',
      value: formatDateWithDot(creditOffer?.contractStartDate)
    }
  ];

  return (
    <div className="credit-offer-table-wrapper">
      <TableHead
        values={tableHeadings}
        columnWidths={[50, 50]}
        translationKey="admin.projectDetails.projectReview.creditOfferTable.table.heading">
        {data.map((elem, ind) => (
          <tr key={ind}>
            <Text as="td" category="label" size="large">
              {t(`admin.projectDetails.projectReview.creditOfferTable.table.data.${elem.name}`)}
            </Text>
            <Text as="td" category="label" size="large">
              {getValueOrPlaceholder(elem.value)}
            </Text>
          </tr>
        ))}
      </TableHead>
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default CreditOfferTable;
