import { ReactElement, useState } from 'react';
import TabItem, { Props as TabItemProps } from './TabItem/TabItem';

import './Tabs.scss';

type TabsProps = {
  children: ReactElement<TabItemProps>[];
  preSelectedTabIndex?: number;
  hide?: boolean;
  className?: string;
  onTabChange?: (index: number) => void;
};

const Tabs = (props: TabsProps): JSX.Element => {
  const { children, preSelectedTabIndex, hide, className, onTabChange } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(preSelectedTabIndex || 0);

  const getTabClassName = () => `tabs ${hide ? 'hide' : ''} ${className || ''}`;

  const tabChange = (index: number) => {
    setSelectedTabIndex(index);
    onTabChange && onTabChange(index);
  };

  return (
    <div className={getTabClassName()}>
      <ul>
        {children.map((item, index) => (
          <TabItem
            key={index}
            title={item.props.title}
            index={index}
            isActive={index === selectedTabIndex}
            setSelectedTab={tabChange}
            disabled={item.props.disabled}
          />
        ))}
      </ul>
      {children[selectedTabIndex]}
    </div>
  );
};

export default Tabs;
