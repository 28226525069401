import Keycloak, { KeycloakConfig, KeycloakTokenParsed } from 'keycloak-js';
import { createContext, useEffect, useState } from 'react';
import i18n from '../i18n/i18n';

type AuthContextType = {
  keycloak: Keycloak;
  logout: () => void;
  isAuthenticated: boolean;
  getToken: () => void;
  getParsedToken: () => KeycloakTokenParsed | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateToken: (successCallback: any) => Promise<boolean | void>;
};

interface AuthContextProviderProps {
  children: JSX.Element;
}

const keycloakConfig: KeycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  url: process.env.REACT_APP_KEYCLOAK_URL
};

const keycloak = new Keycloak(keycloakConfig);

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [instance, setInstance] = useState<Keycloak | null>(null);

  useEffect(() => {
    const initializeKeycloak = async () => {
      try {
        const isAuthenticatedResponse = await keycloak.init({ onLoad: 'check-sso' });
        setInstance(keycloak);
        setIsAuthenticated(isAuthenticatedResponse);
        i18n.changeLanguage(keycloak?.tokenParsed?.locale);
      } catch {
        setIsAuthenticated(false);
      }
    };

    initializeKeycloak();
  }, []);

  const logout = () => {
    instance?.logout();
    setInstance(null);
    setIsAuthenticated(false);
  };

  const getToken = () => keycloak.token;
  const getParsedToken = () => keycloak.tokenParsed;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateToken = (successCallback: any) =>
    keycloak.updateToken(5).then(successCallback).catch(keycloak.login);

  return (
    instance && (
      <AuthContext.Provider
        value={{
          keycloak: instance,
          logout,
          isAuthenticated,
          getToken,
          getParsedToken,
          updateToken
        }}>
        {props.children}
      </AuthContext.Provider>
    )
  );
};

export default AuthContextProvider;
