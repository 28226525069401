import { useState } from 'react';
import Icon from '../Icon/Icon';
import Radio from '../Inputs/Radio/Radio';
import Text from '../Text/Text';
import './PriceRange.scss';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import { toSwissFrancNotation } from '../../utils/formatting.utils';

export interface PriceRangeType {
  priceFrom?: number;
  priceTo?: number;
}

interface PriceRangeProps {
  label: string;
  values: PriceRangeType[];
  selectedRange: PriceRangeType;
  onPriceRangeSelect: (selectedPriceRange: PriceRangeType) => void;
}

const PriceRange = ({ label, values, selectedRange, onPriceRangeSelect }: PriceRangeProps) => {
  const { t } = useTranslation();

  const ref = useOutsideClick(() => {
    setOpenPriceRangeDropdown(false);
  });
  const fromTransformed = t('from').charAt(0).toUpperCase() + t('from').slice(1);

  const [openPriceRangeDropdown, setOpenPriceRangeDropdown] = useState(false);

  const selectRange = (range: PriceRangeType) => {
    onPriceRangeSelect(range);
    setOpenPriceRangeDropdown(false);
  };

  return (
    <div className={`price-range-wrapper ${openPriceRangeDropdown ? 'show' : 'hide'}`} ref={ref}>
      <button
        className="price-range-button"
        onClick={() => {
          setOpenPriceRangeDropdown((prev) => !prev);
        }}>
        <Icon name="file" />
        <Text as="span" category="label" size="large">
          {label}
        </Text>
      </button>
      <div className="price-range-list">
        {values.map((elem: PriceRangeType, index) => {
          if (index === 0) {
            return (
              <Radio
                key={index}
                className="price-range-radio"
                value={JSON.stringify(elem)}
                label={`${t('to')} ${toSwissFrancNotation(elem.priceTo ? elem.priceTo : '')}`}
                checked={JSON.stringify(elem) === JSON.stringify(selectedRange)}
                onClick={() => {
                  selectRange(elem);
                }}
              />
            );
          }
          if (index + 1 === values.length) {
            return (
              <Radio
                key={index}
                className="price-range-radio"
                value={JSON.stringify(elem)}
                label={`${fromTransformed} ${toSwissFrancNotation(
                  elem.priceFrom ? elem.priceFrom : ''
                )}`}
                checked={JSON.stringify(elem) === JSON.stringify(selectedRange)}
                onClick={() => {
                  selectRange(elem);
                }}
              />
            );
          }
          return (
            <Radio
              key={index}
              className="price-range-radio"
              value={JSON.stringify(elem)}
              label={`${toSwissFrancNotation(elem.priceFrom ? elem.priceFrom : '')} - ${
                elem.priceTo
              }`}
              checked={JSON.stringify(elem) === JSON.stringify(selectedRange)}
              onClick={() => {
                selectRange(elem);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PriceRange;
