import './PartnerOfferView.scss';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import UploadFile from '../../UploadOffer/UploadFile/UploadFile';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import { useParams } from 'react-router-dom';
import { validatePositiveNumber } from '../../../utils/validations.utils';
import Button from '../../../components/Button/Button';
import { formatDate } from '../../../utils/formatting.utils';
import {
  emptyPartnerOfferView,
  partnerOfferViewReducer
} from '../../../store/offers/partner-offer-view.reducer';
import {
  attachOffer,
  getPartnersOfferView,
  updateOffer
} from '../../../store/offers/offers.action';
import { formatAddress } from '../../../utils/formatting.utils';
import { OfferStatus } from '../../../store/offers/offers.types';
import TextArea from '../../../components/Inputs/TextArea/TextArea';
import FileItem from '../../../components/File/FileItem/FileItem';
import {
  copyToClipboard,
  dialNumber,
  parseFilename,
  parseName
} from '../../../utils/general.utils';
import { getProfile } from '../../../store/profile/profile.actions';
import { toastUtil } from '../../../utils/toast.utils';
import useNavigation from '../../../hooks/useNavigation';
import Loader from '../../../components/Loader/Loader';

const PartnerOfferView = () => {
  const { t } = useTranslation();
  const { offerId } = useParams();
  const { goTo } = useNavigation();

  const [view, dispatch] = useReducer(partnerOfferViewReducer, emptyPartnerOfferView);

  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const isDisabled = loading || view.offer?.status !== OfferStatus.PENDING;

  const requiredFields = () => {
    const offer = view.offer;
    return (
      !!offer?.documentUrl &&
      !!offer?.offeredPrice &&
      !!offer?.area &&
      !!offer?.performance &&
      !!offer?.issuanceDate &&
      !!offer?.validThru
    );
  };
  const buttonDisabled = isDisabled || !requiredFields();

  useEffect(() => {
    if (!offerId) return;
    getOfferView(offerId);
  }, [offerId]);

  const getOfferView = async (offerId: string) => {
    setLoading(true);

    const view = await getPartnersOfferView(offerId);
    if (view) {
      dispatch({ type: 'LOAD_PARTNER_OFFER_VIEW', payload: view });
      dispatch({ type: 'CHANGE_OFFER_ISSUANCE_DATE', payload: new Date() });

      if (view.offer?.documentUrl) {
        dispatch({
          type: 'UPLOAD_OFFER',
          payload: view.offer?.documentUrl
        });
      }

      if (view.offer?.status === OfferStatus.PENDING) {
        getProfile().then((user) => {
          if (user) dispatch({ type: 'LOAD_CONTACT', payload: user.profile });
        });
      }
    }
    setLoading(false);
  };

  const handleUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      setUploadedFile(file);
      dispatch({ type: 'UPLOAD_OFFER', payload: file.name });
    }
  };

  const handleSubmit = async () => {
    if (!offerId || !view.offer) {
      toastUtil('error', t('partnerOfferView.mandatoryFieldsShouldBePopulated'));
      return;
    }

    setLoading(true);
    const attachOfferResp = uploadedFile && (await attachOffer(offerId, uploadedFile));
    if (attachOfferResp) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, status, partner, ...data } = view.offer;
      const resp = await updateOffer(offerId, { ...data });
      if (resp) {
        goTo('/partners');
        toastUtil('success', t('uploadSuccessful'));
      }
    }
    setLoading(false);
  };

  const deleteDocumentUrl = () => {
    setUploadedFile(undefined);
    dispatch({ type: 'REMOVE_UPLOADED_OFFER' });
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="offer-edit">
      <Text as="h2" category="display" size="medium">
        {t('offerDetails')}
      </Text>
      <Text as="h2" category="headline" size="medium">
        {t('offers.image.heading')}
      </Text>
      <div className="details-edit">
        <TextInput
          value={parseName(view.owner?.firstName, view.owner?.lastName)}
          name="full-name"
          type="text"
          placeholder={t('fullName')}
          disabled
          required
        />
        <TextInput
          value={formatAddress(view.address)}
          name="address"
          type="text"
          placeholder={t('offers.offerDetails.address')}
          disabled
          required
        />
        <div
          className="partner-action"
          onClick={() => copyToClipboard(view.owner?.email as string)}>
          <TextInput
            value={parseName(view.owner?.email)}
            name="email"
            type="text"
            placeholder={t('email')}
            disabled
            required
            unit={t('copy')}
          />
        </div>
        <div
          className="partner-action"
          onClick={() => dialNumber(view.owner?.phoneNumber as string)}>
          <TextInput
            value={parseName(view.owner?.phoneNumber)}
            name="phone-number"
            type="text"
            placeholder={t('phoneNumber')}
            disabled
            unit={t('call')}
          />
        </div>
        <TextInput
          value={view.offer?.subsidy}
          name="subsidy"
          type="number"
          placeholder={t('offers.offerDetails.subsidy.label')}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_OFFER_SUBSIDY', payload: parseFloat(e.target.value) })
          }
          disabled={isDisabled}
          unit={t('currency.chf')}
        />
        <TextInput
          value={view.offer?.offeredPrice}
          name="price"
          type="number"
          placeholder={t('offers.offerDetails.offerPrice.label')}
          validate={validatePositiveNumber}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_OFFER_PRICE', payload: parseFloat(e.target.value) })
          }
          disabled={isDisabled}
          unit={t('currency.chf')}
          required
        />
        <TextInput
          value={view.offer?.area}
          name="area"
          type="number"
          placeholder={t('offers.offerDetails.pvSystem.label')}
          validate={validatePositiveNumber}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_OFFER_AREA', payload: parseFloat(e.target.value) })
          }
          disabled={isDisabled}
          unit={t('units.meterSqared')}
          required
        />
        <TextInput
          value={view.offer?.performance}
          name="performance"
          type="number"
          placeholder={t('offers.offerDetails.plantPower.label')}
          validate={validatePositiveNumber}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_PERFORMANCE', payload: parseFloat(e.target.value) })
          }
          disabled={isDisabled}
          unit={t('units.kilowattPerformance')}
          required
        />
        <TextInput
          value={formatDate(view.offer?.issuanceDate)}
          name="issuance-date"
          type="date"
          placeholder={t('offers.offerDetails.offerDate')}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_OFFER_ISSUANCE_DATE', payload: new Date(e.target.value) })
          }
          disabled={isDisabled}
          required
        />
        <TextInput
          value={formatDate(view.offer?.deliveryDate)}
          name="delivery-date"
          type="date"
          placeholder={t('offers.offerDetails.installationDate.label')}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_OFFER_DELIVERY_DATE', payload: new Date(e.target.value) })
          }
          disabled={isDisabled}
        />
      </div>
      <Text as="h2" category="headline" size="medium">
        {t('offers.installerDetails')}
      </Text>
      <div className="details-edit">
        <TextInput
          value={view.offer?.contact?.phoneNumber}
          name="contact-phone-number"
          type="text"
          placeholder={t('phoneNumber')}
          disabled
        />
        <TextInput
          value={view.offer?.contact?.email}
          name="contact-email"
          type="text"
          placeholder={t('email')}
          disabled
          required
        />
        <TextInput
          value={parseName(view.offer?.contact?.firstName, view.offer?.contact?.lastName)}
          name="contact-person"
          type="text"
          placeholder={t('offer.contactPerson')}
          disabled
          required
        />
        <TextInput
          value={view.offer?.partner?.name}
          name="partner-company"
          type="text"
          placeholder={t('offer.partnerCompany')}
          disabled
          required
        />
        <TextArea
          value={view.offer?.description}
          name="description"
          className="description"
          placeholder={t('offers.description')}
          onChange={(e) => dispatch({ type: 'CHANGE_DESCRIPTION', payload: e.target.value })}
          disabled={isDisabled}
        />
        <TextInput
          value={formatDate(view.offer?.validThru)}
          name="valid-thru-date"
          type="date"
          placeholder={t('offers.offerDetails.validThruDate.label')}
          onChange={(e) =>
            dispatch({ type: 'CHANGE_OFFER_VALID_THRU_DATE', payload: new Date(e.target.value) })
          }
          disabled={isDisabled}
          required
        />
        <div>
          <Text as="span" category="title" size="medium">
            {t('offers.offerDetails.instructionNote.pleaseNote')}
          </Text>
          <Text as="p" category="body" size="medium">
            {t('offers.offerDetails.instructionNote.instruction')}
          </Text>
          <UploadFile
            handleChangeFiles={handleUpload}
            disable={isDisabled || !!view.offer?.documentUrl}
          />
          {(uploadedFile?.name || view.offer?.documentUrl) && (
            <FileItem
              name={
                (parseFilename(uploadedFile?.name) as string) ||
                (parseFilename(view.offer?.documentUrl) as string)
              }
              onDelete={deleteDocumentUrl}
              disable={isDisabled}
              variation="primary"
            />
          )}
        </div>
      </div>
      <Button
        variation="primary"
        size="large"
        dataTest="partner_offer_view-submit"
        disabled={buttonDisabled}
        onClick={handleSubmit}>
        {t('saveChanges')}
      </Button>
    </div>
  );
};

export default PartnerOfferView;
