import TextInput from '../TextInput/TextInput';
import { phoneNumberValidation } from '../../../utils/validations.utils';
import { useTranslation } from 'react-i18next';

interface PhoneTextInputProps {
  value?: string;
  onChange: (phoneNumber: string) => void;
}

const PhoneTextInput = ({ value, onChange }: PhoneTextInputProps) => {
  const { t } = useTranslation();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    if (!value.includes('+')) {
      value = '+' + e.target.value;
    }
    onChange(value);
  };

  return (
    <TextInput
      required
      name="phoneNumber"
      type="tel"
      placeholder={t('phoneNumber')}
      validate={phoneNumberValidation}
      value={value || ''}
      onChange={handlePhoneNumberChange}
    />
  );
};

export default PhoneTextInput;
