import React, { ReactNode } from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import Text from '../Text/Text';

import './NotificationCard.scss';

interface Props {
  mainText: string;
  icon?: IconProps['name'];
  children?: ReactNode;
  className?: string;
}

const NotificationCard = ({ mainText, icon, children, className }: Props) => {
  return (
    <div className={`notification-card-wrapper ${className ? className : ''}`}>
      <Text as={'p'}>{mainText}</Text>
      {children}
      {icon && <Icon className={'notification-icon'} name={icon} />}
    </div>
  );
};

export default NotificationCard;
