import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { de } from './locales/de/TranslationDe';
import { fr } from './locales/fr/TranslationFr';

export enum Languages {
  DE = 'de',
  FR = 'fr'
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: Object.values(Languages),
    resources: {
      de: { translation: de },
      fr: { translation: fr }
    },
    fallbackLng: Languages.DE,
    interpolation: { escapeValue: false },
    returnNull: false
  });

export default i18n;
