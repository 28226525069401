import { decimalNumberValidation } from '../../../utils/validations.utils';
import Text from '../../Text/Text';
import './CurrencyLinkedValue.scss';

interface CurrencyLinkedValueProps {
  value: string;
  name: string;
  onChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
  currency: string;
  currencyPosition?: 'left' | 'right';
}

const CurrencyLinkedValue = ({
  value,
  name,
  onChange,
  currency,
  currencyPosition = 'left'
}: CurrencyLinkedValueProps) => {
  const getClassName = () => `currency-link-input-wrapper ${currencyPosition}`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!decimalNumberValidation(e.target.value) && !(e.target.value === '')) {
      return;
    }
    onChange(e);
  };

  return (
    <div className={getClassName()}>
      <Text as="span" category="label" size="large">
        {currency}
      </Text>
      {value !== null && <input type="text" value={value} name={name} onChange={handleChange} />}
    </div>
  );
};

export default CurrencyLinkedValue;
