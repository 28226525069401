import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Radio from '../Inputs/Radio/Radio';
import filterIcon from '../../assets/Icons/filter.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useState } from 'react';
import './Filters.scss';

interface FiltersProps {
  filters: string[];
  dropdownPosition?: 'left' | 'right';
  placeholder: string;
  translationKey?: string;
  selectedFilter?: string;
  onFilterChange: (filter: string) => void;
}

const Filters = ({
  filters,
  dropdownPosition = 'right',
  placeholder,
  translationKey,
  selectedFilter,
  onFilterChange
}: FiltersProps) => {
  const { t } = useTranslation();

  const ref = useOutsideClick(() => {
    setOpenFilterDropdown(false);
  });

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [initialPlaceholder, setInitialPlaceholder] = useState(true);

  const handleChange = (value: string) => {
    onFilterChange(value);
    setOpenFilterDropdown(false);
    setInitialPlaceholder(false);
  };

  return (
    <div
      className={`filters-wrapper ${openFilterDropdown ? 'show-dropdown' : 'hide-dropdown'}`}
      ref={ref}>
      <Button
        variation="transparent"
        icon={filterIcon}
        iconPosition="left"
        size="large"
        onClick={() => setOpenFilterDropdown(!openFilterDropdown)}
        dataTest="filter-button"
        className="filter-button">
        {placeholder && initialPlaceholder
          ? placeholder
          : selectedFilter === undefined
          ? placeholder
          : t(`${translationKey}${selectedFilter}`)}
      </Button>
      <div className={`filter-dropdown ${dropdownPosition}`}>
        <Radio
          label={t('all')}
          value="all"
          checked={
            selectedFilter === 'all' || selectedFilter === '' || selectedFilter === undefined
          }
          onClick={handleChange}
          className={'filter-radio'}
        />
        {filters?.map((value, index) => (
          <Radio
            key={index}
            label={t(`${translationKey}${value}`)}
            value={value}
            checked={value === selectedFilter}
            onClick={handleChange}
            className="filter-radio"
          />
        ))}
      </div>
    </div>
  );
};

export default Filters;
