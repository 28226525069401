import { Partner } from '../partners/partners.types';
import { Project } from './project.types';
import { Module } from '../../types/financialOffer.types';
import { Building } from '../../types/sepMap.types';
import { DeepPartial, SwissCanton } from '../../types/util.types';
import { MIN_DOWN_PAYMENT } from '../../utils/loan.utils';

export const emptyProject: DeepPartial<Project> = {
  owner: {
    email: '',
    name: ''
  },
  modules: [],
  // TODO SAKBX475-451: Remove when possible
  financingModel: {
    deposit: MIN_DOWN_PAYMENT
  },
  building: undefined,
  offer: {},
  partners: [],
  cantonCode: undefined
};

type ProjectActions =
  | {
      type: 'CHANGE_OWNER_NAME';
      payload?: string;
    }
  | {
      type: 'CHANGE_OFFER_PRICE';
      payload?: number;
    }
  | {
      type: 'CHANGE_OFFER_SUBSIDY';
      payload?: number;
    }
  | {
      type: 'CHANGE_OFFER_AREA';
      payload?: number;
    }
  | {
      type: 'CHANGE_OFFER_ISSUANCE_DATE';
      payload?: Date;
    }
  | {
      type: 'CHANGE_OFFER_VALID_THRU_DATE';
      payload?: Date;
    }
  | {
      type: 'CHANGE_OFFER_DELIVERY_DATE';
      payload?: Date;
    }
  | {
      type: 'ADD_MODULE';
      payload: Module;
    }
  | {
      type: 'REMOVE_MODULE';
      payload: Module;
    }
  | {
      type: 'CHANGE_BUILDING';
      payload: Building;
    }
  | {
      type: 'SET_CANTON';
      payload: SwissCanton;
    }
  | {
      type: 'REMOVE_BUILDING';
    }
  | {
      type: 'CHANGE_DEPOSIT';
      payload: number;
    }
  | {
      type: 'CHANGE_DURATION';
      payload: number;
    }
  | {
      type: 'CHANGE_PERFORMANCE';
      payload: number;
    }
  | {
      type: 'CHANGE_CONTACT_PERSON';
      payload?: string;
    }
  | {
      type: 'CHANGE_PARTNERS';
      payload: Partial<Partner>[];
    };

export type ProjectActionTypes = ProjectActions;

export const projectReducer = (state: DeepPartial<Project>, action: ProjectActions) => {
  switch (action.type) {
    case 'CHANGE_OWNER_NAME':
      return {
        ...state,
        owner: { name: action.payload }
      };
    case 'CHANGE_OFFER_PRICE':
      return {
        ...state,
        offer: { ...state.offer, offeredPrice: action.payload }
      };
    case 'CHANGE_OFFER_SUBSIDY':
      return {
        ...state,
        offer: { ...state.offer, subsidy: action.payload }
      };
    case 'CHANGE_OFFER_AREA':
      return {
        ...state,
        offer: { ...state.offer, area: action.payload }
      };
    case 'CHANGE_OFFER_ISSUANCE_DATE':
      return {
        ...state,
        offer: { ...state.offer, issuanceDate: action.payload }
      };
    case 'CHANGE_OFFER_VALID_THRU_DATE':
      return {
        ...state,
        offer: { ...state.offer, validThru: action.payload }
      };
    case 'CHANGE_OFFER_DELIVERY_DATE':
      return {
        ...state,
        offer: { ...state.offer, deliveryDate: action.payload }
      };
    case 'ADD_MODULE':
      return {
        ...state,
        modules: [...(state.modules || []), action.payload]
      };
    case 'REMOVE_MODULE':
      return {
        ...state,
        modules: state.modules
          ? [...state.modules.filter((module) => module.id !== action.payload.id)]
          : []
      };
    case 'CHANGE_BUILDING':
      return { ...state, building: action.payload };
    case 'SET_CANTON':
      return { ...state, cantonCode: action.payload };
    case 'REMOVE_BUILDING':
      return { ...state, building: undefined };
    case 'CHANGE_DEPOSIT':
      return {
        ...state,
        financingModel: { ...state.financingModel, deposit: action.payload }
      };
    case 'CHANGE_DURATION':
      return {
        ...state,
        financingModel: { ...state.financingModel, duration: action.payload }
      };
    case 'CHANGE_PERFORMANCE':
      return {
        ...state,
        offer: { ...state.offer, performance: action.payload }
      };
    case 'CHANGE_CONTACT_PERSON': {
      return {
        ...state,
        offer: { ...state.offer, contactPerson: action.payload }
      };
    }
    case 'CHANGE_PARTNERS':
      return {
        ...state,
        partners: action.payload
      };
    default:
      return emptyProject;
  }
};
