import { useTranslation } from 'react-i18next';
import Text from '../../components/Text/Text';
import UploadFile from './UploadFile/UploadFile';
import StatusModal, { STATUS, Status } from './StatusModal/StatusModal';
import { useModal } from '../../hooks/useModal';
import './UploadOffer.scss';
import QRCode from 'react-qr-code';
import { useState } from 'react';
import { UploadedOffer } from '../../store/project/project.types';
import { scanOffer } from '../../store/offers/offers.action';
import useNavigation from '../../hooks/useNavigation';
import { ErrorCode } from '../../store/error/error.type';

const UploadOffer = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { isOpened, toggle } = useModal();

  const [status, setStatus] = useState<Status>(STATUS.PENDING);
  const [uploadedOffer, setUploadedOffer] = useState<UploadedOffer>({
    project: {},
    offer: {},
    owner: {},
    partner: {}
  });
  const [progress, setProgress] = useState(0);

  const scanFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      setStatus(STATUS.PENDING);
      toggle();
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await scanOffer(formData);
        setProgress(response?.percentage as number);
        setUploadedOffer((prev) => ({
          ...prev,
          documentUrl: response?.documentUrl
        }));
        localStorage.setItem('documentUrl', JSON.stringify(response?.documentUrl));
        setStatus(STATUS.SUCCESS);
      } catch (err) {
        if (err === ErrorCode.INVALID_FYLE_TYPE) {
          return setStatus(STATUS.ERROR_TYPE);
        }
        setStatus(STATUS.ERROR);
      } finally {
        !isOpened && toggle();
      }
    }
  };

  const closeModal = () => {
    toggle();
    if (status === STATUS.SUCCESS) {
      goTo('/upload-offer/edit', {
        state: uploadedOffer
      });
    }
    if (status === STATUS.ERROR_TYPE) {
      goTo('/upload-offer');
    }
  };

  return (
    <div className="uploadOffer">
      <Text as="h2" className="col-1" category="display" size="medium">
        {t('uploadOffer.title')}
      </Text>
      <Text as="p" className="uploadOfferDescription" category="body" size="medium">
        {t('uploadOffer.description')}
      </Text>
      <div className="uploadFile-section">
        <UploadFile handleChangeFiles={scanFile} />
        <div className="scanQRCode-section">
          <div className="qr-wrapper">
            <QRCode value={`${window.location.href}`} />
          </div>
          <div className="scanQRCode-text">
            <Text as="h2" className="scanQRCode-heading" category="headline" size="medium">
              {t('uploadOffer.title')}
            </Text>
            <Text
              as="p"
              className="col-1"
              category="label"
              size="medium"
              style={{ marginTop: '16px' }}>
              {t('uploadOffer.qr.description.doNotHaveOfferOficial')}
            </Text>
            <Text
              as="p"
              className="col-1"
              category="label"
              size="medium"
              style={{ marginTop: '36px' }}>
              {t('uploadOffer.qr.description.scanQrCode')}
            </Text>
          </div>
        </div>
      </div>
      <StatusModal
        status={status}
        progress={progress}
        isOpened={isOpened}
        close={toggle}
        onClick={closeModal}
      />
    </div>
  );
};

export default UploadOffer;
