import { Trans, useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';
import './StatusModal.scss';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import Text from '../../../components/Text/Text';
import { hubspotLinks } from '../../../utils/hubspotLinks';

export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  ERROR_TYPE: 'errorType',
  PENDING: 'pending'
};

export type Status = (typeof STATUS)[keyof typeof STATUS];

interface StatusModalProps extends Partial<ModalProps> {
  progress: number;
  status: Status;
  onClick: () => void;
}

const StatusModal = ({
  status,
  progress,
  isOpened = true,
  close = () => '',
  onClick = () => ''
}: StatusModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={t(`uploadOffer.${status}.title`)} isOpened={isOpened} close={close}>
      <div className="status-modal">
        <Text as="p" category="body" size="medium">
          {t(`uploadOffer.${status}.description`)}
        </Text>
        {status === STATUS.PENDING && (
          <div className="progress-bar-wrapper">
            <ProgressBar progress={progress} canBeTracked={true} />
          </div>
        )}
        {status === STATUS.SUCCESS && (
          <Button
            size="small"
            variation="primary"
            onClick={onClick}
            dataTest="upload_offer-check-offer-button">
            {t(`uploadOffer.success.buttonLabel`)}
          </Button>
        )}
        {status == STATUS.ERROR_TYPE && (
          <>
            <Text as="p" category="body" size="medium">
              <Trans
                i18nKey="uploadOffer.success.troubleUploading"
                t={t}
                components={{
                  contactLink: (
                    <a
                      href={hubspotLinks.contact}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="upload-file-contact"
                    />
                  )
                }}
              />
            </Text>
            <Button
              size="small"
              variation="primary"
              onClick={onClick}
              dataTest="upload_offer-check-offer-button">
              {t(`uploadOffer.success.buttonLabel`)}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default StatusModal;
