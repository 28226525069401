import './Footer.scss';
import { useTranslation } from 'react-i18next';
import Text from '../Text/Text';
import Icon, { icons } from '../Icon/Icon';
import Logo from '../../assets/Images/investerra-logo.png';
import Link from '../Link/Link';
import { copyToClipboard, dialNumber } from '../../utils/general.utils';
import Button from '../Button/Button';
import { hubspotLinks } from '../../utils/hubspotLinks';

interface FooterLink {
  key: string;
  to: string;
}

const Footer = () => {
  const { t } = useTranslation();

  const footerInfoLinks: FooterLink[] = [
    { key: 'aboutInvestera', to: hubspotLinks.about },
    { key: 'contact', to: hubspotLinks.contact },
    { key: 'becomePartner', to: hubspotLinks.becomePartner },
    { key: 'faq', to: hubspotLinks.faq },
    { key: 'insurance', to: hubspotLinks.insurance }
  ];

  const footerContactLinks: Partial<FooterLink>[] = [{ key: 'webPage' }, { key: 'number' }];

  const footerSocialLinks: FooterLink[] = [
    // { key: 'facebook', to: '/' },
    { key: 'linkedin', to: 'https://www.linkedin.com/company/investerra-ag/' }
    // { key: 'instagram', to: '/' }
  ];

  const footerRulesLinks: FooterLink[] = [
    { key: 'impresion', to: hubspotLinks.impresium },
    { key: 'dataProtection', to: hubspotLinks.dataProtection },
    { key: 'termsOfUse', to: hubspotLinks.termsOfUse }
  ];

  const handleOnClick = (elem: Partial<FooterLink>) => {
    if (elem.key === 'webPage') {
      copyToClipboard(t(`footer.contact.${elem.key}`));
    }
    if (elem.key === 'number') {
      dialNumber(t('investerraDetails.support.phone'));
    }
  };

  return (
    <footer>
      <div className="footer-flex-wrapper">
        <div className="footer-logo-wrapper">
          <img src={Logo} alt="Company logo" className="logo" />
          <Text as="p" category="label" size="small">
            {t('footer.description')}
          </Text>
          <ul className="social-list">
            {footerSocialLinks.map((elem: FooterLink, index: number) => (
              <li key={index}>
                <Link href={elem.to} variation="secondary" dataTest="">
                  <Icon name={elem.key as keyof typeof icons} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <ul className="info-list">
          {footerInfoLinks.map((elem: FooterLink, index: number) => (
            <li key={index}>
              <Link href={elem.to} variation="tertiary" dataTest="">
                {t(`footer.info.${elem.key}`)}
              </Link>
            </li>
          ))}
        </ul>
        <div className="footer-contact-wrapper">
          <ul className="contact-list">
            {footerContactLinks.map((elem: Partial<FooterLink>, index: number) => (
              <li
                key={index}
                className="contact-list-item"
                onClick={() => {
                  handleOnClick(elem);
                }}>
                <Text as="span" category="label" size="large" variety={2}>
                  {elem.key === 'number' && <Icon name="call" />}
                  {elem.key === 'webPage' && <Icon name="copy" />}
                  {t(`footer.contact.${elem.key}`)}
                </Text>
              </li>
            ))}
          </ul>
          <Button
            variation="secondary"
            size="large"
            dataTest=""
            onClick={() => {
              window.location.href = hubspotLinks.contact;
            }}>
            {t('footer.contact.contactUs')}
          </Button>
        </div>
      </div>
      <div className="copyright-wrapper">
        <Text as="span" category="body" size="small" className="copyright">
          {t('footer.copyright')}
        </Text>
        <ul>
          {footerRulesLinks.map((elem: FooterLink, index) => (
            <li key={index}>
              <Link variation="secondary" href={elem.to} dataTest="">
                <Text as="span" category="body" size="small" className="copyright">
                  {t(`footer.rules.${elem.key}`)}
                </Text>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
