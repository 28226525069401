import { FinancingModel, Module, ModuleType } from '../../types/financialOffer.types';
import { Building } from '../../types/sepMap.types';
import { RoofSustainability } from '../../types/solarSystem.types';
import { Sort, SwissCanton } from '../../types/util.types';
import { PartnerOffer } from '../offers/offers.types';
import { Partner, PartnerCompany } from '../partners/partners.types';
import { Profile } from '../profile/profile.types';

export enum FinancialOverviewType {
  FINAL = 'FINAL',
  INDICATIVE = 'INDICATIVE'
}

// eslint-disable-next-line
export interface Owner extends Pick<Profile, 'name' | 'email'> {}

export interface CreateProjectArgs {
  owner: Profile;
  modules: Module[] | [];
  financingModel: FinancingModel;
  building: Partial<Building>;
}

export interface CreateProject {
  project: NplProject;
  projectDto: Project;
}

export type GetProjectById = CreateProject;

export interface NplProject {
  userId: string;
  loanAmount: string;
  loanTerm: number;
  productTypes: ModuleType[];
  fourEyeCheckId?: string;
  currentState?: NplProjectState;
  id?: string;
}

export interface Project {
  id: number;
  status: string;
  owner: Profile;
  modules: Module[];
  financingModel: FinancingModel;
  building: Building;
  partners: Partner[];
  projectUuid: string;
  offer?: PartnerOffer;
  cantonCode?: SwissCanton;
}
export interface UploadedOffer {
  project: Partial<Project>;
  offer: Partial<PartnerOffer>;
  owner: Partial<Owner>;
  partner: Partial<PartnerCompany>;
  documentUrl?: string;
}

export interface GetProjects {
  keyword?: string;
  size: number;
  page: number;
  userUuid?: string;
  sort?: Sort[];
}

export interface ProjectsListItem {
  projectUuid: string;
  id: number;
  creationTime: string;
  status: ProjectStatus;
  address: string;
  clientName: string;
  partnerName: string;
  lastLogAuthor?: string;
}

export interface ProjectsList {
  projects: ProjectsListItem[];
  size: number;
  totalPages: number;
  totalItems: number;
  pageNumber: number;
}

export interface AdminProjectOverview {
  id: number;
  projectStatus: ProjectStatus;
  projectAddress: string;
  customerId: number;
  customerName: string;
  customerAddress: string;
  partnerName: string;
  contactName: string;
  contactEmail: string;
  offerTime: string;
  offeredPrice: number;
  monthlyRate: number;
  allowance: number;
  projectCheck: ProjectReviewChecks | undefined;
  logs: [];
  latitude: number;
  longitude: number;
  activeModules: Module[];
}

export interface ProjectDetailsType {
  sepId: number | string;
  id: number;
  area: number;
  customerId: number;
  customerName: string;
  deposit: number;
  duration: number;
  monthlyRate: number;
  offerDate: string;
  offerPrice: number;
  partnerAddress: string;
  partnerContactEmail: string;
  partnerContactName: string;
  partnerContactPhone: string;
  partnerName: string;
  performance: number;
  projectAddressCity: string;
  projectAddressNumber: string;
  projectAddressStreet: string;
  projectAddressZip: number;
  status: ProjectStatus;
  subsidy: number;
  offerDocumentUrl: string;
  offerId: number;
  acceptanceReportUrl: string;
  activeModules: Module[];
  latitude: number;
  longitude: number;
  orderConfirmationUrl: string;
}

export enum ProjectStatus {
  OFFER_REQUESTED = 'OFFER_REQUESTED',
  OFFER_RECEIVED = 'OFFER_RECEIVED',
  CHECK_OPEN = 'CHECK_OPEN',
  CHECK_APPROVE_REQUESTED = 'CHECK_APPROVE_REQUESTED',
  CHECK_FAILED = 'CHECK_FAILED',
  CHECK_DONE = 'CHECK_DONE',
  CONTRACTS_ISSUED = 'CONTRACTS_ISSUED',
  CLIENT_SIGNED = 'CLIENT_SIGNED',
  LOAN_OPEN = 'LOAN_OPEN',
  LOAN_CLOSED = 'LOAN_CLOSED'
}

export enum NplProjectState {
  QUOTE_APPROVED = 'quoteApproved',
  QUESTIONAIRE_SUBMITTED = 'questionaireSubmitted',
  KYC_PASSED = 'kycPassed',
  KYC_FAILED = 'kycFailed',
  CREDIT_CHECK_COMPLETED = 'creditCheckCompleted',
  CONTRACT_ISSUED = 'contractIssued',
  CONTRACT_SIGNED = 'contractSigned',
  FOUR_EYE_CHECK_TO_BE_REVIEWED_BY_SUPER_ADMIN = 'fourEyeCheckToBeReviewedBySuperAdmin',
  REOPENED = 'reopened',
  FOUR_EYE_CHECK_PASSED = 'fourEyeCheckPassed',
  FOUR_EYE_CHECK_FAILED = 'fourEyeCheckFailed',
  CLOSED = 'closed'
}

export interface ProjectLog {
  logType: ProjectLogType;
  date: Date;
  partyName: string;
  partyEmail: string;
  comment: string;
  files?: string[];
}

export interface NewProjectLog {
  approved: boolean;
  fileNames: string[];
  comment: string;
}

export interface SelectedFile {
  fileName: string;
  file: File;
  disabled: boolean;
}

export enum ProjectLogType {
  CREATED = 'CREATED',
  QUESTIONAIRE_SUBMITTED = 'QUESTIONAIRE_SUBMITTED',
  FOUR_EYE_CHECK_REQUESTED = 'FOUR_EYE_CHECK_REQUESTED',
  FOUR_EYE_CHECK_APPROVED = 'FOUR_EYE_CHECK_APPROVED',
  FOUR_EYE_CHECK_REJECTED = 'FOUR_EYE_CHECK_REJECTED',
  FOUR_EYE_CHECK_REOPENED = 'FOUR_EYE_CHECK_REOPENED',
  PROJECT_APPROVED = 'PROJECT_APPROVED',
  PROJECT_DECLINED = 'PROJECT_DECLINED',
  PROJECT_RESET = 'PROJECT_RESET',
  PROJECT_RETRIGGER = 'PROJECT_RETRIGGER'
}

export interface ProjectReviewChecks {
  installationPartner: boolean;
  generalCheck: boolean;
  creditCheck: boolean;
  creditWorthiness: boolean;
  profileId: number;
}

export interface FinancialOverviewInputs {
  type: FinancialOverviewType;
  offerPrice?: number;
  deposit: number;
  modules: Pick<Module, 'type' | 'value'>[];
  performance?: number;
  solarPotential: number;
  altitude: number;
  sustainability: number;
}

export interface FinancialOverviewPlanInputs {
  type: FinancialOverviewType;
  offerPrice?: number;
  deposit: number;
  duration: number;
  subsidy: number;
  modules: Pick<Module, 'type' | 'value'>[];
  performance?: number;
}

export interface FinancialOverviewPlan {
  rate: number;
  minDuration: number;
  maxDuration: number;
  minDeposit: number;
  maxDeposit: number;
}

export interface FinancialOverview {
  objectPrice: number;
  loan: number;
  fee: number;
  totalLoan: number;
  subsidy: number;
  effectiveAnnualInterestRate: number;
  selfConsumptionPotential: number;
  potentialCO2Savings: number;
  solarPotential: number;
  sustainability: RoofSustainability;
  annualSavings: number;
  feedInTariff: number;
  performance?: number;
}

export interface ProjectFinancialOverview extends FinancialOverview {
  plan: FinancialOverviewPlan;
}
