import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import { ProjectLog } from '../../../../../store/project/project.types';
import { SetStateType } from '../../../../../types/util.types';
import './CommentPreview.scss';
import Text from '../../../../../components/Text/Text';
import { getInitialsFromFullName } from '../../../../../utils/general.utils';
import { formatDateWithTime } from '../../../../../utils/formatting.utils';

interface CommentPreviewProps {
  nextButton: boolean;
  previousButton: boolean;
  comment?: ProjectLog;
  setSelectedComment: SetStateType<number>;
  toggle: () => void;
}

const CommentPreview = ({
  nextButton,
  previousButton,
  comment,
  setSelectedComment,
  toggle
}: CommentPreviewProps) => {
  const { t } = useTranslation();

  return (
    <>
      {comment && (
        <div className="comment-preview-wrapper">
          <div className="comment-preview-action-wrapper">
            <Button
              variation="secondary"
              size="large"
              disabled={previousButton}
              dataTest=""
              onClick={() => {
                setSelectedComment((prev) => prev - 1);
              }}>
              {t('admin.projectDetails.projectReview.commentPreview.previousComment')}
            </Button>
            <Button
              variation="secondary"
              size="large"
              disabled={nextButton}
              dataTest=""
              onClick={() => {
                setSelectedComment((prev) => prev + 1);
              }}>
              {t('admin.projectDetails.projectReview.commentPreview.nextComment')}
            </Button>
          </div>
          <div className="comment-description">
            <Text as="span" category="body" size="medium" className="comment-person">
              {getInitialsFromFullName(comment.partyName)}
            </Text>
            <div>
              <Text as="span" category="body" size="large">
                {formatDateWithTime(comment.date)}
              </Text>
              <Text as="span" category="label" size="large">
                {comment.logType}
              </Text>
              <Text as="span" category="body" size="small" className="comment-text">
                {comment.comment}
              </Text>
            </div>
          </div>
          <Button variation="primary" size="large" dataTest="" onClick={toggle}>
            {t('close')}
          </Button>
        </div>
      )}
    </>
  );
};
export default CommentPreview;
