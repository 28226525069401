import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './Inbox.scss';
import MessageBox from './MessageBox/MessageBox';
import Button from '../../../components/Button/Button';
import { Message } from '../../../store/profile/profile.types';
import { getAllMessages } from '../../../store/profile/profile.actions';
import { formatDateWithTime } from '../../../utils/formatting.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { User } from '../../../store/user/types';
import useNavigation from '../../../hooks/useNavigation';

const Inbox = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [messages, setMessages] = useState<Message[]>([] as Message[]);

  const [selectedMessage, setSelectedMessage] = useState<Message>(messages[0]);

  useEffect(() => {
    getAllMessages(user.email, 100, 0).then((res) => {
      const updatedMessages = res.messages.map((message) => {
        const { plain_content } = JSON.parse(message.plainContent);
        const values = JSON.parse(message.values);
        const fillEmailPlaceholders = replaceJSON(plain_content, values);

        return {
          ...message,
          plainContent: fillEmailPlaceholders
        };
      });
      setMessages(updatedMessages);
    });
  }, []);

  useEffect(() => {
    !!messages && setSelectedMessage(messages[0]);
  }, [messages]);

  const replaceJSON = (content: string, values: string) => {
    const placeholderPattern = /{{(\w+)}}/g;
    return content
      .replace(/\\n\\/g, '\n')
      .replace(placeholderPattern, (match: string, key: number) => values[key] || '');
  };

  return (
    <div className="inbox-wrapper">
      <Text as="h3" category="display" size="medium">
        {t('inbox.title')}
      </Text>
      <div className="inbox-messages-wrapper">
        <div className="inbox-message-list">
          <div className="inbox-message-box-wrapper">
            {messages.length > 0 &&
              messages.map((message: Message, index) => (
                <MessageBox
                  key={index}
                  message={message}
                  selected={message.id === selectedMessage?.id}
                  setSelected={setSelectedMessage}
                />
              ))}
          </div>
        </div>
        {messages.length ? (
          <div className="selected-message">
            <Text as="span" category="label" size="medium">
              {formatDateWithTime(selectedMessage?.sentTimestamp)}
            </Text>
            <Text as="h4" category="title" size="large">
              {selectedMessage?.subject}
            </Text>
            <Text as="p" category="body" size="medium" className="message-content">
              {selectedMessage?.plainContent}
            </Text>
            <Button
              variation="primary"
              dataTest=""
              size="large"
              onClick={() => {
                goTo('/my-profile');
              }}>
              {t('myProfile.title')}
            </Button>
          </div>
        ) : (
          <Text as={'div'} category={'headline'} size={'large'} className={'inbox-empty'}>
            {t('inbox.empty')}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Inbox;
