import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import CurrencyLinkedValue from '../../../../components/Inputs/CurrencyLinkedValue/CurrencyLinkedValue';
import TableHead from '../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../components/Text/Text';
import './PartnerCommissions.scss';
import { useEffect, useState } from 'react';
import { PartnerCommission } from '../../../../store/partners/partners.types';
import {
  getPartnerCommissions,
  updateAllCommissions
} from '../../../../store/partners/partners.action';
import { toastUtil } from '../../../../utils/toast.utils';

export enum PartnerCommissionsTableHeading {
  LEVEL = 'level',
  BROKERED_VALUE = 'brokeredValue',
  ORDER_VOLUME = 'orderVolume',
  LOAN_AMOUNT = 'loanAmount',
  PAYMENT_TERMS = 'paymentTerms'
}

const PartnerCommissions = () => {
  const { t } = useTranslation();

  const levelsTable = Object.keys(PartnerCommissionsTableHeading).map((elem: string) => elem);

  const [partnerCommissions, setPartnerCommissions] = useState<PartnerCommission[]>([]);

  useEffect(() => {
    getAllCommission();
  }, []);

  const getAllCommission = async () => {
    const data = await getPartnerCommissions();
    data && setPartnerCommissions(data);
  };

  const changeCommission = (
    commissionValue: PartnerCommission,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    setPartnerCommissions((prevCommissions) => {
      return prevCommissions.map((commission) =>
        commission.id === commissionValue.id
          ? {
              ...commission,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              [name]: { ...(commission as any)[name], value: value }
            }
          : commission
      );
    });
  };

  const updateCommissions = async () => {
    const updatedAllCommissions = partnerCommissions.map((elem: PartnerCommission) => {
      return {
        id: elem.id,
        brokeredValue: elem.brokeredValue.value,
        loanAmount: elem.loanAmount.value,
        orderVolume: elem.orderVolume.value,
        paymentTerms: elem.paymentTerms.value
      };
    });
    const resp = await updateAllCommissions(updatedAllCommissions);
    if (resp) {
      toastUtil('success', t('changesSaved'));
      getAllCommission();
    }
  };

  return (
    <div className="partner-levels-wrapper">
      <TableHead
        columnWidths={[24, 23, 17, 17, 19]}
        values={levelsTable}
        translationKey="partnerCommissions.table.heading"
        hoverEffect>
        {partnerCommissions.map((elem: PartnerCommission, index) => (
          <tr key={index}>
            <Text as="td" category="label" size="large">
              {t(`partnerLevel.${elem.partnerLevel}`)}
            </Text>
            <Text as="td" category="label" size="large">
              <CurrencyLinkedValue
                currency={elem.brokeredValue.unit}
                name="brokeredValue"
                onChange={(e) => {
                  changeCommission(elem, e);
                }}
                value={elem.brokeredValue.value ? elem.brokeredValue.value : ''}
                currencyPosition="left"
              />
            </Text>
            <Text as="td" category="label" size="large">
              <CurrencyLinkedValue
                currency={elem.orderVolume.unit}
                name="orderVolume"
                onChange={(e) => {
                  changeCommission(elem, e);
                }}
                value={elem.orderVolume.value}
                currencyPosition="right"
              />
            </Text>
            <Text as="td" category="label" size="large">
              <CurrencyLinkedValue
                currency={elem.loanAmount.unit}
                name="loanAmount"
                onChange={(e) => {
                  changeCommission(elem, e);
                }}
                value={elem.loanAmount.value}
                currencyPosition="right"
              />
            </Text>
            <Text as="td" category="label" size="large">
              <CurrencyLinkedValue
                currency={elem.paymentTerms.unit[0].toLowerCase()}
                name="paymentTerms"
                onChange={(e) => {
                  changeCommission(elem, e);
                }}
                value={elem.paymentTerms.value}
                currencyPosition="right"
              />
            </Text>
          </tr>
        ))}
      </TableHead>
      <Button variation="primary" size="large" dataTest="" onClick={updateCommissions}>
        {t('saveChanges')}
      </Button>
    </div>
  );
};

export default PartnerCommissions;
