import { useCallback } from 'react';
import type { BlockerFunction } from 'react-router-dom';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

const useOnLeave = () => {
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }): boolean =>
      currentLocation.pathname !== nextLocation.pathname,
    []
  );

  const blocker = useBlocker(shouldBlock);

  useBeforeUnload(
    useCallback((e) => {
      e.preventDefault();
      return (e.returnValue = '');
    }, [])
  );

  return { blocker };
};

export default useOnLeave;
