import { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import './Button.scss';
import Text from '../Text/Text';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variation: 'primary' | 'secondary' | 'transparent';
  size: 'small' | 'medium' | 'large';
  dataTest: string;
  children: ReactNode;
  icon?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  iconPosition?: 'right' | 'left';
  to?: () => void;
}

export const Button = ({
  children,
  icon,
  size,
  variation,
  onClick,
  iconPosition,
  type,
  disabled,
  className,
  style,
  dataTest
}: Props) => {
  const getClassName = () => `button ${variation} ${size} ${icon ? 'button-icon' : ''}
  ${iconPosition === 'left' ? 'left' : ''} ${className ? className : ''}`;

  return (
    <button
      className={getClassName()}
      style={style}
      onClick={onClick}
      type={type}
      disabled={disabled}
      data-test={dataTest}>
      <Text as="span" category="label" size="large">
        {children}
      </Text>
      {icon && <ReactSVG src={icon} />}
    </button>
  );
};

export default Button;
