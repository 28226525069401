import { copyToClipboard, dialNumber } from '../../utils/general.utils';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import './ContactBox.scss';

interface ContactBoxProps {
  name: string;
  email: string;
  phone?: string;
  partnerName?: string;
  partnerAdress?: string;
}

const ContactBox = ({ name, phone, email, partnerName, partnerAdress }: ContactBoxProps) => {
  return (
    <div className="contact-box-wrapper">
      {(partnerName || partnerAdress) && (
        <div className="partner-contact-box-wrapper">
          <Text as="h4" category="title" size="large">
            {partnerName}
          </Text>
          <Text as="span" category="body" size="medium">
            {partnerAdress}
          </Text>
        </div>
      )}
      <div className="user-contact-box-wrapper">
        <Text as="span" category="label" size="large">
          <Icon name="person" />
          {name}
        </Text>
        {phone && (
          <Text
            as="span"
            category="label"
            size="large"
            onClick={() => {
              phone && dialNumber(phone);
            }}>
            <Icon name="call" />
            {phone}
          </Text>
        )}
        <Text
          as="span"
          category="label"
          size="large"
          onClick={() => {
            copyToClipboard(email);
          }}>
          <Icon name="email" />
          {email}
        </Text>
      </div>
    </div>
  );
};

export default ContactBox;
