import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './UploadFile.scss';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';

interface UploadFileProps {
  style?: React.CSSProperties;
  disable?: boolean;
  acceptFileType?: string;
  handleChangeFiles: (payload: FileList | null) => void;
}

const UploadFile = ({
  style = {},
  disable = false,
  acceptFileType,
  handleChangeFiles
}: UploadFileProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInput.current && fileInput.current.click();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleChangeFiles(e.dataTransfer.files);
  };

  const handleFileSelect = () => {
    if (fileInput.current?.files) {
      handleChangeFiles(fileInput.current.files);
      fileInput.current.value = '';
    }
  };

  return (
    <div
      className={`upload-file-wrapper ${disable ? 'disabled' : ''}`}
      style={style}
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      <div className="uploadFile" data-test="upload_offer-open_file-button">
        <input
          type="file"
          ref={fileInput}
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          accept={acceptFileType ? `.${acceptFileType}` : undefined}
        />
        <Icon name={disable ? 'upload-disabled' : 'upload'} height={128} width={128} />
        <div className="upload-file-text">
          <Text as="p" category="body" size="medium" style={{ marginTop: '19px' }}>
            {t('uploadOffer.dragFileHere')}
          </Text>
          <Button variation="primary" size="medium" dataTest="" disabled={disable}>
            <Text as="p" category="label" size="large" className="extensions">
              {t('searchFile')}
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
