import { useTranslation } from 'react-i18next';
import { Address } from '../../store/questionnaire/questionnaire.types';
import TextInput from '../Inputs/TextInput/TextInput';
import { validateEmpty, validatePositiveNumber } from '../../utils/validations.utils';
import './AddressForm.scss';

interface AddressFormProps {
  address: Address;
  required?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddressForm = ({ address, required, isDisabled, onChange }: AddressFormProps) => {
  const { t } = useTranslation();

  const addressFields = [
    { name: 'street', value: address?.street, type: 'text' },
    { name: 'houseNumber', value: address?.houseNumber, type: 'text' },
    { name: 'postalCode', value: address?.postalCode, type: 'number' },
    { name: 'municipality', value: address?.municipality, type: 'text' },
    {
      name: 'country',
      value: address?.country && t(`country.${address?.country}`),
      type: 'text',
      disabled: true
    },
    {
      name: 'canton',
      value: address?.canton && t(`cantons.${address?.canton}`),
      type: 'text',
      disabled: true
    }
  ];

  return (
    <div className="address-data-wrapper">
      {addressFields.map((field, index) =>
        index % 2 === 0 ? (
          <div key={index}>
            <TextInput
              value={field.value}
              placeholder={t(`creditCheck.housingSituation.customerAddress.${field.name}`)}
              name={field.name}
              type={field.type}
              validate={field.type === 'text' ? validateEmpty : validatePositiveNumber}
              disabled={field.disabled || isDisabled}
              onChange={onChange}
              required={required}
            />
            {index + 1 < addressFields.length && (
              <TextInput
                value={addressFields[index + 1].value}
                placeholder={t(
                  `creditCheck.housingSituation.customerAddress.${addressFields[index + 1].name}`
                )}
                name={addressFields[index + 1].name}
                type={addressFields[index + 1].type}
                disabled={field.disabled || isDisabled}
                onChange={onChange}
                required={required}
              />
            )}
          </div>
        ) : null
      )}
    </div>
  );
};

export default AddressForm;
