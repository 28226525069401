import { useEffect } from 'react';
import ga4 from 'react-ga4';

type TrackingMethodParams = ['setPath' | 'trackPageView' | 'identify', (string | object)?];

declare global {
  interface Window {
    _hsq: { push: (callParam: TrackingMethodParams) => void };
  }
}

export type UseTracking = {
  setPathPageView: (path: string) => void;
  setIdentity: (email: string, customPropertities?: Record<string, unknown>) => void;
  sendGAPageview: (path: string) => void;
};

const useTracking = (): UseTracking => {
  const GA4_ID = process.env.REACT_APP_GA_TRACKING_ID;

  const init = () => GA4_ID && ga4.initialize(GA4_ID);

  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/139785987.js';

    if (process.env.REACT_APP_ENABLE_TRACKING?.toString() === 'enable') {
      document.body.appendChild(script);
      init();
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _hsq = typeof window !== 'undefined' && (window as any)._hsq ? (window as any)._hsq : [];

  const setPathPageView = (path: string): void => {
    const originalPageTitle = document.title;
    document.title = path;

    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);

    document.title = originalPageTitle;
  };

  const setIdentity = (email: string, customPropertities?: Record<string, unknown>) => {
    _hsq.push(['identify', { email, ...customPropertities }]);
  };

  const sendGAPageview = (path: string) => ga4.send({ hitType: 'pageview', page: path });

  return {
    setPathPageView,
    setIdentity,
    sendGAPageview
  };
};

export default useTracking;
