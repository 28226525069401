/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { toastUtil } from '../../utils/toast.utils';
import { kotlinApi } from '../../api/api';
import { CompanyDetails } from '../partners/partners.types';

export const getZefixSuggestionsByName = async (
  name: string,
  deletedFirms = false,
  formerNames = false
) => {
  try {
    const normalizedName = encodeURIComponent(name.replace(/\s+/g, ' '));

    const resp = await axios.get(
      `${process.env.REACT_APP_ZEFIX_SUGGESTIONS_API}/autocomplete.json?prefix=${normalizedName}&deletedFirms=${deletedFirms}&formerNames=${formerNames}`
    );
    return resp.data?.suggestions.map((elem: any) => ({ value: elem }));
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getCompanyDetails = async (name: string): Promise<CompanyDetails[] | undefined> => {
  try {
    const { data } = await kotlinApi.get(`company-register?name=${encodeURIComponent(name)}`);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};
