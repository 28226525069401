import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { User } from '../../../store/user/types';
import { ProfileCardType } from '../../Profile/Profile';
import { RootState } from '../../../reducers/rootReducer';
import ProfileCard from '../../Profile/ProfileCard/ProfileCard';
import Text from '../../../components/Text/Text';
import './PartnerDashboard.scss';
import IconText from '../../../components/IconText/IconText';
import { PartnerLevel } from '../../../store/partners/partners.types';
import useNavigation from '../../../hooks/useNavigation';
import { useEffect, useState } from 'react';
import { getPartnerDetails } from '../../../store/partners/partners.action';
import Loader from '../../../components/Loader/Loader';

const PartnerDashboard = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [loading, setLoading] = useState(false);
  const [partnerLevel, setPartnerLevel] = useState<PartnerLevel | undefined>();

  const profileCards: ProfileCardType[] = [
    { key: 'myAccount', icon: 'user', to: '/partners/account' },
    // { key: 'financesAndContracts', icon: 'calculator', to: '/partners/finance' },
    { key: 'projectsOverview', icon: 'folder', to: '/partners/offers' },
    // { key: 'myBranches', icon: 'house', to: '/' },
    { key: 'support', icon: 'support', to: '/' }
  ];

  useEffect(() => {
    user?.partnerId && getPartnerLevel(user?.partnerId);
  }, [!user?.partnerId]);

  const getPartnerLevel = async (partnerId: number) => {
    setLoading(true);
    const data = await getPartnerDetails(partnerId);
    setPartnerLevel(data?.partnerLevel.partnerLevel);
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Text as="h1" category="display">
        {t('partners.dashboard.title')}
      </Text>
      <div className="partner-dashboard-details">
        <Text>
          {t('partners.id')}: {user?.partnerId}
        </Text>
        {partnerLevel && (
          <IconText
            icon="partner-level"
            description={t(`partnerLevel.${partnerLevel}`)}
            size="large"
          />
        )}
      </div>
      <div className="partners-dashboard-cards">
        {profileCards.map((card, index: number) => (
          <ProfileCard
            key={index}
            title={t(`partners.dashboard.cards.${card.key}.heading`)}
            description={t(`partners.dashboard.cards.${card.key}.text`)}
            buttonText={t(`partners.dashboard.cards.${card.key}.buttonText`)}
            icon={card.icon}
            dataTest={`partners-dashboard-${card.key}-button`}
            onClick={() => {
              if (card.key === 'support') {
                window.open('https://www.investerra.ch/faq');
                return;
              }
              goTo(card.to);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default PartnerDashboard;
