import { HttpStatusCode } from 'axios';
import api, { kotlinApi } from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import {
  BankDetailsType,
  CreateProfile,
  GetProfiles,
  MessagesList,
  Profile,
  ProfilesList,
  UpdateProfile
} from './profile.types';
import { t } from 'i18next';

export const createProfile = async (profile: CreateProfile) => {
  try {
    const { data } = await kotlinApi({
      method: 'POST',
      url: '/user',
      data: profile
    });
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    throw new Error(err.response.data);
  }
};

export const getProfile = async (supress = false): Promise<Profile | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/me`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    !supress && toastUtil('error', err.message);
    throw new Error(err.response.data);
  }
};

export const getAllProfiles = async ({
  keyword,
  status,
  size,
  sort,
  page
}: GetProfiles): Promise<ProfilesList> => {
  const transformSort = sort?.map((item) => `${item.name},${item.sort}`).join('&sort=');

  try {
    const { data } = await api.get(
      `profiles/admin/users${transformSort ? `?sort=${transformSort}` : ''}`,
      {
        params: {
          size: size,
          page: page,
          ...(status && { status: status }),
          ...(keyword && { keyword: keyword })
        }
      }
    );

    const transformedData: ProfilesList = {
      profiles: data.content,
      size: data.size,
      totalPages: data.totalPages,
      totalItems: data.totalElements,
      pageNumber: data.number,
      empty: data.empty
    };

    return transformedData;
  } catch (err: any) {
    toastUtil('error', err.message);
    return Promise.reject(err);
  }
};

export const getClientProfile = async (clientUid: string): Promise<Profile | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/user/${clientUid}`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updateProfile = async (
  profile: Partial<UpdateProfile>,
  userUuid: string
): Promise<boolean> => {
  try {
    const { status } = await kotlinApi.put(`/user/${userUuid}`, profile);
    toastUtil('success', t('myProfile.personalData.profileUpdated'));
    return status === HttpStatusCode.Ok;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const updateBankDetails = async (bankDetails: BankDetailsType, userUuid: string) => {
  try {
    await kotlinApi.put(`/user/${userUuid}`, bankDetails);
    toastUtil('success', t('myProfile.bankDetails.updateSuccess'));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getAllMessages = async (
  email: string,
  size: number,
  page: number
): Promise<MessagesList> => {
  try {
    const { data } = await api.get('/inbox-messages', {
      params: {
        email,
        size: size,
        page: page
      }
    });

    const transformedData: MessagesList = {
      messages: data.content,
      size: data.size,
      totalPages: data.totalPages,
      totalItems: data.totalElements,
      pageNumber: data.number,
      empty: data.empty
    };

    return transformedData;
  } catch (err: any) {
    toastUtil('error', err.message);
    return Promise.reject(err);
  }
};

export const deleteUser = async (userUuid: string): Promise<boolean> => {
  try {
    const resp = await kotlinApi.delete(`/user/${userUuid}`);
    return HttpStatusCode.Ok === resp.status;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const checkTwoFactorCredentials = async (): Promise<boolean> => {
  try {
    const { status } = await kotlinApi.get('/user/credentials/otp');
    return status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    return false;
  }
};

export const deleteTwoFactor = async (): Promise<boolean> => {
  try {
    const { status } = await kotlinApi.delete('/user/credentials/otp');
    return status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    return false;
  }
};
