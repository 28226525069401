import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProjects } from '../../store/project/project.action';
import { ProjectsListItem } from '../../store/project/project.types';
import Pagination from '../../components/Pagination/Pagination';
import Text from '../../components/Text/Text';
import ProjectItem from './Projectitem/ProjectItem';
import './Projects.scss';
import { User } from '../../store/user/types';
import { useLocation } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import Modal from '../../components/Modal/Modal';
import OfferRequestedNotification from '../Offers/OfferRequestedNotification/OfferRequestedNotification';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import Search from '../../components/Inputs/Search/Search';
import { AuthContext } from '../../context/AuthContextProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';

const Projects = () => {
  const auth = useContext(AuthContext);
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const { t } = useTranslation();

  const { state } = useLocation();

  const { isOpened, toggle } = useModal();

  const [projects, setProjects] = useState<ProjectsListItem[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state !== null && state !== 'isAdmin') {
      toggle();
      window.history.replaceState(null, '');
    }
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await getProjects({
        userUuid: user.uid,
        keyword: search,
        page: currentPage - 1,
        size: 4
      });
      setProjects(res?.projects);
      setTotalPages(res?.totalPages);
      setLoading(false);
      search && setCurrentPage(1);
    };

    fetchProjects();
  }, [currentPage, search, auth?.isAuthenticated]);

  return (
    <>
      <div className={'projects-wrapper'}>
        <Text as={'h1'} category={'display'}>
          {t('myProfile.cards.myProjects.title')}
        </Text>
        <Search setValue={setSearch} placeholder={t('myProfile.myProjects.labels.search')} />
        <div className={'projects-list'}>
          {!loading &&
            projects?.map((project, index) => {
              return <ProjectItem key={index} project={project} />;
            })}
        </div>
        <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages} />
      </div>
      <Modal
        isOpened={isOpened}
        close={toggle}
        title={
          state?.offerRequestedNotification
            ? t('requestOfferTitle.offerRequestedNotification')
            : t('uploadOfferEdit.thankYou')
        }>
        {state?.offerRequestedNotification ? (
          <OfferRequestedNotification />
        ) : (
          <NotificationCard
            mainText={t('emailConfirmation.financingRequestSent')}
            icon={'envelope'}>
            <Text as={'p'}>{t('emailConfirmation.willBeNotifiedInTwoDays')}</Text>
            <Text as={'p'} category={'label'} size={'small'} className={'check-spam-text'}>
              {t('emailConfirmation.checkYourSpamOffer')}
            </Text>
          </NotificationCard>
        )}
      </Modal>
    </>
  );
};

export default Projects;
