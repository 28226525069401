import { HttpStatusCode } from 'axios';
import { kotlinApi } from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import { NewProjectLog, ProjectLog } from '../project/project.types';
import { Get4EyeProjectDetails } from './fourEyeCheck.types';
import { previewFile } from '../../utils/general.utils';

export const getFourEyeProjectDetails = async (
  fourEyeCheckId: string
): Promise<Get4EyeProjectDetails | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/fourEyeCheck/${fourEyeCheckId}/data`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getFourEyeProjectLogs = async (
  projectUuid: string
): Promise<ProjectLog[] | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/project/${projectUuid}/logs`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const uploadFourEyeCheckDocument = async (
  fourEyeCheckId: string,
  file: File
): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await kotlinApi.post(
      `/fourEyeCheck/${fourEyeCheckId}/upload-document`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.status === HttpStatusCode.Created;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const reopenFourEyeCheck = async (fourEyeCheckId: string): Promise<boolean> => {
  try {
    const response = await kotlinApi.post(`/fourEyeCheck/${fourEyeCheckId}/reopen`);
    return response.status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const declineFourEyeCheck = async (fourEyeCheckId: string): Promise<boolean> => {
  try {
    const response = await kotlinApi.post(`/fourEyeCheck/${fourEyeCheckId}/mark-declined`);
    return response.status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const makeFourEyeCheckDecision = async (
  fourEyeCheckId: string,
  log: NewProjectLog
): Promise<boolean> => {
  try {
    const response = await kotlinApi.put(`/fourEyeCheck/${fourEyeCheckId}/decision`, log);
    return response.status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const downloadLogsFile = async (projectId: string, filePath: string) => {
  try {
    const response = await kotlinApi.get(`/project/${projectId}/log-file?filePath=${filePath}`, {
      responseType: 'blob'
    });
    previewFile(response.data);
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};
