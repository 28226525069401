import { t } from 'i18next';
import { Module, ModuleType } from '../../types/financialOffer.types';
import { toSwissFrancNotation } from '../../utils/formatting.utils';
import InfoField from '../InfoField/InfoField';
import TooltipHeader from '../TooltipHeader/TooltipHeader';
import Price from '../Price/Price';
import './ModulesAndExtras.scss';

interface ModulesAndExtrasProps {
  modules: Module[];
  annualSavings: number;
}

const ModulesAndExtras = ({ modules, annualSavings }: ModulesAndExtrasProps) => {
  return (
    <>
      <div className="modules-extras-wrapper">
        {(modules.length > 0 || annualSavings) && (
          <TooltipHeader
            heading={t('offers.financialOfferPreview.extrasAndModules')}
            tooltip={{ text: t('offers.financialOfferPreview.extrasAndModulesTooltip') }}
          />
        )}
        {modules.map((module, index: number) => {
          return (
            <div key={index} className="field modules-field">
              <InfoField
                className="field"
                label={t(`module.types.${module.type}`)}
                tooltipText={{
                  heading: t(`module.types.${module.type}`),
                  text: t(`module.tooltips.${module.type}`)
                }}
                disabled={false}>
                {module.type === ModuleType.INVESTERRA_ELECTRICITY_SALES
                  ? `Rp. ${module.value} pro ${t('units.kilowatt')}`
                  : `${toSwissFrancNotation(+module.value)} `}
              </InfoField>
            </div>
          );
        })}
      </div>
      {annualSavings && (
        <InfoField
          className="field"
          label={t('financalOfferPreview.anualSavings.label')}
          tooltipText={{
            heading: t('financalOfferPreview.anualSavings.label'),
            text: t('financalOfferPreview.anualSavings.tooltip')
          }}
          disabled={false}>
          <Price as="p" category="headline" size="medium">
            {annualSavings}
          </Price>
        </InfoField>
      )}
    </>
  );
};

export default ModulesAndExtras;
