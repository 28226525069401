import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyDataForm from './CompanyDataForm/CompanyDataForm';
import CantonsSelect from './CantonsSelect/CantonsSelect';
import Checklist from '../../../../components/Checklist/Checklist';
import Button from '../../../../components/Button/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import { User } from '../../../../store/user/types';
import {
  Canton,
  PartnerCompany,
  PartnerProduct,
  UpdatePartnerCompany,
  products
} from '../../../../store/partners/partners.types';
import './CompanyData.scss';
import Text from '../../../../components/Text/Text';
import {
  getPartnerDetails,
  updatePartnerDetails
} from '../../../../store/partners/partners.action';

export interface ContactDetailsType {
  firstName: string;
  lastName: string;
  email: string;
}

const CompanyData = () => {
  const { t } = useTranslation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [companyData, setCompanyData] = useState<PartnerCompany>({} as PartnerCompany);

  useEffect(() => {
    const fetchPartnerDetails = async () => {
      const data = user.partnerId && (await getPartnerDetails(user.partnerId));
      if (data) {
        setCompanyData(data);
      }
    };
    fetchPartnerDetails();
  }, [user.partnerId]);

  const contactDetails = {
    firstName: companyData?.contactFirstName as string,
    lastName: companyData?.contactLastName as string,
    email: companyData?.contactEmail as string
  };

  const checkProduct = (module: PartnerProduct) => {
    companyData.modules?.find((p: Partial<PartnerProduct>) => p.id === module.id)
      ? setCompanyData({
          ...companyData,
          modules: companyData.modules?.filter((p: Partial<PartnerProduct>) => p.id !== module.id)
        })
      : setCompanyData({
          ...companyData,
          modules: [...(companyData.modules as PartnerProduct[]), module]
        });
  };

  const selectCantons = (cantons: Canton[]) => {
    setCompanyData({ ...companyData, cantons });
  };

  const onSubmit = () => {
    const updatedData: UpdatePartnerCompany = {
      contactFirstName: companyData?.contactFirstName,
      contactLastName: companyData?.contactLastName,
      cantons: companyData?.cantons,
      modules: companyData?.modules
    };
    if (updatedData && user.partnerId) {
      updatePartnerDetails(user.partnerId, updatedData);
    }
  };

  return (
    <div className="company-data-wrapper">
      <CompanyDataForm
        companyData={companyData}
        contactDetails={contactDetails}
        setContactDetails={setCompanyData}
      />
      <div>
        <CantonsSelect selectedCantons={companyData.cantons} setSelectedCantons={selectCantons} />
        <Text as="h2" category="headline" size="large">
          {t('partners.partnerProducts.title')}
        </Text>
        <Text>{t('partners.partnerProducts.description')}</Text>
        <Checklist
          items={products.map((p) => {
            return { ...p, label: t(p.label) };
          })}
          checked={(companyData?.modules as PartnerProduct[]) || []}
          onCheck={checkProduct}
        />
      </div>
      <Button variation="primary" size="large" dataTest={''} onClick={onSubmit}>
        {t('saveChanges')}
      </Button>
    </div>
  );
};

export default CompanyData;
