import { useCallback } from 'react';
import './TabItem.scss';
import Text from '../../Text/Text';

export type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  disabled?: boolean;
};

const TabItem = (props: Props): JSX.Element => {
  const { title, setSelectedTab, index, isActive, disabled } = props;

  const handleOnClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li className={`tab-title ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
      <button onClick={handleOnClick} disabled={disabled}>
        <Text as="span" category="label" size="large" variety={1}>
          {title}
        </Text>
      </button>
    </li>
  );
};

export default TabItem;
