import api from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import { CreditTerm } from './creditTerms.types';

export const getAllCreditTerms = async (): Promise<CreditTerm[] | undefined> => {
  try {
    const { data } = await api.get('/global-variables');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updateCreditTerms = async (creditTerms: Pick<CreditTerm, 'id' | 'value'>[]) => {
  try {
    const data = await api.put('/global-variables', creditTerms);
    toastUtil('success', 'Globale Variablen aktualisiert');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return undefined;
  }
};
