import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import Search, { SearchType } from '../../../components/Search/Search';
import Text from '../../../components/Text/Text';
import { CompanyDetails, PartnerCompany } from '../../../store/partners/partners.types';
import { getCompanyDetails, getZefixSuggestionsByName } from '../../../store/zefix/zefix.actions';
import { emailValidation, validateEmpty } from '../../../utils/validations.utils';
import './EditPartner.scss';
import { SetStateType } from '../../../types/util.types';
import { parseFullName } from '../../../utils/general.utils';
import { useEffect, useState } from 'react';
import CompanySelectModal from '../../../components/CompanySelectModal/CompanySelectModal';
import { InputError } from '../UploadOfferEdit';

interface EditPartnerProps {
  partner?: PartnerCompany;
  valueMissing?: InputError;
  setPartner: SetStateType<PartnerCompany>;
}

const EditPartner = ({ partner, valueMissing, setPartner }: EditPartnerProps) => {
  const { t } = useTranslation();

  const [partnerContactName, setPartnerContactName] = useState<string>('');

  const [zefixCompaniesData, setZefixCompaniesData] = useState<CompanyDetails[]>([]);

  useEffect(() => {
    if (zefixCompaniesData.length === 1) {
      setPartner((prev) => {
        return {
          ...prev,
          name: zefixCompaniesData[0].name,
          uid: zefixCompaniesData[0].uid,
          partnerLocations: [
            {
              address: `${zefixCompaniesData[0].address.street} ${zefixCompaniesData[0].address.houseNumber}`,
              municipality: zefixCompaniesData[0].address.city,
              postalCode: zefixCompaniesData[0].address.swissZipCode
            }
          ],
          legalForm: zefixCompaniesData[0].legalForm.shortName.de
        };
      });
    }
  }, [zefixCompaniesData]);

  const getPartnerDetails = async (name: SearchType) => {
    const resp = await getCompanyDetails(name.value);
    if (resp) {
      setZefixCompaniesData(resp);
    }
  };

  const handleClearPartner = () => setPartner({} as PartnerCompany);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPartner((prev) => ({ ...prev, contactEmail: value }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPartnerContactName(e.target.value);
  };

  const changePartnerName = () => {
    const { firstName, lastName } = parseFullName(partnerContactName);
    setPartner((prev) => ({ ...prev, contactFirstName: firstName, contactLastName: lastName }));
  };

  return (
    <>
      <Text as="h3" category="headline" size="large">
        {t('editPartner.partnerDetails')}
      </Text>
      <div className="partner-edit-wrapper">
        <TextInput
          value={partnerContactName}
          name="partnerName"
          type="text"
          placeholder={t('editPartner.partnerContactName')}
          validate={validateEmpty}
          onChange={handleNameChange}
          onBlur={changePartnerName}
          className={
            valueMissing?.partnerName && (!partner?.contactFirstName || !partner?.contactLastName)
              ? 'error'
              : ''
          }
          required
        />
        <Search
          initialValue={partner?.name || ''}
          placeholder={t('editPartner.companyName')}
          fetchData={getZefixSuggestionsByName}
          onOptionSelect={getPartnerDetails}
          onClear={handleClearPartner}
          className={valueMissing?.partnerName && !partner?.name ? 'error' : ''}
          autocompleteOff
          required
        />
        <TextInput
          value={
            partner?.partnerLocations?.length
              ? `${partner?.partnerLocations[0]?.address}, ${partner?.partnerLocations[0]?.postalCode} ${partner?.partnerLocations[0]?.municipality}`
              : ''
          }
          name="company-address"
          type="text"
          placeholder={t('editPartner.companyAddress')}
          disabled
          className={valueMissing?.partnerAddress && !partner?.partnerLocations ? 'error' : ''}
          required
        />
        <TextInput
          value={partner?.contactEmail || ''}
          name="company-email"
          type="email"
          validate={emailValidation}
          placeholder={t('editPartner.companyEmail')}
          onChange={handleEmailChange}
          className={valueMissing?.partnerEmail && !partner?.contactEmail ? 'error' : ''}
          required
        />
      </div>
      {zefixCompaniesData.length > 1 && (
        <CompanySelectModal companies={zefixCompaniesData} setCompanies={setZefixCompaniesData} />
      )}
    </>
  );
};

export default EditPartner;
