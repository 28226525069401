import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './Registration.scss';
import Checkbox from '../../../components/Checkbox/Checkbox';
import IconText from '../../../components/IconText/IconText';
import Search, { SearchType } from '../../../components/Search/Search';
import { getCompanyDetails, getZefixSuggestionsByName } from '../../../store/zefix/zefix.actions';
import Button from '../../../components/Button/Button';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import { CompanyDetails, RegisterPartner } from '../../../store/partners/partners.types';
import Modal from '../../../components/Modal/Modal';
import { useModal } from '../../../hooks/useModal';
import NotificationCard from '../../../components/NotificationCard/NotificationCard';
import CompanySelectModal from '../../../components/CompanySelectModal/CompanySelectModal';
import { hubspotLinks } from '../../../utils/hubspotLinks';
import { createPartner } from '../../../store/partners/partners.action';
import { toastUtil } from '../../../utils/toast.utils';
import { RootState } from '../../../reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../store/user/types';
import { getProfile } from '../../../store/profile/profile.actions';
import { loginUser } from '../../../store/user/actions';
import { userCreation } from '../../../utils/general.utils';
import useNavigation from '../../../hooks/useNavigation';

const Registration = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { isOpened, toggle } = useModal();
  const dispatch = useDispatch();

  const userReducer = useSelector<RootState>((state) => state.userReducer) as User;

  const [zefixCompaniesData, setZefixCompaniesData] = useState<CompanyDetails[]>([]);
  const [partnerAgreement, setPartnerAgreement] = useState(false);

  const getPartnerDetails = async (name: SearchType) => {
    const resp = await getCompanyDetails(name.value);
    if (resp) {
      setZefixCompaniesData(resp);
    }
  };

  const onSubmit = async () => {
    const data: RegisterPartner = {
      name: zefixCompaniesData[0].name,
      uid: zefixCompaniesData[0].uid,
      partnerLocations: [
        {
          address: zefixCompaniesData[0].address.street + zefixCompaniesData[0].address.houseNumber,
          municipality: zefixCompaniesData[0].address.city,
          postalCode: zefixCompaniesData[0].address.swissZipCode
        }
      ],
      contactFirstName: userReducer.firstName,
      contactLastName: userReducer.lastName,
      contactEmail: userReducer.email,
      legalForm: zefixCompaniesData[0].legalForm.shortName.en,
      verified: false
    };

    const resp = await createPartner(data);
    if (resp) {
      const profile = await getProfile(true);
      if (profile) {
        dispatch(loginUser(userCreation(profile)));
        toastUtil('success', t('changesSaved'));
        goTo('/');
      }
    }
  };

  const handleModalClose = () => {
    toggle();
    goTo('/');
  };

  const isDisabled = !partnerAgreement || !zefixCompaniesData[0]?.name;

  return (
    <div className="partner-registration-wrapper">
      <Text as="h1" category="display">
        {t('partners.registration.title')}
      </Text>
      <Text as="h3" category="headline">
        {t('partners.registration.personalData')}
      </Text>
      <form className="partner-registration-form">
        <Search
          autocompleteOff
          placeholder={t('companyName')}
          fetchData={getZefixSuggestionsByName}
          onOptionSelect={getPartnerDetails}
          onClear={() => {
            zefixCompaniesData.length && setZefixCompaniesData([]);
          }}
          required
        />
        <Text as="span" category="label" size="small">
          <Trans
            i18nKey="partners.registration.cantFindCompany"
            t={t}
            components={{
              emailLink: <a href={`mailto:${hubspotLinks.supportEmail}`} className="email-link" />
            }}
          />
        </Text>
        <div className="company-data-form">
          <TextInput
            value={zefixCompaniesData[0]?.legalForm.shortName.de || ''}
            name="legalForm"
            type="text"
            disabled
            placeholder={t('partners.legalForm')}
          />
          <div className="company-address">
            <div className="street-house-wrapper">
              <TextInput
                value={zefixCompaniesData[0]?.address.street || ''}
                name="street"
                type="text"
                disabled
                placeholder={t('street')}
              />
              <TextInput
                value={zefixCompaniesData[0]?.address.houseNumber || ''}
                name="house-number"
                type="text"
                disabled
                placeholder={t('houseNumber')}
              />
            </div>
            <TextInput
              value={zefixCompaniesData[0]?.address.swissZipCode || ''}
              name="postalCode"
              type="text"
              disabled
              placeholder={t('zipCode')}
            />
            <TextInput
              value={zefixCompaniesData[0]?.address.city || ''}
              name="location"
              type="text"
              disabled
              placeholder={t('location')}
            />
          </div>
          <TextInput
            value={zefixCompaniesData[0]?.uid || ''}
            name="vatNumber"
            type="text"
            disabled
            placeholder={t('partners.vatNumber')}
          />
        </div>
      </form>
      <Text as="h3" category="headline">
        {t('partners.registration.instalationPartnerContract')}
      </Text>
      <div className="installation-contract">
        <Checkbox
          onClick={() => {
            setPartnerAgreement((prev) => !prev);
          }}
          checked={partnerAgreement}
          label={t('partners.registration.acceptContract')}
          required
        />
        <IconText
          icon="download"
          description={t('partners.registration.downloadPdfContract')}
          onClick={() => {
            window.open(hubspotLinks.partnerTermsAndConditions, '_blank');
          }}
          clasName="download-partner-contract"
        />
        <Button
          variation="primary"
          size="large"
          disabled={isDisabled}
          dataTest=""
          onClick={onSubmit}>
          {t('saveChanges')}
        </Button>
      </div>
      <Modal title={t('auth.thankForRegistration')} isOpened={isOpened} close={handleModalClose}>
        <NotificationCard
          mainText={t('auth.toActivateAccount')}
          icon="envelope"
          className="verification-modal">
          <Text as="p">{t('auth.confirmYourEmail')}</Text>
          <Text as="p" category="label" size="small" className="check-spam-text">
            {t('auth.checkSpamSignUp')}
          </Text>
          <Text as="p" category="label" size="small" className="helpdesk-text">
            <Trans
              i18nKey="auth.contactUs"
              t={t}
              components={{
                contactUs: (
                  <a
                    href={hubspotLinks.contact}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-us"
                  />
                )
              }}
            />
          </Text>
        </NotificationCard>
      </Modal>
      {zefixCompaniesData.length > 1 && (
        <CompanySelectModal companies={zefixCompaniesData} setCompanies={setZefixCompaniesData} />
      )}
    </div>
  );
};

export default Registration;
