import { ChangeEvent, useState, useEffect } from 'react';
import Icon from '../Icon/Icon';
import './Search.scss';
import Text from '../Text/Text';
import { useTranslation } from 'react-i18next';

export interface SearchType {
  value: string;
  id?: number;
}

interface SearchProps<T extends SearchType> {
  placeholder: string;
  autocompleteOff?: boolean;
  initialValue?: string;
  required?: boolean;
  className?: string;
  fetchData: (value: string) => Promise<T[]>;
  onOptionSelect: (value: SearchType) => Promise<void>;
  onClear: () => void;
}

const Search = <T extends SearchType>({
  initialValue = '',
  autocompleteOff,
  fetchData,
  placeholder,
  onOptionSelect,
  onClear,
  required,
  className = ''
}: SearchProps<T>) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<SearchType | undefined>(undefined);
  const [options, setOptions] = useState<SearchType[]>([]);
  const [searchText, setSearchText] = useState(initialValue);

  useEffect(() => {
    const fetchOptions = async () => {
      if (searchText.length > 2) {
        const data = await fetchData(searchText);
        if (data && data.length === 1 && searchText === initialValue) {
          onOptionSelect(data[0]);
          setSelected(data[0]);
        }
        setOptions(data);
      }
    };
    fetchOptions();
  }, [searchText]);

  useEffect(() => {
    !!selected?.value && onOptionSelect(selected);
  }, [selected]);

  const getSearchAddressClassName = () =>
    `search-wrapper ${searchText.length > 0 && !selected ? 'display' : ''}`;

  const handleOptionClick = (option?: SearchType) => {
    setSearchText(option?.value || '');
    setSelected(option);
  };

  const handleInputChange = (inputValue: string) => {
    setSearchText(inputValue);
    setSelected(undefined);
  };

  const clearInput = () => {
    handleOptionClick(undefined);
    onClear();
  };

  return (
    <div className={getSearchAddressClassName()}>
      <div className={`search-input-wrapper ${className}`}>
        <div>
          <Icon name="search" />
          <input
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event.target.value)
            }
            autoComplete={autocompleteOff ? 'off' : undefined}
            placeholder={`${placeholder} ${required && '*'}`}
            value={searchText}
            onBlur={() => {
              setSelected(undefined);
            }}
          />
        </div>
        <Icon
          name="close"
          className="close-icon"
          onClick={() => {
            clearInput();
          }}
        />
      </div>
      <div className="options">
        {options?.length > 0 ? (
          options.map((elem: SearchType, index: number) => (
            <Text
              as="span"
              category="label"
              size="large"
              key={index}
              onClick={() => {
                handleOptionClick(elem);
              }}>
              {elem.value}
            </Text>
          ))
        ) : (
          <Text as="span" category="label" size="large" className="no-option">
            {t('noOptions')}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Search;
