import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QuestionnaireFormActionType,
  HouseholdType,
  HousingReference,
  QuestionnaireType,
  MaritalStatus
} from '../../../store/questionnaire/questionnaire.types';
import Text from '../../../components/Text/Text';
import { generateDropdownValues, generateYearArray } from '../../../utils/general.utils';
import { Month } from '../../../types/util.types';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import { validatePositiveNumber } from '../../../utils/validations.utils';
import Dropdown, { DropdownType } from '../../../components/Dropdown/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import '../Questionnaire.scss';

const HousingSituation = () => {
  const { t } = useTranslation();
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const [numberOfChildren, setNumberOfChildren] = useState<number | undefined>(
    questionnaire.creditCheckData?.household?.childrenInHouseholdAge.length
  );

  const months = generateDropdownValues(Month, 'months');
  const years: DropdownType[] = generateYearArray(1950).map((year) => ({
    value: year,
    displayValue: year
  }));
  const housingReferences = generateDropdownValues(HousingReference, 'creditCheck.residenceType');
  const residingSinceYear = questionnaire.creditCheckData?.applicant?.residingSinceYear ?? '';
  const residingSinceMonth = questionnaire.creditCheckData?.applicant?.residingSinceMonth ?? '';
  const numberOfSupportingGrownups =
    questionnaire.creditCheckData?.household.householdNumberOfSupportedGrownUps ?? '';

  const showMarriedCoupleHouseholdType =
    questionnaire.creditCheckData.applicant?.maritalStatus === MaritalStatus.MARRIED ||
    questionnaire.creditCheckData.applicant?.maritalStatus === MaritalStatus.REGISTERED;

  const singleSupportingGrownup =
    questionnaire.creditCheckData.household?.householdType ===
    HouseholdType.SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD;

  useEffect(() => {
    if (!numberOfChildren) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_CHILD_AGE,
        payload: []
      });
    }
  }, [numberOfChildren]);

  const residingSinceMonthInitialValue = (): DropdownType => {
    if (months[+residingSinceMonth - 1]) {
      return {
        value: months[+residingSinceMonth - 1].value,
        displayValue: months[+residingSinceMonth - 1].displayValue
      };
    } else {
      return {
        value: '',
        displayValue: ''
      };
    }
  };

  const householdValues = () => {
    if (showMarriedCoupleHouseholdType) {
      return generateDropdownValues(HouseholdType, 'creditCheck.householdType');
    } else {
      const modifiedValues = Object.values(HouseholdType).filter(
        (value) => value !== HouseholdType.MARRIED_COUPLE
      );
      return generateDropdownValues(modifiedValues, 'creditCheck.householdType');
    }
  };

  const changeChildAgeHandler = (e: React.ChangeEvent<HTMLInputElement>, elem: number) => {
    const { value } = e.target;
    if (+value >= 18 || +value < 0) return;
    const childrenAge = questionnaire.creditCheckData.household?.childrenInHouseholdAge as number[];
    childrenAge[elem] = +e.target.value;
    if (value === '') childrenAge.splice(elem, 1);
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_CHILD_AGE,
      payload: childrenAge
    });
  };

  const changeNumberOfSupportedGrownupsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && (+e.target.value <= 0 || +e.target.value > 9)) return;
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MEMBERS,
      payload: parseFloat(e.target.value)
    });
  };

  const changeMonthlyInsuranceFeeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value < 0) return;
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MONTHLY_INSURANCE_FEE,
      payload: parseFloat(e.target.value)
    });
  };

  const changeHouseholdTypeHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_TYPE,
      payload: value as HouseholdType
    });

    value !== HouseholdType.SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD &&
      numberOfSupportingGrownups &&
      dispatch({
        type: QuestionnaireFormActionType.REMOVE_HOUSEHOLD_MEMBERS
      });
  };

  return (
    <form className="credit-check-form">
      <div className="address-wrapper">
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.housingSituation.residingSince')}
        </Text>
        <div className="resident-wrapper">
          <Dropdown
            values={years}
            noValueText={t('year')}
            initialValue={{
              value: `${residingSinceYear}`,
              displayValue: `${residingSinceYear}`
            }}
            getValue={(value) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_YEAR,
                payload: +value
              });
            }}
            required
          />
          <Dropdown
            values={months}
            noValueText={t('month')}
            initialValue={residingSinceMonthInitialValue()}
            getValue={(value) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_MONTH,
                payload: months.findIndex((month) => month.value === value) + 1
              });
            }}
            required
          />
        </div>
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.housingSituation.selectHousingReference')}
        </Text>
        <Dropdown
          values={housingReferences}
          noValueText={t('creditCheck.housingSituation.residenceTypePlaceholder')}
          initialValue={{
            value: questionnaire.creditCheckData?.applicant?.residenceType || '',
            displayValue: t(
              `creditCheck.residenceType.${questionnaire.creditCheckData.applicant?.residenceType}`
            )
          }}
          getValue={(value) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_RESIDENCE_TYPE,
              payload: value as HousingReference
            });
          }}
          required
        />
      </div>
      <div className="household-type-wrapper">
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.housingSituation.selectHouseholdType')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.housingSituation.householdTypeText')}
        </Text>
        <Dropdown
          values={householdValues()}
          noValueText={t('creditCheck.housingSituation.householdPlaceholder')}
          initialValue={{
            value: questionnaire.creditCheckData?.household?.householdType || '',
            displayValue: t(
              `creditCheck.householdType.${questionnaire.creditCheckData?.household?.householdType}`
            )
          }}
          getValue={changeHouseholdTypeHandler}
          required
        />
      </div>
      {singleSupportingGrownup ? (
        <div className="household-member-wrapper">
          <Text as="span" category="headline" size="medium">
            {t('creditCheck.housingSituation.numberOfHouseholdMembers')}
          </Text>
          <Text size="large">{t('creditCheck.housingSituation.howManyAdultsInHousehold')}</Text>
          <TextInput
            type="number"
            value={numberOfSupportingGrownups}
            name="householdMembers"
            placeholder={t('creditCheck.housingSituation.householdMembers')}
            validate={validatePositiveNumber}
            onChange={changeNumberOfSupportedGrownupsHandler}
            required
          />
        </div>
      ) : (
        questionnaire.creditCheckData.household?.householdType !==
          HouseholdType.SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD && (
          <div>
            <Text as="span" category="headline" size="medium">
              {t('creditCheck.housingSituation.howManyChildrenInHousehold')}
            </Text>
            <Dropdown
              values={Array.from({ length: 7 }, (_, index) => index).map((elem) => ({
                value: `${elem}`,
                displayValue: `${elem}`
              }))}
              noValueText={t('creditCheck.housingSituation.numberOfChildren')}
              initialValue={{
                value: numberOfChildren?.toString() || '',
                displayValue: numberOfChildren?.toString() as string
              }}
              getValue={(value) => {
                setNumberOfChildren(+value);
              }}
              required
            />
            {!!numberOfChildren &&
              Array.from({ length: numberOfChildren }, (_, index) => index).map((elem, i) => {
                return (
                  <div key={i}>
                    <Text>
                      {t('creditCheck.housingSituation.childAge')} {elem + 1}
                    </Text>
                    <TextInput
                      type="number"
                      value={
                        questionnaire?.creditCheckData?.household?.childrenInHouseholdAge?.[elem]
                      }
                      name="childAge"
                      placeholder={t('creditCheck.housingSituation.childAge')}
                      validate={validatePositiveNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        changeChildAgeHandler(e, elem);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        )
      )}
      <div>
        <Text as="h2" category="headline" size="medium">
          {t('creditCheck.housingSituation.householdInsuranceFee')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.housingSituation.householdInsuranceFeeText')}
        </Text>
        <TextInput
          type="number"
          value={questionnaire.creditCheckData?.household?.householdMonthlyInsuranceFee}
          name="householdMonthlyInsuranceFee"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          validate={validatePositiveNumber}
          onChange={changeMonthlyInsuranceFeeHandler}
          required
        />
      </div>
    </form>
  );
};
export default HousingSituation;
