/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Building } from '../../types/sepMap.types';
import { mesConfig } from '../../config/mes.config';

interface BuildingParam {
  paramName: string;
  value?: any;
}

interface DataMap {
  [key: string]: any;
}

export const searchMesAddress = async (address: string) => {
  try {
    const resp = await axios.get(
      `https://api3.geo.admin.ch/rest/services/api/SearchServer?type=locations&origins=address&limit=5&searchText=${address}`
    );
    return resp.data;
  } catch (err) {
    Promise.reject('Faild to fetch SEP address.');
  }
};

export const getRoofDetails = async (attrs: any) => {
  try {
    const resp = await axios.post(`https://tachionframework.ch/tachionserver/resources/sim/roofs`, {
      ...mesConfig,
      attrs: attrs
    });
    return resp.data;
  } catch (err) {
    Promise.reject('Faild to fetch roof details for building.');
  }
};

export const getBuildingReport = async (roof: any) => {
  try {
    const resp = await axios.post(
      `https://tachionframework.ch/tachionserver/resources/sim/buildingReport`,
      {
        ...mesConfig,
        ...roof
      }
    );
    return resp.data;
  } catch (err) {
    Promise.reject('Failed to fetch building report.');
  }
};

export const getBuildingByAddress = async (attrs: any): Promise<Building | undefined> => {
  try {
    const roof = await getRoofDetails(attrs);
    const buildingReport = await getBuildingReport(roof);

    const params: BuildingParam[] = buildingReport.param;

    const pvSystem = buildingReport.pvSystem;
    const data = params.reduce<DataMap>((acc, item) => {
      acc[item.paramName] = item.value ?? null;
      return acc;
    }, {});

    const transformedBuilding: Building = {
      sepId: roof.attrs.featureId,
      sustainability: data.roofSuitability,
      performance: data.nominalPower,
      solarPotential: data.Eac[0],
      address: {
        street: roof.attrs.strname1,
        houseNumber: roof.attrs.num,
        postalCode: roof.attrs.plz4,
        municipality: roof.attrs.gdename
      },
      feedInTariff: formatFeedInTariff(+pvSystem.feedinTariff),
      location: {
        coordinates: {
          lat: roof.attrs.lat,
          lng: roof.attrs.lon
        },
        altitude: roof.attrs.z
      }
    };

    return transformedBuilding;
  } catch (err) {
    console.debug(err);
  }
};

export const formatFeedInTariff = (value: number): number => {
  const feedInTadrrifRappen = value * 100;
  return parseFloat(feedInTadrrifRappen.toFixed(2));
};
