import './ProgressBar.scss';

interface ProgressBarProps {
  progress: number;
  canBeTracked?: boolean;
}

const ProgressBar = ({ progress, canBeTracked = true }: ProgressBarProps) => {
  if (progress < 0 || progress > 100) throw new Error('Progress must be between 0 and 100');

  return (
    <div className={`progress-bar${canBeTracked ? '' : ' notTracked'}`}>
      <div className="progress-bar-inner" style={{ width: `${canBeTracked ? progress : 100}%` }} />
    </div>
  );
};

export default ProgressBar;
