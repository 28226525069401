import api, { kotlinApi } from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import {
  GetOffers,
  OfferResponse,
  OffersList,
  UpdatePartnerOffer,
  UpploadScanOffer
} from './offers.types';
import { AxiosProgressEvent, HttpStatusCode } from 'axios';
import { PartnerOfferView } from './offers.types';
import { previewFile } from '../../utils/general.utils';
import { Sort } from '../../types/util.types';
import { ErrorCode } from '../error/error.type';
import { FinancingModel } from '../../types/financialOffer.types';

export const getOffer = async (projectId: number): Promise<OfferResponse | undefined> => {
  try {
    const { data } = await api.get(`/offers/project/${projectId}`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getPartnersOfferView = async (
  offerId: number | string
): Promise<PartnerOfferView | undefined> => {
  try {
    const resp = await api.get(`/offers/${offerId}`);
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const updateOffer = async (
  offerId: number | string,
  offer: Partial<UpdatePartnerOffer>
): Promise<boolean> => {
  try {
    const { status } = await api.put(`/offers/${offerId}/receive`, offer);
    return status === HttpStatusCode.Ok;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const attachOffer = async (
  offerId: number | string,
  file: File,
  onProgress?: (event: AxiosProgressEvent) => void
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const { status } = await api.post(`/offers/${offerId}/attach-file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress(progressEvent) {
        onProgress && onProgress(progressEvent);
      }
    });
    return status === HttpStatusCode.Ok;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const downloadOfferDocument = async (offerId: number) => {
  try {
    const { data } = await api.get(`/offers/${offerId}/download`, {
      responseType: 'blob'
    });
    previewFile(data);
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};

export const getAllOffers = async (
  userUuid: string,
  { keyword, status, size, sort, page, priceFrom, priceTo, dateRange }: GetOffers
): Promise<OffersList> => {
  let transformSort = '';

  if (sort?.find((elem: Sort) => elem.sort)) {
    transformSort = sort?.map((item) => `${item.name},${item.sort}`).join('&sort=');
  }

  try {
    const { data } = await api.get(
      `/offers/partner/search${transformSort ? `?sort=${transformSort}` : ''}`,
      {
        params: {
          userUuid: userUuid,
          size: size,
          page: page,
          ...(dateRange?.dateFrom && dateRange?.dateTo && { dateFrom: dateRange.dateFrom }),
          ...(dateRange?.dateTo && dateRange?.dateFrom && { dateTo: dateRange.dateTo }),
          ...(priceFrom !== undefined && priceTo !== undefined && { priceFrom: priceFrom }),
          ...(priceTo !== undefined && priceFrom !== undefined && { priceTo: priceTo }),
          ...(status && { status: status }),
          ...(keyword && { keyword: keyword })
        }
      }
    );

    const transformedData: OffersList = {
      offers: data.content,
      size: data.size,
      totalPages: data.totalPages,
      totalItems: data.totalElements,
      pageNumber: data.number,
      empty: data.empty
    };

    return transformedData;
  } catch (err: any) {
    toastUtil('error', err.message);
    return Promise.reject(err);
  }
};

export const scanOffer = async (formData: FormData): Promise<UpploadScanOffer | undefined> => {
  let percentage = 0;
  try {
    const {
      data: { documentUrl }
    } = await api.post('/offers/scan', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress(progressEvent) {
        const total = !progressEvent.total ? 1 : progressEvent.total;
        percentage = Math.round((progressEvent.loaded * 100) / total);
      }
    });
    return { documentUrl, percentage };
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    if (error.response.data.code === ErrorCode.INVALID_FYLE_TYPE) {
      return Promise.reject(ErrorCode.INVALID_FYLE_TYPE);
    }
    toastUtil('error', error.message);
    return Promise.reject(error.message);
  }
};

export const requestLoan = async (
  projectId: string,
  offerId: number,
  plan: FinancingModel
): Promise<boolean> => {
  try {
    const response = await kotlinApi.put(
      `/project/${projectId}/offer/${offerId}/loan/request`,
      plan
    );
    return response.status === HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    toastUtil('error', error.message);
    return false;
  }
};
