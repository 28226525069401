import Dropdown from '../../../../components/Dropdown/Dropdown';
import './SinglePartnerCommissions.scss';
import TextInput from '../../../../components/Inputs/TextInput/TextInput';
import { PartnerCompany, PartnerLevel } from '../../../../store/partners/partners.types';
import { useTranslation } from 'react-i18next';
import {
  createCustomPartnerLevel,
  updatePartnerLevel
} from '../../../../store/partners/partners.action';
import { toastUtil } from '../../../../utils/toast.utils';
import Button from '../../../../components/Button/Button';
import { SetStateType } from '../../../../types/util.types';
import { ChangeEvent } from 'react';

interface SinglePartnerCommisionsProps {
  partnerData: PartnerCompany;
  setPartnerData: SetStateType<PartnerCompany>;
  partnerId?: string;
  fetchPartnerDetails: () => void;
}

const SinglePartnerCommissions = ({
  partnerData,
  setPartnerData,
  partnerId,
  fetchPartnerDetails
}: SinglePartnerCommisionsProps) => {
  const { t } = useTranslation();

  const partnerLevels = Object.keys(PartnerLevel).map((elem) => ({
    value: elem,
    displayValue: t(`partnerLevel.${elem}`)
  }));

  const currentLevel = {
    value: partnerData.partnerLevel.partnerLevel,
    displayValue: t(`partnerLevel.${partnerData.partnerLevel.partnerLevel}`)
  };

  const updateLevel = async (val: string) => {
    if (partnerId) {
      const resp = await updatePartnerLevel(+partnerId, val as PartnerLevel);
      toastUtil('success', t('changesSaved'));
      resp && fetchPartnerDetails();
    }
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setPartnerData((prevData: PartnerCompany) => ({
      ...prevData,
      partnerLevel: {
        ...prevData.partnerLevel,
        [name]: value
      }
    }));
  };

  const saveCustomLevel = async () => {
    if (partnerId) {
      // eslint-disable-next-line
      const { partnerLevel, ...updatedPartnerLevelDetails } = partnerData.partnerLevel;
      const resp = await createCustomPartnerLevel(+partnerId, updatedPartnerLevelDetails);
      resp && toastUtil('success', t('changesSaved'));
    }
  };

  return (
    <div className="partner-commissions-wrapper">
      <div>
        <Dropdown initialValue={currentLevel} values={partnerLevels} getValue={updateLevel} />
        <TextInput
          value={partnerData.partnerLevel.brokeredValue}
          name="brokeredValue"
          type="text"
          placeholder={t('partnerCommissions.table.heading.BROKERED_VALUE')}
          unit={partnerData.partnerLevel.brokeredValueUnit}
          onChange={handleChange}
        />
        <TextInput
          value={partnerData.partnerLevel.orderVolume}
          name="orderVolume"
          type="text"
          placeholder={t('partnerCommissions.table.heading.ORDER_VOLUME')}
          unit={partnerData.partnerLevel.orderVolumeUnit}
          onChange={handleChange}
        />
        <TextInput
          value={partnerData.partnerLevel.loanAmount}
          name="loanAmount"
          type="text"
          placeholder={t('partnerCommissions.table.heading.LOAN_AMOUNT')}
          unit={partnerData.partnerLevel.loanAmountUnit}
          onChange={handleChange}
        />
        <TextInput
          value={partnerData.partnerLevel.paymentTerms}
          name="paymentTerms"
          type="text"
          placeholder={t('partnerCommissions.table.heading.PAYMENT_TERMS')}
          unit={t(`commissionUnits.${partnerData.partnerLevel.paymentTermsUnit}`)}
          onChange={handleChange}
        />
      </div>
      <Button variation="primary" size="large" dataTest="" onClick={saveCustomLevel}>
        {t('saveChanges')}
      </Button>
    </div>
  );
};

export default SinglePartnerCommissions;
