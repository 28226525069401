import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import './TooltipHeader.scss';

interface TooltipHeaderProps {
  heading: string;
  tooltip?: { text: string; heading?: string };
}

const TooltipHeader = ({ heading, tooltip }: TooltipHeaderProps) => {
  return (
    <div className="tooltip-header-wrapper">
      <Text as="h3" category="headline" size="large">
        {heading}
      </Text>
      {tooltip && <Tooltip {...tooltip} />}
    </div>
  );
};

export default TooltipHeader;
