import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './Clients.scss';
import SearchFilter from '../../../components/Inputs/SearchFilter/SearchFilter';
import Pagination from '../../../components/Pagination/Pagination';
import { useEffect, useState } from 'react';
import { KycStatus, Profile } from '../../../store/profile/profile.types';
import { getAllProfiles } from '../../../store/profile/profile.actions';
import { parseName } from '../../../utils/general.utils';
import { Sort, SortBy } from '../../../types/util.types';
import TableSortable from '../../../components/Tables/TableSortable/TableSortable';
import useNavigation from '../../../hooks/useNavigation';

enum ClientsTableHeading {
  CUSTOMER_NAME = 'firstName',
  EMAIL = 'email',
  BANK_DETAILS = 'bankDetails',
  STATUS = 'status'
}

const Clients = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const initialSort = Object.values(ClientsTableHeading).map((elem: string) => {
    if (elem === ClientsTableHeading.CUSTOMER_NAME) {
      return { name: elem, sort: SortBy.ASCENDING };
    }
    return { name: elem, sort: undefined };
  });

  const [profiles, setProfiles] = useState<Profile[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<KycStatus | undefined>();
  const [sort, setSort] = useState<Sort[]>(initialSort);

  const statusMap = {
    all: undefined,
    [t('clientStatuses.KYC_OPEN')]: KycStatus.KYC_OPEN,
    [t('clientStatuses.KKG_FINAL')]: KycStatus.KKG_FINAL,
    [t('clientStatuses.AML_FINAL')]: KycStatus.AML_FINAL,
    [t('clientStatuses.FIRST_CHECK_SUCCESS')]: KycStatus.FIRST_CHECK_SUCCESS,
    [t('clientStatuses.FIRST_CHECK_FAIL')]: KycStatus.FIRST_CHECK_FAIL,
    [t('clientStatuses.SECOND_CHECK_SUCCESS')]: KycStatus.SECOND_CHECK_SUCCESS,
    [t('clientStatuses.SECOND_CHECK_FAIL')]: KycStatus.SECOND_CHECK_FAIL,
    [t('clientStatuses.KYC_SUCCESS')]: KycStatus.KYC_SUCCESS,
    [t('clientStatuses.KYC_FAIL')]: KycStatus.KYC_FAIL,
    [t('clientStatuses.ADMONISHED')]: KycStatus.ADMONISHED
  };

  const clientStatusClassName = (status: string) => {
    let setClassName;

    switch (status) {
      case KycStatus.ADMONISHED ||
        KycStatus.AML_FINAL ||
        KycStatus.SECOND_CHECK_FAIL ||
        KycStatus.KYC_FAIL:
        setClassName = 'unsuccessful';
        break;
      case KycStatus.KYC_SUCCESS:
        setClassName = 'successful';
        break;
      default:
        setClassName = 'pending';
        break;
    }

    return setClassName;
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      const res = await getAllProfiles({
        keyword: search,
        status: filter,
        page: page - 1,
        sort: sort.filter((elem: Sort) => elem.sort),
        size: 6
      });
      setProfiles(res?.profiles);
      setTotalPages(res?.totalPages);
      setLoading(false);
      (search || filter) && setPage(1);
    };

    fetchProfiles();
  }, [page, search, filter, sort]);

  const changeFilter = (filter: string) => {
    setFilter(statusMap[filter]);
  };

  return (
    <div className="clients-wrapper">
      <Text as="h2" category="display">
        {t('admin.clients.clientOverview')}
      </Text>
      <SearchFilter
        placeholder={t('admin.clients.searchPlaceholder')}
        setValue={setSearch}
        filters={Object.values(KycStatus).map((status) => t(`clientStatuses.${status}`))}
        setFilterValue={changeFilter}
      />
      <TableSortable
        values={sort}
        columnWidths={[35, 35, 15, 15]}
        hiddenSortColumns={[3, 4]}
        onSortChange={setSort}
        translationKey="admin.clients.table.heading."
        sortable
        hoverEffect>
        <>
          {!loading &&
            profiles?.map((profile, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => {
                    goTo(`/admin/client/${profile?.userUuid}`, { replace: true });
                  }}>
                  <Text as="td" category="label" size="large">
                    {parseName(profile?.firstName, profile?.lastName)}
                  </Text>
                  <Text as="td" category="label" size="large">
                    {profile?.email}
                  </Text>
                  <Text as="td" category="label" size="large">
                    {profile?.iBAN ? t('yes') : t('no')}
                  </Text>
                  <Text
                    as="td"
                    category="label"
                    size="large"
                    className={`client-status-${clientStatusClassName(profile?.kycStatus)}`}>
                    {t(`clientStatuses.${profile?.kycStatus}`)}
                  </Text>
                </tr>
              );
            })}
        </>
      </TableSortable>
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    </div>
  );
};

export default Clients;
