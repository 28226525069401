import './FileItem.scss';
import Checkbox from '../../Checkbox/Checkbox';
import Text from '../../Text/Text';
import Icon from '../../Icon/Icon';
import { useTranslation } from 'react-i18next';

interface FileItemProps {
  name: string;
  alignment?: 'left' | 'right';
  variation: 'primary' | 'secondary';
  disable?: boolean;
  className?: string;
  onDelete?: () => void;
}

const FileItem = ({
  name,
  alignment = 'left',
  disable = false,
  variation = 'primary',
  className,
  onDelete
}: FileItemProps) => {
  const { t } = useTranslation();

  const primaryVariationText = () => (variation === 'primary' ? `- ${t('uploaded')} !` : '');

  return (
    <div
      className={`file-item ${alignment} ${disable ? 'disabled' : ''} ${
        className ? className : ''
      }`}>
      <Icon className="file-icon" name="file" />
      <Text className="file-label" as="label" category="label" size="large" variety={1}>
        {`${name} ${primaryVariationText()}`}
      </Text>
      {variation === 'primary' && (
        <>
          <Checkbox
            className="file-upload-success"
            type="round"
            checked={true}
            disabled={disable}
          />
          {onDelete && <Icon name="delete" className="delete-icon" onClick={onDelete} />}
        </>
      )}
    </div>
  );
};

export default FileItem;
