import Button from '../../../../../components/Button/Button';
import './Admin4EyeReview.scss';
import close from '../../../../../assets/Icons/close.svg';
import check from '../../../../../assets/Icons/check.svg';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { useState } from 'react';
import { ModalTitle } from '../ProjectReview';
import { SetStateType } from '../../../../../types/util.types';
import { NewProjectLog } from '../../../../../store/project/project.types';
import { FourEyeCheckStatus } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';

interface Admin4EyeReviewProps {
  fourEyeCheckStatus: FourEyeCheckStatus;
  setNewLog: SetStateType<NewProjectLog>;
  openModal: (modalTitle: ModalTitle) => void;
}

const Admin4EyeReview = ({ fourEyeCheckStatus, setNewLog, openModal }: Admin4EyeReviewProps) => {
  const { t } = useTranslation();

  const [formChecks, setFormChecks] = useState({ checkedInstaller: false, generalCheck: false });

  const bothChecks = formChecks.checkedInstaller && formChecks.generalCheck;

  const handleClick = (decision: boolean) => {
    setNewLog((prev) => ({ ...prev, approved: decision }));
    decision
      ? openModal(ModalTitle.DATA_CONFIRMATION_CONFIRM)
      : openModal(ModalTitle.DATA_CONFIRMATION_REJECT);
  };

  return (
    <div className="admin-4eye-review-wrapper">
      <Checkbox
        label={t('admin.projectDetails.projectReview.userProjectData.questions.installerChecked')}
        checked={formChecks.checkedInstaller}
        onClick={() => {
          setFormChecks((prev) => ({ ...prev, checkedInstaller: !prev.checkedInstaller }));
        }}
      />
      <div>
        <Checkbox
          label={t('admin.projectDetails.projectReview.userProjectData.questions.confirmation')}
          checked={formChecks.generalCheck}
          onClick={() => {
            setFormChecks((prev) => ({ ...prev, generalCheck: !prev.generalCheck }));
          }}
        />
        <div className="admin-button-action-wrapper">
          <Button
            size="large"
            variation="secondary"
            iconPosition="left"
            icon={close}
            dataTest=""
            disabled={!bothChecks}
            onClick={() => {
              handleClick(false);
            }}>
            {t('reject')}
          </Button>
          <Button
            size="large"
            variation="primary"
            icon={check}
            iconPosition="left"
            dataTest=""
            disabled={!bothChecks}
            onClick={() => {
              handleClick(true);
            }}>
            {fourEyeCheckStatus === FourEyeCheckStatus.TO_BE_REVIEWED_BY_SUPER_ADMIN
              ? t('reopen')
              : t('confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Admin4EyeReview;
