import { User, UserActions } from './types';

export const loginUser = (payload: User) => {
  return {
    type: UserActions.LOGIN,
    payload: payload
  };
};

export const logoutUser = () => {
  return {
    type: UserActions.LOGOUT
  };
};

export const verifyMail = () => {
  return {
    type: UserActions.VERIFY_MAIL
  };
};

export const setTwoFactorAuth = (payload: boolean) => {
  return {
    type: UserActions.SET_TWO_FACTOR_AUTH,
    payload: payload
  };
};

export const updateUser = (payload: Partial<User>) => {
  return {
    type: UserActions.UPDATE_USER,
    payload: payload
  };
};

export const setLoginError = () => {
  return {
    type: UserActions.SET_ERROR
  };
};
