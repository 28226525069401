import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';
import useNavigation from '../../../hooks/useNavigation';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContextProvider';

const KycSuccess = () => {
  const { t, i18n } = useTranslation();
  const { goTo } = useNavigation();
  const auth = useContext(AuthContext);

  return (
    <div className="kyc-success-wrapper">
      <Text as="h2" category="display" size="medium">
        {t('kycResult.success.heading')}
      </Text>
      <Icon name="kyc-success" />
      <Text as="span" category="body" size="medium" className="kyc-success-notification">
        {t('kycResult.success.emailNotification')}
      </Text>
      <Text as="h4" category="headline" size="large">
        {t('kycResult.success.steps')}
      </Text>
      <ul>
        <Text as="li" category="body" size="medium">
          {t('kycResult.success.creditWorthiness')}
        </Text>
        <Text as="li" category="body" size="medium">
          {t('kycResult.success.applicationChecks')}
        </Text>
        <Text as="li" category="body" size="medium">
          {t('kycResult.success.confirmation')}
        </Text>
        <Text as="li" category="body" size="medium">
          {t('kycResult.success.projectFinancing')}
        </Text>
      </ul>
      <Button
        dataTest=""
        size="large"
        variation="primary"
        onClick={() => {
          if (auth?.isAuthenticated) {
            goTo('/my-profile');
          } else {
            auth?.keycloak.login({
              redirectUri: `${window.location.origin}/${i18n.language}/my-profile`
            });
          }
        }}>
        {t('toMyProfile')}
      </Button>
    </div>
  );
};

export default KycSuccess;
