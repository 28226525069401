import { useTranslation } from 'react-i18next';
import {
  EmploymentStatus,
  QuestionnaireFormActionType,
  QuestionnaireType,
  TransportType
} from '../../../store/questionnaire/questionnaire.types';
import Text from '../../../components/Text/Text';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import { changeHouseholdPartnerFinancialDetails } from '../../../store/questionnaire/questionnaire.actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Radio from '../../../components/Inputs/Radio/Radio';
import '../Questionnaire.scss';

const LifePartnerFinancialSituation = () => {
  const { t } = useTranslation();
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const dispatchLifePartnerTransport = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_TRANSPORT_TYPE,
      payload: value as TransportType
    });

    if (value !== TransportType.CAR && value !== TransportType.PUBLIC) {
      dispatch({ type: QuestionnaireFormActionType.REMOVE_PARTNER_MONTHLY_TRANSPORTATION_COSTS });
    }
  };

  const handleHouseholdPartnerNumberOfMonthlyIncome = (
    field: 'numberOfMonthlyIncomeMain' | 'numberOfMonthlyIncomeAdditional'
  ) => {
    let value = questionnaire.creditCheckData.householdPartner?.financialData?.[field];

    value === 12 || !value ? (value = 13) : (value = 12);

    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_FINANCIAL_DETAILS,
      payload: { field, value }
    });
  };

  const handlePartnerAdditionalIncome = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const payload = value ? [parseFloat(value)] : [];

    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_MONTHLY_INCOME_ADDITONAL,
      payload: payload
    });
  };

  const isChurchTaxObligatory = () =>
    questionnaire.creditCheckData.householdPartner?.financialData?.isChurchTaxObligatory ?? false;

  return (
    <form className="credit-check-form">
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.lifePartnerEmploymentStatus')}
        </Text>
        <Dropdown
          values={Object.values(EmploymentStatus).map((elem) => ({
            value: elem,
            displayValue: t(`creditCheck.employmentStatus.${elem}`)
          }))}
          initialValue={{
            value: questionnaire.creditCheckData.householdPartner
              ?.employmentStatus as EmploymentStatus,
            displayValue: t(
              `creditCheck.employmentStatus.${questionnaire.creditCheckData.householdPartner?.employmentStatus}`
            )
          }}
          noValueText={t('creditCheck.financialSituation.employmentStatus')}
          getValue={(value) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_PARTNER_EMPLOYMENT_STATUS,
              payload: value as EmploymentStatus
            });
          }}
          required
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.lifePartnerMonthlyIncomeGrossAmountMain')}
        </Text>
        <TextInput
          type="text"
          value={
            questionnaire.creditCheckData.householdPartner?.financialData
              ?.monthlyIncomeGrossAmountMain
          }
          name="monthlyIncomeGrossAmountMain"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
        <Checkbox
          checked={
            questionnaire.creditCheckData.householdPartner?.financialData
              ?.numberOfMonthlyIncomeMain === 13
          }
          label={t('creditCheck.lifePartnerFinancialSituation.thirteenSalary')}
          onClick={() => {
            handleHouseholdPartnerNumberOfMonthlyIncome('numberOfMonthlyIncomeMain');
          }}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.additionalIncome')}
        </Text>
        <Text as="span" category="body" size="large">
          {t('creditCheck.lifePartnerFinancialSituation.ammountOfAdditionalIncome')}
        </Text>
        <TextInput
          type="text"
          value={
            questionnaire.creditCheckData.householdPartner?.financialData
              ?.monthlyIncomeGrossAmountAdditional?.[0]
          }
          name="monthlyIncomeGrossAmountAdditional"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={handlePartnerAdditionalIncome}
        />
        <Checkbox
          checked={
            questionnaire.creditCheckData.householdPartner?.financialData
              ?.numberOfMonthlyIncomeAdditional === 13
          }
          label={t('creditCheck.lifePartnerFinancialSituation.additionalIncomeThirteenSalary')}
          onClick={() => {
            handleHouseholdPartnerNumberOfMonthlyIncome('numberOfMonthlyIncomeAdditional');
          }}
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.workingDaysPerMonth')}
        </Text>
        <TextInput
          value={
            questionnaire.creditCheckData?.householdPartner?.financialData?.workingDaysPerMonth
          }
          name="workingDaysPerMonth"
          type="text"
          placeholder={`${t('days')} / ${t('month')}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.rentalCostMonthly')}
        </Text>
        <Text size="large">{t('creditCheck.financialSituation.rentalCostMonthlyExamples')}</Text>
        <TextInput
          value={questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyHousingCost}
          name="monthlyHousingCost"
          type="number"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
      </div>
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.lifePartnerFinancialSituation.workTransportText')}
        </Text>
        <Dropdown
          values={Object.values(TransportType).map((elem) => ({
            value: elem,
            displayValue: t(`creditCheck.transportType.${elem}`)
          }))}
          initialValue={{
            value:
              questionnaire.creditCheckData?.householdPartner?.financialData?.transportationType ||
              '',
            displayValue:
              t(
                `creditCheck.transportType.${questionnaire.creditCheckData?.householdPartner?.financialData?.transportationType}`
              ) || ''
          }}
          noValueText={t('creditCheck.financialSituation.workTransport')}
          getValue={dispatchLifePartnerTransport}
          required
        />
        {(questionnaire.creditCheckData.householdPartner?.financialData?.transportationType ===
          TransportType.CAR ||
          questionnaire.creditCheckData.householdPartner?.financialData?.transportationType ===
            TransportType.PUBLIC) && (
          <>
            <Text as="span" category="headline" size="medium">
              {t('creditCheck.lifePartnerFinancialSituation.monthlyTransportationCosts')}
            </Text>
            <TextInput
              type="number"
              value={
                questionnaire.creditCheckData.householdPartner?.financialData
                  ?.monthlyTransportationCosts
              }
              name="monthlyTransportationCosts"
              placeholder={`${t('currency.chf')} / ${t('month')}`}
              onChange={(e) => {
                changeHouseholdPartnerFinancialDetails(e, dispatch);
              }}
              required
            />
          </>
        )}
      </div>
      <div>
        <Text as="h3" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.isChurchTaxObligatory')}
        </Text>
        <div className="church-obligation-wrapper">
          <Radio
            value="yes"
            variation="secondary"
            checked={isChurchTaxObligatory()}
            label={t('yes')}
            onClick={() => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_PARTNER_CHURCH_TAX_OBLIGATION,
                payload: true
              });
            }}
          />
          <Radio
            value="no"
            variation="secondary"
            checked={!isChurchTaxObligatory()}
            label={t('no')}
            onClick={() => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_PARTNER_CHURCH_TAX_OBLIGATION,
                payload: false
              });
            }}
          />
        </div>
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyAlimony')}
        </Text>
        <TextInput
          value={
            questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyExpensesAlimony
          }
          name="monthlyExpensesAlimony"
          type="text"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(e) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyEducationCosts')}
        </Text>
        <TextInput
          value={
            questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyEducationCosts
          }
          name="monthlyEducationCosts"
          type="text"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(e) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyOtherFixExpenses')}
        </Text>
        <TextInput
          value={
            questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyOtherFixExpenses
          }
          name="monthlyOtherFixExpenses"
          type="text"
          placeholder={`${t('currency.chf')} / ${t('month')}`}
          onChange={(e) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
      </div>
      <div>
        <Text as="span" category="headline" size="medium">
          {t('creditCheck.lifePartnerFinancialSituation.monthlyOneTimeExpenses')}
        </Text>
        <Text size="large">
          {t('creditCheck.lifePartnerFinancialSituation.majorExpensesPlanned')}
        </Text>
        <TextInput
          value={
            questionnaire.creditCheckData?.householdPartner?.financialData?.monthlyOneTimeExpenses
          }
          name="monthlyOneTimeExpenses"
          type="text"
          placeholder={t('currency.chf')}
          onChange={(e) => {
            changeHouseholdPartnerFinancialDetails(e, dispatch);
          }}
          required
        />
      </div>
    </form>
  );
};
export default LifePartnerFinancialSituation;
