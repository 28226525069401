import { useTranslation } from 'react-i18next';
import CommentBox from '../../../../../components/CommentBox/CommentBox';
import Text from '../../../../../components/Text/Text';
import Button from '../../../../../components/Button/Button';
import SaveIcon from '../../../../../assets/Icons/save.svg';
import EditIcon from '../../../../../assets/Icons/edit.svg';
import { useState } from 'react';
import { SetStateType } from '../../../../../types/util.types';
import { NewProjectLog } from '../../../../../store/project/project.types';
import { toastUtil } from '../../../../../utils/toast.utils';

interface CommentSubmissionProps {
  setNewLog: SetStateType<NewProjectLog>;
}

const CommentSubmission = ({ setNewLog }: CommentSubmissionProps) => {
  const { t } = useTranslation();
  const [commentSubmission, setCommentSubmission] = useState({
    save: true,
    edit: true,
    comment: '',
    commentBox: false
  });

  const submitComment = () => {
    setCommentSubmission((prev) => ({
      ...prev,
      commentBox: true,
      save: true
    }));

    setNewLog((prevLogs) => ({ ...prevLogs, comment: commentSubmission.comment }));
    toastUtil('success', t('admin.projectDetails.commentSaved'));
  };

  return (
    <div>
      <Text as="h2" category="headline">
        {t('admin.projectDetails.projectReview.newComment')}
      </Text>
      <CommentBox
        value={commentSubmission.comment}
        onSubmit={(comment) => {
          setCommentSubmission((prev) => ({
            ...prev,
            comment: comment,
            save: false,
            commentBox: true,
            edit: false
          }));
        }}
        disabled={commentSubmission.commentBox}
      />
      <div className="comment-submission-action-wrapper">
        <Button
          size="large"
          variation="secondary"
          iconPosition="left"
          disabled={commentSubmission.edit}
          icon={EditIcon}
          dataTest=""
          onClick={() => {
            setCommentSubmission((prev) => ({
              ...prev,
              commentBox: !prev.commentBox
            }));
          }}>
          {t('edit')}
        </Button>
        <Button
          size="large"
          variation="primary"
          icon={SaveIcon}
          iconPosition="left"
          dataTest=""
          disabled={commentSubmission.save}
          onClick={submitComment}>
          {t('save')}
        </Button>
      </div>
    </div>
  );
};

export default CommentSubmission;
