export enum ScrollActions {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK'
}

export interface LockScroll {
  type: ScrollActions.LOCK;
}

export interface UnlockScroll {
  type: ScrollActions.UNLOCK;
}

export type ActionTypes = LockScroll | UnlockScroll;
