import { ChecklistItemType } from '../../components/Checklist/ChecklistItem/ChecklistItem';
import { Module, ModuleType } from '../../types/financialOffer.types';
import { BuildingAddress } from '../../types/sepMap.types';
import { Sort, SwissCanton, UnitValue } from '../../types/util.types';
import { OfferStatus } from '../offers/offers.types';
import { Contact, Profile } from '../profile/profile.types';

export enum PartnerLevel {
  STARTER = 'STARTER',
  VOLT_ON = 'VOLT_ON',
  HEAVY_CURRENT = 'HEAVY_CURRENT',
  POWER_PLANT = 'POWER_PLANT'
}

export enum PartnerType {
  INSTALLATION_PARTNER = 'INSTALLATION_PARTNER',
  SALES_PARTNER = 'SALES_PARTNER'
}

export interface PartnerCommissionDetails {
  partnerLevel: PartnerLevel;
  brokeredValue: number;
  brokeredValueUnit: string;
  orderVolume: number;
  orderVolumeUnit: string;
  loanAmount: number;
  loanAmountUnit: string;
  paymentTerms: number;
  paymentTermsUnit: string;
}

export interface PartnerLocation {
  address: string;
  latitude: number;
  longitude: number;
  municipality?: string;
  postalCode?: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface PartnerAddress {
  street: string;
  houseNumber: string;
  city: string;
  swissZipCode: string;
}

export interface CompanyDetails {
  name: string;
  uid: string;
  address: PartnerAddress;
  legalForm: CompanyLegalForm;
}

export interface PartnerCompany {
  name: string;
  uid: string;
  legalForm: string;
  contactLastName: string;
  contactFirstName: string;
  partnerLevel: PartnerCommissionDetails;
  partnerLocations: Partial<PartnerLocation>[];
  profile?: Profile;
  rating?: string;
  cantons?: Canton[];
  modules?: PartnerProduct[];
  contactEmail?: string;
  contactPhoneNumber?: string;
}

export interface UpdatePartnerCompany {
  contactFirstName?: string;
  contactLastName?: string;
  cantons?: Canton[];
  modules?: PartnerProduct[];
}

export interface Canton {
  id: number;
  code?: string;
  name: string;
}

export interface Partner {
  id: number;
  name: string;
  contactFirstName: string;
  contactLastName: string;
  type: PartnerType;
  modules: PartnerProduct[];
  partnerLevel: PartnerLevel;
  partnerLocations: PartnerLocation[];
}

export interface GetPartnersFilter {
  page: number;
  size: number;
  keyword?: string;
  sort?: Sort[];
  cantonCode?: SwissCanton;
  verified?: boolean;
  partnerType?: PartnerType;
  moduleType?: ModuleType;
}

export type PartnerProduct = ChecklistItemType & { type: ModuleType };

export const products: PartnerProduct[] = [
  {
    id: 1,
    label: `partnerProducts.${ModuleType.E_CHARGING_STATION}`,
    type: ModuleType.E_CHARGING_STATION
  },
  {
    id: 2,
    label: `partnerProducts.${ModuleType.HEAT_PUMP}`,
    type: ModuleType.HEAT_PUMP
  },
  {
    id: 3,
    label: `partnerProducts.${ModuleType.STORAGE_SOLUTION}`,
    type: ModuleType.STORAGE_SOLUTION
  },
  {
    id: 5,
    label: `partnerProducts.${ModuleType.SOLAR_SYSTEM}`,
    type: ModuleType.SOLAR_SYSTEM
  }
];

export interface PartnerCommission {
  id: number;
  partnerLevel: PartnerLevel;
  brokeredValue: UnitValue;
  loanAmount: UnitValue;
  orderVolume: UnitValue;
  paymentTerms: UnitValue;
}

export interface PartnerRegistrationForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  acceptInstallationContract: boolean;
}

export interface RegisterPartner {
  name: string;
  uid: string;
  partnerLocations: Partial<PartnerLocation>[];
  legalForm: string;
  contactEmail: string;
  cantons?: Canton[];
  modules?: Module[];
  contactFirstName?: string;
  contactLastName?: string;
  contactPhoneNumber?: string;
  rating?: number;
  verified?: boolean;
}

export interface PartnerProjectData {
  id: number;
  offerId: number;
  contact: Contact;
  address: {
    sepId: number | string;
    address: BuildingAddress;
    latitude: number;
    longitude: number;
  };
  date: string;
  price: string | number;
  documentUrl: string;
  status: OfferStatus;
  performance: number;
  area: number;
  acceptanceReportUrl: string;
  activeModules: Module[];
}

export interface CompanyLegalForm {
  id: number;
  uid: string;
  shortName: {
    de: string;
    fr: string;
    it: string;
    en: string;
  };
}
