import React, { useEffect, useState } from 'react';
import Text from '../Text/Text';
import './Pagination.scss';

interface PaginationProps {
  page: number;
  totalPages: number;
  setPage: (pageNumber: number) => void;
}

interface PageRange {
  first: number;
  last: number;
}

const Pagination = ({ totalPages, setPage, page }: PaginationProps) => {
  const pagesPerView = 5;

  const pages = [...Array(totalPages)].fill('').map((_, index) => index + 1);
  const [pageRange, setPageRange] = useState<PageRange>({ first: 0, last: pagesPerView });

  useEffect(() => {
    setPage(pageRange.first + 1);
  }, [pageRange]);

  useEffect(() => {
    if (page > pageRange.last) {
      setPageRange((prev) => ({
        ...prev,
        first: prev.first + pagesPerView,
        last: prev.last + pagesPerView
      }));
    }
    if (page <= pageRange.first) {
      setPageRange((prev) => ({
        ...prev,
        first: prev.first - pagesPerView,
        last: prev.last - pagesPerView
      }));
    }
  }, [page]);

  return totalPages ? (
    <div className="pagination">
      {totalPages > pagesPerView && (
        <Text
          as="span"
          category="label"
          size="medium"
          onClick={() =>
            setPageRange((prev) => ({
              ...prev,
              first: prev.first - pagesPerView,
              last: prev.last - pagesPerView
            }))
          }
          className={`pagination-btn ${
            pageRange.first === 0 && pageRange.last === pagesPerView ? 'disabled' : ''
          }`}>
          {'<<'}
        </Text>
      )}
      <Text
        as="span"
        category="label"
        size="medium"
        onClick={() => setPage(page - 1)}
        className={`pagination-btn ${page === 1 ? 'disabled' : ''}`}>
        {'<'}
      </Text>
      {pages.slice(pageRange.first, pageRange.last).map((pageNumber) => (
        <Text
          as="span"
          category="label"
          size="medium"
          key={pageNumber}
          onClick={() => setPage(pageNumber)}
          className={`pagination-btn ${page === pageNumber ? 'active' : ''}`}>
          {pageNumber}
        </Text>
      ))}
      <Text
        as="span"
        category="label"
        size="medium"
        onClick={() => setPage(page + 1)}
        className={`pagination-btn ${page === totalPages ? 'disabled' : ''}`}>
        {'>'}
      </Text>
      {totalPages > pagesPerView && (
        <Text
          as="span"
          category="label"
          size="medium"
          onClick={() =>
            setPageRange((prev) => ({
              ...prev,
              first: prev.first + pagesPerView,
              last: prev.last + pagesPerView
            }))
          }
          className={`pagination-btn ${
            totalPages >= pageRange.first && totalPages <= pageRange.last ? 'disabled' : ''
          }`}>
          {'>>'}
        </Text>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
