import { useTranslation } from 'react-i18next';
import ProfileCard from './ProfileCard/ProfileCard';
import Text from '../../components/Text/Text';
import './Profile.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { User } from '../../store/user/types';
import useNavigation from '../../hooks/useNavigation';

export interface ProfileCardType {
  key: string;
  icon: string;
  to: string;
}

const Profile = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const profileCards: ProfileCardType[] = [
    { key: 'personalData', icon: 'user', to: '/my-profile/personal-data' },
    { key: 'myProjects', icon: 'folder', to: '/my-profile/projects' },
    { key: 'myNews', icon: 'envelope', to: '/my-profile/inbox' },
    { key: 'support', icon: 'support', to: '/' }
  ];

  return (
    <>
      <Text as={'h1'} category={'display'}>
        {t('myProfile.title')}
      </Text>
      <Text className="customer-number">
        {t('customerNumber')} {user.id}
      </Text>
      <div className={'profile-cards'}>
        {profileCards.map((card: ProfileCardType, index: number) => {
          return (
            <ProfileCard
              key={index}
              title={t(`myProfile.cards.${card.key}.title`)}
              description={t(`myProfile.cards.${card.key}.description`)}
              buttonText={t(`myProfile.cards.${card.key}.buttonText`)}
              icon={card.icon}
              dataTest={`my-profile${card.key}-button`}
              onClick={() => {
                if (card.key === 'support') {
                  window.open('https://www.investerra.ch/faq');
                  return;
                }
                goTo(card.to);
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default Profile;
