import api from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import { ProductType } from './products.types';

export const getAllProducts = async (): Promise<ProductType[] | undefined> => {
  try {
    const { data } = await api.get('/modules');
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};
