import { Dispatch, SetStateAction } from 'react';

export type DeepPartial<T> =
  | T
  | (T extends Array<infer U>
      ? DeepPartial<U>[]
      : T extends Map<infer K, infer V>
      ? Map<DeepPartial<K>, DeepPartial<V>>
      : T extends Set<infer M>
      ? Set<DeepPartial<M>>
      : T extends Date
      ? T
      : T extends object
      ? { [K in keyof T]?: DeepPartial<T[K]> }
      : T);

export type SetStateType<T> = Dispatch<SetStateAction<T>>;

export interface TootipType {
  heading: string;
  text: string;
}

export interface CheckboxType {
  name: string;
  checked: boolean;
  disabled: boolean;
}

export interface SearchRequest {
  page: number;
  keyword: string;
  size: number;
  sort?: string[];
}

export interface PagableResponse<T> {
  content: T[];
  totalElements: number;
  totalPages?: number;
}

export interface Sort {
  name: string;
  sort?: SortBy;
}
export enum SortBy {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export interface UnitValue {
  value: string;
  unit: string;
}

export enum Month {
  Jan = 'Jan',
  Feb = 'Feb',
  Mar = 'Mar',
  Apr = 'Apr',
  May = 'May',
  Jun = 'Jun',
  Jul = 'Jul',
  Aug = 'Aug',
  Sep = 'Sep',
  Oct = 'Oct',
  Nov = 'Nov',
  Dec = 'Dec'
}

export enum SwissCanton {
  AG = 'AG',
  AR = 'AR',
  AI = 'AI',
  BL = 'BL',
  BS = 'BS',
  BE = 'BE',
  FR = 'FR',
  GE = 'GE',
  GL = 'GL',
  GR = 'GR',
  JU = 'JU',
  LU = 'LU',
  NE = 'NE',
  NW = 'NW',
  OW = 'OW',
  SG = 'SG',
  SH = 'SH',
  SZ = 'SZ',
  SO = 'SO',
  TG = 'TG',
  TI = 'TI',
  UR = 'UR',
  VS = 'VS',
  VD = 'VD',
  ZG = 'ZG',
  ZH = 'ZH'
}
