import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { lockScroll, unlockScroll } from '../store/scrollLock/actions';

export const useModal = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpened(false);
        dispatch(unlockScroll());
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const toggle = () => {
    if (!isOpened) {
      dispatch(lockScroll());
      setIsOpened(true);
    } else if (isOpened) {
      dispatch(unlockScroll());
      setIsOpened(false);
    }
  };

  return {
    isOpened,
    toggle
  };
};
