import { ActionTypes, ScrollActions } from '../store/scrollLock/types';

export interface ScrollState {
  isLocked: boolean;
}

const initialState: ScrollState = {
  isLocked: false
};

const scrollReducer = (state: ScrollState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case ScrollActions.LOCK:
      return {
        ...state,
        isLocked: true
      };
    case ScrollActions.UNLOCK:
      return {
        ...state,
        isLocked: false
      };

    default:
      return state;
  }
};

export default scrollReducer;
