import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import Text from '../../../../../components/Text/Text';

interface ExperianResponseProps {
  experianReasons?: string[];
  toggle: () => void;
}

const ExperianResponse = ({ experianReasons, toggle }: ExperianResponseProps) => {
  const { t } = useTranslation();

  return (
    <div className="experian-response-wrapper">
      {!!experianReasons?.length &&
        experianReasons?.map((elem, ind) => (
          <div key={ind}>
            <Text as="p" category="body" size="medium">
              {elem}
            </Text>
          </div>
        ))}
      <Button size="large" variation="primary" iconPosition="left" dataTest="" onClick={toggle}>
        {t('confirm')}
      </Button>
    </div>
  );
};

export default ExperianResponse;
