import React, { ReactElement } from 'react';

type Props = {
  title: string;
  children: ReactElement | ReactElement[];
  className?: string;
  disabled?: boolean;
};

const TabContent = ({ children, className }: Props): JSX.Element => (
  <div className={className}>{children}</div>
);

export default TabContent;
