import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_KOTLIN_ENDPOINT}/api`
});

export const kotlinApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_KOTLIN_ENDPOINT
});

export default api;
