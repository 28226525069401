import './FinancingViaOfferCalculator.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import Text from '../../../components/Text/Text';
import useNavigation from '../../../hooks/useNavigation';

const FinancingViaOfferCalculator = () => {
  const { goTo } = useNavigation();
  const { t } = useTranslation();

  return (
    <div className={'offer-calculator'}>
      <Text as="h3" category="headline" size="large">
        {t('offerAndFinancingText.calculateOfferHeadline')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('offerAndFinancingText.energyTransitionTopicQuestion')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('offerAndFinancingText.energyTransitionTopicAnswer')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('offerAndFinancingText.solarCalculatorDescription')}
      </Text>
      <Button
        size="large"
        variation="secondary"
        onClick={() => {
          goTo('/solar-system');
        }}
        dataTest="">
        {t('offerAndFinancingText.calculateOfferNow')}
      </Button>
    </div>
  );
};

export default FinancingViaOfferCalculator;
