import { ChecklistItemType } from '../components/Checklist/ChecklistItem/ChecklistItem';
import { format } from '../utils/formatting.utils';

export type Currency = 'CHF';

export enum ModuleType {
  E_CHARGING_STATION = 'E_CHARGING_STATION',
  HEAT_PUMP = 'HEAT_PUMP',
  STORAGE_SOLUTION = 'STORAGE_SOLUTION',
  SOLAR_SYSTEM = 'SOLAR_SYSTEM',
  INVESTERRA_ELECTRICITY_SALES = 'INVESTERRA_ELECTRICITY_SALES'
}

export type ModuleUnit = 'CHF' | 'EU' | 'Rp.';

export type Module = ChecklistItemType & { type: string; value: number | string };

export const modules: Module[] = [
  {
    id: 1,
    label: `module.types.${ModuleType.E_CHARGING_STATION}`,
    type: ModuleType.E_CHARGING_STATION,
    value: 1500,
    unit: 'CHF',
    displayValue: `CHF ${format(1500)}`
  },
  {
    id: 2,
    label: `module.types.${ModuleType.HEAT_PUMP}`,
    type: ModuleType.HEAT_PUMP,
    value: 40000,
    unit: 'CHF',
    displayValue: `CHF ${format(40000)}`
  },
  {
    id: 3,
    label: `module.types.${ModuleType.STORAGE_SOLUTION}`,
    type: ModuleType.STORAGE_SOLUTION,
    value: 8000,
    unit: 'CHF',
    displayValue: `CHF ${format(8000)}`
  }
];

export const investerraModule: Module = {
  id: 4,
  label: `module.types.${ModuleType.INVESTERRA_ELECTRICITY_SALES}`,
  type: ModuleType.INVESTERRA_ELECTRICITY_SALES,
  value: 10.143,
  unit: 'Rp.',
  displayValue: `Rp. ${format(10.143)} pro kWh`
};

export type FinancingModel = {
  duration: number;
  monthlyRate: number;
  baseFee: number;
  deposit: number;
  interestRate?: number;
  currency?: Currency;
};
