import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FinancialOfferPreview from '../../components/FinancialOfferPreview/FinancialOfferPreview';
import Tabs from '../../components/Tabs/Tabs';
import TabContent from '../../components/Tabs/TabContent/TabContent';
import OfferDetails from './OfferDetails/OfferDetails';
import './Offers.scss';
import { getOffer, requestLoan } from '../../store/offers/offers.action';
import { Offer, OfferStatus, PartnerOffer } from '../../store/offers/offers.types';
import {
  FinancialOverview,
  FinancialOverviewInputs,
  FinancialOverviewType,
  Project,
  ProjectFinancialOverview,
  ProjectStatus
} from '../../store/project/project.types';
import { useLocation, useParams } from 'react-router-dom';
import Text from '../../components/Text/Text';
import { DeepPartial } from 'redux';
import { FinancingModel, Module } from '../../types/financialOffer.types';
import FinancingPlan from '../../components/FinancingPlan/FinancingPlan';
import Snackbar from '../../components/Snackbar/Snackbar';
import { toastUtil } from '../../utils/toast.utils';
import Map from '../../components/Map/Map';
import { getFinancialOverview } from '../../store/project/project.action';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { UserRoles } from '../../store/user/types';
import { AuthContext } from '../../context/AuthContextProvider';
import useNavigation from '../../hooks/useNavigation';
import { getBuildingByAddress, searchMesAddress } from '../../store/mes/mes.actions';
import Loader from '../../components/Loader/Loader';

// enum OffersModalType {
//   REGISTER = 'register',
//   DELETE_PROJECT = 'deleteProject'
// }

const Offers = () => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  // const { isOpened, toggle } = useModal();
  const { projectId } = useParams();
  const ulrHash = +useLocation().hash.substring(1);
  const { goTo } = useNavigation();

  const [loading, setLoading] = useState(false);

  const [project, setProject] = useState<DeepPartial<Project>>();
  const [currentOffer, setCurrentOffer] = useState<PartnerOffer | undefined>();
  const [recivedOffers, setRecivedOffers] = useState<PartnerOffer[]>();
  const role = useSelector<RootState>((state) => state.userReducer.role) as UserRoles;

  const [planDisabled, setPlanDisabled] = useState(false);
  // const [modalTitle, setModalTitle] = useState<OffersModalType | string>('');
  const [overview, setOverview] = useState<FinancialOverview | undefined>(undefined);

  useEffect(() => {
    if (!projectId) return;

    const getOfferDetails = async () => {
      setLoading(true);
      const data = await getOffer(+projectId);
      setRecivedOffers(data?.offers);
      setCurrentOffer(
        ulrHash ? data?.offers.find((offer) => offer.id === ulrHash) : data?.offers[0]
      );
      if (data?.project.building?.address) {
        const { results } = await searchMesAddress(
          `${data?.project.building?.address.street}
          ${data?.project.building?.address.houseNumber}
          ${data?.project.building?.address.postalCode}
          ${data?.project.building?.address.municipality}`
        );
        const building = await getBuildingByAddress(results[0].attrs);
        setProject((prevState) => ({
          ...prevState,
          ...data?.project,
          building: building
        }));
      }
    };

    projectId && getOfferDetails();
  }, [projectId]);

  useEffect(() => {
    getOverview();
  }, [project?.id, currentOffer?.id]);

  const getOverview = () => {
    if (!project) return;

    let inputs = {
      deposit: project.financingModel?.deposit,
      modules: project.modules?.map((m) => {
        return {
          type: m?.type,
          value: m?.value
        } as Pick<Module, 'type' | 'value'>;
      }),
      solarPotential: project.building?.solarPotential,
      altitude: project.building?.location?.altitude,
      sustainability: project.building?.sustainability
    };

    if (
      OfferStatus.PENDING === currentOffer?.status ||
      OfferStatus.EXPIRED === currentOffer?.status
    ) {
      inputs = {
        ...inputs,
        type: FinancialOverviewType.INDICATIVE,
        performance: project.building?.performance
      } as FinancialOverviewInputs;
    } else {
      inputs = {
        ...inputs,
        type: FinancialOverviewType.FINAL,
        offerPrice: currentOffer?.offeredPrice
      } as FinancialOverviewInputs;
    }
    getFinancialOverview(inputs).then((data) => {
      const feedIn = data?.feedInTariff || project.building?.feedInTariff || '0';
      const performance = project.building?.performance ?? '';
      setOverview({ ...data, feedInTariff: feedIn, performance } as ProjectFinancialOverview);
    });
    setLoading(false);
  };

  const changeCurrentOffer = (index: number) => {
    recivedOffers && setCurrentOffer(recivedOffers[index]);
  };

  const getPreselectedTab = () => {
    if (recivedOffers) {
      const allPartnersId = recivedOffers.map((elem: Offer) => elem.id);
      const defaultTabIndex = allPartnersId.indexOf(ulrHash);
      return defaultTabIndex !== -1 ? defaultTabIndex : 0;
    }
    return 0;
  };

  const changeDeposit = (newDeposit: number) => {
    setProject((prevState) => ({
      ...prevState,
      financingModel: { ...prevState?.financingModel, deposit: newDeposit }
    }));
  };

  const changeDuration = (newDuration: number) => {
    setProject((prevState) => ({
      ...prevState,
      financingModel: { ...prevState?.financingModel, duration: newDuration }
    }));
  };

  const startFinancing = async (plan: FinancingModel, offer: Offer) => {
    const response =
      project?.projectUuid && (await requestLoan(project.projectUuid, offer.id, plan));

    if (response) {
      toastUtil('success', `${t('offers.loanRequested.success')}`);
      setPlanDisabled(true);
      if (process.env.REACT_APP_FEATURE_FLAG_QUESTIONNAIRE_KYC_KKG?.toString() === 'show') {
        goTo(`/questionnaire/${project?.projectUuid}`);
      } else {
        goTo('/my-profile/projects');
      }
    }
  };

  // const openProjectDeletion = () => {
  //   setModalTitle(OffersModalType.DELETE_PROJECT);
  //   toggle();
  // };

  // const renderModalContent = () => {
  //   switch (modalTitle) {
  //     case OffersModalType.DELETE_PROJECT:
  //       return <DeleteProject closeModal={toggle} projectId={project?.projectUuid} />;
  //     default:
  //       return <></>;
  //   }
  // };

  return loading ? (
    <Loader />
  ) : (
    <div className="offers-wrapper">
      <div className="heading-wrapper">
        <Text as="h2" category="display" size="medium" className="heading">
          {t('offers.heading')}
        </Text>
        {/* {role?.includes(UserRoles.ADMIN) && (
          <Button variation="primary" size="large" dataTest="" onClick={openProjectDeletion}>
            {t('deleteProject.heading')}
          </Button>
        )} */}
      </div>
      {recivedOffers && (
        <Tabs
          preSelectedTabIndex={getPreselectedTab()}
          hide={recivedOffers.length === 1}
          onTabChange={changeCurrentOffer}>
          {recivedOffers.map((offer: PartnerOffer, index: number) => {
            return (
              <TabContent key={index} title={`${t('offers.tab.heading')}: ${offer.partner.name}`}>
                <Snackbar
                  type={offer.status === OfferStatus.PENDING ? 'info' : 'success'}
                  heading={t(`offers.snackbar.heading.${offer.status}`)}
                  text={t(`offers.snackbar.text.${offer.status}`)}
                  closeable
                />
                <div className="offer-tab-wrapper">
                  <div className="map-wrapper">
                    <Text as="h3" category="headline" size="large">
                      {t('offers.image.heading')}
                    </Text>
                    {project?.building?.location?.coordinates?.lat &&
                      project?.building?.location?.coordinates?.lng && (
                        <Map
                          hideControls
                          className={'map-container'}
                          initialCordinates={{
                            lat: +project?.building?.location?.coordinates?.lat,
                            lng: +project?.building?.location?.coordinates?.lng
                          }}
                        />
                      )}
                  </div>
                  <div className="partners-offer-wrapper">
                    <OfferDetails project={project} offer={offer} />
                    <main>
                      {offer.status !== OfferStatus.PENDING && (
                        <FinancialOfferPreview
                          className="offer-financial-preview"
                          overview={overview}
                          modules={project?.modules as Module[]}
                          status={offer.status === OfferStatus.EXPIRED ? 'indicative' : 'final'}
                          showModules
                        />
                      )}
                      {(offer.status === OfferStatus.CREATED ||
                        offer.status === OfferStatus.ACCEPTED) && (
                        <FinancingPlan
                          showRate
                          disable={
                            planDisabled ||
                            project?.status === ProjectStatus.CHECK_OPEN ||
                            role?.includes(UserRoles.ADMIN) ||
                            role?.includes(UserRoles.PARTNER)
                          }
                          baseFee={overview?.fee}
                          interestRate={overview?.effectiveAnnualInterestRate}
                          amount={offer.offeredPrice}
                          deposit={project?.financingModel?.deposit}
                          duration={project?.financingModel?.duration}
                          modules={project?.modules as Module[]}
                          setDeposit={changeDeposit}
                          setDuration={changeDuration}
                          status="final"
                          onDepositChange={getOverview}
                          showProhibitText
                          onSubmit={(plan) => {
                            auth?.isAuthenticated
                              ? startFinancing(plan, offer)
                              : auth?.keycloak.login({ redirectUri: window.location.href });
                          }}
                        />
                      )}
                    </main>
                  </div>
                </div>
              </TabContent>
            );
          })}
        </Tabs>
      )}
      {/* <Modal isOpened={isOpened} close={toggle} title={t(`offers.modal.${modalTitle}`)}>
        {renderModalContent()}
      </Modal> */}
    </div>
  );
};

export default Offers;
