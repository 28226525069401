import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import TableSortable from '../../../components/Tables/TableSortable/TableSortable';
import { PagableResponse, Sort, SortBy } from '../../../types/util.types';
import { useEffect, useState } from 'react';
import Search from '../../../components/Inputs/Search/Search';
import './PartnersOverview.scss';
import Filters from '../../../components/Filters/Filters';
import {
  PartnerType,
  PartnerProduct,
  GetPartnersFilter,
  Partner
} from '../../../store/partners/partners.types';
import { getAllPartners } from '../../../store/partners/partners.action';
import Pagination from '../../../components/Pagination/Pagination';
import { ModuleType } from '../../../types/financialOffer.types';
import useNavigation from '../../../hooks/useNavigation';

enum PartnersOverviewTableHeading {
  PARTNER_NAME = 'name',
  NAME = 'contactFirstName',
  PARTNER_TYPE = 'partnerType',
  LEVEL = 'level',
  PRODUCTS = 'partnerProducts'
}

const PartnersOverview = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const initialSort: Sort[] = Object.values(PartnersOverviewTableHeading).map((elem) => ({
    name: elem,
    sort: elem === PartnersOverviewTableHeading.PARTNER_NAME ? SortBy.ASCENDING : undefined
  }));
  const initialFilters: GetPartnersFilter = {
    keyword: '',
    page: 1,
    size: 6,
    verified: true,
    partnerType: undefined,
    moduleType: undefined,
    sort: initialSort
  };

  const partnerProducts = Object.values(ModuleType).filter(
    (elem) => elem !== ModuleType.INVESTERRA_ELECTRICITY_SALES
  );
  const partnersType = Object.values(PartnerType).map((elem) => elem);

  const [partners, setPartners] = useState<PagableResponse<Partner> | undefined>();
  const [filter, setFilter] = useState(initialFilters);

  useEffect(() => {
    fetchAllPartners();
  }, [filter]);

  const fetchAllPartners = async () => {
    const data = await getAllPartners(filter);
    data && setPartners(data);
  };

  return (
    <div className="partners-overview-wrapper">
      <Text as="h2" category="display">
        {t('partnersOverview.heading')}
      </Text>
      <div className="partners-overview-filter-wrapper">
        <Search
          placeholder={t('partnersOverview.searchText')}
          setValue={(e: string) =>
            setFilter((prevFilter) => ({ ...prevFilter, keyword: e, page: 1 }))
          }
          value={filter.keyword}
        />
        <Filters
          placeholder={t('partnersOverview.partnerType')}
          selectedFilter={filter.partnerType}
          filters={partnersType}
          translationKey="partnerType."
          onFilterChange={(value) => {
            setFilter((prevFilter) => ({
              ...prevFilter,
              partnerType: value === 'all' ? undefined : (value as PartnerType)
            }));
          }}
        />
        <Filters
          selectedFilter={filter.moduleType}
          filters={partnerProducts}
          translationKey="partnerProducts."
          placeholder={t('products')}
          onFilterChange={(value) => {
            setFilter((prevFilter) => ({
              ...prevFilter,
              moduleType: value === 'all' ? undefined : (value as ModuleType)
            }));
          }}
        />
      </div>
      {partners?.content && !!partners.content.length && filter.sort && (
        <TableSortable
          values={filter.sort}
          columnWidths={[18, 20, 15, 15, 32]}
          onSortChange={(val: Sort[]) => {
            setFilter((prevFilter) => ({ ...prevFilter, sort: val }));
          }}
          hiddenSortColumns={[3, 4, 5]}
          translationKey="partnersOverview.table.heading."
          sortable
          hoverEffect>
          {partners.content.map((elem: Partner, ind) => (
            <tr
              key={ind}
              onClick={() => {
                goTo(`/admin/partners/${elem.id}`);
              }}>
              <Text as="td" category="label" size="large">
                {elem.name}
              </Text>
              <Text as="td" category="label" size="large">
                {`${elem.contactFirstName} ${elem.contactLastName}`}
              </Text>
              <Text as="td" category="label" size="large">
                {t(`partnerType.${elem.type}`)}
              </Text>
              <Text as="td" category="label" size="large">
                {t(`partnerLevel.${elem.partnerLevel}`)}
              </Text>
              <Text as="td" category="label" size="large">
                {`${elem?.modules
                  ?.map((elem: PartnerProduct) => t(`partnerProducts.${elem.type}`))
                  .join(', ')} `}
              </Text>
            </tr>
          ))}
        </TableSortable>
      )}
      {!!partners?.content.length && (
        <Pagination
          page={filter.page}
          totalPages={partners?.totalPages as number}
          setPage={(newPage) =>
            setFilter((prevFilter) => ({
              ...prevFilter,
              page: newPage
            }))
          }
        />
      )}
    </div>
  );
};

export default PartnersOverview;
