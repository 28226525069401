import { useEffect } from 'react';
import { useModal } from '../../hooks/useModal';
import Modal from '../Modal/Modal';
import './Loader.scss';
import { useTranslation } from 'react-i18next';

const Loader = () => {
  const { t } = useTranslation();

  const { toggle } = useModal();

  useEffect(() => {
    toggle();
  }, []);

  return (
    <Modal isOpened={true} close={toggle} title={t('loader.heading')}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Modal>
  );
};

export default Loader;
