import { useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './UploadOfferEdit.scss';
import Text from '../../components/Text/Text';
import { useTranslation } from 'react-i18next';
import Checklist from '../../components/Checklist/Checklist';
import { FinancingModel, Module, investerraModule } from '../../types/financialOffer.types';
import FinancialOfferPreview from '../../components/FinancialOfferPreview/FinancialOfferPreview';
import uploadedOfferCover from '../../assets/Images/uploaded-offer-cover.png';
import FinancingPlan from '../../components/FinancingPlan/FinancingPlan';
import { projectReducer } from '../../store/project/project.reducer';
import EditProject from '../../components/EditProject/EditProject';
import {
  createScannedProject,
  getFinancialOverview,
  getFinancialOverviewPlan
} from '../../store/project/project.action';
import {
  FinancialOverview,
  FinancialOverviewType,
  Project,
  ProjectFinancialOverview
} from '../../store/project/project.types';
import useDebounce from '../../hooks/useDebounce';
import { MAX_LOAN_DURATION_IN_YEARS, MIN_DOWN_PAYMENT } from '../../utils/loan.utils';
import EditPartner from './EditPartner/EditPartner';
import { PartnerCompany } from '../../store/partners/partners.types';
import { emailValidation } from '../../utils/validations.utils';
import { AuthContext } from '../../context/AuthContextProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import useNavigation from '../../hooks/useNavigation';
import { toastUtil } from '../../utils/toast.utils';
import Loader from '../../components/Loader/Loader';
import { User } from '../../store/user/types';

export type InputError = {
  [key: string]: boolean;
};
const UploadOfferEdit = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const { goTo } = useNavigation();

  const auth = useContext(AuthContext);

  const scannedProjectSession = localStorage.getItem('scannedProject');
  const overviewSession = localStorage.getItem('overview');
  const partnerSession = localStorage.getItem('partner');
  const documentUrl = localStorage.getItem('documentUrl');

  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [disableFinanancePlan, setDisabledFinancingPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState<FinancialOverview | undefined>(undefined);

  const [project, dispatch] = useReducer(projectReducer, {
    owner: {
      name: state?.owner?.name
    },
    building: state?.project?.building,
    modules: [],
    // TODO SAKBX475-451: Remove when possible
    financingModel: {
      duration: MAX_LOAN_DURATION_IN_YEARS,
      deposit: MIN_DOWN_PAYMENT
    },
    offer: {
      area: state?.offer?.area,
      offeredPrice: state?.offer?.offeredPrice,
      subsidy: state?.offer.subsidy,
      issuanceDate: state?.offer?.issuanceDate,
      validThru: state?.offer?.validThru,
      deliveryDate: state?.offer?.deliveryDate,
      contactPerson: state?.offer?.contactPerson
    }
  });

  const [partner, setPartner] = useState<PartnerCompany>({} as PartnerCompany);
  const [valueMissing, setValueMissing] = useState<InputError>();

  const priceDebounced = useDebounce(project.offer?.offeredPrice, 500);

  const inputError = (): InputError => {
    const err: InputError = {};

    if (!project.owner?.name) err.name = true;
    if (!project.offer?.offeredPrice) err.offeredPrice = true;
    if (!project.building?.address) err.address = true;
    if (!project.offer?.issuanceDate) err.issuanceDate = true;
    if (!project.offer?.validThru) err.validThru = true;
    if (!project.offer?.performance) err.performance = true;
    if (!partner?.partnerLocations) err.partnerAddress = true;
    if (!partner?.contactFirstName || !partner?.contactLastName) err.partnerName = true;
    if (!partner?.name) err.companyName = true;
    if (!partner?.partnerLocations) err.partnerAddress = true;
    if (emailValidation(partner?.contactEmail)) err.partnerEmail = true;

    return err;
  };

  useEffect(() => {
    getOverview();
  }, [priceDebounced, project.building?.sepId]);

  useEffect(() => {
    if (auth?.isAuthenticated && scannedProjectSession && overviewSession && partnerSession) {
      const scaned = JSON.parse(scannedProjectSession as string);
      const overview = JSON.parse(overviewSession as string);
      const partner = JSON.parse(partnerSession as string);
      if (user.uid) {
        initiateProjectAndRequestLoanWithAuthentication(
          scaned as Project,
          overview as FinancialOverview,
          partner as PartnerCompany
        );
      }
    }

    if (user.firstName && user.lastName) {
      dispatch({ type: 'CHANGE_OWNER_NAME', payload: `${user.firstName} ${user.lastName}` });
    }

    return () => {
      const location = window.location.href;
      if (
        !location.includes(process.env.REACT_APP_KEYCLOAK_URL?.toString() as string) &&
        !location.includes('upload-offer')
      ) {
        clearLocalStorage();
      }
    };
  }, [auth?.isAuthenticated, user.uid]);

  const getOverview = () => {
    if (project && project.offer?.offeredPrice) {
      const inputs = {
        type: FinancialOverviewType.FINAL,
        offerPrice: project.offer?.offeredPrice,
        deposit: project.financingModel?.deposit,
        subsidy: project.offer?.subsidy,
        modules: project.modules?.map((m) => {
          return {
            type: m.type,
            value: m.value
          } as Pick<Module, 'type' | 'value'>;
        }),
        solarPotential: project.building?.solarPotential,
        sustainability: project.building?.sustainability
      };
      getFinancialOverview(inputs).then((resp) => {
        const feedIn = resp?.feedInTariff || project.building?.feedInTariff || '0';
        const performance = project.building?.performance ?? '';
        setOverview({ ...resp, feedInTariff: feedIn, performance } as ProjectFinancialOverview);
      });
    }
  };

  const changeDeposit = (newDeposit: number) => {
    dispatch({ type: 'CHANGE_DEPOSIT', payload: newDeposit });
  };

  const changeDuration = (newDuration: number) => {
    dispatch({ type: 'CHANGE_DURATION', payload: newDuration });
  };

  const checkModule = (module: Module) => {
    project.modules?.find((m) => m.id === module.id)
      ? dispatch({ type: 'REMOVE_MODULE', payload: module })
      : dispatch({ type: 'ADD_MODULE', payload: module });
  };

  const initiateProjectAndRequestLoanWithAuthentication = async (
    project: Project,
    overview: FinancialOverview,
    partner: PartnerCompany
  ) => {
    setLoading(true);
    getFinancialOverviewPlan({
      type: FinancialOverviewType.FINAL,
      offerPrice: project?.offer?.offeredPrice,
      duration: project.financingModel?.duration as number,
      deposit: project.financingModel?.deposit as number,
      modules: project.modules?.map((m) => {
        return {
          type: m.type,
          value: m.value
        } as Pick<Module, 'type' | 'value'>;
      })
    }).then((data) => {
      if (data) {
        initiateProjectAndRequestLoan(
          {
            duration: project.financingModel?.duration as number,
            deposit: project.financingModel?.deposit as number,
            baseFee: overview?.fee || 0,
            interestRate: overview?.effectiveAnnualInterestRate,
            monthlyRate: data.rate
          },
          project,
          partner
        );
      }
    });
  };

  const initiateProjectAndRequestLoan = async (
    plan: FinancingModel,
    project: Project,
    partner: PartnerCompany
  ) => {
    if (!loading) setLoading(true);

    const modifiedProject = {
      ...project,
      financingModel: plan,
      documentUrl: state?.documentUrl || JSON.parse(documentUrl as string),
      loanAmount: overview?.loan || JSON.parse(overviewSession as string)?.loan,
      loanTerms: (project.financingModel?.duration as number) * 12,
      partner
    };
    const response = await createScannedProject(modifiedProject as Project, user.uid);
    !response && setDisabledFinancingPlan(false);
    if (response) {
      clearLocalStorage();
      setDisabledFinancingPlan(true);
      goTo(`/questionnaire/${response?.projectDto.projectUuid}`, {
        replace: true
      });
    } else {
      setDisabledFinancingPlan(false);
    }
    setLoading(false);
  };

  const onSubmit = (plan: FinancingModel) => {
    if (Object.values(inputError()).some((value) => value === true)) {
      setValueMissing(inputError());
      toastUtil('error', t('uploadOfferEdit.allFieldsRequired'));
    } else if (!auth?.isAuthenticated) {
      localStorage.setItem('scannedProject', JSON.stringify(project));
      localStorage.setItem('overview', JSON.stringify(overview));
      localStorage.setItem('partner', JSON.stringify(partner));
      auth?.keycloak.login({ redirectUri: window.location.href });
    } else {
      initiateProjectAndRequestLoan(plan, project as Project, partner);
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('scannedProject');
    localStorage.removeItem('overview');
    localStorage.removeItem('partner');
    localStorage.removeItem('documentUrl');
  };

  return (
    <div className="upload-offer-edit">
      <Text as="h3" category="display" size="medium" className="uploaded-result-headline">
        {t('offerDetails')}
      </Text>
      {/* <Snackbar
        type="info"
        heading={t('uploadOfferEdit.maintenance')}
        text={t('uploadOfferEdit.maintenanceText')}
        closeable
      /> */}
      <div className="main">
        <div className="uploaded-result">
          <Text as="h3" category="headline" size="large">
            {t('uploadOfferEdit.projectDetails')}
          </Text>
          <img className="cover" src={uploadedOfferCover} />
          <Text as={'label'} category={'label'}>
            {t('uploadOfferEdit.allFieldsRequired')}
          </Text>
          <div className="uploaded-offer-details">
            <EditProject project={project} dispatch={dispatch} valueMissing={valueMissing} />
          </div>
          <div>
            <EditPartner partner={partner} setPartner={setPartner} valueMissing={valueMissing} />
          </div>
          <div>
            <Text as="h3" category="headline" size="large">
              {t('module.labelPowerFeed')}
            </Text>
            <Text size="large">{t('module.selectPowerFeedModule')}</Text>
            <Checklist
              items={[investerraModule].map((m) => {
                return { ...m, label: t(`${m.label}`) };
              })}
              checked={project.modules as Module[]}
              onCheck={checkModule}
              isPrice
            />
          </div>
        </div>
        <div className="financial-overview">
          <FinancialOfferPreview
            overview={overview}
            modules={project.modules as Module[]}
            status="final"
          />
          <FinancingPlan
            showRate={!!project.offer?.offeredPrice}
            amount={project.offer?.offeredPrice || 0}
            interestRate={overview?.effectiveAnnualInterestRate}
            baseFee={overview?.fee}
            deposit={project.financingModel?.deposit}
            duration={project.financingModel?.duration}
            setDeposit={changeDeposit}
            setDuration={changeDuration}
            disable={disableFinanancePlan}
            status="final"
            onSubmit={onSubmit}
            onDepositChange={getOverview}
            showProhibitText
          />
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default UploadOfferEdit;
