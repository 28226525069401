import { useTranslation } from 'react-i18next';
import Text from '../../../../components/Text/Text';
import '../../../../components/PersonalDataForm/PersonalDataForm.scss';
import './SecurityInfo.scss';
import Switch from '../../../../components/Inputs/Switch/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../../context/AuthContextProvider';
import Button from '../../../../components/Button/Button';
import { setTwoFactorAuth } from '../../../../store/user/actions';
import {
  checkTwoFactorCredentials,
  deleteTwoFactor
} from '../../../../store/profile/profile.actions';
import { RootState } from '../../../../reducers/rootReducer';
import { User } from '../../../../store/user/types';

const SecurityInfo = () => {
  const { t } = useTranslation();

  const { twoFactorAuth } = useSelector<RootState>((state) => state.userReducer) as User;
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    twoFactorDetails();
  }, []);

  const twoFactorDetails = async () => {
    const response = await checkTwoFactorCredentials();
    dispatch(setTwoFactorAuth(response));
  };

  const handleCheck = async () => {
    if (twoFactorAuth) {
      const status = await deleteTwoFactor();
      dispatch(setTwoFactorAuth(!status));
    } else {
      auth?.keycloak.login({ action: 'CONFIGURE_TOTP' });
    }
  };

  return (
    <div className="security-info-wrapper">
      <div className="two-factor-auth-wrapper">
        <Text as="h2" category="headline" size="large">
          {t('myProfile.twoFactorAuth.title')}
        </Text>
        <Switch
          id={1}
          checked={twoFactorAuth}
          label={t('myProfile.twoFactorAuth.label')}
          onCheck={handleCheck}
          className="two-factor-auth"
        />
        <Text>{t('myProfile.twoFactorAuth.description')}</Text>
      </div>
      <div className="change-pass">
        <Text as="h2" category="headline" size="large">
          {t('myProfile.twoFactorAuth.changePasswordHeading')}
        </Text>
        <Text as="p" category="body" size="medium">
          {t('myProfile.twoFactorAuth.changePasswordText')}
        </Text>
        <Button
          variation="primary"
          size="large"
          dataTest=""
          onClick={() => {
            auth?.keycloak.login({ action: 'UPDATE_PASSWORD' });
          }}>
          {t('myProfile.changePassword.title')}
        </Button>
      </div>
    </div>
  );
};

export default SecurityInfo;
