import { useEffect, useRef, useState } from 'react';
import './FinancingPlan.scss';
import { useTranslation } from 'react-i18next';
import RangeSlider from '../Inputs/RangeSlider/RangeSlider';
import Text from '../Text/Text';
import Button from '../Button/Button';
import { toSwissFrancNotation } from '../../utils/formatting.utils';
import Price from '../Price/Price';
import { FinancingModel, Module } from '../../types/financialOffer.types';
import TooltipHeader from '../TooltipHeader/TooltipHeader';
import { getFinancialOverviewPlan } from '../../store/project/project.action';
import {
  FinancialOverviewPlan,
  FinancialOverviewPlanInputs,
  FinancialOverviewType
} from '../../store/project/project.types';
import { emptyStringIfNull } from '../../utils/general.utils';
import Icon from '../Icon/Icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import Tooltip from '../Tooltip/Tooltip';

interface FinancingPlanProps {
  amount: number;
  baseFee?: number;
  interestRate?: number;
  status: 'indicative' | 'final';
  deposit?: number;
  duration?: number;
  disable?: boolean;
  modules?: Module[];
  subsidy?: number;
  performance?: number;
  showRate?: boolean;
  initial?: FinancialOverviewPlan;
  showProhibitText?: boolean;
  setDeposit?: (deposit: number) => void;
  setDuration?: (duration: number) => void;
  onSubmit?: (plan: FinancingModel) => void;
  onDepositChange?: () => void;
}

const FinancingPlan = ({
  amount,
  baseFee = 0,
  deposit = 0,
  duration = 0,
  interestRate,
  modules = [],
  showRate = false,
  disable = false,
  subsidy,
  performance,
  status,
  initial,
  showProhibitText = false,
  setDeposit = () => '',
  setDuration = () => '',
  onSubmit = () => '',
  onDepositChange = () => ''
}: FinancingPlanProps) => {
  const { t } = useTranslation();

  const [plan, setPlan] = useState<FinancialOverviewPlan | undefined>(initial);

  const [editDeposit, setEditDeposit] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const outsideClickRef = useOutsideClick(() => {
    setEditDeposit(false);
  });

  useEffect(() => {
    !initial && updateMonthlyRate();
  }, []);

  useEffect(() => {
    if (disable && !initial) updateMonthlyRate();
  }, [deposit, duration]);

  useEffect(() => {
    setDuration(duration || (plan?.maxDuration as number));
  }, [plan?.maxDuration]);

  useEffect(() => {
    if (!disable) updateMonthlyRate();
  }, [modules?.length, amount, disable]);

  useEffect(() => {
    editDeposit && inputRef.current?.focus();
  }, [editDeposit]);

  const updateMonthlyRate = async () => {
    let inputs = {
      deposit,
      duration,
      modules: modules?.map((m) => {
        return {
          type: m.type,
          value: m.value
        } as Pick<Module, 'type' | 'value'>;
      })
    };

    if (status === 'indicative') {
      inputs = {
        ...inputs,
        type: FinancialOverviewType.INDICATIVE,
        performance,
        subsidy
      } as FinancialOverviewPlanInputs;
    } else {
      inputs = {
        ...inputs,
        type: FinancialOverviewType.FINAL,
        offerPrice: amount
      } as FinancialOverviewPlanInputs;
    }

    const data = await getFinancialOverviewPlan(inputs);
    setPlan(data);
  };

  const durationChange = () => {
    if (!disable) updateMonthlyRate();
  };

  const depositChange = () => {
    if (!disable) {
      onDepositChange();
      updateMonthlyRate();
    }
  };

  const editDepositHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, min, max } = e.target;
    const maxValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    if (!isNaN(maxValue)) {
      setDeposit(+maxValue.toFixed(2));
    }
  };

  return (
    <div className="financing-plan-wrapper">
      <TooltipHeader
        heading={t('solarSystem.financingPlan.label')}
        tooltip={{
          text: t('solarSystem.financingPlan.tooltip'),
          heading: t('solarSystem.financingPlan.label')
        }}
      />
      <div className="financing-plan">
        <div className="financing-plan-item">
          <div className="financing-label">
            <Text as="span" category="label" size="large">
              {t('solarSystem.financingPlan.duration')}
            </Text>
            <Text as="label" category="headline" size="medium">
              {duration} {t('years')}
            </Text>
          </div>
          <RangeSlider
            min={plan?.minDuration || 0}
            max={plan?.maxDuration || 0}
            value={duration}
            setValue={setDuration}
            disabled={disable}
            onSlidingDone={durationChange}
          />
        </div>
        <div className="financing-plan-item">
          <div className="financing-label">
            <Text as="span" category="label" size="large">
              {t('solarSystem.financingPlan.deposit')}
            </Text>
            <div className="deposit-input" ref={outsideClickRef}>
              {!editDeposit ? (
                <Text as="label" category="headline" size="medium">
                  {toSwissFrancNotation(deposit)}
                </Text>
              ) : (
                <input
                  ref={inputRef}
                  type={'number '}
                  min={plan?.minDeposit || 0}
                  max={plan?.maxDeposit || 0}
                  value={deposit}
                  onChange={editDepositHandler}
                />
              )}
              <Icon
                className={`edit-deposit ${disable ? 'disabled' : ''}`}
                name="edit"
                width={24}
                onClick={() => {
                  setEditDeposit(true);
                }}
              />
            </div>
          </div>
          <RangeSlider
            min={plan?.minDeposit || 0}
            max={plan?.maxDeposit || 0}
            step={100}
            value={+emptyStringIfNull(deposit)}
            setValue={setDeposit}
            disabled={disable}
            onSlidingDone={depositChange}
          />
        </div>
        <div className="monthly-rate">
          <Text as="span" category="label" size="large">
            {status === 'final'
              ? t('offers.finalMonthlyRate')
              : t('solarSystem.financingPlan.indicativePrice')}
            <Tooltip
              text={
                status === 'final'
                  ? t('offers.tooltipFinalMonthlyRate')
                  : t('solarSystem.financingPlan.tooltipIndicativePrice')
              }
              className={'monthly-rate-tooltip'}
            />
          </Text>
          {showRate && (
            <Price as="label" category="headline" size="large" className="monthly-rate-ammount">
              {emptyStringIfNull(plan?.rate)}
            </Price>
          )}
        </div>
        {!disable && (
          <Button
            variation="primary"
            size="large"
            onClick={() => {
              onSubmit({
                duration,
                deposit,
                monthlyRate: plan?.rate as number,
                baseFee,
                interestRate
              });
            }}
            disabled={disable}
            dataTest="request_offer-partners-button">
            {status === 'final' ? t('startFinancing') : t('getQuote')}
          </Button>
        )}
        {showProhibitText && (
          <Text as="span" category="label" size="large">
            {t('solarSystem.financingPlan.prohibitLending')}
          </Text>
        )}
      </div>
    </div>
  );
};

export default FinancingPlan;
