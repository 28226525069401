import Text from '../Text/Text';
import './StatusField.scss';

interface StatusProps {
  status: string;
  style: string;
  label: string;
  variation?: 'primary' | 'secondary';
}

const StatusField = ({ status, variation = 'primary', style, label }: StatusProps) => {
  const getClassName = () => `circle status-${style} ${variation === 'secondary' ? 'hide' : ''}`;

  return (
    <div className="status-field-wrapper">
      <Text as="h3" category="label" size="large">
        {label}
      </Text>

      {status && (
        <>
          <span className={getClassName()} />
          <Text
            as="span"
            category="label"
            size="large"
            className={`status ${style?.toLowerCase()}`}>
            {status}
          </Text>
        </>
      )}
    </div>
  );
};

export default StatusField;
