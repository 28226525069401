import { ProjectLog } from '../../store/project/project.types';
import LogItem from './LogItem/LogItem';

import './ProjectLogs.scss';

interface ProjectLogProps {
  logs: ProjectLog[];
  onClick: (logIndex: number) => void;
}

const ProjectLogs = ({ logs, onClick }: ProjectLogProps) => {
  return (
    <>
      <div className="project-log-wrapper">
        {logs &&
          [...logs]?.map((log, index) => (
            <LogItem
              key={index}
              icon="person"
              log={log}
              onClick={() => {
                onClick(index);
              }}
            />
          ))}
      </div>
    </>
  );
};

export default ProjectLogs;
