import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import './TextInput.scss';
import Text from '../../Text/Text';
import Icon from '../../Icon/Icon';
import { useTranslation } from 'react-i18next';
import { emptyStringIfNull } from '../../../utils/general.utils';

export interface TextInputProps {
  name: string;
  type: string;
  placeholder?: string;
  value?: string | number;
  disabled?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (evt: FocusEvent<HTMLInputElement>) => void;
  validate?: (value: string) => string;
  error?: string;
  dataTest?: string;
  required?: boolean;
  unit?: string;
  maxLenght?: number;
  max?: number;
  min?: number;
  className?: string;
}

const TextInput = ({
  value,
  name,
  placeholder,
  type,
  disabled,
  onChange,
  onBlur,
  validate = () => '',
  error,
  dataTest,
  required,
  unit = '',
  maxLenght = undefined,
  max,
  className = ''
}: TextInputProps) => {
  const { t } = useTranslation();

  const [visibility, setVisibility] = useState(false);
  const [validation, setValidation] = useState<string | undefined>(error);

  const getClassName = () =>
    `input-container ${disabled ? 'disabled' : ''} ${
      error || validation ? 'error' : ''
    } ${className}`;

  const getMaxAttribute = () => (type === 'date' ? '9999-12-31' : max);

  useEffect(() => {
    if (error) {
      setValidation(error);
      return;
    }
  }, [value, error]);

  return (
    <div className={getClassName()}>
      <input
        className="input"
        placeholder=" "
        id={name}
        name={name}
        value={emptyStringIfNull(value)}
        maxLength={maxLenght}
        type={visibility ? 'text' : type}
        max={getMaxAttribute()}
        onChange={onChange}
        onBlur={(evt: FocusEvent<HTMLInputElement>) => {
          validate && setValidation(validate(value as string));
          onBlur && onBlur(evt);
        }}
        disabled={disabled}
        data-test={dataTest}
        onWheel={(ev: React.MouseEvent<HTMLElement>) => ev.currentTarget.blur()}
      />
      {unit && (
        <Text as="p" category="label" size="large" className="unit">
          {unit}
        </Text>
      )}
      {type !== 'password' ? null : visibility ? (
        <Icon
          name="visibility-of"
          onClick={() => {
            setVisibility(false);
          }}
        />
      ) : (
        <Icon
          name="visibility-on"
          onClick={() => {
            setVisibility(true);
          }}
        />
      )}
      <Text as="label" category="label" size="large" variety={1} className="placeholder">
        {placeholder}
        {required ? '*' : ''}
      </Text>
      {validation && (
        <Text as="p" category="body" size="small" className="error-text">
          {t(validation)}
        </Text>
      )}
    </div>
  );
};

export default TextInput;
