import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import { useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination/Pagination';
import './PartnerProjectsOverview.scss';
import { GetOffers, OfferListItem, OfferStatus } from '../../../store/offers/offers.types';
import { getAllOffers } from '../../../store/offers/offers.action';
import { parseName } from '../../../utils/general.utils';
import {
  formatAddress,
  formatDateWithDot,
  toSwissFrancNotation
} from '../../../utils/formatting.utils';
import Search from '../../../components/Inputs/Search/Search';
import Filters from '../../../components/Filters/Filters';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import ResetFilter from '../../../components/ResetFilter/ResetFilter';
import PriceRange from '../../../components/PriceRange/PriceRange';
import { Sort, SortBy } from '../../../types/util.types';
import TableSortable from '../../../components/Tables/TableSortable/TableSortable';
import { User } from '../../../store/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import useNavigation from '../../../hooks/useNavigation';

enum PartnerProjectsTableHeading {
  PROJECT_ID = 'id',
  NAME = 'project.owner.firstName',
  PROJECT_ADDRESS = 'project.building.address.street',
  DATE = 'sentTimestamp',
  OBJECT_PRICE = 'offeredPrice',
  STATUS = 'status'
}

const PartnerProjectsOverview = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const offerStatuses = Object.values(OfferStatus).map((elem: string) => elem);

  const priceRangeValues = [
    { priceFrom: 0, priceTo: 20000 },
    { priceFrom: 20000, priceTo: 50000 },
    { priceFrom: 100000, priceTo: 150000 },
    { priceFrom: 150000, priceTo: 500000 }
  ];

  const initialSort = Object.values(PartnerProjectsTableHeading).map((elem: string) => {
    if (elem === PartnerProjectsTableHeading.PROJECT_ID) {
      return { name: elem, sort: SortBy.ASCENDING };
    }
    return { name: elem, sort: undefined };
  });

  const [partnerProjects, setPartnerProjects] = useState<OfferListItem[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const [totalPages, setTotalPages] = useState<number>(0);

  const initialFilters = {
    keyword: '',
    page: 1,
    status: undefined,
    dateRange: { dateFrom: undefined, dateTo: undefined },
    size: 6,
    sort: initialSort,
    priceFrom: undefined,
    priceTo: undefined
  };
  const [filters, setFilters] = useState<GetOffers>(initialFilters);

  useEffect(() => {
    const fetchOffers = async () => {
      if (user.uid) {
        const res = await getAllOffers(user.uid, {
          keyword: filters.keyword,
          status: filters.status,
          page: filters.page - 1,
          size: filters.size,
          sort: filters.sort?.filter((e: Sort) => e.sort),
          priceFrom: filters.priceFrom,
          priceTo: filters.priceTo,
          dateRange: filters.dateRange
        });
        setPartnerProjects(res?.offers);
        setTotalPages(res?.totalPages);
        setLoading(false);
        (filters.keyword || filters.status) &&
          res.empty &&
          setFilters((prev) => {
            return { ...prev, page: 1 };
          });
      }
    };
    fetchOffers();
  }, [filters, user.uid]);

  const handleFilter = (filter: string | undefined) => {
    if (filter === 'all') {
      filter = undefined;
    }
    setFilters((prev) => {
      return { ...prev, status: filter as OfferStatus | undefined };
    });
  };

  return (
    <div className="partner-projects-overview-wrapper">
      <Text as="h2" category="display" size="medium">
        {t('partnerProjectsOverview.heading')}
      </Text>
      <div className="filters-wrapper">
        <Search
          value={filters.keyword as string}
          setValue={(e: string) => {
            setFilters((prev) => {
              return { ...prev, keyword: e };
            });
          }}
          placeholder={t('partnerProjectsOverview.searchByNameEmail')}
        />
        <TextInput
          value={filters.dateRange?.dateFrom}
          name="delivery-date"
          type="date"
          placeholder={t('startDate')}
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              dateRange: {
                ...prev.dateRange,
                dateFrom: e.target.value
              }
            }));
          }}
        />
        <TextInput
          value={filters.dateRange?.dateTo}
          name="delivery-date"
          type="date"
          placeholder={t('endDate')}
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              dateRange: {
                ...prev.dateRange,
                dateTo: e.target.value
              }
            }));
          }}
        />
        <PriceRange
          label={t('partnerProjectsOverview.creditPrice')}
          selectedRange={{ priceFrom: filters.priceFrom, priceTo: filters.priceTo }}
          values={priceRangeValues}
          onPriceRangeSelect={(selectedPrice) => {
            setFilters((prev) => ({
              ...prev,
              priceFrom: selectedPrice.priceFrom,
              priceTo: selectedPrice.priceTo
            }));
          }}
        />
        <Filters
          placeholder={t('status')}
          filters={offerStatuses}
          selectedFilter={filters.status}
          translationKey="offerStatus."
          onFilterChange={handleFilter}
        />
        <ResetFilter
          onClick={() => {
            setFilters(initialFilters);
          }}
        />
      </div>
      <TableSortable
        translationKey="partnerProjectsOverview.table.heading."
        onSortChange={(value) => {
          setFilters((prev) => ({
            ...prev,
            sort: value
          }));
        }}
        values={filters.sort}
        columnWidths={[10, 20, 25, 15, 18, 12]}
        hiddenSortColumns={[7]}
        sortable
        hoverEffect>
        <>
          {!loading &&
            partnerProjects?.map(({ id, project, sentTimestamp, offeredPrice, status }, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => {
                    if (
                      status === OfferStatus.ACCEPTED ||
                      status === OfferStatus.OFFER_IN_DELIVERY ||
                      status === OfferStatus.OFFER_COMPLETED
                    ) {
                      goTo(`/partners/project/${project.projectUuid}`);
                    } else {
                      goTo(`/partners/offer/${id}`);
                    }
                  }}>
                  <Text as="td" category="label" size="large">
                    {project.id}
                  </Text>
                  <Text as="td" category="label" size="large">
                    {parseName(project.owner.firstName, project.owner.lastName)}
                  </Text>
                  <Text as="td" category="label" size="large">
                    {formatAddress(project.building.address)}
                  </Text>
                  <Text as="td" category="label" size="large">
                    {formatDateWithDot(sentTimestamp)}
                  </Text>
                  <Text as="td" category="label" size="large">
                    {toSwissFrancNotation(offeredPrice || 0)}
                  </Text>
                  <Text
                    as="td"
                    category="label"
                    size="large"
                    className={`project-status ${status.toLowerCase()}`}>
                    {t(`offerStatus.${status}`)}
                  </Text>
                </tr>
              );
            })}
        </>
      </TableSortable>

      <Pagination
        page={filters.page}
        setPage={(page) => {
          setFilters((prev) => {
            return { ...prev, page: page };
          });
        }}
        totalPages={totalPages}
      />
    </div>
  );
};

export default PartnerProjectsOverview;
