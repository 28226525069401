import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MaritalStatusList from '../MaritalStatusList/MaritalStatusList';
import Text from '../../../components/Text/Text';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import { RootState } from '../../../reducers/rootReducer';
import { User } from '../../../store/user/types';
import {
  Country,
  QuestionnaireFormActionType,
  QuestionnaireFormProps,
  GenderType,
  MaritalStatus,
  QuestionnaireType,
  Address,
  CityAndCanton,
  PermitType
} from '../../../store/questionnaire/questionnaire.types';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { formatDate } from '../../../utils/formatting.utils';
import { getCityAndCanton } from '../../../utils/general.utils';
import AddressForm from '../../../components/AddressForm/AddressForm';
import PhoneTextInput from '../../../components/Inputs/PhoneTextInput/PhoneTextInput';
import { validateAtLeast18YearsOld } from '../../../utils/validations.utils';
import { permitTypeRequired } from '../../../utils/questionnaire.utils';
import Icon from '../../../components/Icon/Icon';
import { hubspotLinks } from '../../../utils/hubspotLinks';
import '../Questionnaire.scss';

const PersonalDetails = ({ onSave }: QuestionnaireFormProps) => {
  const { t, i18n } = useTranslation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const [save, setSave] = useState<boolean>(false);

  const nationality = questionnaire.kycData.nationality ?? '';
  const permitType = questionnaire.creditCheckData.applicant?.permitType ?? '';
  const maritalStatus = questionnaire.creditCheckData.applicant?.maritalStatus ?? '';
  const householdType = questionnaire.creditCheckData.household.householdType ?? '';

  useEffect(() => {
    if (save && onSave) {
      onSave({
        kycData: questionnaire.kycData,
        creditCheckData: questionnaire.creditCheckData
      });
      window.open(`${window.origin}/${i18n.language}/my-profile/personal-data`, '_blank');
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    const fetchCityAndCanton = async () => {
      if (questionnaire.kycData?.address?.postalCode?.length === 4) {
        const res = (await getCityAndCanton(
          questionnaire.kycData?.address?.postalCode
        )) as CityAndCanton;
        res &&
          dispatch({
            type: QuestionnaireFormActionType.CHANGE_CANTON_AND_CITY,
            payload: { ...res, country: Country.SWITZERLAND }
          });
      }
    };

    fetchCityAndCanton();
  }, [questionnaire.kycData?.address?.postalCode]);

  const maritalStatusHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_MARITAL_STATUS,
      payload: value as MaritalStatus
    });
    if (householdType && maritalStatus !== value) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_TYPE,
        payload: undefined
      });
    }
  };

  const changeNationalityHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_NATIONALITY,
      payload: value as Country
    });

    if (!permitTypeRequired(value as Country) && permitType) {
      dispatch({
        type: QuestionnaireFormActionType.CHANGE_PERMIT_TYPE,
        payload: undefined
      });
    }
  };

  return (
    <form className="credit-check-form">
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.personalDetails.name')}
        </Text>
        <TextInput
          name="userFirstName"
          type="text"
          value={user.firstName || ''}
          placeholder={t('name')}
          disabled
          required
        />
        <TextInput
          name="userLastName"
          type="text"
          value={user.lastName || ''}
          placeholder={t('lastName')}
          disabled
          required
        />
      </div>
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.personalDetails.data')}
        </Text>
        <Dropdown
          values={Object.values(GenderType).map((elem) => ({
            value: elem,
            displayValue: t(`gender.${elem}`)
          }))}
          initialValue={{
            value: questionnaire.kycData?.gender as GenderType,
            displayValue: t(`gender.${questionnaire.kycData?.gender}`)
          }}
          noValueText={t('creditCheck.personalDetails.selectGender')}
          getValue={(value) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_GENDER,
              payload: value as GenderType
            });
          }}
          required
        />
        <div className="credit-check-personal-details">
          <TextInput
            type="date"
            value={formatDate(questionnaire.kycData?.dateOfBirth)}
            name="dateOfBirth"
            placeholder={t('dateOfBirth')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_BIRTH_DATE,
                payload: new Date(e.target.value)
              });
            }}
            validate={validateAtLeast18YearsOld}
            required
          />
          <TextInput
            type="text"
            value={questionnaire.kycData?.placeOfBirth}
            name="placeOfBirth"
            placeholder={t('placeOfBirth')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_BIRTH_PLACE,
                payload: e.target.value
              });
            }}
            required
          />
        </div>
      </div>
      <div className="nationality-wrapper">
        <Text as="label" category="headline">
          {t('creditCheck.personalDetails.nationality')}
        </Text>
        <Dropdown
          values={Object.values(Country).map((elem) => ({
            value: elem,
            displayValue: t(`country.${elem}`)
          }))}
          initialValue={{
            value: nationality,
            displayValue: t(`country.${nationality}`)
          }}
          noValueText={t('creditCheck.personalDetails.nationality')}
          getValue={changeNationalityHandler}
          required
        />
      </div>
      {nationality && permitTypeRequired(nationality) && (
        <div>
          <Text as="label" category="headline">
            {t('creditCheck.personalDetails.residencePermit')}
          </Text>
          <Text size="large">{t('creditCheck.personalDetails.doYouHaveResidencePermit')}</Text>
          <a href={hubspotLinks.contact} className="contact-here">
            <Text as="span" size="large">
              {t('contactInvesterra')}
            </Text>
            <Icon name="arrow-right" />
          </a>
          <Dropdown
            values={Object.values(PermitType).map((elem) => ({
              value: elem,
              displayValue: elem
            }))}
            initialValue={{
              value: permitType,
              displayValue: permitType
            }}
            noValueText={t('creditCheck.personalDetails.residencePermit')}
            getValue={(value) => {
              dispatch({
                type: QuestionnaireFormActionType.CHANGE_PERMIT_TYPE,
                payload: value
              });
            }}
            required
          />
        </div>
      )}
      <MaritalStatusList
        label={t('creditCheck.personalDetails.maritalStatus')}
        handleCheck={maritalStatusHandler}
        checkedStatus={maritalStatus}
      />
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.housingSituation.customerAddress.heading')}
        </Text>
        <AddressForm
          address={questionnaire.kycData?.address}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_ADDRESS,
              payload: {
                field: e.target.name as keyof Address,
                value: e.target.value
              }
            });
          }}
          required
        />
      </div>
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.personalDetails.contactDetails')}
        </Text>
        <TextInput
          name="email"
          type="email"
          value={user.email || ''}
          placeholder={t('emailAddress')}
          disabled
          required
        />
        <PhoneTextInput
          value={questionnaire.kycData.phoneNumber}
          onChange={(phoneNumber) => {
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_PHONE_NUMBER,
              payload: phoneNumber
            });
          }}
        />
      </div>
    </form>
  );
};

export default PersonalDetails;
