import { toastUtil } from '../../utils/toast.utils';
import {
  AdminProjectOverview,
  GetProjects,
  ProjectsList,
  ProjectStatus,
  ProjectDetailsType,
  FinancialOverviewInputs,
  FinancialOverview,
  FinancialOverviewPlan,
  FinancialOverviewPlanInputs,
  ProjectFinancialOverview,
  CreateProject,
  GetProjectById
} from './project.types';
import api, { kotlinApi } from '../../api/api';
import { Module, ModuleType } from '../../types/financialOffer.types';
import { t } from 'i18next';
import { HttpStatusCode } from 'axios';
import { previewFile } from '../../utils/general.utils';
import { ErrorCode } from '../error/error.type';

export const createProject = async (project: CreateProject): Promise<CreateProject | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { owner, offer, cantonCode, ...projectWithoutOwnerAndOffer } = project.projectDto;

  const modifiedProject = {
    project: project.project,
    createProjectDto: {
      ...projectWithoutOwnerAndOffer,
      building: {
        sepId: project?.projectDto.building?.sepId,
        address: project?.projectDto.building?.address,
        latitude: project.projectDto.building?.location?.coordinates?.lat,
        longitude: project.projectDto.building?.location?.coordinates?.lng
      },
      modules: projectWithoutOwnerAndOffer?.modules?.map((module: Module) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { displayValue, unit, label, ...rest } = module as Module;
        return rest;
      })
    }
  };

  try {
    const { data } = await kotlinApi.post('/project', modifiedProject, {});
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getProjects = async ({
  userUuid,
  keyword,
  size,
  page,
  sort
}: GetProjects): Promise<ProjectsList> => {
  try {
    const transformSort = sort?.map((item) => `${item.name},${item.sort}`).join('&sort=');

    const { data } = await kotlinApi.get(
      `/project/search${transformSort ? `?sort=${transformSort}` : ''}`,
      {
        params: {
          userUuid: userUuid,
          size: size,
          page: page,
          ...(keyword && { keyword: keyword })
        }
      }
    );

    const transformedData: ProjectsList = {
      projects: data.content,
      size: data.size,
      totalPages: data.totalPages,
      totalItems: data.totalElements,
      pageNumber: data.number
    };

    return transformedData;
  } catch (err: any) {
    toastUtil('error', err.message);
    return Promise.reject(err);
  }
};

export const getAdminProjectDetails = async (
  projectUuid: string
): Promise<AdminProjectOverview | undefined> => {
  try {
    const { data } = await api.get(`/projects/${projectUuid}/details/admin`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getProjectDetails = async (
  projectUuid: string
): Promise<ProjectDetailsType | undefined> => {
  try {
    const { data } = await api.get(`/projects/${projectUuid}/details`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err);
  }
};

export const createScannedProject = async (
  project: any,
  id: string
): Promise<CreateProject | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { offer, owner, ...projectWithoutOwnerAndOffer } = project;

  const modifiedProject = {
    project: {
      userId: id,
      loanAmount: project.loanAmount,
      loanTerm: project.loanTerms,
      productTypes: project?.modules?.map((module: any) => module?.type as ModuleType[])
    },
    createScannedProjectDto: {
      ...projectWithoutOwnerAndOffer,
      building: {
        sepId: project?.building?.sepId,
        address: project?.building?.address,
        latitude: project?.building?.location?.coordinates?.lat,
        longitude: project?.building?.location?.coordinates?.lng
      },
      modules: projectWithoutOwnerAndOffer?.modules?.map((module: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { displayValue, unit, label, ...rest } = module as Module;
        return rest;
      }),
      ...offer
    }
  };

  try {
    const { data } = await kotlinApi.post('/project/scanned', modifiedProject);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const changeProjectStatus = async (projectUuid: string, status: ProjectStatus) => {
  try {
    await api.put(`projects/${projectUuid}/admin/status?projectStatus=${status}`);
    toastUtil('success', t('projectDetails.statusChangeSuccess'));
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getFinancialOverview = async (
  inputs: Partial<FinancialOverviewInputs>
): Promise<FinancialOverview | undefined> => {
  try {
    const resp = await api.post(`/financial-overview`, inputs);
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getFinancialOverviewForProject = async (
  projectId: number | string,
  solarPotential?: number
): Promise<ProjectFinancialOverview | undefined> => {
  try {
    const resp = await api.post(
      `/financial-overview/project/${projectId}?solarPotential=${solarPotential}`
    );
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const getFinancialOverviewPlan = async (
  inputs: Partial<FinancialOverviewPlanInputs>
): Promise<FinancialOverviewPlan | undefined> => {
  try {
    const resp = await api.post(`/financial-overview/plan`, inputs);
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};

export const deleteProject = async (projectUuid: string) => {
  try {
    const resp = await api.delete(`/projects/${projectUuid}/admin`);
    return resp.status == HttpStatusCode.Ok;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.response.data.code === ErrorCode.PROJECT_DELETE_NOT_ALLOWED) {
      return Promise.reject(ErrorCode.PROJECT_DELETE_NOT_ALLOWED);
    }
    return Promise.reject(err.message);
  }
};

export const uploadAcceptanceReport = async (projectUuid: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/projects/${projectUuid}/upload-acceptance-report`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.status == HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const downloadAcceptanceReport = async (projectId: number | string) => {
  try {
    const response = await api.get(`/projects/${projectId}/download-acceptance-report`, {
      responseType: 'blob'
    });
    previewFile(response.data);
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};

export const getProjectById = async (projectUuid: string): Promise<GetProjectById | undefined> => {
  try {
    const { data } = await kotlinApi.get(`/project/${projectUuid}`);
    return data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err);
  }
};
