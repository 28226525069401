import React, { FormEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import TextInput from '../Inputs/TextInput/TextInput';
import Text from '../Text/Text';
import { Profile, UpdateProfile } from '../../store/profile/profile.types';
import { SetStateType } from '../../types/util.types';
import { getCityAndCanton, parseFullName, parseName } from '../../utils/general.utils';
import {
  emailValidation,
  nameValidation,
  phoneNumberValidation
} from '../../utils/validations.utils';
import KycStatusCard from '../KycStatusCard/KycStatusCard';
import { User, UserRoles } from '../../store/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { hubspotLinks } from '../../utils/hubspotLinks';
import AddressForm from '../AddressForm/AddressForm';
import { Country } from '../../store/questionnaire/questionnaire.types';
import './PersonalDataForm.scss';

interface PersonalDataFormProps {
  data: Profile;
  setData: SetStateType<Profile>;
  onSubmit: (data: Partial<UpdateProfile>) => void;
}

const PersonalDataForm = ({ data, setData, onSubmit }: PersonalDataFormProps) => {
  const { t } = useTranslation();

  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [fullName, setFullName] = useState<string>(
    parseName(data?.profile?.firstName, data?.profile?.lastName)
  );

  const [initialDisable, setInitialDisable] = useState(true);

  const postalCode = data.profile?.address?.postalCode ?? '';

  const isDisabled =
    initialDisable ||
    !!nameValidation(data?.profile?.firstName) ||
    !!nameValidation(data?.profile?.lastName) ||
    !!phoneNumberValidation(data?.profile?.phoneNumber || '') ||
    (data.profile?.address && Object.values(data.profile?.address).some((elem) => !elem));

  useEffect(() => {
    const parsedName = parseFullName(fullName);

    setData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        firstName: parsedName.firstName,
        lastName: parsedName.lastName
      }
    }));
  }, [fullName]);

  useEffect(() => {
    const fetchCityAndCanton = async () => {
      if (postalCode.length === 4) {
        const res = await getCityAndCanton(postalCode);
        res &&
          setData((prev) => ({
            ...prev,
            profile: {
              ...prev.profile,
              address: {
                ...prev.profile.address,
                canton: res.canton,
                municipality: res.municipality,
                country: Country.SWITZERLAND
              }
            }
          }));
      }
    };
    fetchCityAndCanton();
  }, [data.profile?.address?.postalCode]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialDisable(false);
    const { value } = e.target;
    setFullName(value);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialDisable(false);
    const { value, name } = e.target;

    if (name === 'postalCode' && value.length > 4) return;

    setData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        address: {
          ...prev.profile.address,
          [name]: value
        }
      }
    }));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialDisable(false);
    let { value } = e.target;
    if (!value.includes('+')) {
      value = '+' + e.target.value;
    }

    setData((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        phoneNumber: value
      }
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { firstName, lastName, phoneNumber, address } = data.profile;
    if (data) {
      onSubmit({
        firstName,
        lastName,
        phoneNumber,
        address
      });
    }
  };

  return (
    <>
      <form className={'personal-data-form'} onSubmit={handleSubmit}>
        <TextInput
          type={'text'}
          name={'customerNumber'}
          value={data?.profile?.id?.toString() || ''}
          placeholder={t('customerNumber')}
          disabled
        />
        <TextInput
          name="name"
          type="text"
          placeholder={t('auth.name')}
          value={fullName}
          onChange={handleNameChange}
          validate={(value) => nameValidation(value as string)}
        />
        <TextInput
          name="email"
          type="text"
          placeholder={t('email')}
          value={data?.profile?.email || ''}
          validate={emailValidation}
          disabled
        />
        <AddressForm address={data.profile?.address} onChange={handleAddressChange} />
        <TextInput
          name="phoneNumber"
          type="tel"
          placeholder={t('phoneNumber')}
          validate={phoneNumberValidation}
          value={data?.profile?.phoneNumber || ''}
          onChange={handlePhoneNumberChange}
        />
        <Button
          type={'submit'}
          size={'large'}
          variation={'primary'}
          dataTest={'update-profile'}
          disabled={user?.role?.includes(UserRoles.ADMIN) ? false : isDisabled}>
          {t('saveChanges')}
        </Button>
        {!user?.role?.includes(UserRoles.ADMIN) && (
          <Text as={'p'} category={'label'} size={'small'} className={'delete-account-text'}>
            <Trans
              i18nKey={'myProfile.personalData.deleteAccount'}
              t={t}
              components={{
                contactLink: (
                  <a
                    href={hubspotLinks.contact}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={'contact-link'}
                  />
                )
              }}
            />
          </Text>
        )}
      </form>
      {data?.profile?.kycStatus &&
        (user.role?.includes(UserRoles.USER) || user.role?.includes(UserRoles.ADMIN)) && (
          <KycStatusCard status={data?.profile?.kycStatus} role={user.role as UserRoles[]} />
        )}
    </>
  );
};

export default PersonalDataForm;
