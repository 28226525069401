import ReactDOM from 'react-dom';
import { ReactSVG } from 'react-svg';
import './Modal.scss';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';

export interface ModalProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  isOpened: boolean;
  close: () => void;
  icon?: string;
}

const Modal = ({ children, title, isOpened, close, icon }: ModalProps) => {
  const modal = (
    <>
      <div className="backdrop" onClick={close}></div>
      <div className="wrapper" aria-modal aria-labelledby={title} tabIndex={-1} role="dialog">
        <Icon name={'close'} className="close-button" onClick={close} dataTest={'close'} />
        <div className="content">
          <Text as="h2" category="display" size="small">
            {icon && <ReactSVG src={icon} />}
            {title}
          </Text>
          <div className="children-wrapper">{children}</div>
        </div>
      </div>
    </>
  );

  return isOpened ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
