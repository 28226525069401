import React from 'react';
import Button from '../../../components/Button/Button';
import Icon, { icons } from '../../../components/Icon/Icon';
import Text from '../../../components/Text/Text';

import './ProfileCard.scss';

interface ProfileCardProps {
  title: string;
  description: string;
  icon: string;
  buttonText: string;
  dataTest: string;
  onClick?: () => void;
  className?: string;
}

const ProfileCard = ({
  title,
  description,
  icon,
  buttonText,
  dataTest,
  onClick,
  className
}: ProfileCardProps) => {
  return (
    <div className={`profile-card-wrapper ${className ? className : ''}`}>
      <Icon name={icon as keyof typeof icons} className={'profile-card-icon'} />
      <Text as={'h1'} category={'headline'} className={'profile-card-title'}>
        {title}
      </Text>
      <Text as={'p'} className={'profile-card-desc'}>
        {description}
      </Text>
      <Button
        variation={'primary'}
        size={'large'}
        dataTest={dataTest}
        onClick={onClick}
        className={'profile-card-bttn'}>
        {buttonText}
      </Button>
    </div>
  );
};

export default ProfileCard;
