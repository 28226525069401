import { useTranslation } from 'react-i18next';
import Text from '../../Text/Text';
import './TableSortable.scss';
import { useEffect, useState } from 'react';
import Icon from '../../Icon/Icon';
import { Sort, SortBy } from '../../../types/util.types';

interface TableSortableProps {
  values: Sort[];
  columnWidths: number[];
  children: JSX.Element | JSX.Element[];
  translationKey: string;
  hiddenSortColumns?: number[];
  hoverEffect?: boolean;
  sortable?: boolean;
  onSortChange: (selectedSortColumn: Sort[]) => void;
}

const TableSortable = ({
  values,
  children,
  columnWidths,
  hoverEffect,
  translationKey,
  hiddenSortColumns,
  onSortChange,
  sortable
}: TableSortableProps) => {
  const { t } = useTranslation();

  const [selectedColumns, setSelectedColumns] = useState<Sort[]>(
    values.filter((elem: Sort) => elem.sort)
  );

  useEffect(() => {
    if (selectedColumns) {
      onSortChange(
        values.map((elem: Sort) => {
          const matchingFilter = selectedColumns.find((sf) => sf.name === elem.name);
          if (matchingFilter) {
            return { ...elem, sort: matchingFilter.sort };
          }
          return { ...elem, sort: undefined };
        })
      );
    } else {
      onSortChange(
        values.map((elem: Sort) => {
          return { name: elem.name, sort: undefined };
        })
      );
    }
  }, [selectedColumns]);

  const getClassName = () => `table-wrapper ${hoverEffect ? 'hover-effect' : ''}`;

  const showSortIcon = (index: number) =>
    `${!hiddenSortColumns?.includes(index + 1) ? 'show' : 'hide'}`;

  const addSortItem = (selectedSort: Sort, sortBy: SortBy | undefined) => {
    let checkSort = sortBy;
    switch (checkSort) {
      case SortBy.DESCENDING: {
        checkSort = undefined;
        break;
      }
      case SortBy.ASCENDING: {
        checkSort = SortBy.DESCENDING;
        break;
      }
      default:
        checkSort = SortBy.ASCENDING;
        break;
    }

    if (!checkSort) {
      setSelectedColumns((prev) => prev?.filter((filter) => selectedSort.name !== filter.name));
    } else {
      setSelectedColumns((prev) => {
        const updatedFilter = prev?.map((filter) => {
          if (filter.name === selectedSort.name) {
            return { ...filter, sort: checkSort };
          }
          return filter;
        });

        if (!updatedFilter?.some((filter) => filter.name === selectedSort.name)) {
          updatedFilter?.push({ name: selectedSort.name, sort: checkSort });
        }

        return updatedFilter;
      });
    }
  };

  return (
    <div className={getClassName()}>
      <table className="table" cellPadding={0} cellSpacing={0}>
        <thead className="table-head">
          <tr className="table-row-head">
            {values.map((elem: Sort, index) => (
              <th key={index} style={{ width: `${columnWidths[index]}%` }}>
                <Text
                  as="span"
                  category="headline"
                  size="small"
                  variety={1}
                  className="table-heading"
                  onClick={() => {
                    showSortIcon(index) === 'show' && addSortItem(elem, elem.sort);
                  }}>
                  {t(`${translationKey}${elem.name}`)}

                  {sortable && (
                    <div className="sort-by-wrapper">
                      {elem.sort === SortBy.ASCENDING ? (
                        <Icon className={showSortIcon(index)} name="sort-up-fill" />
                      ) : (
                        <Icon className={showSortIcon(index)} name="sort-up" />
                      )}

                      {elem.sort === SortBy.DESCENDING ? (
                        <Icon className={showSortIcon(index)} name="sort-down-fill" />
                      ) : (
                        <Icon className={showSortIcon(index)} name="sort-down" />
                      )}
                    </div>
                  )}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default TableSortable;
