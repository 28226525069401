import './FinancingCalculator.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import Text from '../../../components/Text/Text';
import useNavigation from '../../../hooks/useNavigation';

const FinancingCalculator = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const navigatePage = () => {
    goTo('/upload-offer');
  };

  return (
    <div className="rating">
      <Text as="h3" category="headline" size="large">
        {t('uploadOfferHome.uploadOfferHeadline')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('uploadOfferHome.alreadyHaveOffer')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('uploadOfferHome.sugestion')}
      </Text>
      <Text as="p" category="body" size="medium">
        {t('uploadOfferHome.uploadOffer')}
      </Text>
      <Button
        dataTest="upload_offer-open-page-button"
        style={{ marginTop: '55px' }}
        className="upload-button"
        size="large"
        variation="secondary"
        onClick={navigatePage}>
        {t('uploadOfferHome.uploadOfferAndCalulate')}
      </Button>
    </div>
  );
};

export default FinancingCalculator;
