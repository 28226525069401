import { useSelector } from 'react-redux';
import Text from '../../../../components/Text/Text';
import { SetStateType } from '../../../../types/util.types';
import { getInitials } from '../../../../utils/general.utils';
import './MessageBox.scss';
import { RootState } from '../../../../reducers/rootReducer';
import { User } from '../../../../store/user/types';
import { Message } from '../../../../store/profile/profile.types';
import { formatDateWithTime } from '../../../../utils/formatting.utils';

interface MessageBoxProps {
  message: Message;
  setSelected: SetStateType<Message> | undefined;
  selected?: boolean;
}

const MessageBox = ({ selected, message, setSelected }: MessageBoxProps) => {
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const getClassName = () => `message-box-wrapper ${selected ? 'selected' : ''}`;

  return (
    <div
      className={getClassName()}
      onClick={() => {
        setSelected && setSelected(message);
      }}>
      <span className="message-sender">{getInitials(user.firstName, user.lastName)}</span>
      <div className="message-box-content">
        <Text as="span" category="body" size="small">
          {formatDateWithTime(message.sentTimestamp)}
        </Text>
        <Text as="h4" category="label" size="large">
          {message.subject}
        </Text>
        <Text as="p" category="body" size="small">
          {message.plainContent}
        </Text>
      </div>
    </div>
  );
};

export default MessageBox;
