import Text, { TextProps } from '../Text/Text';
import { toSwissFrancNotation } from '../../utils/formatting.utils';

interface PriceProps extends TextProps {
  children: number | string;
}

const Price = ({ children, ...props }: PriceProps) => {
  return <Text {...props}>{toSwissFrancNotation(children)}</Text>;
};

export default Price;
