import { useTranslation } from 'react-i18next';
import './PartnerProjectSummary.scss';
import PartnerProjectDetails from './PartnerProjectDetails/PartnerProjectDetails';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getPartnerFinancialOverview,
  getPartnerProjectDetails
} from '../../../store/partners/partners.action';
import { ProjectFinancialOverview } from '../../../store/project/project.types';
import { PartnerProjectData } from '../../../store/partners/partners.types';
import Text from '../../../components/Text/Text';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { User } from '../../../store/user/types';
import { getBuildingByAddress, searchMesAddress } from '../../../store/mes/mes.actions';

const PartnerProjectSummary = () => {
  const { t } = useTranslation();

  const { projectUuid } = useParams();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [projectDetails, setProjectDetails] = useState<PartnerProjectData>();
  const [overview, setOverview] = useState<ProjectFinancialOverview | undefined>(undefined);

  useEffect(() => {
    const fetchPartnerProjectDetails = async () => {
      if (projectUuid) {
        const data = await getPartnerProjectDetails(projectUuid, user.uid);
        if (data) {
          setProjectDetails(data);
          const { results } = await searchMesAddress(
            `${data?.address.address.street}
            ${data?.address.address.houseNumber}
            ${data?.address.address.postalCode}
            ${data?.address.address.municipality}`
          );
          const building = await getBuildingByAddress(results[0].attrs);
          if (building && projectDetails) {
            const overview = await getPartnerFinancialOverview(
              projectDetails?.id,
              building?.solarPotential
            );
            setOverview(overview);
          }
        }
      }
    };
    fetchPartnerProjectDetails();
  }, [projectUuid]);

  // const partnerProjectSummaryTabs = [
  //   {
  //     key: 'projectDetails',
  //     comp: <PartnerProjectDetails projectDetails={projectDetails} overview={overview} />
  //   },
  //   { key: 'partnerBilling', comp: <PartnerBilling projectId={projectDetails?.id} /> }
  // ];

  return (
    <div className="partner-project-summary-wrapper">
      {projectDetails?.id && (
        <Text as="h2" category="display" size="medium" className="heading">
          {`${t('myProfile.myProjects.labels.project')}: ${projectDetails.id}`}
        </Text>
      )}
      {/* <Tabs>
        {partnerProjectSummaryTabs.map((tab, index) => {
          return (
            <TabContent title={`${t(`partnerProjectSummary.tabs.${tab.key}`)}`} key={index}>
              {tab.comp}
            </TabContent>
          );
        })}
      </Tabs> */}
      <PartnerProjectDetails projectDetails={projectDetails} overview={overview} />
    </div>
  );
};

export default PartnerProjectSummary;
