import './Switch.scss';
import Text from '../../Text/Text';

interface SwitchProps {
  id: number;
  label: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  children?: JSX.Element;
  className?: string;
  onCheck: (id: number, state: boolean) => void;
}

const Switch = ({
  id,
  label,
  value,
  checked,
  children,
  disabled,
  inactive,
  className,
  onCheck
}: SwitchProps) => {
  return (
    <div
      className={`switch-wrapper ${className || ''} ${checked ? 'checked' : ''} ${
        disabled ? 'disabled' : ''
      } ${inactive ? 'inactive' : ''}`}>
      <div className="switch-content">
        <Text as="div" category="title" size="large">
          {label}
        </Text>
        <Text as="div" category="body" size="medium">
          {value}
        </Text>
      </div>
      {children}
      <div className="controls">
        <label className="switch">
          <input
            className="checkbox"
            type="checkbox"
            checked={checked || false}
            onChange={() => {
              onCheck(id, !checked);
            }}
          />
          <span className="slider" />
        </label>
      </div>
    </div>
  );
};

export default Switch;
