import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getFinancialOverviewForProject,
  getProjectDetails
} from '../../../store/project/project.action';
import './ProjectDetails.scss';
import {
  ProjectDetailsType,
  ProjectFinancialOverview,
  ProjectStatus
} from '../../../store/project/project.types';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import FinancingPlan from '../../../components/FinancingPlan/FinancingPlan';
import FinancialOfferPreview from '../../../components/FinancialOfferPreview/FinancialOfferPreview';
import { Module } from '../../../types/financialOffer.types';
import { SetStateType } from '../../../types/util.types';
import { getMatchingModules } from '../../../utils/solarSystem.utils';
import { UserRoles } from '../../../store/user/types';
import { getBuildingByAddress, searchMesAddress } from '../../../store/mes/mes.actions';

interface ProjectDetailsProps {
  role: UserRoles[];
  setStatus?: SetStateType<ProjectStatus>;
  setProjectId?: SetStateType<number | undefined>;
}

const ProjectDetails = ({ setStatus, setProjectId }: ProjectDetailsProps) => {
  // const { t } = useTranslation();
  const { projectUuid } = useParams();

  const [projectDetails, setProjectDetails] = useState<ProjectDetailsType>();
  const [overview, setOverview] = useState<ProjectFinancialOverview | undefined>(undefined);
  // const [acceptanceReport, setAcceptanceReport] = useState<File | undefined>(undefined);

  useEffect(() => {
    fetchProjectDetails();
  }, [projectUuid]);

  const fetchProjectDetails = async () => {
    if (projectUuid) {
      const data = await getProjectDetails(projectUuid);
      if (data) {
        setProjectDetails(data);
        const { results } = await searchMesAddress(
          `${data.projectAddressStreet} ${data.projectAddressNumber} ${data.projectAddressZip} ${data.projectAddressCity}`
        );
        const resp = await getBuildingByAddress(results[0].attrs);
        const overview = await getFinancialOverviewForProject(data.id, resp?.solarPotential);
        const feedIn = overview?.feedInTariff || resp?.feedInTariff || '0';
        const performance = resp?.performance ?? '';
        setOverview({ ...overview, feedInTariff: feedIn, performance } as ProjectFinancialOverview);

        setStatus && setStatus(data.status);
        setProjectId && setProjectId(data.id);
      }
    }
  };

  // const handleUpload = async (files: FileList | null) => {
  //   files && setAcceptanceReport(files[0]);
  // };

  // const submitReport = async () => {
  //   if (projectUuid && acceptanceReport) {
  //     const resp = await uploadAcceptanceReport(projectUuid, acceptanceReport);
  //     if (resp) {
  //       toastUtil('success', t('fileUploadedSuccesfull'));
  //       fetchProjectDetails().then(() => {
  //         setAcceptanceReport(undefined);
  //       });
  //     }
  //   }
  // };

  return (
    <div className="user-project-details-container">
      {projectDetails && overview && (
        <>
          <ProjectInfo
            projectUuid={projectUuid as string}
            projectDetails={projectDetails as ProjectDetailsType}
          />
          <div style={{ isolation: 'isolate' }}>
            <FinancialOfferPreview
              overview={overview}
              modules={getMatchingModules(projectDetails?.activeModules) as Module[]}
              status="final"
              showModules
            />
            <FinancingPlan
              showRate={true}
              initial={overview.plan}
              amount={projectDetails.offerPrice}
              deposit={projectDetails.deposit}
              duration={projectDetails.duration}
              disable
              status="final"
            />
            {/* <div className="user-upload-action-wrapper">
              {(role?.includes(UserRoles.USER) || projectDetails.acceptanceReportUrl) && (
                <Text as="h3" category="headline" size="large">
                  {t('projectDetails.uploadReport')}
                </Text>
              )}
              {!!projectDetails.acceptanceReportUrl && (
                <Button
                  size="large"
                  variation="primary"
                  dataTest=""
                  icon={DownloadIcon}
                  iconPosition="left"
                  onClick={() => {
                    projectUuid && downloadAcceptanceReport(projectUuid);
                  }}>
                  {t('downloadAcceptanceReport')}
                </Button>
              )}
              {!projectDetails.acceptanceReportUrl && role?.includes(UserRoles.USER) && (
                <>
                  <UploadFile handleChangeFiles={handleUpload} disable={!!acceptanceReport} />
                  <Button
                    dataTest=""
                    size="large"
                    variation="primary"
                    disabled={!!projectDetails.acceptanceReportUrl || !acceptanceReport}
                    onClick={submitReport}>
                    {t('sending')}
                  </Button>
                </>
              )}
              {acceptanceReport && (
                <FileItem
                  name={acceptanceReport?.name}
                  variation="primary"
                  onDelete={() => setAcceptanceReport(undefined)}
                />
              )}
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
