import { kotlinApi } from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';

export const getKycLink = async (): Promise<string | undefined> => {
  try {
    const { data } = await kotlinApi.get('/kyc/link');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    toastUtil('error', err.message);
  }
};
