import {
  NplProjectState,
  ProjectStatus,
  ProjectsListItem
} from '../../../../../store/project/project.types';
import { formatDateWithDot } from '../../../../../utils/formatting.utils';
import Text from '../../../../../components/Text/Text';

import './ProjectItem.scss';
import { emptyStringIfNull, getCorrectProjectStatus } from '../../../../../utils/general.utils';
import ProjectStatusField from '../../../../../components/ProjectStatusField/ProjectStatusField';
import useNavigation from '../../../../../hooks/useNavigation';
import { useEffect, useState } from 'react';
import { getProjectById } from '../../../../../store/project/project.action';

interface ProjectItemProps {
  project: ProjectsListItem;
}
const ProjectItem = ({ project }: ProjectItemProps) => {
  const { goTo } = useNavigation();
  const [nplProjectStatus, setNplProjectStatus] = useState<NplProjectState | undefined>();

  useEffect(() => {
    if (!project.projectUuid) return;
    getNplProjectStatus(project.projectUuid);
  }, [project.projectUuid]);

  const navigateAdmin = () => {
    if (
      ProjectStatus.OFFER_REQUESTED === project.status ||
      ProjectStatus.OFFER_RECEIVED === project.status
    ) {
      goTo(`/admin/project/${project.id}/offer`, { state: 'isAdmin' });
    } else {
      goTo(`/admin/project/${project.projectUuid}/review`);
    }
  };

  const getNplProjectStatus = async (projectUuid: string) => {
    const data = await getProjectById(projectUuid);
    setNplProjectStatus(data?.project.currentState);
  };

  return (
    <tr className="projects-table-row" onClick={navigateAdmin}>
      <Text as="td" category="label" size="large">
        {project.id}
      </Text>
      <Text as="td" category="label" size="large">
        {formatDateWithDot(project.creationTime)}
      </Text>
      <Text as="td" category="label" size="large">
        {project.clientName}
      </Text>
      <Text as="td" category="label" size="large">
        {project.address.split(', ').join(`,\n`)}
      </Text>
      <Text as="td" category="label" size="large">
        {project.partnerName}
      </Text>
      <Text as="td" category="label" size="large">
        {emptyStringIfNull(project.lastLogAuthor)}
      </Text>
      <Text as="td" category="label" size="large">
        {nplProjectStatus && (
          <ProjectStatusField
            projectStatus={getCorrectProjectStatus(project.status, nplProjectStatus)}
            withoutDot
          />
        )}
      </Text>
    </tr>
  );
};

export default ProjectItem;
