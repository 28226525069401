import { useTranslation } from 'react-i18next';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';
import Button from '../../../../../components/Button/Button';
import { ZekData } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { formatDate } from '../../../../../utils/formatting.utils';

enum ZekTableHeading {
  CLIENT_ID = 'clientId',
  ZEK = 'zek',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  DATE_OF_BIRTH = 'dateOfBirth',
  FULL_ADDRESS = 'fullAddress',
  NATIONALITY = 'nationality',
  PERSON_STATUS = 'personStatus',
  HIT_SCORE = 'hitScore'
}

interface ZekTableProps {
  zekData?: ZekData;
  toggle: () => void;
}

const ZekTable = ({ zekData, toggle }: ZekTableProps) => {
  const { t } = useTranslation();

  const zekTableHeading = Object.values(ZekTableHeading).map((elem) => elem);

  return (
    <div className="zek-table-wrapper">
      <Text as="p" category="body" size="medium" className="table-name-heading">
        {t('admin.projectDetails.projectReview.zek.description')}
      </Text>
      <Text as="p" category="body" size="medium" className="table-name-heading">
        {t('admin.projectDetails.projectReview.zek.zekScore')}: {zekData?.zekScore}
      </Text>
      {zekData && zekData.zekHits && (
        <TableHead
          values={zekTableHeading}
          columnWidths={[10, 10, 10, 10, 10, 10]}
          translationKey="admin.projectDetails.projectReview.zek.table.heading">
          {zekData?.zekHits.map((elem, ind) => (
            <tr key={ind}>
              <Text as="td" category="label" size="large">
                {elem?.clientId}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.zekScoreValue}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.firstName}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.lastName}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.gender}
              </Text>
              <Text as="td" category="label" size="large">
                {formatDate(elem?.dateOfBirth)}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.fullAddress}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.nationality}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.personStatus}
              </Text>
              <Text as="td" category="label" size="large">
                {elem?.hitScore}
              </Text>
            </tr>
          ))}
        </TableHead>
      )}
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default ZekTable;
