import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import './QuestionnaireActions.scss';

interface QuestionnaireActionsProps {
  nextStepDisabled: boolean;
  saveDisabled?: boolean;
  lastStep?: boolean;
  firstStep?: boolean;
  onBack: () => void;
  onSave: () => void;
  onNextStep: () => void;
}

const QuestionnaireActions = ({
  nextStepDisabled,
  saveDisabled,
  lastStep,
  firstStep,
  onBack,
  onSave,
  onNextStep
}: QuestionnaireActionsProps) => {
  const { t } = useTranslation();

  return (
    <div className="questionnare-actions-wrapper">
      <Button size="large" dataTest="" variation="secondary" onClick={onBack}>
        {firstStep ? t('cancel') : t('back')}
      </Button>
      <div>
        <Button
          size="large"
          dataTest=""
          variation="secondary"
          onClick={onSave}
          disabled={saveDisabled}>
          {t('save')}
        </Button>
        <Button
          size="large"
          dataTest=""
          variation="primary"
          disabled={nextStepDisabled}
          onClick={onNextStep}
          className="next-step-action">
          {lastStep ? t('sending') : t('nextStep')}
        </Button>
      </div>
    </div>
  );
};

export default QuestionnaireActions;
