import { ProjectLog } from '../../../store/project/project.types';
import { formatDateWithTime } from '../../../utils/formatting.utils';
import Icon, { icons } from '../../Icon/Icon';
import Text from '../../Text/Text';

import './LogItem.scss';
import { useTranslation } from 'react-i18next';

interface LogItemProps {
  log: ProjectLog;
  icon: string;
  onClick: () => void;
}

const LogItem = ({ log, icon, onClick }: LogItemProps) => {
  const { t } = useTranslation();

  return (
    <div className="log-item-wrapper" onClick={onClick}>
      <Icon name={icon as keyof typeof icons} className="log-icon" />
      <div className="log">
        <div className="log-info">
          <Text as="span" category="label" size="large">
            {formatDateWithTime(log.date)}
          </Text>
          <div className="log-author-wrapper">
            <Text as="span" category="label" size="large" className="log-author">
              {log.partyName}
            </Text>
            <Icon name="comment" />
          </div>
        </div>
        <div>
          <Text as="span" category="label">
            {t(`projectLogs.${log.logType}`)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LogItem;
