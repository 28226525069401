import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import i18n from '../i18n/i18n';

const useNavigation = () => {
  const navigate = useNavigate();
  const selectedLanguage = i18n.language;
  const { language } = useParams();

  const goTo = (to: string | number, options?: NavigateOptions) => {
    if (to === '/') {
      return navigate(`/${selectedLanguage}`);
    }

    if (to === -1) {
      return navigate(-1);
    }

    if (language) {
      return navigate(`/${selectedLanguage}${to}`, { ...options, replace: true });
    }

    return navigate(`${selectedLanguage}${to}`, options);
  };

  return { goTo };
};

export default useNavigation;
