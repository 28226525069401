import { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import './Dropdown.scss';
import useOutsideClick from '../../hooks/useOutsideClick';

export interface DropdownType {
  value: string;
  displayValue: string;
}

interface DropdownProps {
  values: DropdownType[];
  getValue: (value: string) => void;
  initialValue?: DropdownType;
  noValueText?: string;
  required?: boolean;
}

const Dropdown = ({ values, noValueText, getValue, initialValue, required }: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<DropdownType>();
  const [filteredValues, setFilteredValues] = useState<DropdownType[]>(values);
  const [active, setActive] = useState<number>(1);

  const ref = useOutsideClick(() => setOpen(false));

  useEffect(() => {
    selectedValue?.value && getValue(selectedValue?.value as string);
    setFilteredValues(values);
  }, [selectedValue]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const regex = /^[\p{L}]$/u;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        filteredValues.length > active && setActive((prev) => prev + 1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        active > 1 && setActive((prev) => prev - 1);
        break;
      case 'Enter':
        if (!open) {
          setOpen((prev) => !prev);
          setActive(1);
        } else {
          setSelectedValue(filteredValues.find((_, i) => i + 1 === active));
          setOpen(false);
        }
        break;
      case 'Backspace':
        setFilteredValues(values);
        break;
      default:
        if (open && regex.test(e.key) && filteredValues.length > 10) {
          setFilteredValues(
            filteredValues.filter((val) => val.displayValue.toLowerCase().startsWith(e.key))
          );
        }
        break;
    }
  };

  const getClassName = () => {
    return `dropdown-list-wrapper ${open ? 'open' : ' '} ${
      selectedValue?.value || initialValue?.value ? '' : 'no-value'
    }`;
  };

  return (
    <div
      className={getClassName()}
      onClick={() => {
        setOpen((prev) => !prev);
        setActive(1);
      }}
      ref={ref}
      tabIndex={0}
      onKeyDown={handleKeyDown}>
      <Text as="span" category="label" size="large" className="dropdown-list-heading">
        {selectedValue?.value
          ? selectedValue.displayValue
          : initialValue?.value
          ? initialValue.displayValue
          : `${noValueText}${required ? '*' : ''}`}
      </Text>
      <ul className="dropdown-list-items">
        {filteredValues.map((elem: DropdownType, index) => (
          <Text
            as="li"
            category="label"
            size="large"
            key={index}
            className={`dropdown-list-item ${active === index + 1 ? 'active' : 'no-active'}`}
            onClick={() => {
              setSelectedValue(elem);
            }}>
            {elem.displayValue}
          </Text>
        ))}
      </ul>
      <Icon name="arrow-down" />
    </div>
  );
};

export default Dropdown;
