import './Search.scss';
import { ChangeEvent } from 'react';
import { SetStateType } from '../../../types/util.types';
import Icon from '../../Icon/Icon';

interface SearchProps {
  setValue: SetStateType<string> | ((value: string) => void);
  placeholder: string;
  value?: string;
  className?: string;
}

const Search = ({ value, setValue, placeholder, className = '' }: SearchProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  return (
    <div className={`search-form ${className}`}>
      <label htmlFor="search">
        <Icon name="search" />
      </label>
      <input
        type="text"
        id="search"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;
