import { useTranslation } from 'react-i18next';
import Text from '../../components/Text/Text';
import './NotFound.scss';
import Button from '../../components/Button/Button';
import useNavigation from '../../hooks/useNavigation';

const NotFound = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  return (
    <div className={'not-found-wrapper'}>
      <Text as={'h1'} category={'display'}>
        {t('pageNotFound')}
      </Text>
      <Button
        variation={'primary'}
        size={'large'}
        dataTest={''}
        onClick={() => {
          goTo('/');
        }}>
        {t('backToHomepage')}
      </Button>
    </div>
  );
};

export default NotFound;
