import { useTranslation } from 'react-i18next';
import Text from '../../../../../components/Text/Text';

const ReviewInstructions = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text as="h2" category="headline" className="project-review-heading">
        {t('admin.projectDetails.projectReview.instructions.heading')}
      </Text>
      <div className="review-instructions-text">
        <div>
          <Text as="h4" category="title" size="medium">
            {t('admin.projectDetails.projectReview.instructions.processingNote.heading')}
          </Text>
          <Text as="p" category="body" size="medium">
            {t('admin.projectDetails.projectReview.instructions.processingNote.text')}
          </Text>
        </div>
        <div>
          <Text as="h4" category="title" size="medium">
            {t('admin.projectDetails.projectReview.instructions.pleaseNote.heading')}
          </Text>
          <Text as="p" category="body" size="medium">
            {t('admin.projectDetails.projectReview.instructions.pleaseNote.text')}
          </Text>
        </div>
      </div>
    </>
  );
};

export default ReviewInstructions;
