import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabContent from '../../../../components/Tabs/TabContent/TabContent';
import Tabs from '../../../../components/Tabs/Tabs';
import Text from '../../../../components/Text/Text';
import BankDetails from '../../../../components/BankDetails/BankDetails';
import { Profile } from '../../../../store/profile/profile.types';
import './ClientDetails.scss';
import { getClientProfile, updateProfile } from '../../../../store/profile/profile.actions';
import PersonalDataForm from '../../../../components/PersonalDataForm/PersonalDataForm';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContextProvider';

const ClientDetails = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const auth = useContext(AuthContext);

  const [clientDetails, setClientDetails] = useState<Profile>({} as Profile);

  useEffect(() => {
    const fetchClientDetails = async () => {
      const data = clientId && (await getClientProfile(clientId));
      data && setClientDetails(data);
    };

    fetchClientDetails();
  }, [auth?.isAuthenticated]);

  return (
    <>
      <Text as={'h1'} category={'display'} className={'personal-data-title'}>
        {t('admin.clients.clientDetails')}
      </Text>
      <Tabs>
        <TabContent title={t(`admin.clients.clientData`)} className={'personal-data-wrapper'}>
          <PersonalDataForm
            data={clientDetails}
            setData={setClientDetails}
            onSubmit={(data) => {
              clientId && updateProfile(data, clientId);
            }}
          />
        </TabContent>
        <TabContent title={t(`bankDetails`)} className="personal-data-wrapper">
          <BankDetails
            className="personal-data-form"
            user={clientDetails.profile}
            updateBankDetails={(data) => {
              updateProfile(data, `${clientDetails?.profile?.userUuid}`);
            }}
          />
        </TabContent>
      </Tabs>
    </>
  );
};

export default ClientDetails;
