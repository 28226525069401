import { useTranslation } from 'react-i18next';
import Text from '../../../../components/Text/Text';
import './ProjectInfo.scss';
import InfoField from '../../../../components/InfoField/InfoField';
import { NplProjectState, ProjectDetailsType } from '../../../../store/project/project.types';
import Button from '../../../../components/Button/Button';
import DownloadIcon from '../../../../assets/Icons/download.svg';
import Map from '../../../../components/Map/Map';
import ProjectStatusField from '../../../../components/ProjectStatusField/ProjectStatusField';
import { formatDateWithDot, toSwissFrancNotation } from '../../../../utils/formatting.utils';
import { downloadOfferDocument } from '../../../../store/offers/offers.action';
import { getCorrectProjectStatus } from '../../../../utils/general.utils';
import { getProjectById } from '../../../../store/project/project.action';
import { useEffect, useState } from 'react';
import ContactBox from '../../../../components/ContactBox/ContactBox';

interface ProjectInfoProps {
  projectUuid: string;
  projectDetails: ProjectDetailsType;
}

const ProjectInfo = ({ projectUuid, projectDetails }: ProjectInfoProps) => {
  const { t } = useTranslation();
  // const role = useSelector<RootState>((state) => state.userReducer.role) as UserRoles;
  const [nplProjectStatus, setNplProjectStatus] = useState<NplProjectState | undefined>();

  // const statuses: string[] = Object.values(ProjectStatus);

  useEffect(() => {
    if (!projectUuid) return;
    getNplProjectStatus(projectUuid);
  }, [projectUuid]);

  const getNplProjectStatus = async (projectUuid: string) => {
    const data = await getProjectById(projectUuid);
    setNplProjectStatus(data?.project.currentState);
  };

  return (
    <div className="project-info">
      <Text as="h3" category="headline" size="large">
        {t('projectDetails.label')}
      </Text>
      <Map
        initialCordinates={{ lat: projectDetails.latitude, lng: projectDetails.longitude }}
        hideControls
        className="map-container"
      />
      <div className="project-info-field">
        <div>
          <Text as="span" category="label" size="large">
            {t('projectDetails.status')}
          </Text>
          {nplProjectStatus && (
            <ProjectStatusField
              projectStatus={getCorrectProjectStatus(projectDetails.status, nplProjectStatus)}
            />
          )}
        </div>
        {/* {role?.includes(UserRoles.ADMIN) && (
          <ChangeStatus
            currentStatus={projectStatus}
            statuses={statuses}
            label={'projectStatus'}
            onSubmit={(status) => {
              changeStatus(status as ProjectStatus);
            }}
            buttonVariation={'secondary'}
          />
        )} */}
      </div>
      <div className="project-details-info">
        <div>
          <InfoField className="field" label={t('fullName')} variety={2}>
            {`${projectDetails.customerName}`}
          </InfoField>
          <InfoField className="field" label={t('customerNumber')} variety={2}>
            {String(projectDetails.customerId)}
          </InfoField>
        </div>
        <InfoField className="field" label={t('offers.offerDetails.address')} variety={2}>
          {`${projectDetails.projectAddressStreet} ${projectDetails.projectAddressNumber}, ${projectDetails.projectAddressZip} ${projectDetails.projectAddressCity}`}
        </InfoField>
        <InfoField className="field" label={t('offers.offerDetails.offerDate')} variety={2}>
          {formatDateWithDot(projectDetails.offerDate)}
        </InfoField>
      </div>
      <div className={`${projectDetails.area ? 'project-details-space' : ''}`}>
        {projectDetails.area && (
          <InfoField
            className="field"
            label={t('offers.offerDetails.pvSystem.label')}
            tooltipText={{
              heading: t('offers.offerDetails.pvSystem.tooltip.heading'),
              text: t('offers.offerDetails.pvSystem.tooltip.text')
            }}
            variety={2}>
            {`${projectDetails?.area} ${t('units.meterSqared')}`}
          </InfoField>
        )}
        <InfoField
          className="field"
          label={t('offer.plantPower')}
          tooltipText={{
            heading: t('offers.offerDetails.plantPower.tooltip.heading'),
            text: t('offers.offerDetails.plantPower.tooltip.text')
          }}
          variety={2}>
          {`${projectDetails.performance} ${t('units.kilowattPerformance')}`}
        </InfoField>
      </div>
      <InfoField
        className="field"
        label={t('offers.offerDetails.offerPrice.label')}
        tooltipText={{
          heading: t('offers.offerDetails.offerPrice.tooltip.heading'),
          text: t('offers.offerDetails.offerPrice.tooltip.text')
        }}
        variety={2}>
        {toSwissFrancNotation(projectDetails.offerPrice)}
      </InfoField>
      <ContactBox
        partnerName={projectDetails.partnerName}
        partnerAdress={`${projectDetails.partnerAddress}`}
        name={projectDetails.partnerContactName}
        phone={projectDetails.partnerContactPhone}
        email={projectDetails.partnerContactEmail}
      />
      <div className="project-info-buttons-wrapper">
        <Button
          size="large"
          variation="primary"
          dataTest=""
          icon={DownloadIcon}
          iconPosition="left"
          onClick={() => {
            downloadOfferDocument(projectDetails.offerId);
          }}>
          {t('offers.contract.buttonText')}
        </Button>
        {/* {role?.includes(UserRoles.ADMIN) && projectDetails.orderConfirmationUrl && (
          <Button
            size="large"
            variation="primary"
            dataTest=""
            icon={DownloadIcon}
            iconPosition="left"
            onClick={() => {
              downloadOrderConfirmation(projectDetails.id);
            }}>
            {t('offers.contract.orderConfirmationDownload')}
          </Button>
        )} */}
      </div>
    </div>
  );
};

export default ProjectInfo;
