import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon/Icon';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import { getKycLink } from '../../../store/kyc/kyc.action';
import { useContext, useEffect, useState } from 'react';
import useNavigation from '../../../hooks/useNavigation';
import { AuthContext } from '../../../context/AuthContextProvider';

const KycError = () => {
  const { t, i18n } = useTranslation();
  const { goTo } = useNavigation();
  const [kycLink, setKycLink] = useState('');
  const auth = useContext(AuthContext);

  useEffect(() => {
    auth?.isAuthenticated && getKyc();
  }, []);

  const getKyc = async () => {
    const data = await getKycLink();
    data && setKycLink(data);
  };

  return (
    <div className="kyc-error-wrapper">
      <Text as="h2" category="display" size="medium">
        {t('kycResult.error.heading')}
      </Text>
      <Icon name="kyc-error" />
      <Text as="span" category="body" size="medium" className="kyc-error-indentification">
        {t('kycResult.error.enabledIdentification')}
      </Text>
      <Text as="h4" category="headline" size="large">
        {t('kycResult.error.reasons')}
      </Text>
      <ul>
        <Text as="li" category="body" size="medium">
          {t('kycResult.error.incompleteInformation')}
        </Text>
        <Text as="li" category="body" size="medium">
          {t('kycResult.error.noMatchInfo')}
        </Text>
      </ul>
      <Text as="span" category="body" size="medium">
        {t('kycResult.error.startProcessAgain')}
      </Text>
      <div className="kyc-action-wrapper">
        <Button
          dataTest=""
          size="large"
          variation="secondary"
          onClick={() => {
            if (auth?.isAuthenticated) {
              goTo('/my-profile');
            } else {
              auth?.keycloak.login({
                redirectUri: `${window.location.origin}/${i18n.language}/my-profile`
              });
            }
          }}>
          {t('toMyProfile')}
        </Button>
        <Button
          dataTest=""
          size="large"
          variation="primary"
          onClick={() => {
            if (auth?.isAuthenticated && kycLink) {
              window.open(kycLink, '_blank');
            } else {
              auth?.keycloak.login();
            }
          }}>
          {t('restartIdentification')}
        </Button>
      </div>
    </div>
  );
};

export default KycError;
