import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './GlobalVariables.scss';
import CreditTerms from './CreditTerms/CreditTerms';
import Tabs from '../../../components/Tabs/Tabs';
import TabContent from '../../../components/Tabs/TabContent/TabContent';
import PartnerCommissions from './PartnerCommissions/PartnerCommissions';
import Products from './Products/Products';

const GlobalVariables = () => {
  const { t } = useTranslation();

  const creditTermsTabs = [
    { key: 'creditTerms', comp: <CreditTerms /> },
    { key: 'products', comp: <Products /> },
    { key: 'partnerCommissions', comp: <PartnerCommissions /> }
  ];

  return (
    <div className="credit-terms-wrapper">
      <Text as="h2" category="display">
        {t('admin.dashboard.cards.platformCondition.heading')}
      </Text>
      <Tabs>
        {creditTermsTabs.map((tab, index) => {
          return (
            <TabContent title={`${t(`admin.dashboard.cards.tabs.${tab.key}.heading`)}`} key={index}>
              {tab.comp}
            </TabContent>
          );
        })}
      </Tabs>
    </div>
  );
};

export default GlobalVariables;
