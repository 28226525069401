import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map from '../../../../components/Map/Map';
import ProjectLogs from '../../../../components/ProjectLog/ProjectLogs';
import Text from '../../../../components/Text/Text';
import {
  AdminProjectOverview,
  GetProjectById,
  NewProjectLog,
  ProjectLogType,
  SelectedFile
} from '../../../../store/project/project.types';
import { formatDateWithTime } from '../../../../utils/formatting.utils';
import '../Project.scss';
import InfoField from '../../../../components/InfoField/InfoField';
import { useModal } from '../../../../hooks/useModal';
import Modal from '../../../../components/Modal/Modal';
import arrowRight from '../../../../assets/Icons/arrow-right-green.svg';
import KremoTables from './KremoTables/KremoTables';
import ComplianceCheck from './ComplianceCheck/ComplianceCheck';
import DataConfirmation from './DataConfirmation/DataConfirmation';
import ExperianResponse from './ExperianResponse/ExperianResponse';
import FinancialValuationTable from './FinancialValuationTable/FinancialValuationTable';
import UserProjectData from './UserProjectData/UserProjectData';
import ZekTable from './ZekTable/ZekTable';
import './ProjectReview.scss';
import CreditOfferTable from './CreditOfferTable/CreditOfferTable';
import CommentPreview from './CommentPreview/CommentPreview';
import UploadFile from '../../../UploadOffer/UploadFile/UploadFile';
import UploadedFileDetails from '../../../../components/File/UploadedFileDetails/UploadedFileDetails';
import {
  downloadLogsFile,
  getFourEyeProjectDetails,
  getFourEyeProjectLogs,
  uploadFourEyeCheckDocument
} from '../../../../store/fourEyeCheck/fourEyeCheck.action';
import { useParams } from 'react-router-dom';
import {
  FourEyeCheckStatus,
  FourEyeProjectDetails
} from '../../../../store/fourEyeCheck/fourEyeCheck.types';
import KycPersonalDetailsTable from '../KycPersonalDetailsTable/KycPersonalDetailsTable';
import PersonalDetailsTable from '../PersonalDetailsTable/PersonalDetailsTable';
import Loader from '../../../../components/Loader/Loader';
import { formatFilePath } from '../../../../utils/general.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import { User, UserRoles } from '../../../../store/user/types';
import { toastUtil } from '../../../../utils/toast.utils';
import Admin4EyeReview from './Admin4EyeReview/Admin4EyeReview';
import { ErrorCode } from '../../../../store/error/error.type';
import { reviewersLastLog } from '../../../../utils/fourEyeCheckHelper.utils';
import { downloadOfferDocument } from '../../../../store/offers/offers.action';
import ReviewInstructions from './ReviewInstructions/ReviewInstructions';
import FileSelectorUpload from '../../../../components/File/FileSelectorUpload/FileSelectorUpload';
import CommentSubmission from './CommentSubmision/CommentSubmission';

export enum ModalTitle {
  KREMO = 'kremo',
  EXPERIAN = 'experian',
  DATA_CONFIRMATION_CONFIRM = 'dataConfirmationConfirm',
  DATA_CONFIRMATION_REJECT = 'dataConfirmationReject',
  COMPLIANCE_CHECK = 'complianceCheck',
  ZEK = 'zek',
  FINANCIAL_DATA_VALUATION = 'financialDataValuation',
  KYC_PERSONAL_DETAILS = 'kycPersonalDetails',
  CREDIT_OFFER = 'creditOffer',
  PERSONAL_DETAILS = 'personalDetails',
  COMMENT = 'comment'
}

interface ProjectReviewProps {
  project: AdminProjectOverview;
  nplProject?: GetProjectById;
}

const ProjectReview = ({ project, nplProject }: ProjectReviewProps) => {
  const { t } = useTranslation();

  const { isOpened, toggle } = useModal();
  const { projectUuid } = useParams();

  const loggedUser = useSelector<RootState>((state) => state.userReducer) as User;

  const [loading, setLoading] = useState(false);

  const [modalTitle, setModalTitle] = useState<ModalTitle>();

  const [selectedComment, setSelectedComment] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);

  const [projectData, setProjectData] = useState<FourEyeProjectDetails>({});
  const [newLog, setNewLog] = useState<NewProjectLog>({
    approved: false,
    comment: '',
    fileNames: []
  });

  const projectStatus = projectData.projectDetails?.currentState;

  useEffect(() => {
    projectUuid && getProjectDetailsById(projectUuid);
  }, [projectUuid]);

  const reviewerAlreadyApproved = () =>
    !!projectData.projectLogs?.filter(
      (log) =>
        log.partyEmail === loggedUser.email &&
        log.logType === ProjectLogType.FOUR_EYE_CHECK_APPROVED
    ).length;

  const getAllProjectDetails = async (projectId: string) => {
    const initialProject: FourEyeProjectDetails = {
      projectLogs: undefined,
      projectDetails: undefined,
      fourEyeCheckId: undefined,
      offerId: undefined
    };

    initialProject.fourEyeCheckId = nplProject?.project.fourEyeCheckId;
    initialProject.offerId = nplProject?.projectDto.offer?.id;
    if (initialProject.fourEyeCheckId) {
      initialProject.projectDetails = await getFourEyeProjectDetails(initialProject.fourEyeCheckId);
    }
    initialProject.projectLogs = await getFourEyeProjectLogs(projectId);

    return initialProject;
  };

  const getProjectDetailsById = async (projectId: string) => {
    setLoading(true);
    const projectData = await getAllProjectDetails(projectId);
    setProjectData(projectData);
    setLoading(false);
  };

  const openModal = (modalTitle: ModalTitle) => {
    setModalTitle(modalTitle);
    toggle();
  };

  const closeModal = () => {
    setModalTitle(undefined);
    toggle();
  };

  const addFileLog = async (files: FileList | null) => {
    if (files && files.length > 0 && files[0].name) {
      const newSelectedFile: SelectedFile = {
        fileName: files[0].name,
        file: files[0],
        disabled: false
      };
      setSelectedFiles((prev) => [...prev, newSelectedFile]);
    }
  };

  const renderModalContent = () => {
    switch (modalTitle) {
      case ModalTitle.KREMO:
        return (
          <KremoTables
            toggle={toggle}
            kremoData={projectData.projectDetails?.creditCheckData?.kremoData}
          />
        );
      case ModalTitle.EXPERIAN:
        return (
          <ExperianResponse
            experianReasons={
              projectData.projectDetails?.creditCheckData?.experianData?.experianReasonCodes
            }
            toggle={toggle}
          />
        );
      case ModalTitle.DATA_CONFIRMATION_CONFIRM:
      case ModalTitle.DATA_CONFIRMATION_REJECT:
        return (
          <DataConfirmation
            setNewLog={setNewLog}
            getProjectDetails={getProjectDetailsById}
            fourEyeCheckId={projectData.fourEyeCheckId}
            toggle={toggle}
            fourEyeCheckStatus={projectStatus}
            newLog={newLog}
          />
        );
      case ModalTitle.COMPLIANCE_CHECK:
        return (
          <ComplianceCheck
            complianceCheckData={projectData.projectDetails?.creditCheckData?.complianceCheckData}
            toggle={toggle}
          />
        );
      case ModalTitle.ZEK:
        return (
          <ZekTable
            toggle={toggle}
            zekData={projectData.projectDetails?.creditCheckData?.zekData}
          />
        );
      case ModalTitle.FINANCIAL_DATA_VALUATION:
        return (
          <FinancialValuationTable
            toggle={toggle}
            financialValuationData={
              projectData.projectDetails?.creditCheckData?.financialValuationData
            }
          />
        );
      case ModalTitle.KYC_PERSONAL_DETAILS:
        return (
          <KycPersonalDetailsTable toggle={toggle} projectDetails={projectData.projectDetails} />
        );
      case ModalTitle.CREDIT_OFFER:
        return (
          <CreditOfferTable
            toggle={toggle}
            creditOffer={projectData.projectDetails?.creditCheckData?.creditOffer}
            projectDetails={nplProject}
          />
        );
      case ModalTitle.PERSONAL_DETAILS:
        return <PersonalDetailsTable userId={nplProject?.project.userId} toggle={toggle} />;
      case ModalTitle.COMMENT:
        return (
          <CommentPreview
            toggle={toggle}
            comment={projectData.projectLogs?.[selectedComment]}
            previousButton={selectedComment === 0}
            nextButton={selectedComment === (projectData.projectLogs?.length as number) - 1}
            setSelectedComment={setSelectedComment}
          />
        );
      default:
        return <></>;
    }
  };

  const removeSelectedFile = (fileName: SelectedFile) => {
    setSelectedFiles((prev) => prev.filter((elem) => elem.fileName !== fileName.fileName));
  };

  const submitSelectedFile = async (file: SelectedFile) => {
    if (!projectData.fourEyeCheckId) return;
    try {
      await uploadFourEyeCheckDocument(projectData.fourEyeCheckId, file.file);
      setNewLog((prevLog) => ({
        ...prevLog,
        fileNames: [...prevLog.fileNames, file.fileName]
      }));
      setSelectedFiles((prev) =>
        prev.map((elem) => (elem.fileName === file.fileName ? { ...elem, disabled: true } : elem))
      );
      toastUtil('success', t('uploadSuccessful'));
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.response.data.message.includes(ErrorCode.FILE_ALREADY_EXISTS)) {
        toastUtil('error', t('errorCode.FILE_ALREADY_EXISTS'));
        return;
      }
      toastUtil('error', error.message);
    }
  };

  const fourEyeCheckFinished = () =>
    projectStatus === FourEyeCheckStatus.APPROVED || projectStatus === FourEyeCheckStatus.DECLINED;

  const loggedUserCanMakeDecision = () =>
    !fourEyeCheckFinished() &&
    projectData.projectLogs &&
    !reviewersLastLog(projectData.projectLogs, loggedUser.email) &&
    !reviewerAlreadyApproved() &&
    loggedUser.role?.includes(UserRoles.REVIEWER) &&
    !(projectStatus === FourEyeCheckStatus.TO_BE_REVIEWED_BY_SUPER_ADMIN);

  const superAdminCanMakeDecision = () =>
    projectStatus === FourEyeCheckStatus.TO_BE_REVIEWED_BY_SUPER_ADMIN &&
    loggedUser.role?.includes(UserRoles.SUPER_ADMIN);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="project-review">
            <Text as="h2" category="headline">
              {t('admin.projectDetails.title')}
            </Text>
            <div className="project-details">
              <Map
                initialCordinates={{ lat: project.latitude, lng: project.longitude }}
                hideControls
                className="project-details-map"
              />
              <div className="user-info">
                <div className="user-info-row">
                  <InfoField variety={2} label={t('admin.projectDetails.project')}>
                    {project.id}
                  </InfoField>
                  <InfoField variety={2} label={t('customerNumber')}>
                    {project.customerId}
                  </InfoField>
                  <InfoField
                    variety={2}
                    label={t('admin.projects.labels.customerName')}
                    buttonIcon={arrowRight}
                    onClick={() => {
                      openModal(ModalTitle.PERSONAL_DETAILS);
                    }}>
                    {project.customerName}
                  </InfoField>
                  <InfoField
                    variety={2}
                    label={t('admin.projects.labels.requestLoanAmount')}
                    buttonIcon={arrowRight}
                    onClick={() => {
                      openModal(ModalTitle.CREDIT_OFFER);
                    }}>
                    {project.offeredPrice}
                  </InfoField>
                </div>
                <div className="user-info-row">
                  <InfoField variety={2} label={t('admin.projects.labels.projectAddress')}>
                    {project.projectAddress}
                  </InfoField>
                  {project.customerAddress && (
                    <InfoField variety={2} label={t('admin.projectDetails.residentalAddress')}>
                      {project.customerAddress}
                    </InfoField>
                  )}
                  <InfoField
                    variety={2}
                    label={t('admin.projectDetails.offerRequested')}
                    buttonIcon={arrowRight}
                    onClick={() => {
                      projectData.offerId && downloadOfferDocument(projectData.offerId);
                    }}>
                    {formatDateWithTime(project.offerTime)}
                  </InfoField>
                </div>
              </div>
            </div>
          </div>
          <UserProjectData openModal={openModal} projectDetails={projectData.projectDetails} />
          <Text as="h2" category="headline" className="project-review-heading">
            {t('admin.projectDetails.history')}
          </Text>
          <div className="project-log">
            <div>
              <Text as="h2" category="headline">
                {t('admin.projectDetails.course')}
              </Text>
              {!!projectData.projectLogs?.length && (
                <ProjectLogs
                  logs={projectData.projectLogs}
                  onClick={(log) => {
                    setSelectedComment(log);
                    setModalTitle(ModalTitle.COMMENT);
                    toggle();
                  }}
                />
              )}
            </div>
            {!!projectData.projectLogs?.some((log) => log.files?.length) && (
              <div className="project-review-uploaded-files">
                <Text as="h2" category="headline">
                  {t('admin.projectDetails.log')}
                </Text>
                {projectData.projectLogs.map(
                  (elem, index) =>
                    elem?.files &&
                    elem.files.map((file, innerIndex) => (
                      <UploadedFileDetails
                        key={`${index}-${innerIndex}`}
                        fileName={formatFilePath(file)}
                        fileAuthor={elem.partyName}
                        uploadedFileTime={elem.date}
                        onClick={() => {
                          projectUuid && downloadLogsFile(projectUuid, file);
                        }}
                      />
                    ))
                )}
              </div>
            )}
          </div>
          <ReviewInstructions />
          <div className="new-log-wrapper">
            {loggedUserCanMakeDecision() && (
              <>
                <CommentSubmission setNewLog={setNewLog} />
                <div>
                  <Text as="h2" category="headline">
                    {t('admin.projectDetails.projectReview.documentUpload')}
                  </Text>
                  <UploadFile handleChangeFiles={addFileLog} acceptFileType="pdf" />
                  {!!selectedFiles.length &&
                    selectedFiles.map((file, ind) => (
                      <FileSelectorUpload
                        key={ind}
                        name={file.fileName}
                        disable={file.disabled}
                        onDelete={() => {
                          removeSelectedFile(file);
                        }}
                        onUpload={() => {
                          submitSelectedFile(file);
                        }}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
          {(loggedUserCanMakeDecision() || superAdminCanMakeDecision()) && projectStatus && (
            <Admin4EyeReview
              setNewLog={setNewLog}
              openModal={openModal}
              fourEyeCheckStatus={projectStatus}
            />
          )}
          {modalTitle && (
            <Modal
              title={t(`admin.projectDetails.projectReview.modalTitles.${modalTitle}`, {
                experianStatus:
                  projectData.projectDetails?.creditCheckData?.experianData?.systemDecision
              })}
              isOpened={isOpened}
              close={closeModal}>
              {renderModalContent()}
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default ProjectReview;
