import { NavLink } from 'react-router-dom';
import Text from '../Text/Text';
import Icon, { icons } from '../Icon/Icon';
import './Link.scss';
import { ReactNode } from 'react';

interface LinkProps {
  to?: string;
  href?: string;
  variation: 'primary' | 'secondary' | 'tertiary';
  children?: ReactNode;
  dataTest: string;
  icon?: keyof typeof icons;
  disabled?: boolean;
  iconPosition?: 'right';
  reload?: boolean;
  onClick?: () => void;
}

const Link = ({
  variation,
  to,
  children,
  icon,
  disabled,
  iconPosition,
  dataTest,
  reload = false,
  onClick,
  href
}: LinkProps) => {
  const getLinkClassName = () =>
    `nav-link ${variation} ${disabled ? 'disabled' : ''} ${iconPosition ? 'right' : ''}`;

  return to ? (
    <NavLink
      to={to}
      className={getLinkClassName()}
      data-test={dataTest}
      onClick={onClick}
      reloadDocument={reload}>
      {icon && <Icon name={icon} />}
      <Text as="span" category="label" size="large" variety={1}>
        {children}
      </Text>
    </NavLink>
  ) : (
    <a href={href} className={getLinkClassName()} target="_blank" rel="noopener noreferrer">
      <Text as="span" category="label" size="large" variety={1}>
        {children}
      </Text>
      {variation === 'tertiary' && <Icon name="arrow-right" />}
    </a>
  );
};

export default Link;
