import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import './ResetFilter.scss';
import Text from '../Text/Text';

interface ResetFilterProps {
  onClick: () => void;
}

const ResetFilter = ({ onClick }: ResetFilterProps) => {
  const { t } = useTranslation();

  return (
    <button onClick={onClick} className="reset-filter-wrapper">
      <Icon name="close" />
      <Text as="span" category="label" size="large">
        {t('resetAllFilters')}
      </Text>
    </button>
  );
};

export default ResetFilter;
