import { useTranslation } from 'react-i18next';
import DownloadIcon from '../../../../../assets/Icons/download.svg';
import './PartnerProjectInfo.scss';
import Text from '../../../../../components/Text/Text';
import Button from '../../../../../components/Button/Button';
import InfoField from '../../../../../components/InfoField/InfoField';
import StatusField from '../../../../../components/StatusField/StatusField';
import UserInfoBox from '../../../../../components/UserInfoBox/UserInfoBox';
import { downloadOfferDocument } from '../../../../../store/offers/offers.action';
import { PartnerProjectData } from '../../../../../store/partners/partners.types';
import { toSwissFrancNotation } from '../../../../../utils/formatting.utils';
import Map from '../../../../../components/Map/Map';

interface PartnerProjectInfoProps {
  data: PartnerProjectData;
}

const PartnerProjectInfo = ({ data }: PartnerProjectInfoProps) => {
  const { t } = useTranslation();

  return (
    <div className="partner-project-info">
      <Text as="h3" category="headline" size="large">
        {t('projectDetails.label')}
      </Text>
      <Map
        initialCordinates={{ lat: data.address.latitude, lng: data.address.longitude }}
        hideControls
        className="map-container"
      />
      <StatusField
        status={t(`offerStatus.${data.status}`)}
        label={t('partnerProjectDetails.offerStatus')}
        style={data.status?.toLowerCase()}
      />
      <UserInfoBox
        id={data.id}
        name={data.contact.name}
        date={data.date}
        address={data.address.address}
      />
      <div className="partner-project-space">
        <InfoField
          className="field"
          label={t('offers.offerDetails.pvSystem.label')}
          tooltipText={{
            heading: t('offers.offerDetails.pvSystem.tooltip.heading'),
            text: t('offers.offerDetails.pvSystem.tooltip.text')
          }}
          disabled={false}
          variety={2}>
          {`${data.area} ${t('units.meterSqared')}`}
        </InfoField>
        <InfoField
          className="field"
          label={t('offer.plantPower')}
          tooltipText={{
            heading: t('offers.offerDetails.plantPower.tooltip.heading'),
            text: t('offers.offerDetails.plantPower.tooltip.text')
          }}
          disabled={false}
          variety={2}>
          {`${data.performance} ${t('units.kilowattPerformance')}`}
        </InfoField>
      </div>
      <InfoField
        className="field"
        label={t('offers.offerDetails.offerPrice.label')}
        tooltipText={{
          heading: t('offers.offerDetails.offerPrice.tooltip.heading'),
          text: t('offers.offerDetails.offerPrice.tooltip.text')
        }}
        disabled={false}
        variety={2}>
        {toSwissFrancNotation(data.price)}
      </InfoField>
      <div className="partner-project-action-wrapper">
        <Button
          size="large"
          variation="primary"
          dataTest=""
          icon={DownloadIcon}
          iconPosition="left"
          onClick={() => {
            downloadOfferDocument(data.offerId);
          }}>
          {t('offers.contract.buttonText')}
        </Button>
        {/* {data.acceptanceReportUrl && (
          <Button
            size="large"
            variation="primary"
            dataTest=""
            icon={DownloadIcon}
            iconPosition="left"
            onClick={() => {
              downloadAcceptanceReport(data.id);
            }}>
            {t('downloadAcceptanceReport')}
          </Button>
        )} */}
      </div>
    </div>
  );
};

export default PartnerProjectInfo;
