import { KeycloakProfile } from 'keycloak-js';
import { Sort } from '../../types/util.types';
import { UserRoles } from '../user/types';
import { Address } from '../questionnaire/questionnaire.types';

export interface Profile {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  address: Address;
  kycStatus: KycStatus;
  bankName: string;
  iBAN: string;
  name?: string;
  phoneNumber?: string;
  bic?: string;
  userUuid?: string;
  partnerId?: number;
}

export interface UserDto {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRoles[];
}
export interface Profile {
  user: UserDto;
  profile: Profile;
}

export interface CreateProfile {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRoles[];
}

export interface UpdateProfile {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: Address;
  kycStatus: KycStatus;
  bankDetails: BankDetailsType;
}

export enum KycStatus {
  KYC_OPEN = 'KYC_OPEN',
  KKG_FINAL = 'KKG_FINAL',
  AML_FINAL = 'AML_FINAL',
  FIRST_CHECK_SUCCESS = 'FIRST_CHECK_SUCCESS',
  FIRST_CHECK_FAIL = 'FIRST_CHECK_FAIL',
  SECOND_CHECK_SUCCESS = 'SECOND_CHECK_SUCCESS',
  SECOND_CHECK_FAIL = 'SECOND_CHECK_FAIL',
  KYC_SUCCESS = 'KYC_SUCCESS',
  KYC_FAIL = 'KYC_FAIL',
  ADMONISHED = 'ADMONISHED'
}

export interface BankDetailsType {
  bankName: string;
  iBAN: string;
}

export interface ProfilesList {
  profiles: Profile[];
  size: number;
  totalPages: number;
  totalItems: number;
  pageNumber: number;
  empty: boolean;
}

export interface GetProfiles {
  size: number;
  page: number;
  sort: Sort[];
  keyword?: string;
  status?: KycStatus;
}

export interface Message {
  id: number;
  email: string;
  subject: string;
  plainContent: string;
  sentTimestamp: string;
  values: string;
}

export interface MessagesList {
  messages: Message[];
  size: number;
  totalPages: number;
  totalItems: number;
  pageNumber: number;
  empty: boolean;
}
export interface Contact {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
}

export interface InvProfile extends KeycloakProfile {
  attributes: {
    ROLE_: UserRoles[];
  };
}
