import { User, UserActionTypes, UserActions } from '../store/user/types';

const initialState: User = {
  uid: '',
  id: undefined,
  firstName: '',
  lastName: '',
  kycStatus: undefined,
  email: '',
  phoneNumber: '',
  role: undefined,
  emailVerified: false,
  hasError: false,
  twoFactorAuth: false,
  address: undefined
};

const userReducer = (state: User = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case UserActions.LOGIN:
      return action.payload;
    case UserActions.LOGOUT:
      return initialState;
    case UserActions.VERIFY_MAIL:
      return { ...state, emailVerified: true };
    case UserActions.SET_TWO_FACTOR_AUTH:
      return { ...state, twoFactorAuth: action.payload };
    case UserActions.UPDATE_USER:
      return { ...state, ...action.payload };
    case UserActions.SET_ERROR:
      return { ...state, hasError: true };
    default:
      return state;
  }
};

export default userReducer;
