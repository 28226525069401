import './SelectPartnerModal.scss';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, useState } from 'react';
import MapsContent from './MapsContent/MapsContent';
import { ProjectActionTypes } from '../../../store/project/project.reducer';
import { Project } from '../../../store/project/project.types';
import { DeepPartial } from '../../../types/util.types';

interface SelectPartnerModalProps {
  project: DeepPartial<Project>;
  dispatch: Dispatch<ProjectActionTypes>;
  createProject: () => void;
  toggle: () => void;
}

const SelectPartnerModal = ({
  project,
  dispatch,
  createProject,
  toggle
}: SelectPartnerModalProps) => {
  const { t } = useTranslation();

  const [partnerSelected, setPartnerSelected] = useState(true);

  return (
    <div className="modal-wrapper">
      <MapsContent project={project} isPartnerSelected={setPartnerSelected} dispatch={dispatch} />
      <div className="steps-wrapper">
        <Button
          dataTest="request_offer-partners-back-button"
          variation="secondary"
          size="medium"
          onClick={toggle}>
          {t('back')}
        </Button>
        <Button
          dataTest="request_offer-partners-next-button"
          variation="primary"
          size="medium"
          disabled={!partnerSelected}
          onClick={createProject}>
          {t('next')}
        </Button>
      </div>
    </div>
  );
};

export default SelectPartnerModal;
